import { TableHeadProps } from "../../../../../../app/types/props";

export const tableHeadData: TableHeadProps = [
  {
    id: 0,
    title: "adminSection.adminAccountsPage.tableHeader.username",
    filteringInfo: "username",
  },
  {
    id: 1,
    title: "adminSection.adminAccountsPage.tableHeader.userType",
    filteringInfo: "userAccountType",
  },
  {
    id: 2,
    title: "adminSection.adminAccountsPage.tableHeader.groups",
    filteringInfo: "groups",
  },
  {
    id: 3,
    title: "adminSection.adminAccountsPage.tableHeader.email",
    filteringInfo: "email",
  },
  {
    id: 4,
    title: "adminSection.adminAccountsPage.tableHeader.createdOn",
    filteringInfo: "createdOn",
  },
  {
    id: 5,
    title: "adminSection.adminAccountsPage.tableHeader.modifiedOn",
    filteringInfo: "modifiedOn",
  },
  {
    id: 6,
    title: "adminSection.adminAccountsPage.tableHeader.modifiedBy",
    filteringInfo: "modifiedBy",
  },
  {
    id: 7,
    title: "adminSection.adminAccountsPage.tableHeader.mechanical",
    filteringInfo: "mechanical",
  },
];
