import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { StatusEnum } from "../../../../app/types/enums";

import { useFetch } from "../../common/hooks/useFetch";

import {
  downloadNewTemplate,
  uploadFile,
  downloadResult,
} from "./bulkImportUserAccountsAPI";

type BulkImportUserAccountsInitialState = {
  status: StatusEnum;
};

const initialState: BulkImportUserAccountsInitialState = {
  status: StatusEnum.Idle,
};

export const downloadNewTemplateThunk = createAsyncThunk(
  "downloadNewTemplateThunk",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await downloadNewTemplate(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadFileThunk = createAsyncThunk(
  "uploadFileThunk",
  async (payload: any, { rejectWithValue }) => {
    try {
      const _uploadFile = await useFetch();
      const response = await _uploadFile({
        data: payload,
        fetchFunction: uploadFile,
        customSuccessMsg: "adminSection.common.defaultSuccessMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadResultThunk = createAsyncThunk(
  "downloadResult",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await downloadResult(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bulkImportUserAccountsSlice = createSlice({
  name: "bulkImportUserAccounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadFileThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(uploadFileThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(uploadFileThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      });
  },
});

export const bulkImportUserAccountsSelector = (state: RootState) =>
  state.bulkImportUserAccounts;

export default bulkImportUserAccountsSlice.reducer;
