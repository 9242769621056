import { IncomeTypeDetail } from "../types/props";

export const getPubIncomeTypeDetails = (type: string): IncomeTypeDetail => {
  switch (type) {
    case "perfo":
    case "perf":
      return { color: "#ff00ff", order: 0 };
    case "digit":
    case "digital":
      return { color: "#F8E71C", order: 1 };
    case "mecha":
    case "mech":
      return { color: "#7ED321", order: 2 };
    case "synch":
      return { color: "#F5A623", order: 3 };
    case "other":
      return { color: "#4A90E2", order: 4 };
    default:
      return { color: "black", order: 5 };
  }
};

export const getRecIncomeTypeDetails = (
  channelCode: string
): IncomeTypeDetail => {
  switch (channelCode) {
    case "PHYSICAL":
    case "physical":
      return { color: "#4A90E2", order: 0 };
    case "DIGITAL":
    case "digital":
      return { color: "#F8E71C", order: 1 };
    case "LICENSING":
    case "licensing":
      return { color: "#7ED321", order: 2 };
    case "SYNCHRONISATION":
    case "synchronisation":
      return { color: "#F5A623", order: 3 };
    case "PUBLIC_PERFORMANCE":
    case "performance":
      return { color: "#ff00ff", order: 4 };
    default:
      return { color: "black", order: 5 };
  }
};

export const getTranslationForChart = (type: any, t: any) => {
  switch (type.toLowerCase()) {
    case "perfo":
    case "perf":
      return t(`analysis.territories.mainIncType.perf`);
    case "digit":
      return t(`analysis.territories.mainIncType.digit`);
    case "mecha":
    case "mech":
      return t(`analysis.territories.mainIncType.mech`);
    case "synch":
      return t(`analysis.territories.mainIncType.synch`);
    case "other":
      return t(`analysis.territories.mainIncType.other`);
    case "physical":
      return t(`recChart.physical`);
    case "digital":
      return t(`recChart.digital`);
    case "licensing":
      return t(`recChart.licensing`);
    case "synchronisation":
      return t(`recChart.synchronisation`);
    case "public_performance":
    case "performance":
      return t(`recChart.performance`);
    case "merchandise":
      return t(`recChart.merchandise`);
  }
};
