import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import { GetLoginEmailPayload } from "../../../app/types/payload";
import request from "../../../app/utils/requestUtils";

export function fetchOktaGroupAdd(payload: string, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.usersOktaGroupAdd}`, {
    method: "PUT",
    body: payload,
    signal: thunkAPI?.signal,
  });
}

export function fetchOktaGroupRemove(payload: string, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.usersOktaGroupRemove}`, {
    method: "PUT",
    body: payload,
    signal: thunkAPI?.signal,
  });
}

export function fetchGetLoginEmail(
  payload: GetLoginEmailPayload,
  thunkAPI?: any
) {
  return request(`${USER_API_RESOURCE_BASE_URLS.usersGetLogin}`, {
    method: "PUT",
    body: JSON.stringify(payload),
    signal: thunkAPI?.signal,
  });
}

export function fetchOktaUnEnrollUser(payload: string, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.usersOktaUnenroll}`, {
    method: "PUT",
    body: payload,
    signal: thunkAPI?.signal,
  });
}
