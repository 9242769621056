import { memo } from "react";
import deepCompare from "../../../app/utils/deepCompare";
import Dropdown from "../../atom/Dropdown";
import { DropdownVariantEnum } from "../../atom/Dropdown/Dropdown";
import { SelectedClientIcon, TrashIcon } from "../../atom/Icon";
import { useEffect, useState } from "react";
import styles from "./selectClientsBtn.module.scss";
import { useTranslation } from "react-i18next";
import CurrencyEurBGIcon from "../../atom/Icon/CurrencyEurBGIcon";
import CurrencyUsdBGIcon from "../../atom/Icon/CurrencyUsdBGIcon";
import CurrencyGbpBGIcon from "../../atom/Icon/CurrencyGbpBGIcon";
import CurrencySekBGIcon from "../../atom/Icon/CurrencySekBGIcon";
import CurrencyHkdBGIcon from "../../atom/Icon/CurrencyHkdBGIcon";
import CurrencyCnyBGIcon from "../../atom/Icon/CurrencyCnyBGIcon";
import CurrencyBrlBGIcon from "../../atom/Icon/CurrencyBrlBGIcon";
import CurrencyCadBGIcon from "../../atom/Icon/CurrencyCadBGIcon";
import CurrencyAudBGIcon from "../../atom/Icon/CurrencyAudBGIcon";

export type SelectClientsProps = {
  clientLists?: [];
  selectedClientIds: number[] | string[];
  className?: string;
  disabled?: boolean;
  onSelectClientList: (selectedValue: string) => void;
  onDeleteClientList: (listIdToBeDeleted: number) => void;
};

function SelectClientsBtn(props: SelectClientsProps): JSX.Element {
  const {
    clientLists,
    selectedClientIds,
    className,
    disabled = false,
    onSelectClientList,
    onDeleteClientList,
  } = props;

  const [selectClientOptions, setSelectClientOptions] = useState<Array<any>>(
    []
  );
  const { t } = useTranslation();

  const selectClientsText = t("clientSelection.selectClientsOption");

  useEffect(() => {
    const deleteList = (listIdToBeDeleted: number) => (e: React.MouseEvent) => {
      e.stopPropagation();
      onDeleteClientList(Number(listIdToBeDeleted));
    };

    const getDropdownMenuOptions = (): Array<any> => {
      const optionsArray: any[] = [];

      optionsArray.push({
        label: selectClientsText,
        value: "",
        type: "",
        iconLeft: (
          <i className={styles.selectClientsOptionIcon}>{SelectedClientIcon}</i>
        ),
        iconRight: null,
        className: "clientsSelectionSelectClients_GTM",
      });

      const getCurrencyIcon = (currencyId: string) => {
        switch (currencyId) {
          case "EUR":
            return CurrencyEurBGIcon;
          case "USD":
            return CurrencyUsdBGIcon;
          case "GBP":
            return CurrencyGbpBGIcon;
          case "SEK":
            return CurrencySekBGIcon;
          case "HKD":
            return CurrencyHkdBGIcon;
          case "CNY":
            return CurrencyCnyBGIcon;
          case "BRL":
            return CurrencyBrlBGIcon;
          case "AUD":
            return CurrencyAudBGIcon;
          case "CAD":
            return CurrencyCadBGIcon;
        }
      };

      clientLists?.forEach((clientList: any) => {
        const deleteBtn = (
          <i className={styles.deleteBtn} onClick={deleteList(clientList.id)}>
            <TrashIcon />
          </i>
        );
        const currencyIcon = getCurrencyIcon(clientList.currency);
        optionsArray.push({
          label: clientList.name,
          value: clientList.id,
          type: clientList.type,
          iconLeft: currencyIcon,
          iconRight: deleteBtn,
        });
      });
      return optionsArray;
    };

    setSelectClientOptions(getDropdownMenuOptions());
  }, [onDeleteClientList, clientLists, selectClientsText]);

  return (
    <Dropdown
      id="selectClients"
      variant={DropdownVariantEnum.buttonDropdownComplex}
      options={selectClientOptions}
      disabled={disabled}
      text={t("clientSelection.selectClientsBtn") || ""}
      selectedValues={selectedClientIds}
      onSelectOption_Callback={onSelectClientList}
      className={`${styles.selectClientsBtn} ${className}`}
    />
  );
}

export default memo(
  SelectClientsBtn,
  (prevProps: SelectClientsProps, nextProps: SelectClientsProps) =>
    deepCompare(prevProps, nextProps)
);
