import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import { downloadAnalysisData, fetchAnalysisChannel } from "./recAnalysisAPI";

export type TabChannelAnalyzeByMainIncomeTypesEntry = {
  categoryName: string;
  color: string;
  mainIncType: string;
  revenue: {
    currency: string;
    formattedLong: string;
    formattedPercent: string;
    formattedShort: string;
    percent: number;
    raw: number;
  };
};

export type TabChannelIncomeTypeEntry = {
  category: string;
  code: number;
  currency: string;
  mainIncType: string;
  name: string;
  raw: number;
  royalties: string;
};

export interface AnalysisState {
  tabChannel: {
    pageState: {
      data: {
        clientIds: any;
        clientLists: any;
        periodIds: any;
      };
    };
    chartStatus: "noData" | "loading" | "idle" | "failed";
    chartData: any;
    detailsData: any[];
    downloadStatus: "loading" | "idle" | "failed";
    noDataFlag?: "" | "noData" | "noPeriodData";
  };
  tabAlbum: {
    downloadStatus: "loading" | "idle" | "failed";
  };
}

const initialState: AnalysisState = {
  tabChannel: {
    pageState: {
      data: {
        clientIds: [],
        clientLists: [],
        periodIds: [],
      },
    },
    chartStatus: "noData",
    chartData: null,
    detailsData: [],
    downloadStatus: "idle",
  },
  tabAlbum: {
    downloadStatus: "idle",
  },
};

export const fetchRecAnalysisChannelThunk = createAsyncThunk(
  "recAnalysis/channel",
  async (payload: any, thunkAPI) => {
    const response = await fetchAnalysisChannel(payload, thunkAPI);
    return response;
  }
);

export const recAnalysisChannelDownload = createAsyncThunk(
  "recAnalysis/downloadChannel",
  async (payload: any, thunkAPI) => {
    const response = await downloadAnalysisData(payload, thunkAPI);
    return response;
  }
);

export const recAnalysisAlbumDownload = createAsyncThunk(
  "recAnalysis/downloadAlbum",
  async (payload: any, thunkAPI) => {
    const response = await downloadAnalysisData(payload, thunkAPI);
    return response;
  }
);

export const recAnalysisSlice = createSlice({
  name: "recAnalysis",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecAnalysisChannelThunk.fulfilled, (state, action: any) => {
        state.tabChannel.chartStatus = "idle";
        state.tabChannel.chartData = action.payload.analyzeByMainIncomeTypes;
        state.tabChannel.detailsData = action.payload.analyzeByMinorIncomeTypes;
        state.tabChannel.chartData.totalRoyalty = action.payload.total;
        state.tabChannel.noDataFlag =
          action.payload.total.raw === 0
            ? state?.tabChannel?.pageState?.data?.periodIds?.length === 1
              ? "noPeriodData"
              : "noData"
            : "";
      })
      .addCase(fetchRecAnalysisChannelThunk.pending, (state) => {
        state.tabChannel.chartStatus = "loading";
      })
      .addCase(fetchRecAnalysisChannelThunk.rejected, (state) => {
        state.tabChannel.chartStatus = "failed";
      })
      .addCase(recAnalysisChannelDownload.fulfilled, (state, action: any) => {
        state.tabChannel.downloadStatus = "idle";
      })
      .addCase(recAnalysisChannelDownload.pending, (state) => {
        state.tabChannel.downloadStatus = "loading";
      })
      .addCase(recAnalysisChannelDownload.rejected, (state) => {
        state.tabChannel.downloadStatus = "failed";
      })
      .addCase(recAnalysisAlbumDownload.fulfilled, (state, action: any) => {
        state.tabAlbum.downloadStatus = "idle";
      })
      .addCase(recAnalysisAlbumDownload.pending, (state) => {
        state.tabAlbum.downloadStatus = "loading";
      })
      .addCase(recAnalysisAlbumDownload.rejected, (state) => {
        state.tabAlbum.downloadStatus = "failed";
      });
  },
});

export const recAnalysisChannelSelector = (state: RootState) =>
  state.recAnalysis.tabChannel;
export const recAnalysisAlbumSelector = (state: RootState) =>
  state.recAnalysis.tabAlbum;
export const recAnalysisChannelNoDataFlagSelector = (state: RootState) =>
  state.recAnalysis.tabChannel.noDataFlag;

export default recAnalysisSlice.reducer;
