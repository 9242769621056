export const windowScrollToTop = () => {
  window?.scroll({
    top: 0,
    behavior: "smooth",
  });
};

export const windowScrollToBottom = () => {
  window?.scroll({
    top: document.body.offsetHeight,
    behavior: "smooth",
  });
};
