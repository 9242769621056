import PubSyncHOC from "./PubSyncHOC";
import withClientSelectionData from "../clientSelection/withClientSelectionData";
import {
  PubSyncTabStatusEnum,
  PubSyncTabsEnum,
} from "../../../app/types/enums";

const PubSyncAll = (): JSX.Element => (
  <PubSyncHOC
    status={PubSyncTabStatusEnum.all}
    selectedTab={PubSyncTabsEnum.all}
  />
);

export default withClientSelectionData(PubSyncAll);
