import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import { fetchContacts, fetchForms, downloadContactForms } from "./contactsAPI";

export interface ContactsState {
  contactsPageState: {
    documentType: string;
    filterText: string;
  };
  contactUsFormPageState: {
    isOpen: boolean;
  };
  contacts: {
    list: any[];
  };
  contactForms: any[];
  contactsStatus: "idle" | "loading" | "failed";
}

const initialState: ContactsState = {
  contactsPageState: {
    documentType: "",
    filterText: "",
  },
  contactUsFormPageState: {
    isOpen: false,
  },
  contacts: {
    list: [],
  },
  contactForms: [],
  contactsStatus: "idle",
};

export const fetchContactsThunk = createAsyncThunk(
  "mecContacts/contacts",
  async (payload: any, thunkAPI) => {
    const response = await fetchContacts(payload, thunkAPI);
    return response?.resArr;
  }
);

export const fetchFormsThunk = createAsyncThunk(
  "mecContacts/forms",
  async (payload: any, thunkAPI) => {
    const response = await fetchForms(payload, thunkAPI);
    return response?.resArr;
  }
);

export const mecContactFormsDownload = createAsyncThunk(
  "mecContacts/downloadForms",
  async (payload: any, thunkAPI) => {
    const response = await downloadContactForms(payload, thunkAPI);
    return response;
  }
);

export const mecContactsSlice = createSlice({
  name: "mecContacts",
  initialState,
  reducers: {
    updateContactsListPageState: (state, action: any) => {
      state.contactsPageState.documentType = action.payload.documentType;
      state.contactsPageState.filterText = action.payload.filterText;
    },
    updateContactUsFormPageState: (state, action: any) => {
      state.contactUsFormPageState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactsThunk.fulfilled, (state, action: any) => {
        state.contactsStatus = "idle";
        state.contacts.list = action.payload;
      })
      // download
      .addCase(mecContactFormsDownload.fulfilled, (state) => {
        state.contactsStatus = "idle";
      })
      .addMatcher(isAnyOf(fetchContactsThunk.pending), (state) => {
        state.contactsStatus = "loading";
      })
      .addMatcher(isAnyOf(fetchContactsThunk.rejected), (state) => {
        state.contactsStatus = "failed";
      })
      // download
      .addMatcher(isAnyOf(mecContactFormsDownload.pending), (state) => {
        state.contactsStatus = "loading";
      })
      .addMatcher(isAnyOf(mecContactFormsDownload.rejected), (state) => {
        state.contactsStatus = "failed";
      })
      //
      .addMatcher(isAnyOf(fetchContactsThunk.pending), (state) => {
        state.contactsStatus = "loading";
      })
      .addMatcher(isAnyOf(fetchContactsThunk.rejected), (state) => {
        state.contactsStatus = "failed";
      });
  },
});

export const contactsPageStateSelector = (state: RootState) =>
  state.mecContacts.contactsPageState;
export const contactUsFormPageStateSelector = (state: RootState) =>
  state.mecContacts.contactUsFormPageState;
export const contactsListSelector = (state: RootState) =>
  state.mecContacts.contacts;
export const contactsStatusSelector = (state: RootState) =>
  state.mecContacts.contactsStatus;
export const contactsFormsSelector = (state: RootState) =>
  state.mecContacts.contactForms;

export const {
  updateContactsListPageState: updateContactsListPageStateAction,
  updateContactUsFormPageState: updateContactUsFormPageStateAction,
} = mecContactsSlice.actions;

export default mecContactsSlice.reducer;
