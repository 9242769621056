import OktaAuth from "@okta/okta-auth-js";
import { getCookie, setCookie } from "./cookies";
import { OKTA_VAR } from "../config/okta";

const noMFACheck = ({ oktaAuth }: { oktaAuth: OktaAuth }) => {
  const enrollGACookie = getCookie("enrolGA");
  if (enrollGACookie === "started") {
    setCookie("enrolGA", "", 0);
    const authState = oktaAuth.authStateManager.getAuthState() || {};
    const { idToken } = authState;
    if (idToken) {
      const groups: any = idToken?.claims?.groups;
      if (Array.isArray(groups)) {
        const groupNoMFAIndex = groups?.findIndex(
          (group) => group === OKTA_VAR.MYBMG_NO_MFA_GROUP
        );
        const groupInternalUsersIndex = groups?.findIndex(
          (group) => group === OKTA_VAR.MYBMG_INTERNAL_USERS_GROUP
        );
        if (groupNoMFAIndex > -1 && groupInternalUsersIndex === -1) {
          return true;
        }
      }
    }
  }
  return false;
};

export default noMFACheck;
