import {
  RefObject,
  useCallback,
  forwardRef,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../atom/Button/Button";
import ChevronIcon from "../../../atom/Icon/ChevronIcon";
import { useDispatch, useSelector } from "react-redux";
import { ButtonVariantEnum } from "../../../types/enums";
import {
  FetchRecAnalysisTrackBreakdown_Trend_Thunk,
  FetchRecAnalysisTrackBreakdown_Territory_Thunk,
  FetchRecAnalysisTrackBreakdown_Source_Thunk,
  recAnalysisTrackBreakdown_Download_Thunk,
  FindRecAnalysisTrackBreakdown,
  recAnalysisTrackBreakdownTrendSelector,
  recAnalysisTrackBreakdownTerritorySelector,
  recAnalysisTrackBreakdownSourceSelector,
  recAnalysisTrackBreakdownDownloadStatusSelector,
} from "../../../../features/recording/analysis/track/recAnalysisTrackBreakdownSlice";
import Loader from "../../../atom/Loader";
import AnalysisTrackBreakdownSource from "./AnalysisTrackBreakdown_Source";
import AnalysisTrackBreakdownTerritory from "./AnalysisTrackBreakdown_Territory";
import AnalysisTrackBreakdownTrend from "./AnalysisTrackBreakdown_Trend";
import type {
  RecAnalysisTrackBreakdownProps,
  RecAnalysisTrackBreakdowTerritoryData,
} from "../../../../features/recording/analysis/track/recAnalysisTrackBreakdownSlice";
import styles from "../analysis.module.scss";
import { getNow, getToday } from "../../../utils/dateTime";
import { DownloadIcon } from "../../../atom/Icon";
import { PeriodSelector } from "../../periodSelector/periodSelectorSlice";
import debounce from "../../../utils/debounce";

export declare type AnalysisTrackBreakdownViewProps = {
  isSelected: boolean;
  track: {
    productKey: number;
    isrc: string;
    title: string;
    artist: string;
  };
  periodIds: Array<number>;
  clientIds: Array<number>;
  mobileView: boolean;
  handleClose?: (clickedSong: any) => void;
};

const AnalysisTrackBreakdown = forwardRef<
  HTMLDivElement | null,
  AnalysisTrackBreakdownViewProps
>((props) => {
  const { t } = useTranslation();
  const { clientIds, periodIds } = props;
  const isrc = props.track.isrc;
  const productKey = props.track.productKey || -1;

  const dispatch = useDispatch<any>();

  const [trendData, setTrendData] =
    useState<RecAnalysisTrackBreakdownProps | null>(null);
  const [territoryData, setTerritoryData] =
    useState<RecAnalysisTrackBreakdownProps | null>(null);
  const [sourceData, setSourceData] =
    useState<RecAnalysisTrackBreakdownProps | null>(null);

  const allPeriods = useSelector(PeriodSelector);

  const trackBreakdown_Trend = useSelector(
    recAnalysisTrackBreakdownTrendSelector
  );
  const trackBreakdown_Territories = useSelector(
    recAnalysisTrackBreakdownTerritorySelector
  );
  const trackBreakdown_Sources = useSelector(
    recAnalysisTrackBreakdownSourceSelector
  );

  const trackBreakdown_DownloadsStatus = useSelector(
    recAnalysisTrackBreakdownDownloadStatusSelector
  );

  const downloadFunction = useMemo(() => {
    return recAnalysisTrackBreakdown_Download_Thunk;
  }, []);

  const trendBreakdownPosRef = useRef<HTMLParagraphElement>(null);
  const territoryBreakdownPosRef = useRef<HTMLParagraphElement>(null);
  const sourceBreakdownPosRef = useRef<HTMLParagraphElement>(null);

  // dispatch refs
  const dispatchedTrend = useRef<any>();
  const dispatchedTerritory = useRef<any>();
  const dispatchedSource = useRef<any>();

  // abort functions
  const abortDispatchedTrend = useCallback(() => {
    if (dispatchedTrend.current) dispatchedTrend.current.abort();
  }, []);

  const abortDispatchedTerritory = useCallback(() => {
    if (dispatchedTerritory.current) dispatchedTerritory.current.abort();
  }, []);

  const abortDispatchedSource = useCallback(() => {
    if (dispatchedSource.current) dispatchedSource.current.abort();
  }, []);

  const currentParams = useMemo(() => {
    return {
      clientIds: props.clientIds,
      clientLists: [],
      isrc: props.track.isrc,
      productKey: props.track.productKey || -1,
      periodIds: props.periodIds,
    };
  }, [
    props.clientIds,
    props.track.isrc,
    props.track.productKey,
    props.periodIds,
  ]);

  const handleTrendFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedTrend();
        dispatchedTrend.current = dispatch(
          FetchRecAnalysisTrackBreakdown_Trend_Thunk(currentParams)
        );
      }, 500),
    [abortDispatchedTrend, dispatch]
  );

  const handleTerritoryFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedTerritory();
        dispatchedTerritory.current = dispatch(
          FetchRecAnalysisTrackBreakdown_Territory_Thunk(currentParams)
        );
      }, 500),
    [abortDispatchedTerritory, dispatch]
  );

  const handleSourceFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedSource();
        dispatchedSource.current = dispatch(
          FetchRecAnalysisTrackBreakdown_Source_Thunk(currentParams)
        );
      }, 500),
    [abortDispatchedSource, dispatch]
  );

  const assignTrendData = useCallback(() => {
    const currentTrendBreakdown = FindRecAnalysisTrackBreakdown(
      trackBreakdown_Trend.dataArray,
      productKey,
      isrc
    );

    if (currentTrendBreakdown) setTrendData(currentTrendBreakdown);
    else if (trendData?.isrc !== isrc) {
      setTrendData({
        data: null,
        isrc: isrc,
        productKey: productKey || null,
        periodIds: allPeriods?.periods?.map((p) => p.periodNum) || [],
      });

      handleTrendFunctionFetch({
        clientIds: clientIds,
        isrc: isrc,
        productKey: productKey,
        periodIds: periodIds,
      });
    }
  }, [
    allPeriods?.periods,
    clientIds,
    handleTrendFunctionFetch,
    isrc,
    periodIds,
    productKey,
    trackBreakdown_Trend.dataArray,
    trendData?.isrc,
  ]);

  const assignTerritoryData = useCallback(() => {
    const currentTerritoryBreakdown = FindRecAnalysisTrackBreakdown(
      trackBreakdown_Territories.dataArray,
      productKey,
      isrc
    );

    if (currentTerritoryBreakdown) setTerritoryData(currentTerritoryBreakdown);
    else if (territoryData?.isrc !== isrc) {
      setTerritoryData({
        data: null,
        isrc: isrc,
        productKey: productKey || null,
        periodIds: periodIds,
      });

      handleTerritoryFunctionFetch({
        clientIds: clientIds,
        isrc: isrc,
        productKey: productKey,
        periodIds: periodIds,
      });
    }
  }, [
    clientIds,
    handleTerritoryFunctionFetch,
    isrc,
    periodIds,
    productKey,
    territoryData?.isrc,
    trackBreakdown_Territories.dataArray,
  ]);

  const assignSourceData = useCallback(() => {
    const currentSourceBreakdown = FindRecAnalysisTrackBreakdown(
      trackBreakdown_Sources.dataArray,
      productKey,
      isrc
    );

    if (currentSourceBreakdown) setSourceData(currentSourceBreakdown);
    else if (sourceData?.isrc !== isrc) {
      setSourceData({
        data: null,
        isrc: isrc,
        productKey: productKey || null,
        periodIds: periodIds,
      });

      handleSourceFunctionFetch({
        clientIds: clientIds,
        isrc: isrc,
        productKey: productKey,
        periodIds: periodIds,
      });
    }
  }, [
    clientIds,
    handleSourceFunctionFetch,
    isrc,
    periodIds,
    productKey,
    sourceData?.isrc,
    trackBreakdown_Sources.dataArray,
  ]);

  useEffect(() => {
    return () => {
      abortDispatchedTerritory();
      abortDispatchedSource();
      abortDispatchedTrend();
    };
  }, [abortDispatchedTerritory, abortDispatchedSource, abortDispatchedTrend]);

  useEffect(() => {
    assignTrendData();
    assignTerritoryData();
    assignSourceData();
  }, [assignTerritoryData, assignTrendData, assignSourceData, currentParams]);

  const scrollToBreakdown =
    (refObject: RefObject<HTMLParagraphElement>) => () => {
      refObject.current?.scrollIntoView({ behavior: "smooth" });
    };

  const handleDownloadBtn = () => {
    const analyzeBy = "BY_TRACK_TERRITORY_DETAILS";
    const params = {
      data: {
        periodIds: allPeriods?.periods?.map((p) => p.periodNum) || [],
        clientIds: currentParams.clientIds,
        analyzeBy,
        trackId: currentParams.productKey,
      },
      fileName:
        "Royalties_" + analyzeBy + "_" + getToday() + "-" + getNow() + ".xlsx",
    };
    dispatch(downloadFunction(params));
  };

  const handleCloseBreakdown = useCallback(() => {
    props.handleClose && props.handleClose("");
  }, [props]);

  return (
    <div className={styles.breakDown}>
      {(trackBreakdown_Trend.status === "loading" ||
        trackBreakdown_Territories.status === "loading" ||
        trackBreakdown_Sources.status === "loading" ||
        trackBreakdown_DownloadsStatus === "loading") && <Loader />}
      <header
        id="rightScrollableHeader"
        className="sticky top-0 z-[5] bg-white pt-5"
      >
        <div className="flex justify-between">
          {!props.mobileView && (
            <h3 className={styles.title}>{props.track.title}</h3>
          )}
          {props.handleClose && (
            <Button
              className={`${styles.unselectBtn} ${styles.breakdownUnselectBtn} self-start md:mr-2`}
              variant={ButtonVariantEnum.cleanCta}
              onClick={handleCloseBreakdown}
            >
              +
            </Button>
          )}
        </div>
        {!props.mobileView && (
          <div className={styles.description}>
            <p>{props.track.artist}</p>
            <p>{props.track.isrc}</p>
          </div>
        )}
        <div className={styles.breakdownButtons}>
          <Button
            icon={<DownloadIcon />}
            className={`downloadData_GTM ${styles.downloadBtn}`}
            disabled={trackBreakdown_DownloadsStatus === "loading"}
            onClick={handleDownloadBtn}
            variant={ButtonVariantEnum.cleanCta}
          >
            {t("analysis.songs.breakdown.downloadData")}
          </Button>
          <Button
            onClick={scrollToBreakdown(territoryBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={`${styles.scrollNavBtn} ${styles.chevron}`}
            icon={<ChevronIcon />}
          >
            {t("analysis.songs.breakdown.territory")}
          </Button>
          <Button
            onClick={scrollToBreakdown(trendBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={`${styles.scrollNavBtn} ${styles.chevron}`}
            icon={<ChevronIcon />}
          >
            {t("analysis.songs.breakdown.trend")}
          </Button>
          <Button
            onClick={scrollToBreakdown(sourceBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={`${styles.scrollNavBtn} ${styles.chevron}`}
            icon={<ChevronIcon />}
          >
            {t("analysis.songs.breakdown.source")}
          </Button>
        </div>
      </header>
      <div
        className={`${styles.scrollable} ${styles.breakDownContent} ${
          props.isSelected ? styles.isOpen : ""
        }`}
      >
        <section>
          <p ref={territoryBreakdownPosRef} className={styles.breakdownTitle}>
            {t("analysis.songs.breakdown.breakdownByTerritory")}
          </p>
          <AnalysisTrackBreakdownTerritory
            data={territoryData as RecAnalysisTrackBreakdowTerritoryData | null}
            status={trackBreakdown_Territories.status}
          />
          <p ref={trendBreakdownPosRef} className={styles.breakdownTitle}>
            {t("analysis.songs.breakdown.breakdownByTrend")}
          </p>
          <AnalysisTrackBreakdownTrend
            data={trendData}
            status={trackBreakdown_Trend.status}
          />
          <p ref={sourceBreakdownPosRef} className={styles.breakdownTitle}>
            {t("analysis.songs.breakdown.breakdownBySource")}
          </p>
          <AnalysisTrackBreakdownSource
            data={sourceData}
            status={trackBreakdown_Sources.status}
          />
        </section>
      </div>
    </div>
  );
});

export default AnalysisTrackBreakdown;
