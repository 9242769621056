import { USER_API_RESOURCE_BASE_URLS } from "../../../../app/config/userEndpoints.const";
import request from "../../../../app/utils/requestUtils";

// ok
export type RecAnalysisAlbumBreakdownParams = {
  productKey: number;
  clientIds: number[];
  clientLists?: {
    id: string;
    type: "static";
  }[];
  periodIds: number[];
};

// ?
export type RecAnalysisAlbumBreakdownTypeTrendTerritoryParams = {
  isSelected: boolean;
} & RecAnalysisAlbumBreakdownParams;

export type RecAnalysisAlbumData = {
  productKey: number;
  title: string;
  artist: string;
  configDesc: string;
  productCode: string;
  barCode: string;
  royalties: number;
  formattedRoyalties: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
  digitalUnits: "90";
  physicalUnits: "0";
};

export type RecAnalysisAlbumByTerritoryEntry = {
  countryName: string;
  countryCode: string;
  royalty: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
};

export type RecAnalysisAlbumByTerritoryData = {
  title: string;
  totalRoyalty: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
  territories: RecAnalysisAlbumByTerritoryEntry[];
};

export type RecAnalysisAlbumByTrackEntry = {
  productKey: number;
  title: string;
  isrc: string;
};

export type RecAnalysisAlbumByTrackData = {
  productKey: number;
  title: string;
  artist: string;
  configDesc: string;
  productCode: string;
  barCode: string;
  royalty: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
  tracks: RecAnalysisAlbumByTrackEntry[];
};

export type RecAnalysisAlbumByTrendEntry = {
  periodOrYear: string;
  royalty: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
};

export type RecAnalysisAlbumByTrendData = {
  title: string;
  royaltyTrendsData: {
    totalRoyalty: {
      currency: string;
      raw: number;
      formattedShort: string;
      formattedLong: string;
      formattedPercent: string;
      percent: number;
    };
    perYear: boolean;
    royaltiesPerPeriodOrYear: RecAnalysisAlbumByTrendEntry[];
  };
};

export function FetchAnalysisAlbumBreakdown_Territory(
  params: RecAnalysisAlbumBreakdownParams,
  thunkAPI?: any
): Promise<RecAnalysisAlbumByTerritoryData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.albumsTerritories}?productKey=${params.productKey}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisAlbumBreakdown_Trend(
  params: RecAnalysisAlbumBreakdownParams,
  thunkAPI?: any
): Promise<RecAnalysisAlbumByTrendData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.albumsTrends}?productKey=${params.productKey}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisAlbumBreakdown_Track(
  params: RecAnalysisAlbumBreakdownParams,
  thunkAPI?: any
): Promise<RecAnalysisAlbumByTrackData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.albumsTracks}?productKey=${params.productKey}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function downloadAlbumBreakdownData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.analysisDownload}?clientDocumentType=RECORDING`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
      isDownload: true,
      fileName,
    }
  );
}
