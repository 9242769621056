import { Fragment } from "react";
import { ButtonVariantEnum } from "../../../types/enums";
import Button from "../../../atom/Button";
import AnalysisSourceBreakdown from "../analysisSourceBreakdown/AnalysisSourceBreakdown";
import styles from "../analysis.module.scss";

interface LeftSideItemProps {
  source: any;
  sourceId: string;
  isSelected: boolean;
  sourceCode?: string;
  sourceName: string;
  handleSourceClick: (clickedSource: any) => void;
  royaltyCurrency: string;
  royaltyValue: number;
  documentType: "PUBLISHING" | "RECORDING";
  selectedPeriods: number[];
  selectedClients: any[];
  windowWidth: number | undefined;
}

const AnalysisSourceLeftSideItem = (props: LeftSideItemProps) => {
  const {
    source,
    sourceId,
    isSelected,
    sourceCode,
    sourceName,
    handleSourceClick,
    royaltyCurrency,
    royaltyValue,
    documentType,
    selectedPeriods,
    selectedClients,
    windowWidth,
  } = props;

  return (
    <Fragment key={sourceId}>
      <tr
        onClick={() => handleSourceClick(source)}
        className={`${styles.desktopRow} ${isSelected ? styles.selected : ""}`}
      >
        <td className={styles.sourceCol}>
          <p>{source.sourceName}</p>
        </td>
        <td className={styles.royaltiesCol}>
          {royaltyCurrency} {royaltyValue}
        </td>
        <td className={`${styles.unselectBtnCell} "text-right"`}>
          <Button
            className={`${isSelected ? "block" : "hidden"} ${
              styles.unselectBtn
            }`}
            variant={ButtonVariantEnum.cleanCta}
            onClick={() => handleSourceClick(sourceId)}
          >
            +
          </Button>
        </td>
      </tr>
      <tr
        className={`${styles.mobileRow} ${isSelected ? styles.selected : ""}`}
        onClick={() => handleSourceClick(source)}
      >
        <td className={styles.mobileCol}>
          <div className={"relative"}>
            <p>{source.sourceName}</p>
            <Button
              className={`${isSelected ? "block" : "hidden"} ${
                styles.unselectBtn
              }`}
              variant={ButtonVariantEnum.cleanCta}
              onClick={() => handleSourceClick(sourceId)}
            >
              +
            </Button>
          </div>
          <dl>
            <dt>{royaltyCurrency}</dt>
            <dd>{royaltyValue}</dd>
          </dl>
        </td>
      </tr>
      {isSelected && (
        <tr className={`${styles.selectedContent} ${styles.mobileRow}`}>
          <td colSpan={3}>
            {windowWidth && windowWidth < 1200 && (
              <div className="block w-full">
                <AnalysisSourceBreakdown
                  documentType={documentType}
                  isSelected={source}
                  sourceCode={sourceCode}
                  sourceName={sourceName}
                  periodIds={selectedPeriods}
                  clientIds={selectedClients}
                  mobileView={true}
                  handleClose={handleSourceClick}
                />
              </div>
            )}
          </td>
        </tr>
      )}
    </Fragment>
  );
};

export default AnalysisSourceLeftSideItem;
