import React from "react";
function SyncOverviewIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      name="SyncOverviewIcon"
      viewBox="0 0 27 27"
      {...props}
    >
      <g fill="none" transform="translate(.782 1)">
        <circle cx="12.5" cy="12.5" r="12.5" stroke="currentColor" />
        <path
          d="m8.472 12.747 6.884-7.316-1.528 6.747h3.224l-6.883 7.316 1.528-6.747z"
          stroke="currentColor"
        />
      </g>
    </svg>
  );
}

export default SyncOverviewIcon;
