import { useCallback, memo, useEffect } from "react";
import Button from "../../atom/Button/Button";
import deepCompare from "../../../app/utils/deepCompare";
import ModalPortal from "../../atom/ModalPortal/ModalPortal";
import { ButtonVariantEnum } from "../../types/enums";
import { CloseIcon } from "../../atom/Icon";
import styles from "./modal.module.scss";
import { ModalProps } from "../../types/props";

function Modal(props: ModalProps): JSX.Element {
  const {
    id,
    children,
    primaryBtn,
    cancelBtn,
    className = "",
    primaryBtnOnClick,
    isModalOpen,
    callbackCloseModal,
    disableBackdropClick,
    hideCloseButton,
  } = props;

  const closeModal = useCallback(() => {
    if (callbackCloseModal) callbackCloseModal(id);
  }, [callbackCloseModal, id]);

  const handlePrimaryBtnOnClick = (e: React.MouseEvent) => {
    if (primaryBtnOnClick) primaryBtnOnClick(e);
    closeModal();
  };

  const handleOpenModal = (e: React.MouseEvent) => {
    if (props.openModalBtn && props.openModalBtn.props.onClick) {
      props.openModalBtn?.props.onClick(e);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isModalOpen]);

  const OpenModalBtn = (
    <Button {...props.openModalBtn?.props} onClick={handleOpenModal}></Button>
  );

  return (
    <ModalPortal>
      {props.openModalBtn && OpenModalBtn}
      {isModalOpen ? (
        <div id={id} className={`${styles.modal}`}>
          <div className={`${className} ${styles.contentContainer}`}>
            {!hideCloseButton && (
              <span
                className={`${styles.close} ${props.closeBtnClassName || ""}`}
                onClick={closeModal}
              >
                <CloseIcon />
              </span>
            )}
            <div className={styles.content}>{children}</div>
            {(cancelBtn !== undefined || primaryBtn !== undefined) && (
              <div className={styles.footer}>
                {cancelBtn && (
                  <Button
                    variant={ButtonVariantEnum.outlineCta}
                    onClick={closeModal}
                  >
                    {cancelBtn}
                  </Button>
                )}
                {primaryBtn && (
                  <Button onClick={handlePrimaryBtnOnClick}>
                    {primaryBtn}
                  </Button>
                )}
              </div>
            )}
          </div>
          <div
            className={styles.backdrop}
            onClick={!disableBackdropClick ? closeModal : () => null}
          ></div>
        </div>
      ) : null}
    </ModalPortal>
  );
}

export default memo(Modal, (prevProps: ModalProps, nextProps: ModalProps) =>
  deepCompare(prevProps, nextProps)
);
