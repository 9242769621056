import React from "react";
function DigitalFlashIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      name="DigitalFlashIcon"
      {...props}
    >
      <g clipRule="evenodd" fillRule="evenodd" stroke="none">
        <path d="M17 3H7a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM7 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" />
        <path d="M10 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zM15 10a1 1 0 0 0-1 1v7a1 1 0 1 0 2 0v-7a1 1 0 0 0-1-1zm-4 4a1 1 0 1 1 2 0v4a1 1 0 1 1-2 0zm-3 2a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0z" />
      </g>
    </svg>
  );
}

export default DigitalFlashIcon;
