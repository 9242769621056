import { useTranslation } from "react-i18next";
import styles from "../analysis.module.scss";
import Tooltip from "../../../atom/Tooltip/Tooltip";

interface InfiniteTableHeaderProps {
  filterAnalysisTracksWith: (columnName: string) => void;
}

const AnalysisTrackInfiniteTableHeader = (props: InfiniteTableHeaderProps) => {
  const { t } = useTranslation();
  const { filterAnalysisTracksWith } = props;
  return (
    <div id="table-header" className={styles.headContainer}>
      <table>
        <thead>
          <tr>
            <th
              className={styles.trackCol}
              onClick={() => {
                filterAnalysisTracksWith("title");
              }}
            >
              {t("analysis.tracks.track")}
            </th>
            <th
              className={styles.artistCol}
              onClick={() => {
                filterAnalysisTracksWith("artist");
              }}
            >
              {t("analysis.tracks.artist")}
            </th>
            <th
              className={`sortByRoyalties_GTM ${styles.royaltiesCol}`}
              onClick={() => {
                filterAnalysisTracksWith("royalties");
              }}
            >
              {t("analysis.tracks.royalties")}
              <Tooltip
                text={t("analysis.tracks.royaltiesTooltip")}
                id="analysisTrackRoyaltiesTooltipId"
              />
            </th>
            <th className={styles.downloadUnitsCol}>
              {t("analysis.tracks.downloadUnits")}
            </th>
            <th className={styles.streamingUnitsCol}>
              {t("analysis.tracks.streamingUnits")}
            </th>
            <th></th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default AnalysisTrackInfiniteTableHeader;
