import React from "react";
function LoginTransparencyIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      name="LoginTransparencyIcon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="32.5" cy="32.5" r="32.5" fill="#d20020" />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m36.636 14.727 9.091 9.091v25.455H20.273V14.727zM25.727 23.818h5.455M25.727 29.273h14.546M25.727 34.727h14.546M25.727 40.182h14.546"
        />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M36.636 15.636v8.182h8.182"
        />
      </g>
    </svg>
  );
}

export default LoginTransparencyIcon;
