import React, { useRef, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { useTranslation } from "react-i18next";
import { AlertIcon } from "../../../app/atom/Icon";
import useOutsideClick from "../../../app/hooks/useOutsideClick";
import Button from "../../../app/atom/Button/Button";
import { ButtonVariantEnum } from "../../../app/types/enums";
import {
  isMessageOpenSelector,
  messagesSelector,
  setMessageStateAction,
  softDeleteMessageThunk,
  markMessageAsReadThunk,
} from "./messageSlice";
import styles from "./message.module.scss";
import MessageItem from "./MessageItem";
import MessagePriorityModal from "./MessagePriorityModal";

const Message = (): JSX.Element => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [messagesLength, setMessagesLength] = useState(0);
  const [priorityMessages, setPriorityMessages] = useState<any>([]);
  const isMessageOpenState = useAppSelector(isMessageOpenSelector);
  const messages = useAppSelector(messagesSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const notReadMessages = new Set();
    const priorityMessageArray = [];

    for (let i = 0; i < messages?.data?.length; i++) {
      if (!messages.data[i].read) {
        notReadMessages.add(messages.data[i].id);
      }
      if (messages.data[i].priority && !messages.data[i].read) {
        priorityMessageArray.push({ ...messages.data[i], currentIndex: i + 1 });
      }
    }

    setMessagesLength(notReadMessages.size);
    setPriorityMessages(priorityMessageArray);
  }, [messages?.data]);

  useEffect(() => {
    if (isMessageOpenState) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isMessageOpenState]);

  const handleClose = () => {
    dispatch(setMessageStateAction(false));
  };

  const handleOpenMessage = () => {
    dispatch(setMessageStateAction(!isMessageOpenState));
  };

  useOutsideClick(ref, handleClose);

  const handleSoftDeleteMessage = (id: number) => {
    dispatch(softDeleteMessageThunk(id));
  };

  const handleMarkMessageAsRead = (id: number) => {
    dispatch(markMessageAsReadThunk(id));
  };

  return (
    <>
      <div ref={ref} className="sm:relative">
        <Button
          className={`group relative flex h-full items-center px-3 text-base tracking-3xl text-gray-900 no-underline hover:bg-gray-800 sm:px-5 ${
            isMessageOpenState && "bg-gray-800"
          }`}
          variant={ButtonVariantEnum.textLink}
          icon={
            <AlertIcon
              className={`fill-current h-7 w-7 text-white group-hover:text-green-700 ${
                isMessageOpenState && "text-green-700"
              }`}
            />
          }
          onClick={handleOpenMessage}
        >
          {messagesLength !== 0 && (
            <span className={styles.messageCounter}>{messagesLength}</span>
          )}
        </Button>
        {isMessageOpenState && (
          <div className={`${styles.messageContainer} `}>
            <div className="mb-1 flex items-center justify-between px-4 text-white">
              <span className={styles.messageContainerTitle}>
                {t("message.messages")}
              </span>
              <Button
                onClick={handleClose}
                variant={ButtonVariantEnum.textLink}
                className="p-0 text-xs uppercase text-white"
              >
                {t("message.close")}
              </Button>
            </div>
            <div className={styles.messageList}>
              <ul>
                {messages.data.length ? (
                  messages.data.map((message) => {
                    return (
                      <MessageItem
                        key={message.id}
                        message={message}
                        onSoftDeleteMessage={handleSoftDeleteMessage}
                        onMarkAsReadMessage={handleMarkMessageAsRead}
                      />
                    );
                  })
                ) : (
                  <li className="relative px-4 py-2">
                    <span className="text-xs">{t("message.noMessage")}</span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
      {!!priorityMessages.length && (
        <MessagePriorityModal
          message={priorityMessages[0]}
          onMarkAsReadMessage={handleMarkMessageAsRead}
          numberOfPrioMessages={priorityMessages.length}
        />
      )}
    </>
  );
};

export default Message;
