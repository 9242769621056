import {
  RecSyncTabStatusEnum,
  RecSyncTabsEnum,
} from "../../../app/types/enums";
import withClientSelectionData from "../clientSelection/withClientSelectionData";
import RecSyncHOC from "./RecSyncHOC";

const RecSyncReceived = (): JSX.Element => (
  <RecSyncHOC
    status={RecSyncTabStatusEnum.received}
    selectedTab={RecSyncTabsEnum.received}
  />
);

export default withClientSelectionData(RecSyncReceived);
