import { useMemo } from "react";
import Link from "../../atom/Link";
import { useTranslation } from "react-i18next";
import styles from "./footer.module.scss";
import { useLocation } from "react-router-dom";
import { FooterProps } from "../../types/props";
import { FooterVariantEnum, UserAccountTypeEnum } from "../../types/enums";
import { useAppSelector } from "../../redux/hooks";
import {
  appBEVersionSelector,
  meSelector,
} from "../../../features/appMain/appMainSlice";
import { ROUTES } from "../../../features/routes/routes.const";
import packageJson from "../../../../package.json";

const Footer = (props: FooterProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { footerVariant = FooterVariantEnum.linksAndCopyright } = props;
  const currentYear = new Date().getFullYear();
  const appBEVersion = useAppSelector(appBEVersionSelector);
  const { userAccountType } = useAppSelector(meSelector);
  const displaySiteVersion = useMemo(
    () =>
      userAccountType.toLocaleUpperCase() === UserAccountTypeEnum.admin &&
      pathname?.includes(`/${ROUTES.mybmg}/${ROUTES.admin}`),
    [pathname, userAccountType]
  );

  if (footerVariant === FooterVariantEnum.noFooter) return <></>;

  return (
    <footer className={`z-10 col-span-full`}>
      {footerVariant === FooterVariantEnum.linksAndCopyright && (
        <>
          <div className={styles.footerMenu}>
            <Link
              to="/mybmg/privacypolicy"
              target="_blank"
              text={t("layoutBasic.privacyPolicy")}
            />
            <Link
              to="/mybmg/disclaimer"
              target="_blank"
              text={t("layoutBasic.disclaimer")}
            />
            <Link
              to="/mybmg/termsofuse"
              target="_blank"
              text={t("layoutBasic.termsOfUse")}
            />
            {displaySiteVersion && (
              <div className={styles.siteVersion}>
                {appBEVersion && <p>{appBEVersion}-BE</p>}
                <p>{packageJson.version}-FE</p>
              </div>
            )}
          </div>
          <div className={styles.copyright}>
            © {currentYear}{" "}
            <a href="https://www.bmg.com/" className="underline">
              BMG
            </a>{" "}
            Rights Management GmbH. {t("layoutBasic.allRightsReserved")}
          </div>
        </>
      )}
      {footerVariant === FooterVariantEnum.onlyCopyright && (
        <div className={styles.onlyCopyright}>
          © 2022{" "}
          <a href="https://www.bmg.com/" className="underline">
            BMG
          </a>{" "}
          Rights Management GmbH. {t("layoutBasic.allRightsReserved")}
        </div>
      )}
    </footer>
  );
};

export default Footer;
