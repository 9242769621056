import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { CommonRequestBodyTableState } from "../../../../app/types/props";
import {
  SortDirectionEnum,
  StatusEnum,
  UserAccountStatusEnum,
  UserAccountTypeEnum,
} from "../../../../app/types/enums";

import { DEFAULT_ROWS_PER_PAGE } from "../../common/adminEndpoints.const";
import { useFetch } from "../../common/hooks/useFetch";

import { tableHeadData } from "./components/RequestArchiveTable/RequestArchiveTable.const";

import { getRequestArchiveList } from "./RequestArchiveAPI";

export type RequestArchiveListItem = {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  requestedDate: string;
  requestApprovedDate: string;
  amendedDate: string;
  status: UserAccountStatusEnum;
  userAccountType: UserAccountTypeEnum;
  territory: string;
  amendedBy: string;
  adminUsername: string;
};

type RequestArchiveInitialState = {
  requestArchivePageState: CommonRequestBodyTableState;
  tableData: {
    list: RequestArchiveListItem[] | null;
    count: number | null;
    totalCount: number | null;
  };
  status: StatusEnum;
};

const initialState: RequestArchiveInitialState = {
  requestArchivePageState: {
    filterText: "",
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: tableHeadData?.[5].filteringInfo,
          direction: SortDirectionEnum.Descending,
        },
      ],
    },
  },
  tableData: {
    list: null,
    count: 0,
    totalCount: 0,
  },
  status: StatusEnum.Idle,
};

export const getRequestArchiveThunk = createAsyncThunk(
  "admin/access-requests/archived",
  async (payload: any, { rejectWithValue }) => {
    try {
      const getRequestArchive = await useFetch();
      const response = await getRequestArchive({
        data: payload,
        fetchFunction: getRequestArchiveList,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestArchiveSlice = createSlice({
  name: "requestArchive",
  initialState,
  reducers: {
    updatePageState: (state, action: any) => {
      state.requestArchivePageState.filterText = action.payload.filterText;
      state.requestArchivePageState.pagination = action.payload.pagination;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequestArchiveThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(getRequestArchiveThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getRequestArchiveThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      });
  },
});

export const RequestArchiveSelector = (state: RootState) =>
  state.requestArchive;

export default requestArchiveSlice.reducer;
