import { USER_API_RESOURCE_BASE_URLS } from "../../../../app/config/userEndpoints.const";
import request from "../../../../app/utils/requestUtils";

export type PubAnalysisDigitalBreakdownTrendTerritoryParams = {
  isSelected: boolean;
  sourceCode?: string;
  sourceName: string;
  clientIds: number[];
  clientLists?: {
    id: string;
    type: "static";
  }[];
  periodIds: number[];
};

type PubAnalysisDigitalDataEntry = {
  currency: string;
  raw: number;
  formattedShort: string;
  formattedLong: string;
  formattedPercent: string;
  percent: number;
};

export type PubAnalysisDigitalSourcesByTerritoryEntry = {
  name: string;
  iso: string;
  revenue: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
  zero: boolean;
};

export type PubAnalysisDigitalRoyaltiesPerPeriodOrYearEntry = {
  periodOrYear: string;
  royalty: PubAnalysisDigitalDataEntry;
  zero: boolean;
};

export type PubAnalysisDigitalData = {
  sourceCode?: string;
  sourceName: string;
  perYear?: boolean;
  totalRoyalty: PubAnalysisDigitalDataEntry | null;
  sourcesByTerritory?: PubAnalysisDigitalSourcesByTerritoryEntry[] | null;
  royaltiesPerPeriodOrYear?:
    | PubAnalysisDigitalRoyaltiesPerPeriodOrYearEntry[]
    | null;
};

export function FetchAnalysisDigitalBreakdown_Trend(
  params: PubAnalysisDigitalBreakdownTrendTerritoryParams,
  thunkAPI?: any
): Promise<PubAnalysisDigitalData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.digitalTrends}?sourceCode=${params.sourceCode}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisDigitalBreakdown_Territory(
  params: PubAnalysisDigitalBreakdownTrendTerritoryParams,
  thunkAPI?: any
): Promise<PubAnalysisDigitalData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.digitalTerritories}?sourceCode=${params.sourceCode}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function downloadDigitalBreakdownData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.analysisDownload}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
    isDownload: true,
    fileName,
  });
}
