import AnalysisTrackBreakdown from "../analysisTrackBreakdown/AnalysisTrackBreakdown";
import styles from "../analysis.module.scss";

interface RightSideProps {
  selectedTrack: any;
  topTargetIsOnScreen: boolean;
  selectedPeriods: number[];
  selectedClients: number[];
  handleBreakdownClose?: (clickedSong: any | undefined) => void;
}

const AnalysisTrackRightSide = (props: RightSideProps) => {
  const {
    selectedTrack,
    topTargetIsOnScreen,
    selectedPeriods,
    selectedClients,
    handleBreakdownClose,
  } = props;

  return (
    <div className={`${styles.rightSide} ${selectedTrack ? " md:flex-1" : ""}`}>
      {selectedTrack && (
        <div
          className={`${styles.rightSideSelected} ${
            selectedTrack ? styles.isOpen : ""
          } ${topTargetIsOnScreen ? "" : `${styles.isOpenFixed}`}`}
        >
          <AnalysisTrackBreakdown
            isSelected={selectedTrack}
            track={{
              productKey: selectedTrack.productKey,
              isrc: selectedTrack.isrc,
              title: selectedTrack.title,
              artist: selectedTrack.artist,
            }}
            periodIds={selectedPeriods}
            clientIds={selectedClients}
            mobileView={false}
            handleClose={handleBreakdownClose}
          />
        </div>
      )}
    </div>
  );
};

export default AnalysisTrackRightSide;
