import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/redux/hooks";
import { pubClientSelectionSelector } from "../pubroot/pubrootSlice";
import Grid from "../../../app/atom/Grid";
import Tabs, { Tab } from "../../../app/molecules/tabs/Tabs";
import SyncOverviewIcon from "../../../app/atom/Icon/SyncOverviewIcon";
import SyncAllIcon from "../../../app/atom/Icon/SyncAllIcon";
import SyncQuotingIcon from "../../../app/atom/Icon/SyncQuotingIcon";
import SyncLicensingIcon from "../../../app/atom/Icon/SyncLicensingIcon";
import SyncReceivedIcon from "../../../app/atom/Icon/SyncReceivedIcon";
import SyncCompletedIcon from "../../../app/atom/Icon/SyncCompletedIcon";
import PageHeader from "../../../app/molecules/pageHeader/PageHeader";
import Loader from "../../../app/atom/Loader";
import ClientSelection from "../../../app/molecules/clientSelection/ClientSelection";
import { pubSyncOverviewSelector, pubSyncStatusSelector } from "./pubSyncSlice";
import { TabsVariantEnum } from "../../../app/types/enums";
import type { PubSyncProps } from "../../../app/types/props";

const PubSyncHeaderWrapper = (props: PubSyncProps): JSX.Element => {
  const { selectedTab } = props;

  const { t } = useTranslation();
  const clientSelection = useAppSelector(pubClientSelectionSelector);
  const pageStatus = useAppSelector(pubSyncStatusSelector);
  const pubSyncOverview = useAppSelector(pubSyncOverviewSelector || []);

  const getlicensesByStatusCount = useMemo(
    () => (licenses: any[], status: string) => {
      const license = licenses?.find(
        (license: { status: string; count: number }) =>
          license.status === status
      );
      return license?.count || 0;
    },
    []
  );

  const MemoTabsComponent = memo(() => (
    <Tabs
      id="tabs-container"
      variant={TabsVariantEnum.boxedTabs}
      selectedTabIndex={selectedTab}
    >
      <Tab
        linkTo="/mybmg/user/sync/overview"
        title={
          <>
            <SyncOverviewIcon />
            <span>{t("sync.overview")}</span>
            <p className="h-5"></p>
          </>
        }
      />
      <Tab
        disabled={
          getlicensesByStatusCount(
            pubSyncOverview.licensesByStatus || [],
            "All"
          ) === 0
        }
        linkTo="/mybmg/user/sync/all"
        title={
          <>
            <SyncAllIcon />
            <span>{t("sync.all")}</span>
            <p>
              {getlicensesByStatusCount(
                pubSyncOverview.licensesByStatus || [],
                "All"
              )}
            </p>
          </>
        }
      />
      <Tab
        disabled={
          getlicensesByStatusCount(
            pubSyncOverview.licensesByStatus || [],
            "Quoting"
          ) === 0
        }
        linkTo="/mybmg/user/sync/quoting"
        title={
          <>
            <SyncQuotingIcon />
            <span>{t("sync.quoting")}</span>
            <p>
              {getlicensesByStatusCount(
                pubSyncOverview.licensesByStatus || [],
                "Quoting"
              )}
            </p>
          </>
        }
      />
      <Tab
        disabled={
          getlicensesByStatusCount(
            pubSyncOverview.licensesByStatus || [],
            "Licensing"
          ) === 0
        }
        linkTo="/mybmg/user/sync/licensing"
        title={
          <>
            <SyncLicensingIcon />
            <span>{t("sync.licensing")}</span>
            <p>
              {getlicensesByStatusCount(
                pubSyncOverview.licensesByStatus || [],
                "Licensing"
              )}
            </p>
          </>
        }
      />
      <Tab
        disabled={
          getlicensesByStatusCount(
            pubSyncOverview.licensesByStatus || [],
            "Received"
          ) === 0
        }
        linkTo="/mybmg/user/sync/received"
        title={
          <>
            <SyncReceivedIcon />
            <span>{t("sync.received")}</span>
            <p>
              {getlicensesByStatusCount(
                pubSyncOverview.licensesByStatus || [],
                "Received"
              )}
            </p>
          </>
        }
      />
      <Tab
        disabled={
          getlicensesByStatusCount(
            pubSyncOverview.licensesByStatus || [],
            "Completed"
          ) === 0
        }
        linkTo="/mybmg/user/sync/completed"
        title={
          <>
            <SyncCompletedIcon />
            <span>{t("sync.completed")}</span>
            <p>
              {getlicensesByStatusCount(
                pubSyncOverview.licensesByStatus || [],
                "Completed"
              )}
            </p>
          </>
        }
      />
    </Tabs>
  ));

  const addClassName =
    props.selectedTab === 0 ? { className: "p-5 sm:p-8 sm:py-6" } : undefined;

  return (
    <Grid>
      {/* Loader */}
      {clientSelection.selectedClientsStatus === "loading" && <Loader />}
      <div className={`col-span-full`}>
        <PageHeader
          documentType="PUBLISHING"
          pageTitle={t("sync.pageTitle")}
          selectClientsBtn={
            <ClientSelection
              documentType="PUBLISHING"
              isLoading={pageStatus === "loading"}
              type="SelectClients"
            />
          }
          createANewListBtn={
            <ClientSelection
              documentType="PUBLISHING"
              isLoading={pageStatus === "loading"}
              type="CreateANewList"
            />
          }
          viewAndEditListBtn={
            <ClientSelection
              type="ViewAndEditCurrentClientList"
              documentType="PUBLISHING"
            />
          }
          viewAndEditBtn={
            <ClientSelection
              type="ViewAndEditClientSelection"
              documentType="PUBLISHING"
            />
          }
          clientSelection={clientSelection}
        />
        <MemoTabsComponent />
        <div {...addClassName}>{props.children}</div>
      </div>
    </Grid>
  );
};

export default PubSyncHeaderWrapper;
