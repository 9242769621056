import {
  AdminHeaderProps,
  TableHeadProps,
} from "../../../../../../app/types/props";

export const tableHeadData: TableHeadProps = [
  {
    id: 1,
    title: `adminSection.common.groupsTableHeader.name`,
    filteringInfo: "name",
  },
  {
    id: 2,
    title: "adminSection.common.groupsTableHeader.description",
    filteringInfo: "description",
  },
  {
    id: 3,
    title: "adminSection.common.groupsTableHeader.type",
    filteringInfo: "",
  },
];

export const newsGroupsHeaderData: AdminHeaderProps = {
  titleData: {
    text: "",
  },
  inputData: {
    id: "search",
    handleSearch: (data) => {
      // eslint-disable-next-line no-console
      console.log(data);
    },
  },
  checkboxData: {
    label: "adminSection.news.allGroups",
    handleCheckboxChange: () => {
      // eslint-disable-next-line no-console
      console.log("all groups");
    },
  },
};
