import { SiteLayoutBasicProps } from "../../app/types/props";
import Link from "../../app/atom/Link";
import Image from "../../app/atom/Image";
import { LogoHD } from "../../app/img";
import Grid from "../../app/atom/Grid/Grid";
import Footer from "../../app/molecules/footer";
import BodyWrapper from "../../app/molecules/bodyWrapper";
import { FooterVariantEnum } from "../../app/types/enums";

const SiteLayoutBasic = (props: SiteLayoutBasicProps): JSX.Element => {
  const { children } = props;

  return (
    <Grid className="max-w-full font-light">
      <div className="col-span-full">
        <BodyWrapper>
          <header>
            <Link
              to="/"
              className="logoLink_GTM"
              text={<Image src={LogoHD} alt="logo" className="absolute h-14" />}
            ></Link>
          </header>
          <div className="col-span-full">{children}</div>
        </BodyWrapper>
        <Footer footerVariant={FooterVariantEnum.onlyCopyright} />
      </div>
    </Grid>
  );
};

export default SiteLayoutBasic;
