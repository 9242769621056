import { useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { recClientSelectionSelector } from "../recroot/recrootSlice";
import { useOktaAuth } from "@okta/okta-react";
import { useTranslation } from "react-i18next";
import { RecSyncTabsEnum } from "../../../app/types/enums";
import withClientSelectionData from "../clientSelection/withClientSelectionData";
import deepCompare from "../../../app/utils/deepCompare";
import {
  fetchRecSyncOverviewThunk,
  recSyncOverviewSelector,
  recSyncPageStateSelector,
  recSyncStatusSelector,
  resetRecSyncTabTabsStateAction,
  updateRecSyncPageStateAction,
} from "./recSyncSlice";
import RecSyncHeaderWrapper from "./RecSyncHeaderWrapper";
import Grid from "../../../app/atom/Grid/Grid";
import SyncSuccessMsg from "../../../app/molecules/sync/syncSuccessMsg";
import LiveStatusBlock from "../../../app/molecules/sync/LiveStatusBlock";
import LicenseByMediaTypeBlock from "../../../app/molecules/sync/LicenseByMediaTypeBlock";
import MostRecentLicensesBlock from "../../../app/molecules/sync/MostRecentLicensesBlock";
import RequestedSongsBlock from "../../../app/molecules/sync/RequestedSongsBlock";
import Loader from "../../../app/atom/Loader/Loader";
import debounce from "../../../app/utils/debounce";

const RecSyncOverview = (): JSX.Element => {
  const { t } = useTranslation();
  const { authState } = useOktaAuth();
  const dispatch = useAppDispatch();
  const dispatchedRecSync = useRef<any>();

  const clientSelection = useAppSelector(recClientSelectionSelector);
  const recSyncPageState = useAppSelector(recSyncPageStateSelector);
  const recSyncOverviewState = useAppSelector(recSyncOverviewSelector);
  const recSyncStatus = useAppSelector(recSyncStatusSelector);

  const abortDispatchedRecSync = () => {
    if (dispatchedRecSync.current) dispatchedRecSync.current?.abort();
  };

  const handleSyncFetch = useMemo(
    () =>
      debounce((pageState: any) => {
        abortDispatchedRecSync();
        dispatchedRecSync.current = dispatch(
          fetchRecSyncOverviewThunk(pageState)
        );
        dispatch(updateRecSyncPageStateAction(pageState));
      }, 100),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      abortDispatchedRecSync();
    };
  }, []);

  useEffect(() => {
    if (
      !authState?.isAuthenticated ||
      clientSelection === null ||
      clientSelection?.selectedClients?.length === 0
    ) {
      return;
    }
    const clientData = {
      clientIds: clientSelection?.selectedClients?.map((c) => c.id) || [],
      clientLists: [],
    };
    const recSyncState = {
      ...recSyncPageState,
      data: clientData,
    };

    if (!deepCompare(recSyncState, recSyncPageState)) {
      dispatch(resetRecSyncTabTabsStateAction());
      handleSyncFetch(recSyncState);
    }
  }, [
    authState?.isAuthenticated,
    clientSelection,
    dispatch,
    handleSyncFetch,
    recSyncPageState,
  ]);

  const licensesCounter = useMemo(
    () =>
      recSyncOverviewState.licensesByStatus?.reduce(
        (
          acc,
          row: {
            status: string;
            count: number;
          }
        ) => {
          acc += row.count;
          return acc;
        },
        0
      ) ?? -1,
    [recSyncOverviewState]
  );

  const showError = recSyncStatus === "failed";

  return (
    <RecSyncHeaderWrapper selectedTab={RecSyncTabsEnum.overview}>
      <Grid className="gap-y-8">
        {recSyncStatus === "loading" && <Loader />}
        {licensesCounter === 0 && !showError && (
          <div className="col-span-full px-4 sm:px-0">
            <SyncSuccessMsg
              message={t("sync.noResults") as string}
              id="syncNoResultsFeedback"
            />
          </div>
        )}
        {licensesCounter > 0 && !showError && (
          <>
            <div className="col-span-full">
              <div className="grid grid-cols-12 gap-5 lg:gap-8">
                <LiveStatusBlock
                  licensesByStatus={recSyncOverviewState.licensesByStatus}
                ></LiveStatusBlock>
                <LicenseByMediaTypeBlock
                  licensesByMedia={recSyncOverviewState.licensesByMedia}
                ></LicenseByMediaTypeBlock>
              </div>
            </div>
            <div className="col-span-full">
              <div className="grid grid-cols-12 gap-5 lg:gap-8">
                <MostRecentLicensesBlock
                  mostRecentLicenses={recSyncOverviewState.mostRecentLicenses}
                />
                <RequestedSongsBlock
                  mostRequestedSongs={recSyncOverviewState.mostRequestedSongs}
                />
              </div>
            </div>
          </>
        )}
      </Grid>
    </RecSyncHeaderWrapper>
  );
};

export default withClientSelectionData(RecSyncOverview);
