import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function fetchSyncOverview(params: any, thunkAPI?: any) {
  return request(
    `${USER_API_RESOURCE_BASE_URLS.syncOverview}?documentType=PUBLISHING`,
    {
      method: "POST",
      body: JSON.stringify(params?.data),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchSync(params: any, thunkAPI?: any) {
  const { documentType, count, activeTab, tabsState } = params;
  const { filterText, isAsc, status, start, sortColumn, tabClientData } =
    tabsState[activeTab];
  const awailableParams: any = {
    start,
    count,
    sortColumn,
    documentType,
    isAsc,
    status,
    // NEW NEW NEW
    sortAscending: isAsc,
    filterColumn: [], // string[]
    filterText,
    params: {
      additionalProp1: {},
      additionalProp2: {},
      additionalProp3: {},
    },
    notEqualParams: {
      additionalProp1: {},
      additionalProp2: {},
      additionalProp3: {},
    },
    startInt: 0,
    countInt: 0,
  };

  // sending only params which have values
  const queryString = Object.keys(awailableParams)
    .filter(
      (key) =>
        key === "isAsc" || !!awailableParams[key] || awailableParams[key] === 0
    )
    .map((key) => {
      let value = awailableParams[key];
      if (typeof value === "object") {
        value = JSON.stringify(value);
      }
      return encodeURIComponent(key) + "=" + encodeURIComponent(value);
    })
    .join("&");

  return request(
    `${USER_API_RESOURCE_BASE_URLS.sync}${
      queryString.length ? "?" + queryString : ""
    }`,
    {
      method: "POST",
      body: JSON.stringify(tabClientData),
      signal: thunkAPI?.signal,
    }
  );
}

export function downloadSyncData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.syncDownload}?clientDocumentType=PUBLISHING`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
      isDownload: true,
      fileName,
    }
  );
}
