import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export type AddEditNewsRequestBody = {
  publicationDate: string;
  expiryDate: string | null;
  priority: boolean;
  title: string;
  text: string;
  forAllGroups: boolean;
  webapp: boolean;
  mobile: boolean;
  users: [number] | null;
  groups: [number] | null;
  userAccountType: string | null;
  publishingOwningCriteria: any | null;
  recordingOwningCriteria: any | null;
  mechanicalOwningCriteria: any | null;
};

export function fetchNewsByID(payload: { id: number }) {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.news}/${id}`, {
    method: "GET",
  });
}

export function addNews(payload: { data: AddEditNewsRequestBody }) {
  const { data } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.news}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
}

export function editNews(payload: {
  id: number;
  data: AddEditNewsRequestBody;
}) {
  const { data, id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.news}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
}

export function deleteNews(payload: { id: number }) {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.news}/${id}`, {
    method: "DELETE",
  });
}

export const getSelectedUsers = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.news}/users`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};
