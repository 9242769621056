import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import { fetchOktaUnEnrollUser } from "../../siteBasic/login/loginAPI";
import {
  fetchLanguages,
  setUserLanguage,
} from "./changeLanguage/changeLanguageAPI";

export interface EnrollMFAState {
  enrollMFALoadingStatus: "idle" | "loading" | "failed";
  enrolMFAStatus: "" | "started" | "success" | "fail";
  deEnrolMFAStatus: "" | "success" | "fail";
}

export interface ChangePasswordState {
  changePwdLoadingStatus: "idle" | "loading" | "failed";
  changeStatus: "" | "success" | "fail";
}

export interface SettingsState {
  settingsPageState: {
    isOpen: boolean;
  };
  enrollMFAState: EnrollMFAState;
  changePasswordState: ChangePasswordState;
  languageState: {
    languageStatus: "idle" | "loading" | "failed";
    languages: any[];
    isUserLangSentSuccess: boolean;
    isUserLangSentFail: boolean;
  };
}

const initialState: SettingsState = {
  settingsPageState: {
    isOpen: false,
  },
  enrollMFAState: {
    enrollMFALoadingStatus: "idle",
    enrolMFAStatus: "",
    deEnrolMFAStatus: "",
  },
  changePasswordState: {
    changePwdLoadingStatus: "idle",
    changeStatus: "",
  },
  languageState: {
    languageStatus: "idle",
    languages: [],
    isUserLangSentSuccess: false,
    isUserLangSentFail: false,
  },
};

export const fetchOktaUnEnrollUserThunk = createAsyncThunk(
  "/okta/user/unenroll",
  async (payload: string, thunkAPI) => {
    const response = await fetchOktaUnEnrollUser(payload, thunkAPI);
    return response;
  }
);

export const fetchLanguagesThunk = createAsyncThunk(
  "settings/languages",
  async () => {
    const response = await fetchLanguages();
    return response.resArr;
  }
);

export const updateUserLanguageThunk = createAsyncThunk(
  "settings/sentUserLanguage",
  async (payload: any, thunkAPI) => {
    const response = await setUserLanguage(payload, thunkAPI);
    return response;
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetSettingsState: () => initialState,
    updateSettingsPageState: (state, action: any) => {
      state.settingsPageState = action.payload;
    },
    enrollMFAStartLoading: (state) => {
      state.enrollMFAState.enrollMFALoadingStatus = "loading";
    },
    enrollMFAEndLoading: (state) => {
      state.enrollMFAState.enrollMFALoadingStatus = "idle";
    },
    enrollMFAInitiate: (state) => {
      state.enrollMFAState.enrolMFAStatus = "started";
    },
    enrollMFASuccess: (state) => {
      state.enrollMFAState.enrollMFALoadingStatus = "idle";
      state.enrollMFAState.enrolMFAStatus = "success";
    },
    enrollMFAFail: (state) => {
      state.enrollMFAState.enrollMFALoadingStatus = "idle";
      state.enrollMFAState.enrolMFAStatus = "fail";
    },
    changePasswordInitiate: (state) => {
      state.changePasswordState.changePwdLoadingStatus = "loading";
      state.changePasswordState.changeStatus = "";
    },
    changePasswordSuccess: (state) => {
      state.changePasswordState.changePwdLoadingStatus = "idle";
      state.changePasswordState.changeStatus = "success";
    },
    changePasswordFail: (state) => {
      state.changePasswordState.changePwdLoadingStatus = "idle";
      state.changePasswordState.changeStatus = "fail";
    },
    updateIsUserLanguageSentState: (state, action: any) => {
      state.languageState.isUserLangSentSuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOktaUnEnrollUserThunk.fulfilled, (state, action: any) => {
        state.enrollMFAState.enrollMFALoadingStatus = "idle";
        state.enrollMFAState.deEnrolMFAStatus = "success";
      })
      .addCase(fetchLanguagesThunk.fulfilled, (state, action: any) => {
        state.languageState.languageStatus = "idle";
        state.languageState.languages = [
          ...state.languageState.languages,
          ...action.payload,
        ];
      })
      .addCase(fetchLanguagesThunk.pending, (state) => {
        state.languageState.languageStatus = "loading";
      })
      .addCase(fetchLanguagesThunk.rejected, (state) => {
        state.languageState.languageStatus = "failed";
      })
      .addCase(updateUserLanguageThunk.fulfilled, (state, action: any) => {
        state.languageState.languageStatus = "idle";

        if (action.payload?.resStatus === 200) {
          state.languageState.isUserLangSentSuccess = true;
          state.languageState.isUserLangSentFail = false;
        }

        if (action.payload?.resStatus > 400) {
          state.languageState.isUserLangSentSuccess = false;
          state.languageState.isUserLangSentFail = true;
        }
      })
      .addCase(updateUserLanguageThunk.pending, (state) => {
        state.languageState.languageStatus = "loading";
        state.languageState.isUserLangSentSuccess = false;
        state.languageState.isUserLangSentFail = false;
      })
      .addCase(updateUserLanguageThunk.rejected, (state) => {
        state.languageState.languageStatus = "failed";
        state.languageState.isUserLangSentSuccess = false;
        state.languageState.isUserLangSentFail = true;
      })
      .addMatcher(isAnyOf(fetchOktaUnEnrollUserThunk.pending), (state) => {
        state.enrollMFAState.enrollMFALoadingStatus = "loading";
        state.enrollMFAState.deEnrolMFAStatus = "";
      })
      .addMatcher(isAnyOf(fetchOktaUnEnrollUserThunk.rejected), (state) => {
        state.enrollMFAState.enrollMFALoadingStatus = "failed";
        state.enrollMFAState.deEnrolMFAStatus = "fail";
      });
  },
});

export const settingsPageStateSelector = (state: RootState) =>
  state.settings.settingsPageState;

export const changePasswordStateSelector = (state: RootState) =>
  state.settings.changePasswordState;

export const enrollMFAStateSelector = (state: RootState) =>
  state.settings.enrollMFAState;

export const settingsLanguagesSelector = (state: RootState) =>
  state.settings.languageState.languages;

export const settingsLanguageStatusSelector = (state: RootState) =>
  state.settings.languageState.languageStatus;

export const isUserLanguageSentSuccessSelector = (state: RootState) =>
  state.settings.languageState.isUserLangSentSuccess;

export const isUserLanguageSentFailSelector = (state: RootState) =>
  state.settings.languageState.isUserLangSentFail;

export const {
  updateSettingsPageState: updateSettingsPageStateAction,
  resetSettingsState: resetSettingsStateAction,
  enrollMFAStartLoading: enrollMFAStartLoadingAction,
  enrollMFAEndLoading: enrollMFAEndLoadingAction,
  enrollMFAInitiate: enrollMFAInitiateAction,
  enrollMFASuccess: enrollMFASuccessAction,
  enrollMFAFail: enrollMFAFailAction,
  changePasswordInitiate: changePasswordInitiateAction,
  changePasswordSuccess: changePasswordSuccessAction,
  changePasswordFail: changePasswordFailAction,
  updateIsUserLanguageSentState: updateIsUserLanguageSentStateAction,
} = settingsSlice.actions;

export default settingsSlice.reducer;
