import {
  PubSyncTabStatusEnum,
  PubSyncTabsEnum,
} from "../../../app/types/enums";
import withClientSelectionData from "../clientSelection/withClientSelectionData";
import PubSyncHOC from "./PubSyncHOC";

const PubSyncReceived = (): JSX.Element => (
  <PubSyncHOC
    status={PubSyncTabStatusEnum.received}
    selectedTab={PubSyncTabsEnum.received}
  />
);

export default withClientSelectionData(PubSyncReceived);
