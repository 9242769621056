import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import { setYupLocale } from "../yup/yup_locales";
import packageJson from "../../../package.json";

export const languageFallback = "en";

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(detector)
  .init({
    fallbackLng: languageFallback.toLowerCase(),
    debug: false,
    //nonExplicitSupportedLngs: true,
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${packageJson.version}`,
      requestOptions: {
        cache: "no-store",
      },
    },
    load: "all",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnNull: false,
    saveMissing: true, // Must be set to true
    parseMissingKeyHandler: function (key) {
      //if (process.env.REACT_APP_APP_ENVIRONMENT?.toUpperCase() === "DEV") {
      // alert("Warning: Missing translation found. Check console.");
      // eslint-disable-next-line no-console
      console.log("No translation found for key: %c" + key, "color: red");
      //}
      return "MISSING_TRANSLATION";
    },
  });

// set yup validation language
setYupLocale(languageFallback.toLowerCase());

export default i18n;
