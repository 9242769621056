import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../../../app/redux/store";
import { CommonRequestBodyTableState } from "../../../../../../app/types/props";
import {
  SortDirectionEnum,
  StatusEnum,
} from "../../../../../../app/types/enums";

import { useFetch } from "../../../../common/hooks/useFetch";
import { DEFAULT_ROWS_PER_PAGE } from "../../../../common/adminEndpoints.const";

import { getUsersList } from "../../../Users/UsersAPI";
import { getSelectedUsers } from "../../AddEditNewsAPI";

import { tableHeadData } from "./NewsUsers.const";

export type NewsUsersListItem = {
  id: number;
  username: string;
  email: string;
  groups: string[];
};

type NewsUsersInitialState = {
  newsUsersPageState: CommonRequestBodyTableState;
  tableData: {
    list: NewsUsersListItem[] | null;
    count: number | null;
    totalCount: number | null;
  };
  status: StatusEnum;
};

const initialState: NewsUsersInitialState = {
  newsUsersPageState: {
    filterText: "",
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: tableHeadData?.[0].filteringInfo,
          direction: SortDirectionEnum.Ascending,
        },
      ],
    },
  },
  tableData: {
    list: null,
    count: 0,
    totalCount: 0,
  },
  status: StatusEnum.Idle,
};

export const getNewsUsersThunk = createAsyncThunk(
  "admin/news/users",
  async (payload: any, { rejectWithValue }) => {
    try {
      const getUsers = await useFetch();
      const response = await getUsers({
        data: payload,
        fetchFunction: getUsersList,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSelectedUsersThunk = createAsyncThunk(
  "admin/news/users/selected",
  async (payload: any, { rejectWithValue }) => {
    try {
      const fetchSelectedUsers = await useFetch();
      const response = await fetchSelectedUsers({
        data: payload,
        fetchFunction: getSelectedUsers,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const newsUsersSlice = createSlice({
  name: "newsUsers",
  initialState,
  reducers: {
    updatePageState: (state, action: any) => {
      state.newsUsersPageState.filterText = action.payload.filterText;
      state.newsUsersPageState.pagination = action.payload.pagination;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsUsersThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(getNewsUsersThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getNewsUsersThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      })
      .addCase(getSelectedUsersThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(getSelectedUsersThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getSelectedUsersThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      });
  },
});

export const NewsUsersSelector = (state: RootState) => state.newsUsers;
export default newsUsersSlice.reducer;
