import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Sidebar } from "../../app/molecules/sidebar";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";

import {
  appBEVersionSelector,
  fetchAppBEVersionThunk,
  meSelector,
} from "../appMain/appMainSlice";

import SiteLayoutWrapper from "../common/SiteLayoutWrapper/SiteLayoutWrapper";
import { menus } from "./sidebarAdminMenu";

import * as Paths from "../routes/paths.const";
import { UserAccountTypeEnum } from "../../app/types/enums";
import debounce from "../../app/utils/debounce";

const SiteLayoutAdmin = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const user = useAppSelector(meSelector);
  const { userAccountType, isGuided } = user;
  const navigate = useNavigate();
  const appBEVersion = useAppSelector(appBEVersionSelector);
  const dispatch = useAppDispatch();
  const dispatchedAppBEVersion = useRef<any>();

  const abortDispatchedAppBEVersion = useCallback(() => {
    if (dispatchedAppBEVersion.current) dispatchedAppBEVersion.current.abort();
  }, []);

  let permittedMenus = menus;
  if (user.isReadOnlyAdmin) {
    permittedMenus = menus.filter((menu) => menu.isReadOnlyAdmin);
  } else if (!user.isSuperAdmin) {
    permittedMenus = menus.filter((menu) => !menu.isSuperAdmin);
  }

  const isUserAccountTypeAdmin = useMemo(
    () => userAccountType.toLocaleUpperCase() === UserAccountTypeEnum.admin,
    [userAccountType]
  );

  const handleDispatchedAppBEVersion = useMemo(
    () =>
      debounce(() => {
        abortDispatchedAppBEVersion();
        dispatchedAppBEVersion.current = dispatch(fetchAppBEVersionThunk({}));
      }, 500),
    [abortDispatchedAppBEVersion, dispatch]
  );

  useEffect(() => {
    if (userAccountType.length && !isUserAccountTypeAdmin && !isGuided) {
      navigate(Paths.PATH_ERROR403);
      navigate(0);
    }
  }, [isGuided, isUserAccountTypeAdmin, navigate, userAccountType.length]);

  useEffect(() => {
    if (appBEVersion === null) {
      handleDispatchedAppBEVersion();
    }
  }, [appBEVersion, handleDispatchedAppBEVersion]);

  useEffect(() => {
    return () => {
      abortDispatchedAppBEVersion();
    };
  }, [abortDispatchedAppBEVersion]);

  return (
    <SiteLayoutWrapper
      sidebarComponent={Sidebar}
      children={isUserAccountTypeAdmin ? children : null}
      menus={isUserAccountTypeAdmin ? permittedMenus : null}
    />
  );
};

export default SiteLayoutAdmin;
