import React from "react";
function SyncIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      name="TickIcon"
      {...props}
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#6AB418"
        strokeWidth=".25"
        d="M16.264.716 9.106 9.55c-.022.031-.024.035-.026.04l-1.937 2.408a.314.314 0 0 1-.22.118.314.314 0 0 1-.232-.081L1.138 7.003a.255.255 0 0 0-.358.017.252.252 0 0 0 .017.357l5.554 5.032a.818.818 0 0 0 1.186-.093L9.5 9.876c.006-.009 7.16-8.842 7.16-8.842a.254.254 0 0 0-.395-.317Z"
      />
    </svg>
  );
}

export default SyncIcon;
