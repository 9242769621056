import { useTranslation } from "react-i18next";
import type {
  RecAnalysisAlbumByTrackData,
  RecAnalysisAlbumByTrackEntry,
} from "../../../../features/recording/analysis/album/recAnalysisAlbumBreakdownAPI";
import styles from "./../analysis.module.scss";

const AnalysisAlbumBreakdownTracks = ({
  data,
  status,
}: {
  data: RecAnalysisAlbumByTrackData | null;
  status: "noData" | "loading" | "idle" | "failed";
}): JSX.Element => {
  const { t } = useTranslation();

  if (status === "loading")
    return <p className="pl-2 pt-4">{t("app.loading")}</p>;

  if (!data) {
    if (status === "idle")
      return (
        <p className="pl-2 pt-4">{t("analysis.songs.breakdown.noData")}</p>
      );
    else return <p className="pl-2 pt-4">{t("app.loading")}</p>;
  }

  const renderStatRow = (i: number, name: string, isrc: string) => {
    return (
      <div className={styles.statRow} key={i}>
        <div className={styles.title}>{name}</div>
        <div className={styles.isrc}>
          {t("analysis.tracks.isrc")}: {isrc}
        </div>
      </div>
    );
  };

  return (
    <div className={`smallScrollbar ${styles.tracksStatsContainer}`}>
      {data.tracks.map((t: RecAnalysisAlbumByTrackEntry, i: number) => {
        return renderStatRow(i, t.title, t.isrc);
      })}
    </div>
  );
};

export default AnalysisAlbumBreakdownTracks;
