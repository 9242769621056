import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

import { maintenanceDataProps } from "./maintenanceSlice";

export const saveMaintenanceWindow = (
  data: maintenanceDataProps,
  signal?: any
) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.maintenance}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal,
  });
};

export const getMaintenanceWindows = () => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.maintenance}`, {
    method: "GET",
  });
};

export const editMaintenanceWindow = (
  data: maintenanceDataProps,
  signal?: any
) => {
  const id = data.id;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.maintenance}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
    signal,
  });
};

export function deleteMaintenanceWindow(id: number, signal?: any) {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.maintenance}/${id}`, {
    method: "DELETE",
    body: JSON.stringify(id),
    signal,
  });
}
