import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import { fetchSendForgotUsernamePayload } from "./forgotUsernameAPI";

export interface ForgotUsernameState {
  retieveStatus: "idle" | "loading" | "failed";
  retieveSuccess: boolean;
}

const initialState: ForgotUsernameState = {
  retieveStatus: "idle",
  retieveSuccess: false,
};

export const fetchSubmitForgotUsernameThunk = createAsyncThunk(
  "forgot/username",
  async (payload: string, thunkAPI?: any) => {
    const response = await fetchSendForgotUsernamePayload(payload, thunkAPI);
    return response;
  }
);

export const useForgotUsernameSlice = createSlice({
  name: "userForgotUsername",
  initialState,
  reducers: {
    resetForgotUsernameForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubmitForgotUsernameThunk.fulfilled, (state) => {
        state.retieveStatus = "idle";
        state.retieveSuccess = true;
      })
      .addCase(fetchSubmitForgotUsernameThunk.pending, (state) => {
        state.retieveStatus = "loading";
      })
      .addCase(fetchSubmitForgotUsernameThunk.rejected, (state) => {
        state.retieveStatus = "failed";
        state.retieveSuccess = false;
      });
  },
});

export const retrieveStatusSelector = (state: RootState) =>
  state.userForgotUsername.retieveStatus;

export const retrieveSuccessSelector = (state: RootState) =>
  state.userForgotUsername.retieveSuccess;

export const { resetForgotUsernameForm: resetForgotUsernameFormAction } =
  useForgotUsernameSlice.actions;

export default useForgotUsernameSlice.reducer;
