/* 
    Used in sitebarLayout/SitebarLayout.tsx to build sidebar nav items .
    Please add more paths here :)
*/

import { ROUTES } from "./routes.const";

export const PATH_ERROR403 = `/${ROUTES.forbidden}`;
export const PATH_ERROR500 = `/${ROUTES.internalError}`;
export const PATH_WELCOME_TOUR = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.welcometour}`;
export const PATH_COMBINED_DASHBOARD = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.combdashboard}`;

export const PATH_PUB_DASHBOARD = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.dashboard}`;
export const PATH_PUB_ANALYSIS_SONGS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.analysis}/${ROUTES.songs}`;
export const PATH_PUB_ANALYSIS_TERRITORIES = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.analysis}/${ROUTES.territories}`;
export const PATH_PUB_ANALYSIS_SOURCES = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.analysis}/${ROUTES.sources}`;
export const PATH_PUB_ANALYSIS_DIGITAL = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.analysis}/${ROUTES.digital}`;
export const PATH_PUB_ANALYSIS_TYPE = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.analysis}/${ROUTES.type}`;
export const PATH_PUB_SYNC_OVERVIEW = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.sync}/${ROUTES.overview}`;
export const PATH_PUB_SYNC_ALL = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.sync}/${ROUTES.all}`;
export const PATH_PUB_SYNC_QUOTING = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.sync}/${ROUTES.quoting}`;
export const PATH_PUB_SYNC_LICENSING = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.sync}/${ROUTES.licensing}`;
export const PATH_PUB_SYNC_RECEIVED = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.sync}/${ROUTES.received}`;
export const PATH_PUB_SYNC_COMPLETED = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.sync}/${ROUTES.completed}`;
export const PATH_PUB_STATEMENTS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.statements}/${ROUTES.statements}`;
export const PATH_PUB_TAX_DOCUMENTS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.statements}/${ROUTES.taxdocuments}`;
export const PATH_PUB_OVERVIEW = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.overview}`;
export const PATH_PUB_CLIENTS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.clients}`;
export const PATH_PUB_CONTACTS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.contacts}`;
export const PATH_PUB_REGISTRATION = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.songregistration}`;

export const PATH_REC_DASHBOARD = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.dashboard}`;
export const PATH_REC_ANALYSIS_TERRITORIES = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.analysis}/${ROUTES.territories}`;
export const PATH_REC_ANALYSIS_TRACKS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.analysis}/${ROUTES.tracks}`;
export const PATH_REC_ANALYSIS_ALBUMS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.analysis}/${ROUTES.albums}`;
export const PATH_REC_ANALYSIS_SOURCES = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.analysis}/${ROUTES.sources}`;
export const PATH_REC_ANALYSIS_DIGITAL = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.analysis}/${ROUTES.digital}`;
export const PATH_REC_ANALYSIS_CHANNEL = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.analysis}/${ROUTES.distchannels}`;
export const PATH_REC_SYNC_OVERVIEW = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.sync}/${ROUTES.overview}`;
export const PATH_REC_SYNC_ALL = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.sync}/${ROUTES.all}`;
export const PATH_REC_SYNC_QUOTING = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.sync}/${ROUTES.quoting}`;
export const PATH_REC_SYNC_LICENSING = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.sync}/${ROUTES.licensing}`;
export const PATH_ERC_DIGITAL_FLASH = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.digitalflash}`;
export const PATH_REC_SYNC_RECEIVED = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.sync}/${ROUTES.received}`;
export const PATH_REC_SYNC_COMPLETED = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.sync}/${ROUTES.completed}`;
export const PATH_REC_STATEMENTS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.statements}/${ROUTES.statements}`;
export const PATH_REC_TAX_DOCUMENTS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.statements}/${ROUTES.taxdocuments}`;
export const PATH_REC_OVERVIEW = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.overview}`;
export const PATH_REC_CONTACTS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.recording}/${ROUTES.contacts}`;

export const PATH_MEC_DASHBOARD = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.mechanical}/${ROUTES.dashboard}`;
export const PATH_MEC_STATEMENTS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.mechanical}/${ROUTES.statements}`;
export const PATH_MEC_CONTACTS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.mechanical}/${ROUTES.contacts}`;

export const PATH_HELP = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.help}`; //?page=all&tag=360,publishing,recording
export const PATH_CONTACTUS = `/${ROUTES.mybmg}/${ROUTES.contactUs}`;
export const PATH_SETTINGS = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.settings}`;

export const PATH_MORE_ADMIN = `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.users}`;
export const PATH_MORE_CONTACT = `/${ROUTES.mybmg}/${ROUTES.user}/${ROUTES.more}/${ROUTES.contacts}`;
