import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { StatusEnum } from "../../../../app/types/enums";

import { useFetch } from "../../common/hooks/useFetch";

import {
  getPublishingOwningSystems,
  getRecordingOrMechanicalOwningSystems,
  getEmailFooter,
  updateEmailFooter,
} from "./EmailFootersAPI";

export interface emailFootersState {
  status: StatusEnum;
}

const initialState: emailFootersState = {
  status: StatusEnum.Idle,
};

export const getPublishingOwningSystemsThunk = createAsyncThunk(
  "admin/emailFooters/publishingOwningSystems",
  async (_, { rejectWithValue }) => {
    try {
      const getPublishingSystems = await useFetch();
      const response = await getPublishingSystems({
        fetchFunction: getPublishingOwningSystems,
      });

      return response?.resArr.sort();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRecordingOrMechanicalOwningSystemsThunk = createAsyncThunk(
  "admin/emailFooters/recordingOrMechanicalOwningSystems",
  async (_, { rejectWithValue }) => {
    try {
      const getRecordingOrMechanicalSystems = await useFetch();
      const response = await getRecordingOrMechanicalSystems({
        fetchFunction: getRecordingOrMechanicalOwningSystems,
      });

      return response?.resArr.sort();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEmailFootersThunk = createAsyncThunk(
  "admin/emailFooters/get",
  async (payload: any, { rejectWithValue }) => {
    try {
      const getFooters = await useFetch();
      const response = await getFooters({
        fetchFunction: getEmailFooter,
        data: payload,
      });

      return response?.footer;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateEmailFooterThunk = createAsyncThunk(
  "admin/emailFooters/update",
  async (payload: any, { rejectWithValue }) => {
    try {
      const updateFooter = await useFetch();
      const response = await updateFooter({
        fetchFunction: updateEmailFooter,
        data: payload,
        customSuccessMsg: "adminSection.common.successEditMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const emailFootersSlice = createSlice({
  name: "emailFooters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getPublishingOwningSystemsThunk.fulfilled,
        (state, action: any) => {
          state.status = StatusEnum.Idle;
        }
      )
      .addCase(
        getPublishingOwningSystemsThunk.pending,
        (state, action: any) => {
          state.status = StatusEnum.Loading;
        }
      )
      .addCase(
        getPublishingOwningSystemsThunk.rejected,
        (state, action: any) => {
          state.status = StatusEnum.Failed;
        }
      )
      .addCase(
        getRecordingOrMechanicalOwningSystemsThunk.fulfilled,
        (state, action: any) => {
          state.status = StatusEnum.Idle;
        }
      )
      .addCase(
        getRecordingOrMechanicalOwningSystemsThunk.pending,
        (state, action: any) => {
          state.status = StatusEnum.Loading;
        }
      )
      .addCase(
        getRecordingOrMechanicalOwningSystemsThunk.rejected,
        (state, action: any) => {
          state.status = StatusEnum.Failed;
        }
      )
      .addCase(getEmailFootersThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(getEmailFootersThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getEmailFootersThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(updateEmailFooterThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(updateEmailFooterThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(updateEmailFooterThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      });
  },
});

export const emailFootersSelector = (state: RootState) => state.emailFooters;
export default emailFootersSlice.reducer;
