import React from "react";
function TriangleIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 8 8"
      fill="none"
      name="TriangleIcon"
      {...props}
    >
      <g>
        <polygon points="0 0 7.40132919 0 3.70066459 5"></polygon>
      </g>
    </svg>
  );
}

export default TriangleIcon;
