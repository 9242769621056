import React from "react";
function EditIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 17 15"
      name="EditIcon"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.165 5.201a9.296 9.296 0 0 0-1.9-3.046A9.535 9.535 0 0 0 11.332.033a.35.35 0 0 0-.381.056l-7.21 6.43-.833.743a.349.349 0 0 0-.107.14l-.012.029-1.939 4.92-.827 2.1a.333.333 0 0 0 .067.348.348.348 0 0 0 .348.096l2.218-.614 5.215-1.445a.362.362 0 0 0 .142-.077l.85-.76 7.21-6.43a.334.334 0 0 0 .092-.368ZM1.356 12.939c.014.009.03.013.044.02.147.091.284.191.398.315.114.121.204.263.283.415.009.013.012.03.018.045l-1.186.329.443-1.124Zm6.253-.731L2.78 13.546c-.013-.03-.038-.051-.052-.08a2.503 2.503 0 0 0-.42-.646 2.56 2.56 0 0 0-.623-.468c-.029-.015-.046-.041-.075-.056l1.802-4.572.54-.48.437.466 3.757 4.019-.537.479Zm1.046-.934-1.092-1.167 5.732-5.11a.336.336 0 0 0 .111-.25.326.326 0 0 0-.09-.225.351.351 0 0 0-.487-.021L7.1 9.611l-.225-.24-.859-.921 5.729-5.11a.333.333 0 0 0 .022-.476.353.353 0 0 0-.487-.02l-5.73 5.11L4.46 6.79 11.24.74a8.81 8.81 0 0 1 2.515 1.872 8.623 8.623 0 0 1 1.68 2.615l-6.78 6.047Z"
      />
    </svg>
  );
}

export default EditIcon;
