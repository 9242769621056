const throttle = (fn: any, ms = 300) => {
  let wait = false;
  return function (this: any, ...args: any[]) {
    if (wait) {
      return;
    }
    wait = true;
    fn.apply(this, args as []);
    setTimeout(() => {
      wait = false;
    }, ms);
  };
};

export default throttle;
