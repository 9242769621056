import {
  PubSyncTabStatusEnum,
  PubSyncTabsEnum,
} from "../../../app/types/enums";
import withClientSelectionData from "../clientSelection/withClientSelectionData";
import PubSyncHOC from "./PubSyncHOC";

const PubSyncLicensing = (): JSX.Element => (
  <PubSyncHOC
    status={PubSyncTabStatusEnum.licensing}
    selectedTab={PubSyncTabsEnum.licensing}
  />
);

export default withClientSelectionData(PubSyncLicensing);
