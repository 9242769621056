import {
  RecSyncTabStatusEnum,
  RecSyncTabsEnum,
} from "../../../app/types/enums";
import withClientSelectionData from "../clientSelection/withClientSelectionData";
import withPeriodSelectorData from "../../../app/molecules/periodSelector/withPeriodSelectorData";
import RecSyncHOC from "./RecSyncHOC";

const RecSyncCompleted = (props: any): JSX.Element => (
  <RecSyncHOC
    status={RecSyncTabStatusEnum.completed}
    selectedTab={RecSyncTabsEnum.completed}
    hasPeriodSelector={true}
  />
);

export default withClientSelectionData(
  withPeriodSelectorData(RecSyncCompleted)
);
