import React from "react";
function LikeIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="100%"
      height="100%"
      name="LikeIcon"
      {...props}
    >
      <g data-name="1">
        <path d="M348.45 432.7H261.8a141.5 141.5 0 0 1-49.52-8.9l-67.5-25.07a15 15 0 0 1 10.45-28.12l67.49 25.07a111.79 111.79 0 0 0 39.08 7h86.65a14.21 14.21 0 1 0 0-28.42 15 15 0 0 1 0-30h20.45a14.21 14.21 0 1 0 0-28.42 15 15 0 0 1 0-30h20.44a14.21 14.21 0 0 0 10.05-24.26 14.08 14.08 0 0 0-10.05-4.16 15 15 0 0 1 0-30h20.45a14.21 14.21 0 0 0 10-24.26 14.09 14.09 0 0 0-10-4.17H268.15A15 15 0 0 1 255 176.74a100.2 100.2 0 0 0 9.2-29.33c3.39-21.87-.79-41.64-12.42-58.76a12.28 12.28 0 0 0-22.33 7c.49 51.38-23.25 88.72-68.65 108a15 15 0 1 1-11.72-27.61c18.72-8 32.36-19.75 40.55-35.08 6.68-12.51 10-27.65 9.83-45C199.31 77 211 61 229.18 55.34s36.81.78 47.45 16.46c24.71 36.36 20.25 74.1 13.48 97.21h119.68a44.21 44.21 0 0 1 19.59 83.84 44.27 44.27 0 0 1-20.44 58.42 44.27 44.27 0 0 1-20.45 58.43 44.23 44.23 0 0 1-40 63Z" />
        <path d="M155 410.49H69.13a15 15 0 0 1-15-15V189.86a15 15 0 0 1 15-15H155a15 15 0 0 1 15 15v205.63a15 15 0 0 1-15 15Zm-70.84-30H140V204.86H84.13Z" />
      </g>
    </svg>
  );
}

export default LikeIcon;
