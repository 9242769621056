import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  fetchGetLoginEmail,
  fetchOktaGroupAdd,
  fetchOktaGroupRemove,
} from "./loginAPI";
import { RootState } from "../../../app/redux/store";
import { GetLoginEmailPayload } from "../../../app/types/payload";

export interface LoginState {
  loginStatus: "idle" | "loading" | "failed";
  userEmails?: { [key: string]: { email: string } };
}

const initialState: LoginState = {
  loginStatus: "idle",
  userEmails: undefined,
};

export const fetchOktaGroupAddThunk = createAsyncThunk(
  "okta/group/add",
  async (payload: string, thunkAPI) => {
    const response = await fetchOktaGroupAdd(payload, thunkAPI);
    return response;
  }
);

export const fetchOktaGroupRemoveThunk = createAsyncThunk(
  "okta/group/remove",
  async (payload: string, thunkAPI) => {
    const response = await fetchOktaGroupRemove(payload, thunkAPI);
    return response;
  }
);

export const fetchGetLoginEmailThunk = createAsyncThunk(
  "users/getlogin",
  async (payload: GetLoginEmailPayload, thunkAPI) => {
    const response = await fetchGetLoginEmail(payload, thunkAPI);
    if (response?.resStatus === 200) {
      const res: { email: string; id: string } = {
        email: response.resBody,
        id: payload?.identifier,
      };
      return res;
    } else {
      return thunkAPI.rejectWithValue(response.resBody);
    }
  }
);

export const loginSlice = createSlice({
  name: "userLogin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOktaGroupAddThunk.fulfilled, (state, action: any) => {
        state.loginStatus = "idle";
      })
      .addCase(fetchOktaGroupRemoveThunk.fulfilled, (state) => {
        state.loginStatus = "idle";
      })
      .addCase(fetchGetLoginEmailThunk.fulfilled, (state, action) => {
        state.loginStatus = "idle";
        state.userEmails = {
          ...state?.userEmails,
          [action.payload.id]: { email: action.payload.email },
        };
      })
      .addMatcher(
        isAnyOf(
          fetchOktaGroupAddThunk.pending,
          fetchOktaGroupRemoveThunk.pending,
          fetchGetLoginEmailThunk.pending
        ),
        (state) => {
          state.loginStatus = "loading";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchOktaGroupAddThunk.rejected,
          fetchOktaGroupRemoveThunk.rejected,
          fetchGetLoginEmailThunk.rejected
        ),
        (state) => {
          state.loginStatus = "failed";
        }
      );
  },
});

// export const {} = loginSlice.actions;

export const loginStatusSelector = (state: RootState) =>
  state.userLogin.loginStatus;
export const userEmailsSelector = (state: RootState) =>
  state.userLogin.userEmails;

export default loginSlice.reducer;
