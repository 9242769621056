import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { getJobs, triggerJob } from "./MiscAPI";
import { StatusEnum } from "../../../../app/types/enums";

import { useFetch } from "../../common/hooks/useFetch";

type Job = {
  job: string;
  status: string;
  startTime?: string;
  finishTime?: string;
  runtime?: string;
  totalProgress?: string;
};

export interface miscState {
  status: StatusEnum;
  jobs: Job[];
}

const initialState: miscState = {
  status: StatusEnum.Idle,
  jobs: [],
};

export const getJobsThunk = createAsyncThunk(
  "admin/misc/getJobs",
  async (_, { rejectWithValue }) => {
    try {
      const fetchJobs = useFetch();
      const response = await fetchJobs({
        fetchFunction: getJobs,
      });
      return response?.resArr;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const triggerJobThunk = createAsyncThunk(
  "admin/misc/triggerJob",
  async (payload: any, { rejectWithValue }) => {
    try {
      const fetchTriggerJob = useFetch();
      const response = await fetchTriggerJob({
        fetchFunction: triggerJob,
        data: payload,
        customSuccessMsg: "adminSection.elasticsearch.triggeredJobTitle",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const miscSlice = createSlice({
  name: "misc",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getJobsThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.jobs = action.payload;
      })
      .addCase(getJobsThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getJobsThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(triggerJobThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(triggerJobThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(triggerJobThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      });
  },
});

export const miscSelector = (state: RootState) => state.misc;
export default miscSlice.reducer;
