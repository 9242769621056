import React from "react";
function AdminIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 14 16"
      fill="none"
      name="AdminIcon"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M13.919 3.41c0-.625-.375-1.048-1.005-1.128-.12-.016-.242-.03-.362-.043-.355-.043-.69-.08-1.011-.15C10.284 1.813 9.066 1.244 7.72.3 7.498.143 7.252 0 6.964 0c-.286 0-.531.143-.753.296C4.41 1.537 2.788 2.17 1.11 2.276.383 2.322.002 2.735.002 3.47v1.324c-.003 1.221-.003 2.482.01 3.727.003.462.063.941.172 1.427.259 1.138.85 2.163 1.805 3.13 1.19 1.209 2.683 2.14 4.56 2.849.126.046.262.073.405.073.172 0 .345-.037.497-.103.103-.047.21-.09.312-.133l.01-.004c.454-.196.922-.399 1.38-.638 1.525-.809 2.68-1.764 3.529-2.922.762-1.038 1.157-2.082 1.204-3.194.01-.212.016-.439.02-.695l.016-2.056V5.42c0-.672 0-1.341-.003-2.01Zm-.796 2.805v.023l-.017 2.063c-.003.246-.01.463-.016.669-.04.948-.385 1.85-1.052 2.755-.776 1.058-1.844 1.936-3.26 2.688-.428.226-.882.423-1.324.612l-.01.003-.315.137a.453.453 0 0 1-.305.017c-1.767-.67-3.167-1.54-4.275-2.662-.842-.862-1.363-1.76-1.589-2.748-.1-.43-.149-.852-.152-1.258-.013-1.238-.01-2.499-.01-3.716V3.473c0-.153.023-.266.07-.312.043-.047.145-.077.295-.087 1.827-.12 3.575-.792 5.499-2.122.149-.104.249-.153.302-.153.053 0 .152.053.301.156 1.437 1.005 2.743 1.614 4.107 1.913.358.08.713.12 1.09.163.12.013.236.027.356.043.232.03.308.113.308.336v2.007l-.003.798Z"
      />
    </svg>
  );
}

export default AdminIcon;
