import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { SortDirectionEnum, StatusEnum } from "../../../../app/types/enums";
import { SortingCriteria } from "../../../../app/types/props";
import { useFetch } from "../../common/hooks/useFetch";
import {
  createParent,
  deleteParentsById,
  getCsvTemplate,
  getCurrencies,
  getParentsById,
  getParentsTableData,
  updateParent,
  uploadFile,
} from "./ParentsAPI";

import {
  DEFAULT_ROWS_PER_PAGE,
  parentsFor,
} from "../../common/adminEndpoints.const";
import { tableHeaderData } from "./ParentsPage.const";

export type CurrenciesProps = {
  currencyCode: string;
}[];

export interface ParentsState {
  currencies: CurrenciesProps | [];
  status: StatusEnum;
}

export type ParentsListItem = {
  id: number;
  name: string;
  currency: string;
  description: string;
  linkedClients: string;
};

export type ParentsPageState = {
  filterText: string;
  pagination: {
    page: number;
    size: number;
    sortingCriteria: SortingCriteria[];
  };
  searchCriteria: {
    clientDocumentType: string;
  };
};

export type ParentsFormDataState = {
  id: number;
  name: string;
  currency: string;
  description: string;
  clientDocumentType: string;
  clients: [];
};

export type PublishingLinkedClientsArrayState = {
  id: number;
  name: string;
  territory: string;
  email: string;
  siteCode: string;
  depth: number;
  clientId: string;
};

type ParentsInitialState = {
  currencies: CurrenciesProps | [];
  parentsPageState: ParentsPageState;
  tableData: {
    list: ParentsListItem[] | null;
    count: number | null;
    totalCount: number | null;
  };
  status: StatusEnum;
  parentsFormData: ParentsFormDataState | null;
  publishingLinkedClientsArray: PublishingLinkedClientsArrayState[];
  recordingLinkedClientsArray: PublishingLinkedClientsArrayState[];
};

const initialState: ParentsInitialState = {
  currencies: [],
  status: StatusEnum.Idle,
  parentsPageState: {
    filterText: "",
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: tableHeaderData?.[0].filteringInfo,
          direction: SortDirectionEnum.Ascending,
        },
      ],
    },
    searchCriteria: {
      clientDocumentType: parentsFor[0].value,
    },
  },
  tableData: {
    list: null,
    count: 0,
    totalCount: 0,
  },
  parentsFormData: null,
  publishingLinkedClientsArray: [],
  recordingLinkedClientsArray: [],
};

export const getCurrenciesThunk = createAsyncThunk(
  "parents/get-currencies",
  async (payload: any, { rejectWithValue }) => {
    try {
      const _getCurrencies = await useFetch();
      const response = await _getCurrencies({
        data: payload,
        fetchFunction: getCurrencies,
      });

      return response.resArr;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getParentsTableDataThunk = createAsyncThunk(
  "admin/parents",
  async (payload: any, { rejectWithValue }) => {
    try {
      const _getParentsTableData = await useFetch();
      const response = await _getParentsTableData({
        data: payload,
        fetchFunction: getParentsTableData,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getParentsByIdThunk = createAsyncThunk(
  "admin/parents/get/by-id",
  async (payload: any, { rejectWithValue }) => {
    try {
      const _getParentsById = await useFetch();
      const response = await _getParentsById({
        data: payload,
        fetchFunction: getParentsById,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteParentsByIdThunk = createAsyncThunk(
  "admin/parents/delete/by-id",
  async (payload: any, { rejectWithValue }) => {
    try {
      const _deleteParentsById = await useFetch();
      const response = await _deleteParentsById({
        data: payload,
        fetchFunction: deleteParentsById,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createParentThunk = createAsyncThunk(
  "admin/parents/create",
  async (payload: any, { rejectWithValue }) => {
    try {
      const _createParent = await useFetch();
      const response = await _createParent({
        data: payload,
        fetchFunction: createParent,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateParentThunk = createAsyncThunk(
  "admin/parents/update",
  async (payload: any, { rejectWithValue }) => {
    try {
      const _createParent = await useFetch();
      const response = await _createParent({
        data: payload,
        fetchFunction: updateParent,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCsvTemplateThunk = createAsyncThunk(
  "admin/parents/get-csv-template",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getCsvTemplate(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadFileThunk = createAsyncThunk(
  "admin/parents/upload-csv-file",
  async (payload: any, { rejectWithValue }) => {
    try {
      const _uploadFile = await useFetch();
      const response = await _uploadFile({
        data: payload,
        fetchFunction: uploadFile,
        customSuccessMsg: "adminSection.parents.bulkImportPage.successUploaded",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const parentsSlice = createSlice({
  name: "parents",
  initialState,
  reducers: {
    updatePageState: (state, action: any) => {
      state.parentsPageState.searchCriteria = action.payload.searchCriteria;
      state.parentsPageState.filterText = action.payload.filterText;
      state.parentsPageState.pagination = action.payload.pagination;
    },
    updatePublishingLinkedClientsArray: (state, action: any) => {
      state.publishingLinkedClientsArray = action.payload;
    },
    updateRecordingLinkedClientsArray: (state, action: any) => {
      state.recordingLinkedClientsArray = action.payload;
    },
    resetAllData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrenciesThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.currencies = action.payload;
      })
      .addCase(getCurrenciesThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getCurrenciesThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(getParentsTableDataThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(getParentsTableDataThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getParentsTableDataThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      })
      .addCase(getParentsByIdThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.parentsFormData = action.payload;
      })
      .addCase(getParentsByIdThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getParentsByIdThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(deleteParentsByIdThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(deleteParentsByIdThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(deleteParentsByIdThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(createParentThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(createParentThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(createParentThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(getCsvTemplateThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(getCsvTemplateThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getCsvTemplateThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(uploadFileThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(uploadFileThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(uploadFileThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      });
  },
});

export const ParentsSelector = (state: RootState) => state.parents;

export default parentsSlice.reducer;
