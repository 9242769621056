import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../hooks";
import styles from "./../analysis.module.scss";

const AnalysisSongBreakdownType = ({
  data,
  status,
}: {
  data: any;
  status: "noData" | "loading" | "idle" | "failed";
}): JSX.Element => {
  const { t } = useTranslation();
  const { width = 0 } = useWindowSize();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  if (status === "loading")
    return <p className="pl-2 pt-4">{t("app.loading")}</p>;

  if (!data || !data.incomeTypes) {
    if (status === "idle")
      return (
        <p className="pl-2 pt-4">{t("analysis.songs.breakdown.noData")}</p>
      );
    else return <p className="pl-2 pt-4">{t("app.loading")}</p>;
  }

  const chartData = data.incomeTypes.map((d: any) => {
    return {
      name: d.categoryName,
      y: d.revenue.percent,
      color: d.color,
    };
  });

  const pie_options = {
    title: {
      text: "",
    },
    chart: {
      backgroundColor: "transparent",
      plotBorderWidth: 0,
      plotShadow: false,
      height: 226,
      width: 226,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        borderWidth: 1,
        borderRadius: 0,
        borderColor: null,
        dataLabels: {
          enabled: false,
        },
        size: "100%",
        center: ["50%", "50%"],
      },
      series: {
        enableMouseTracking: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        name: "Stats",
        type: "pie",
        innerSize: "79%",
        data: chartData,
      },
    ],
  };

  const column_options = {
    chart: {
      type: "column",
      backgroundColor: "transparent",
      plotBorderWidth: 0,
      plotShadow: false,
      height: 130,
      width:
        width < 768
          ? width - 110
          : width < 1024
          ? width - 250
          : width < 1200
          ? width - 300
          : width,
    },
    tooltip: { enabled: false },
    title: {
      style: { display: "none" },
    },
    subtitle: {
      style: { display: "none" },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        minPointLength: 2,
      },
    },
    series: [
      {
        name: "Stats",
        type: "column",
        data: chartData,
      },
    ],
  };

  const renderStatRow = (i: number, rowData: any) => {
    return (
      <div className={styles.statRow} key={i}>
        <div className={styles.info}>
          <i
            style={{
              backgroundColor: rowData.color,
            }}
          ></i>
          <span>
            {t(`analysis.territories.mainIncType.${rowData.mainIncType}`)}
          </span>
        </div>
        <div className={styles.value}>
          <span className={styles.royalty}>
            {rowData.revenue.currency} {rowData.revenue.formattedShort}
          </span>
          <span className={styles.percent}>{rowData.revenue.percent}%</span>
        </div>
      </div>
    );
  };

  return (
    <div className={`smallScrollbar ${styles.typeStatsContainer}`}>
      <div className={styles.columnChartContainer}>
        <HighchartsReact
          highcharts={Highcharts}
          options={column_options}
          ref={chartComponentRef}
        />
        <hr />
      </div>
      <div className={styles.pieChartContainer}>
        <HighchartsReact
          highcharts={Highcharts}
          options={pie_options}
          ref={chartComponentRef}
        />
        <div className={styles.chartLabel}>
          <span>
            {t("analysis.songs.breakdown.typeBreakdown.total")}
            <br />
            {data.revenue.currency} {data.revenue.formattedShort}
          </span>
        </div>
      </div>
      <div className="pt-4 md:w-1/2 md:pt-0">
        {data.incomeTypes.map((d: any, i: number) => {
          return renderStatRow(i, d);
        })}
      </div>
    </div>
  );
};
export default AnalysisSongBreakdownType;
