import {
  TitleVariantEnum,
  ButtonVariantEnum,
} from "../../../../app/types/enums";
import { AdminHeaderProps } from "../../../../app/types/props";
import { BackIcon } from "../../../../app/atom/Icon";

export enum LinkedClientsTableColumnsVariantEnum {
  id = "id",
  name = "name",
  email = "email",
}

export type FormStateType = {
  owningSites?: string;
  owningSystem?: string;
  paymentCycle?: string;
  paymentDays?: string;
  status?: string;
  regularClosingDate?: string;
  closingPeriod?: string;
  offsetDays?: string;
  closeOn?: string;
  systemTerritoryFor?: string;
};

export const closingDateDetailsHeaderData: AdminHeaderProps = {
  titleData: {
    text: "adminSection.closingDateDetails.title",
    variant: TitleVariantEnum.primaryTitle,
  },
  btnsData: [
    {
      text: "adminSection.common.goBackBtn",
      variant: ButtonVariantEnum.primaryCta,
      icon: BackIcon,
      handleBtnClick: () => {
        // eslint-disable-next-line no-console
        console.log("MOCK");
      },
    },
  ],
};

export const linkedClientsHeaderData: AdminHeaderProps = {
  titleData: {
    text: "adminSection.common.linkedClientsTableHeader.linkedClients",
    variant: TitleVariantEnum.primaryTitle,
  },
  inputData: {
    id: "search",
    handleSearch: (data) => {
      // eslint-disable-next-line no-console
      console.log(data);
    },
  },
};

export enum VariantEnum {
  publishing = "PUBLISHING",
  recording = "RECORDING",
  mechanical = "MECHANICAL",
}

export const filterBy = [
  {
    value: VariantEnum.publishing,
    label: "PUBLISHING",
  },
  {
    value: VariantEnum.recording,
    label: "RECORDING",
  },
  {
    value: VariantEnum.mechanical,
    label: "MECHANICAL",
  },
];

export const DefaultLinkedClientsTable = "name";
