import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import { fetchTaxDocuments, fetchTaxDocumentsFiles } from "./taxDocumentsAPI";

export declare type TaxDocumentsPageStateProps = {
  data: {
    clients: Array<{ id: number; territory: string }>;
    pageSize: number;
    pageNo: number;
  };
};

export interface RecTaxDocumentsState {
  recTaxDocumentsPageState: TaxDocumentsPageStateProps;
  taxDocuments: {
    list: Array<any>;
    total: number | null;
  };
  status: "init" | "idle" | "loading" | "failed";
  errorStatusCode: number | null;
}

const initialState: RecTaxDocumentsState = {
  recTaxDocumentsPageState: {
    data: {
      clients: [],
      pageSize: 10,
      pageNo: 1,
    },
  },
  taxDocuments: {
    list: [],
    total: null,
  },
  status: "init",
  errorStatusCode: null,
};

export const fetchTaxDocumentsThunk = createAsyncThunk(
  "recording/taxDocuments",
  async (payload: any, thunkAPI) => {
    try {
      const response = await fetchTaxDocuments(payload, thunkAPI);
      return response;
    } catch (err: any) {
      throw thunkAPI.rejectWithValue({
        errorStatusCode: err?.response?.status,
      });
    }
  }
);

export const fetchMoreTaxDocumentsThunk = createAsyncThunk(
  "recording/moreTaxDocuments",
  async (payload: any, thunkAPI) => {
    const response = await fetchTaxDocuments(payload, thunkAPI);
    return response;
  }
);

export const fetchTaxDocumentsFilesThunk = createAsyncThunk(
  "recording/taxDocumentsFiles",
  async (payload: any, thunkAPI) => {
    const response = await fetchTaxDocumentsFiles(payload, thunkAPI);
    return response;
  }
);

export const recTaxDocumentsSlice = createSlice({
  name: "recTaxDocuments",
  initialState,
  reducers: {
    resetRecTaxDocuments: () => initialState,
    updateTaxDocumentsPageState: (state, action: any) => {
      state.recTaxDocumentsPageState.data = action.payload.data;
    },
    updateDownloadedTaxDocument: (state, action: any) => {
      const documents = state.taxDocuments?.list;
      const downloadedReferenceId = action?.payload?.referenceId;
      const downloadedDocument = documents.find(
        (document) => document.pdfReferenceId === downloadedReferenceId
      );
      downloadedDocument.downloaded = true;
      state.taxDocuments.list = [...documents];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxDocumentsThunk.fulfilled, (state, action: any) => {
        state.status = "idle";
        state.errorStatusCode = null;
        state.taxDocuments.list = action.payload?.creditNoteList;
        state.taxDocuments.total = action.payload?.totalPagesCount;
      })
      .addCase(fetchMoreTaxDocumentsThunk.fulfilled, (state, action: any) => {
        const taxDocuments = action.payload?.creditNoteList || [];
        state.status = "idle";
        state.taxDocuments.list = [...state.taxDocuments.list, ...taxDocuments];
        state.taxDocuments.total = action.payload?.totalPagesCount;
      })
      .addCase(fetchTaxDocumentsFilesThunk.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(fetchTaxDocumentsThunk.rejected, (state: any, action: any) => {
        state.status = "failed";
        state.errorStatusCode = action.payload?.errorStatusCode;
      })
      .addMatcher(
        isAnyOf(
          fetchTaxDocumentsThunk.pending,
          fetchMoreTaxDocumentsThunk.pending,
          fetchTaxDocumentsFilesThunk.pending
        ),
        (state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchMoreTaxDocumentsThunk.rejected,
          fetchTaxDocumentsFilesThunk.rejected
        ),
        (state: any) => {
          state.status = "failed";
        }
      );
  },
});

export const taxDocumentsSelector = (state: RootState) => state.recTaxDocuments;

export default recTaxDocumentsSlice.reducer;
