import React from "react";
import { useTranslation } from "react-i18next";
import Block from "./Block";
import BlockItem from "./BlockItem";
import MusicNoteIcon from "../../atom/Icon/MusicNoteIcon";
import styles from "./sync.module.scss";

export declare type MostReqSongsProp = {
  songCode: string;
  songTitle: string;
  recordingIsrc: string;
  recordingTitle: string;
  numberOfRequests: number;
};

export declare type RequestedSongsBlockProps = {
  mostRequestedSongs: Array<MostReqSongsProp> | null;
};

const RequestedSongsBlock = ({
  mostRequestedSongs,
}: RequestedSongsBlockProps): JSX.Element => {
  const { t } = useTranslation();

  if (mostRequestedSongs === null) {
    return <></>;
  }

  return (
    <Block
      title={t("sync.overviewTab.requestedSongs.title")}
      subTitle={t("sync.overviewTab.requestedSongs.subTitle")}
    >
      <ul className="px-4">
        {mostRequestedSongs?.length === 0 ? (
          <span className={styles.nodata}>
            {t("sync.overviewTab.requestedSongs.noData")}
          </span>
        ) : (
          <>
            {mostRequestedSongs?.map(
              (song: MostReqSongsProp, index: number) => (
                <BlockItem
                  key={song.songCode || song.recordingIsrc}
                  icon={MusicNoteIcon}
                  title={song.songTitle || song.recordingTitle}
                  isLastRow={index === mostRequestedSongs.length - 1}
                  contentLeft={
                    <div className="text-xs sm:text-base">
                      <span>
                        {song.numberOfRequests === 1 &&
                          t("sync.overviewTab.requestedSongs.requested_one", {
                            count: song.numberOfRequests,
                          })}
                        {song.numberOfRequests > 1 &&
                          t("sync.overviewTab.requestedSongs.requested_other", {
                            count: song.numberOfRequests,
                          })}
                      </span>
                    </div>
                  }
                />
              )
            )}
          </>
        )}
      </ul>
    </Block>
  );
};

export default RequestedSongsBlock;
