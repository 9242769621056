import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import {
  FetchAnalysisDigitalBreakdown_Trend,
  FetchAnalysisDigitalBreakdown_Territory,
  downloadDigitalBreakdownData,
} from "./pubAnalysisDigitalBreakdownAPI";
import { PubAnalysisDigitalData } from "./pubAnalysisDigitalBreakdownAPI";

export declare type PubAnalysisDigitalBreakdownProps =
  | PubAnalysisDigitalData
  | null
  | undefined;

export interface pubAnalysisBreakdownState {
  songBreakdownTrend: {
    dataArray: Array<PubAnalysisDigitalBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  songBreakdownTerritory: {
    dataArray: Array<PubAnalysisDigitalBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  digitalBreakdownDownloadStatus: "idle" | "loading" | "failed";
}

const initialState: pubAnalysisBreakdownState = {
  songBreakdownTrend: { dataArray: [], status: "noData" },
  songBreakdownTerritory: { dataArray: [], status: "noData" },
  digitalBreakdownDownloadStatus: "idle",
};

export const FetchPubAnalysisDigitalBreakdown_Trend_Thunk = createAsyncThunk(
  "publishing/FetchPubAnalysisDigitalBreakdown_Type",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisDigitalBreakdown_Trend(params, thunkAPI);
  }
);

export const FetchPubAnalysisDigitalBreakdown_Territory_Thunk =
  createAsyncThunk(
    "publishing/FetchPubAnalysisDigitalBreakdown_Territory",
    async (params: any, thunkAPI) => {
      return await FetchAnalysisDigitalBreakdown_Territory(params, thunkAPI);
    }
  );

export const pubAnalysisDigitalBreakdown_Download_Thunk = createAsyncThunk(
  "pubAnalysis/breakdown/downloadDigital",
  async (payload: any, thunkAPI) => {
    const response = await downloadDigitalBreakdownData(payload, thunkAPI);
    return response;
  }
);

export const FindPubAnalysisDigitalBreakdown = (
  digital: Array<PubAnalysisDigitalBreakdownProps>,
  sourceCode: string
): PubAnalysisDigitalData | null => {
  const breakdown = digital.find((digital) => {
    return digital?.sourceCode === sourceCode;
  });
  return breakdown ? breakdown : null;
};

export const pubAnalysisDigitalBreakdownSlice = createSlice({
  name: "pubAnalysisDigitalBreakdown",
  initialState,
  reducers: {
    clearTrendBreakdownData: (state, action: any) => {
      state.songBreakdownTrend.dataArray = [];
      state.songBreakdownTrend.status = "noData";
    },
    clearTerritoryBreakdownData: (state, action: any) => {
      state.songBreakdownTerritory.dataArray = [];
      state.songBreakdownTerritory.status = "noData";
    },
  },
  extraReducers: (builder) => {
    builder
      // download
      .addCase(
        pubAnalysisDigitalBreakdown_Download_Thunk.fulfilled,
        (state) => {
          state.digitalBreakdownDownloadStatus = "idle";
        }
      )
      .addCase(pubAnalysisDigitalBreakdown_Download_Thunk.pending, (state) => {
        state.digitalBreakdownDownloadStatus = "loading";
      })
      .addCase(pubAnalysisDigitalBreakdown_Download_Thunk.rejected, (state) => {
        state.digitalBreakdownDownloadStatus = "failed";
      })
      // FetchPubAnalysisDigitalBreakdown_Trend_Thunk
      .addCase(
        FetchPubAnalysisDigitalBreakdown_Trend_Thunk.fulfilled,
        (state, action) => {
          const typeData = action.payload;
          typeData.sourceCode = action.meta.arg.sourceCode;
          state.songBreakdownTrend.dataArray.push(typeData);
          state.songBreakdownTrend.status = "idle";
          return state;
        }
      )
      .addCase(
        FetchPubAnalysisDigitalBreakdown_Trend_Thunk.pending,
        (state) => {
          state.songBreakdownTrend.status = "loading";
        }
      )
      .addCase(
        FetchPubAnalysisDigitalBreakdown_Trend_Thunk.rejected,
        (state) => {
          state.songBreakdownTrend.status = "failed";
        }
      )
      .addCase(
        FetchPubAnalysisDigitalBreakdown_Territory_Thunk.fulfilled,
        (state, action) => {
          const territoryData = action.payload;
          territoryData.sourceCode = action.meta.arg.sourceCode;
          state.songBreakdownTerritory.dataArray.push(territoryData);
          state.songBreakdownTerritory.status = "idle";
          return state;
        }
      )
      .addCase(
        FetchPubAnalysisDigitalBreakdown_Territory_Thunk.pending,
        (state) => {
          state.songBreakdownTerritory.status = "loading";
        }
      )
      .addCase(
        FetchPubAnalysisDigitalBreakdown_Territory_Thunk.rejected,
        (state) => {
          state.songBreakdownTerritory.status = "failed";
        }
      );
  },
});

export const pubAnalysisDigitalBreakdownTrendSelector = (state: RootState) =>
  state.pubAnalysisDigitalBreakdown.songBreakdownTrend;
export const pubAnalysisDigitalBreakdownTerritorySelector = (
  state: RootState
) => state.pubAnalysisDigitalBreakdown.songBreakdownTerritory;
export const pubAnalysisDigitalBreakdownDownloadStatusSelector = (
  state: RootState
) => state.pubAnalysisDigitalBreakdown.digitalBreakdownDownloadStatus;

export const {
  clearTrendBreakdownData: clearTrendBreakdownDataAction,
  clearTerritoryBreakdownData: clearTerritoryBreakdownDataAction,
} = pubAnalysisDigitalBreakdownSlice.actions;

export default pubAnalysisDigitalBreakdownSlice.reducer;
