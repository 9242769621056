import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { downloadAnalysisData, fetchAnalysisSources } from "../recAnalysisAPI";
import { RootState } from "../../../../app/redux/store";

export const RecAnalysisSourcePageInitialState = {
  data: {
    clientIds: [],
    clientLists: [],
    periodIds: [],
  },
  start: 0,
  count: 200,
  sortColumn: "",
  sortAscending: true,
  filterText: "",
  filterColumn: [],
};

export interface RecAnalysisSourceState {
  analysisSourcePageState: {
    data: {
      clientIds: Array<number>;
      clientLists: Array<any>;
      periodIds: Array<number>;
    };
    start: number;
    count: number;
    sortColumn: string;
    sortAscending: boolean;
    filterText: string;
    filterColumn: Array<string>;
  };
  sources: {
    data: {
      sources: Array<any>;
      totalRoyalty: Record<string, any>;
    };
    total: number;
  };
  analysisSourceStatus: "init" | "idle" | "loading" | "failed";
  downloadStatus: "loading" | "idle" | "failed";
  noDataFlag?: "" | "noData" | "noPeriodData" | "noSearchData";
}

const initialState: RecAnalysisSourceState = {
  analysisSourcePageState: RecAnalysisSourcePageInitialState,
  sources: {
    data: {
      sources: [],
      totalRoyalty: {},
    },
    total: 0,
  },
  analysisSourceStatus: "init",
  downloadStatus: "idle",
  noDataFlag: undefined,
};

export const fetchAnalysisSourcesThunk = createAsyncThunk(
  "recording/analysisSources",
  async (payload: any, thunkAPI: any) => {
    const response = await fetchAnalysisSources(payload, thunkAPI);
    return response;
  }
);

export const fetchMoreAnalysisSourcesThunk = createAsyncThunk(
  "recording/moreAnalysisSources",
  async (payload: any, thunkAPI: any) => {
    const response = await fetchAnalysisSources(payload, thunkAPI);
    return response;
  }
);

export const recAnalysisSourcesDownload = createAsyncThunk(
  "recAnalysis/downloadSources",
  async (payload: any, thunkAPI) => {
    const response = await downloadAnalysisData(payload, thunkAPI);
    return response;
  }
);

export const recAnalysisSourceSlice = createSlice({
  name: "recAnalysisSource",
  initialState,
  reducers: {
    resetAnalysisSources: (state, action: any) => {
      state.sources = initialState.sources;
    },
    updateAnalysisSourcePageState: (state: any, action: any) => {
      state.analysisSourcePageState.data.clientIds =
        action.payload.data.clientIds;
      state.analysisSourcePageState.data.clientLists =
        action.payload.data.clientLists;
      state.analysisSourcePageState.data.periodIds =
        action.payload.data.periodIds;
      state.analysisSourcePageState.start = action.payload.start;
      state.analysisSourcePageState.count = action.payload.count;
      state.analysisSourcePageState.sortColumn = action.payload.sortColumn;
      state.analysisSourcePageState.sortAscending =
        action.payload.sortAscending;
      state.analysisSourcePageState.filterColumn = action.payload.filterColumn;
      state.analysisSourcePageState.filterText = action.payload.filterText;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAnalysisSourcesThunk.fulfilled,
        (state: any, action: any) => {
          state.analysisSourceStatus = "idle";
          state.sources.data = action.payload.data;
          state.sources.total = action.payload.total;
          state.noDataFlag =
            action.payload?.total === 0
              ? state?.analysisSourcePageState?.filterText?.length > 0
                ? "noSearchData"
                : state?.analysisSourcePageState?.data?.periodIds?.length === 1
                ? "noPeriodData"
                : "noData"
              : "";
        }
      )
      .addCase(
        fetchMoreAnalysisSourcesThunk.fulfilled,
        (state: any, action: any) => {
          state.analysisSourceStatus = "idle";
          state.sources.data.sources = [
            ...state.sources.data.sources,
            ...action.payload.data.sources,
          ];
          state.sources.data.totalRoyalty = action.payload.data.totalRoyalty;
          state.sources.total = action.payload.total;
        }
      )
      .addCase(recAnalysisSourcesDownload.fulfilled, (state) => {
        state.downloadStatus = "idle";
      })
      .addCase(recAnalysisSourcesDownload.pending, (state) => {
        state.downloadStatus = "loading";
      })
      .addCase(recAnalysisSourcesDownload.rejected, (state) => {
        state.downloadStatus = "failed";
      })
      .addMatcher(
        isAnyOf(
          fetchAnalysisSourcesThunk.pending,
          fetchMoreAnalysisSourcesThunk.pending
        ),
        (state) => {
          state.analysisSourceStatus = "loading";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchAnalysisSourcesThunk.rejected,
          fetchMoreAnalysisSourcesThunk.rejected
        ),
        (state) => {
          state.analysisSourceStatus = "failed";
        }
      );
  },
});

export const recAnalysisSourcePageStateSelector = (state: RootState) =>
  state.recAnalysisSource.analysisSourcePageState;
export const recAnalysisSourcesSelector = (state: RootState) =>
  state.recAnalysisSource.sources;
export const recAnalysisSourcePeriodIdsSelector = (state: RootState) =>
  state.recAnalysisSource.analysisSourcePageState.data.periodIds;
export const recAnalysisDownloadStatusSelector = (state: RootState) =>
  state.recAnalysisSource.downloadStatus;
export const recAnalysisSourceStatusSelector = (state: RootState) =>
  state.recAnalysisSource.analysisSourceStatus;
export const recAnalysisNoDataFlagSelector = (state: RootState) =>
  state.recAnalysisSource.noDataFlag;

export const {
  resetAnalysisSources: resetAnalysisSourcesAction,
  updateAnalysisSourcePageState: updateAnalysisSourcePageStateAction,
} = recAnalysisSourceSlice.actions;

export default recAnalysisSourceSlice.reducer;
