import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { StatusEnum } from "../../../../app/types/enums";

import { useFetch } from "../../common/hooks/useFetch";

import {
  saveMaintenanceWindow,
  getMaintenanceWindows,
  editMaintenanceWindow,
  deleteMaintenanceWindow,
} from "./maintenanceRequestAPI";

export declare type maintenanceDataProps = {
  id: number | null;
  startDate: string;
  duration: number;
  enabled: boolean;
  daysOfWeek: Array<string>;
  interval: string;
};

export interface maintenanceRequestState {
  data: maintenanceDataProps[];
  status: StatusEnum;
}

const initialState: maintenanceRequestState = {
  data: [],
  status: StatusEnum.Idle,
};

export const fetchSaveMaintenanceWindowThunk = createAsyncThunk(
  "fetchSaveMaintenanceWindowThunk",
  async (payload: any, { rejectWithValue }) => {
    try {
      const fetchSaveMaintenanceWindow = await useFetch();
      const response = await fetchSaveMaintenanceWindow({
        data: payload,
        fetchFunction: saveMaintenanceWindow,
        customSuccessMsg:
          "adminSection.maintenancePage.successAddDowntimeMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchEditMaintenanceWindowThunk = createAsyncThunk(
  "fetchEditMaintenanceWindowThunk",
  async (payload: any, { rejectWithValue }) => {
    try {
      const fetchEditMaintenanceWindow = await useFetch();
      const response = await fetchEditMaintenanceWindow({
        data: payload,
        fetchFunction: editMaintenanceWindow,
        customSuccessMsg: "adminSection.common.successEditMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchGetMaintenanceWindowsThunk = createAsyncThunk(
  "fetchGetMaintenanceWindowsThunk",
  async (_, { rejectWithValue }) => {
    try {
      const fetchGetMaintenanceWindows = await useFetch();
      const response = await fetchGetMaintenanceWindows({
        fetchFunction: getMaintenanceWindows,
      });

      return response?.resArr;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDeleteMaintenanceWindowThunk = createAsyncThunk(
  "fetchDeleteMaintenanceWindowThunk",
  async (payload: any, { rejectWithValue }) => {
    try {
      const fetchDeleteMaintenanceWindow = await useFetch();
      const response = await fetchDeleteMaintenanceWindow({
        data: payload,
        fetchFunction: deleteMaintenanceWindow,
        customSuccessMsg:
          "adminSection.maintenancePage.successDeleteDowntimeMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchGetMaintenanceWindowsThunk.fulfilled,
        (state, action: any) => {
          state.status = StatusEnum.Idle;
          state.data = action.payload;
        }
      )
      .addCase(fetchGetMaintenanceWindowsThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(
        fetchGetMaintenanceWindowsThunk.rejected,
        (state, action: any) => {
          state.status = StatusEnum.Failed;
        }
      )
      .addCase(fetchEditMaintenanceWindowThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(fetchEditMaintenanceWindowThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(
        fetchEditMaintenanceWindowThunk.rejected,
        (state, action: any) => {
          state.status = StatusEnum.Failed;
        }
      )
      .addCase(fetchSaveMaintenanceWindowThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(fetchSaveMaintenanceWindowThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(
        fetchSaveMaintenanceWindowThunk.rejected,
        (state, action: any) => {
          state.status = StatusEnum.Failed;
        }
      )
      .addCase(fetchDeleteMaintenanceWindowThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(fetchDeleteMaintenanceWindowThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(
        fetchDeleteMaintenanceWindowThunk.rejected,
        (state, action: any) => {
          state.status = StatusEnum.Failed;
        }
      );
  },
});

export const maintenanceDataSelector = (state: RootState) =>
  state.maintenance.data;
export const maintenanceStatusSelector = (state: RootState) =>
  state.maintenance.status;

export default maintenanceSlice.reducer;
