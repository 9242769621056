import React from "react";

function ClientIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 11 14"
      name="ClientIcon"
      {...props}
    >
      <g>
        <path d="M4.45167997,6.74936034 C6.2351963,6.74936034 7.68595331,5.23526355 7.68595331,3.37484036 C7.68595331,1.51409678 6.2351963,0 4.45167997,0 C2.66908455,0 1.21863452,1.51409678 1.21863452,3.37484036 C1.21863452,5.23526355 2.66908455,6.74936034 4.45167997,6.74936034 L4.45167997,6.74936034 Z M4.45167997,0.480563939 C5.98132917,0.480563939 7.22549298,1.7790477 7.22549298,3.37484036 C7.22549298,4.97031263 5.98132917,6.2687964 4.45167997,6.2687964 C2.92295168,6.2687964 1.67909485,4.97031263 1.67909485,3.37484036 C1.67909485,1.7790477 2.92295168,0.480563939 4.45167997,0.480563939 L4.45167997,0.480563939 Z M9.25704394,12.9140345 C9.05382745,11.9897499 8.3336675,9.24508906 7.79769168,8.51687451 C6.95781204,7.05660088 5.19332807,7.24049668 5.14329138,7.24722458 L3.70450634,7.24722458 C3.64495347,7.24850608 2.22151711,7.20717758 1.387163,8.4582457 C0.923632939,9.15378191 0.244300469,11.810019 0.0057820193,12.9149957 C-0.0224595475,13.0444276 0.0555117347,13.1728983 0.17952905,13.2020525 C0.303853338,13.2321679 0.426949732,13.1501516 0.454577352,13.0207197 C0.705681717,11.8599976 1.36874459,9.32774606 1.76535442,8.73248753 C2.4526682,7.70151769 3.67779964,7.72842927 3.69652503,7.72778852 L5.16784926,7.72650701 C5.18381189,7.72362363 6.72543306,7.57016355 7.40906316,8.77509753 C7.41458869,8.78438843 7.42042118,8.79367933 7.42686763,8.80200911 C7.84650047,9.35497801 8.54916293,11.8426973 8.80824861,13.0216809 C8.83249952,13.1328513 8.92766132,13.2081397 9.0323393,13.2081397 C9.04952982,13.2081397 9.06672034,13.2062174 9.08391086,13.2020525 C9.20792818,13.1725779 9.28559248,13.0434664 9.25704394,12.9140345 L9.25704394,12.9140345 Z"></path>
      </g>
    </svg>
  );
}
export default ClientIcon;
