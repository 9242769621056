import { memo, useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import {
  isHamburgerOpenSelector,
  setHamburgerStateAction,
} from "./siteLayoutSlice";
import deepCompare from "../../../app/utils/deepCompare";
import {
  acceptTermsThunk,
  meSelector,
  meStatus,
  setActivityLogThunk,
} from "../../appMain/appMainSlice";
import { SidebarMenuProps } from "../../../app/types/props";
import Grid from "../../../app/atom/Grid";
import Footer from "../../../app/molecules/footer";
import Header from "../header/";
import BodyWrapper from "../../../app/molecules/bodyWrapper";
import TermsOfUseModal from "../../../app/molecules/termsOfUseModal/TermsOfUseModal";
import debounce from "../../../app/utils/debounce";
import { unImpersonateUserAccountThunk } from "../../admin/pages/AddEditUsers/addEditUsersSlice";
import { ROUTES } from "../../routes/routes.const";
import { UserAccountTypeEnum } from "../../../app/types/enums";
import throttle from "../../../app/utils/throttle";

interface SiteLayoutWrapperProps {
  children: React.ReactNode;
  sidebarComponent: React.ElementType;
  menus: SidebarMenuProps | null;
}

const SiteLayoutWrapper = (props: SiteLayoutWrapperProps): JSX.Element => {
  const { children, sidebarComponent: SidebarComponent, menus } = props;
  const dispatch = useAppDispatch();
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  const hamburgerOpenState = useAppSelector(isHamburgerOpenSelector);
  const user = useAppSelector(meSelector);
  const meStatusState = useAppSelector(meStatus);

  const hamburgerHandler = useCallback(
    (isHamburgerOpen: boolean) => {
      dispatch(setHamburgerStateAction(isHamburgerOpen));
    },
    [dispatch]
  );

  const handleOktaLogOut = useMemo(
    () =>
      throttle(async () => {
        await dispatch(setActivityLogThunk("LOGOUT"));
        await oktaAuth.signOut();
      }, 5000),
    [dispatch, oktaAuth]
  );

  const logOutRedirect = useCallback(async () => {
    if (user.isGuided) {
      dispatch(unImpersonateUserAccountThunk()).then((response) => {
        if (response.payload.resStatus === 200) {
          if (
            user.userAccountType === UserAccountTypeEnum.admin.toLowerCase()
          ) {
            navigate(
              `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.admins}/edit/${response.payload.user}`
            );
          } else {
            navigate(
              `/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.users}/edit/${response.payload.user}`
            );
          }
          window.location.reload();
        }
      });
    } else {
      handleOktaLogOut();
    }
  }, [
    dispatch,
    handleOktaLogOut,
    navigate,
    user.isGuided,
    user.userAccountType,
  ]);

  const handleAcceptTermsOfUse = useMemo(
    () =>
      debounce((language: string) => {
        dispatch(acceptTermsThunk(language));
      }, 500),
    [dispatch]
  );

  const handleDeclineTermsOfUse = useCallback(() => {
    localStorage.setItem("logoutReason", "declineTOU");
    logOutRedirect();
  }, [logOutRedirect]);

  return (
    <Grid>
      <SidebarComponent
        hamburgerHandler={hamburgerHandler}
        hamburgerOpenState={hamburgerOpenState}
        logOutRedirect={logOutRedirect}
        user={user}
        menus={menus}
      >
        <Header
          hamburgerHandler={hamburgerHandler}
          hamburgerOpenState={hamburgerOpenState}
        />
        <BodyWrapper>{children}</BodyWrapper>
      </SidebarComponent>
      <Footer />
      {user.hasAcceptedTerms === false && !user.isGuided && (
        <TermsOfUseModal
          onAcceptTerms={handleAcceptTermsOfUse}
          onDeclineTerms={handleDeclineTermsOfUse}
          userLanguage={user.language}
          status={meStatusState}
        />
      )}
    </Grid>
  );
};

export default memo(
  SiteLayoutWrapper,
  (prevProps: SiteLayoutWrapperProps, nextProps: SiteLayoutWrapperProps) =>
    deepCompare(prevProps, nextProps)
);
