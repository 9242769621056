import { useEffect, useCallback, useRef, useState, memo, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { getNow, getToday } from "../../../app/utils/dateTime";
import { useTranslation } from "react-i18next";
import { DownloadIcon } from "../../../app/atom/Icon";
import {
  fetchRecSyncThunk,
  fetchRecSyncOverviewThunk,
  recSyncPageStateSelector,
  recSyncStatusSelector,
  recSyncCurrentTabSelector,
  updateRecSyncPageStateAction,
  resetRecSyncSearchAction,
  recSyncDownloadThunk,
  fetchMoreRecSyncThunk,
  RecSyncTabEntry,
  RecSyncPageStateProps,
  resetRecSyncTabTabsStateAction,
} from "./recSyncSlice";
import {
  recClientSelectionSelector,
  recRootHasErrorSelector,
} from "../recroot/recrootSlice";
import debounce from "../../../app/utils/debounce";
import deepCompare from "../../../app/utils/deepCompare";
import RecSyncHeaderWrapper from "./RecSyncHeaderWrapper";
import RecSyncPeriodSelector from "./RecSyncPeriodSelector";
import SyncTableView from "../../../app/molecules/sync/syncTableView/SyncTableView";
import Search from "../../../app/molecules/search/Search";
import Button from "../../../app/atom/Button/Button";
import Loader from "../../../app/atom/Loader";
import {
  ButtonVariantEnum,
  RecSyncTabStatusEnum,
  RecSyncTabsEnum,
} from "../../../app/types/enums";
import useInfiniteScroll from "../../../app/hooks/useInfiniteScroll";
import { windowScrollToTop } from "../../../app/utils/scrolling";
import { RootState } from "../../../app/redux/store";
import { PeriodSelector } from "../../../app/molecules/periodSelector/periodSelectorSlice";
import getPeriod from "../../../app/utils/getPeriod";

interface RecSyncHOCProps {
  status: RecSyncTabStatusEnum;
  selectedTab: RecSyncTabsEnum;
  hasPeriodSelector?: boolean;
}

const activeTabMapper = (selectedTab: number) => {
  let activeTab: RecSyncTabEntry = "UNKNOWN";
  switch (selectedTab) {
    case 1:
      activeTab = "ALL";
      break;
    case 2:
      activeTab = "QUOTING";
      break;
    case 3:
      activeTab = "LICENSING";
      break;
    case 4:
      activeTab = "RECEIVED";
      break;
    case 5:
      activeTab = "COMPLETED";
      break;
  }
  return activeTab;
};

const RecSyncHOC = (props: RecSyncHOCProps): JSX.Element => {
  const { status, selectedTab, hasPeriodSelector = false } = props;

  const { t } = useTranslation();
  const { authState } = useOktaAuth();
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const { selectedClients } = useAppSelector(recClientSelectionSelector);
  const pageState = useAppSelector(recSyncPageStateSelector);
  const activeTab = useAppSelector(recSyncCurrentTabSelector);
  const pageStatus = useAppSelector(recSyncStatusSelector);
  const pubRootHasError = useAppSelector(recRootHasErrorSelector);
  const allPeriods = useAppSelector(PeriodSelector);

  const [syncSearchText, setSyncSearchText] = useState(
    state?.search || pageState.tabsState[status].filterText || ""
  );
  const [allSongsStart, setAllSongsStart] = useState(
    pageState.tabsState[status].start || 0
  );
  const currentTabData = useAppSelector((state: RootState) => {
    const recSyncTab = state.recSync.recSyncTab;
    return recSyncTab.data[status];
  });
  const currentSelectedPeriod = useAppSelector((state: RootState) => {
    const periodId =
      state.recSync.recSyncPageState.tabsState[activeTab]?.tabClientData
        ?.periodIds?.[0];
    return periodId;
  });
  const [currentTabAscOrder, setCurrentTabAscOrder] = useState(
    pageState.tabsState[status]?.isAsc
  );
  const [currentTabSortColumnName, setCurrentTabSortColumnName] = useState(
    pageState.tabsState[status]?.sortColumn
  );
  const dispatchedSongs = useRef<any>();
  const observerTopTarget = useRef<HTMLDivElement | null>(null);
  const { isOnScreen: topTargetIsOnScreen } =
    useInfiniteScroll(observerTopTarget);
  const dispatchedRecSyncOverview = useRef<any>();
  const observerBottomTarget = useRef<HTMLDivElement | null>(null);
  const {
    pageCount: bottomTargetPageCount,
    setPageCount: setBottomTargetPageCount,
  } = useInfiniteScroll(observerBottomTarget);

  const abortDispatchedAllSongs = () => {
    if (dispatchedSongs.current) dispatchedSongs.current?.abort();
  };
  const abortDispatchedRecSyncOverview = () => {
    if (dispatchedRecSyncOverview.current)
      dispatchedRecSyncOverview.current?.abort();
  };

  const handleAllSongsFetch = useMemo(
    () =>
      debounce((pageState: RecSyncPageStateProps) => {
        if (
          activeTab === "UNKNOWN" ||
          pageState.tabsState[activeTab]?.start === 0
        ) {
          abortDispatchedAllSongs();
          dispatchedSongs.current = dispatch(fetchRecSyncThunk(pageState));
        } else {
          dispatch(fetchMoreRecSyncThunk(pageState));
        }
        dispatch(updateRecSyncPageStateAction(pageState));
      }, 100),
    [activeTab, dispatch]
  );

  const handleRecSyncOverviewFetch = useMemo(
    () =>
      debounce((pageState: RecSyncPageStateProps) => {
        abortDispatchedRecSyncOverview();
        dispatchedRecSyncOverview.current = dispatch(
          fetchRecSyncOverviewThunk(pageState)
        );
      }, 100),
    [dispatch]
  );

  const fetchMoreData = useCallback(() => {
    setAllSongsStart(() => (bottomTargetPageCount - 1) * pageState.count);
  }, [bottomTargetPageCount, pageState.count]);

  const handleSetPeriod = useCallback(
    (selectedPeriod: number | null) => {
      const recSyncPageState = {
        ...pageState,
        tabsState: {
          ...pageState.tabsState,
          [activeTab]: {
            ...pageState.tabsState[activeTab],
            tabClientData: {
              ...pageState.tabsState[activeTab].tabClientData,
              periodIds: selectedPeriod
                ? [selectedPeriod]
                : allPeriods?.periods?.map((p) => p.periodNum),
            },
          },
        },
      };
      dispatch(resetRecSyncSearchAction());
      handleAllSongsFetch(recSyncPageState);
    },
    [activeTab, allPeriods?.periods, dispatch, handleAllSongsFetch, pageState]
  );

  useEffect(() => {
    if (!authState?.isAuthenticated || selectedClients.length === 0) return;
    const activeTab = activeTabMapper(selectedTab);
    const clientData = {
      clientIds: selectedClients?.map((c: any) => c.id),
      clientLists: [],
    };
    let tabsState = pageState.tabsState;
    if (activeTab !== "UNKNOWN") {
      tabsState = {
        ...pageState.tabsState,
        [activeTab]: {
          tabClientData: {
            ...pageState.tabsState[activeTab].tabClientData,
            ...clientData,
          },
          filterText: syncSearchText,
          start: allSongsStart,
          status: status,
          isAsc: currentTabAscOrder,
          sortColumn: currentTabSortColumnName,
        },
      };
    }
    const recSyncPageState: RecSyncPageStateProps = {
      ...pageState,
      data: clientData,
      activeTab: activeTab,
      tabsState: tabsState,
    };
    if (
      !deepCompare(
        //ignore activeTab value in deep compare
        { ...recSyncPageState, activeTab: null },
        { ...pageState, activeTab: null }
      )
    ) {
      handleAllSongsFetch(recSyncPageState);
    }
    if (
      !deepCompare(
        //ignore activeTab and start value in deep compare
        {
          ...recSyncPageState,
          activeTab: null,
          tabsState: {
            [activeTab]: {
              ...recSyncPageState.tabsState[activeTab],
              start: null,
            },
          },
        },
        {
          ...pageState,
          activeTab: null,
          tabsState: {
            [activeTab]: { ...pageState.tabsState[activeTab], start: null },
          },
        }
      )
    ) {
      setAllSongsStart(0);
    }
    if (!deepCompare(recSyncPageState.data, pageState.data)) {
      dispatch(resetRecSyncTabTabsStateAction());
      handleRecSyncOverviewFetch(recSyncPageState);
    }
  }, [
    allSongsStart,
    authState?.isAuthenticated,
    currentTabAscOrder,
    currentTabSortColumnName,
    dispatch,
    handleAllSongsFetch,
    handleRecSyncOverviewFetch,
    pageState,
    selectedClients,
    selectedTab,
    setBottomTargetPageCount,
    status,
    syncSearchText,
  ]);

  useEffect(() => {
    const prevPageCount = allSongsStart / pageState.count;
    if (prevPageCount > bottomTargetPageCount) {
      setBottomTargetPageCount(prevPageCount + 1);
    }
  }, [
    allSongsStart,
    bottomTargetPageCount,
    pageState.count,
    setBottomTargetPageCount,
  ]);

  useEffect(() => {
    if (!currentTabData) return;
    const hasMore = currentTabData?.data?.length < currentTabData?.total;
    if (hasMore && bottomTargetPageCount > 1) {
      fetchMoreData();
    }
  }, [bottomTargetPageCount, currentTabData, fetchMoreData]);

  const handleColumnSorting = useCallback(
    (event: Event) => {
      const target = event.target as HTMLButtonElement;
      if (target) {
        /**
         * isAsc:
         * A flag indicating whether the sorting is ascending. Default: false for statusLastModifiedDate and true for other columns.
         */
        const isAsc =
          target.name === pageState.tabsState[activeTab].sortColumn
            ? !pageState.tabsState[activeTab].isAsc
            : target.name === "statusLastModifiedDate"
            ? false
            : true;

        setCurrentTabAscOrder(isAsc);
        setCurrentTabSortColumnName(target.name);
      }
    },
    [activeTab, pageState?.tabsState]
  );

  const handleAfterFilterText = useMemo(
    () =>
      debounce(() => {
        windowScrollToTop();
      }, 1500),
    []
  );

  const handleFilterText = useCallback(
    (filterText: string) => {
      dispatch(resetRecSyncSearchAction());
      setSyncSearchText(filterText);
      handleAfterFilterText();
    },
    [dispatch, handleAfterFilterText]
  );

  const handleDownloadBtn = useCallback(() => {
    const params = {
      data: {
        clientIds: selectedClients?.map((c: any) => c.id),
        clientLists: [],
      },
      fileName: "Sync_Licenses_" + getToday() + "-" + getNow() + ".xlsx",
    };
    dispatch(recSyncDownloadThunk(params));
  }, [dispatch, selectedClients]);

  return (
    <RecSyncHeaderWrapper selectedTab={selectedTab}>
      {/* Loader */}
      {pageStatus === "loading" && <Loader />}
      {/* Peroid Selector */}
      {hasPeriodSelector && (
        <RecSyncPeriodSelector
          setSelectedPeriod={handleSetPeriod}
          selectedPeriod={
            currentSelectedPeriod ? getPeriod(currentSelectedPeriod) : ""
          }
        />
      )}
      {/* Download button, search field */}
      <div ref={observerTopTarget} />
      <div
        id="search-section"
        className={`sticky top-0 z-[5] bg-white pt-3 ${
          topTargetIsOnScreen === false && bottomTargetPageCount > 0
            ? "shadow-lg"
            : ""
        }`}
      >
        <div className="flex w-full justify-between px-5">
          <div className="mt-2 md:flex-1">
            <Button
              icon={
                <DownloadIcon className="h-6 w-4 group-hover:fill-green-700" />
              }
              onClick={handleDownloadBtn}
              className="textLink syncDownload_GTM group hidden p-0 text-sm font-normal no-underline sm:block"
              variant={ButtonVariantEnum.textLink}
            >
              {t("sync.table.download")}
            </Button>
          </div>
          {currentTabData && (
            <Search
              searchText={syncSearchText}
              resultsCount={currentTabData.total || 0}
              onSearchChange={handleFilterText}
              onBackToTopClick={windowScrollToTop}
              placeholderText={t("sync.table.filterTextPlaceholder2") || ""}
              status={pageStatus}
            />
          )}
        </div>
      </div>
      {/* Table output */}

      <div className="min-h-[30vh]">
        {currentTabData?.data &&
          pageStatus !== "failed" &&
          pubRootHasError === false && (
            <SyncTableView
              documentType="RECORDING"
              onColumnSorting={handleColumnSorting}
              songs={currentTabData}
              status={status}
              topTargetIsOnScreen={topTargetIsOnScreen}
              bottomTargetPageCount={bottomTargetPageCount}
            />
          )}
        {currentTabData &&
          currentTabData?.data?.length < currentTabData?.total && (
            <h5 className="p-3">{t("app.loading")}</h5>
          )}
      </div>
      {currentTabData?.total !== 0 &&
        currentTabData?.data?.length === currentTabData?.total && (
          <p className="py-8 text-center">
            <b>{t("sync.table.seenAll")}</b>
          </p>
        )}
      <div
        className={`${
          currentTabData?.data?.length === currentTabData?.total ? "hidden" : ""
        }`}
        ref={observerBottomTarget}
      ></div>
    </RecSyncHeaderWrapper>
  );
};

export default memo(
  RecSyncHOC,
  (prevProps: RecSyncHOCProps, nextProps: RecSyncHOCProps) =>
    deepCompare(prevProps, nextProps)
);
