import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";

export enum SelectedPeriodVariant {
  allPeriods = "allPeriods",
  period = "period",
  periods = "periods",
}
export interface PubAnalysisHeaderState {
  allPeriodsData: Array<any>;
  selectedPeriods: Array<number>;
  tabSelectedPeriodTitle: Record<SelectedPeriodVariant, string>;
  selectedPeriodText: {
    titleVariant: SelectedPeriodVariant;
    details: string;
  };
  modalYearRange: { start: number; end: number };
  selectedYears: Array<number>;
  visiblePeriods: Array<string>;
  visiblePeriodIds: Array<number>;
}

const initialState: PubAnalysisHeaderState = {
  allPeriodsData: [],
  selectedPeriods: [],
  tabSelectedPeriodTitle: {
    allPeriods: "",
    period: "",
    periods: "",
  },
  selectedPeriodText: {
    titleVariant: SelectedPeriodVariant.allPeriods,
    details: "",
  },
  modalYearRange: { start: 0, end: 0 },
  selectedYears: [],
  visiblePeriods: [],
  visiblePeriodIds: [],
};

export const pubAnalysisHeaderSlice = createSlice({
  name: "pubAnalysisHeader",
  initialState,
  reducers: {
    updateAllPeriodsData: (state: any, action: any) => {
      state.allPeriodsData = action.payload;
    },
    updateTabSelectedPeriodTitle: (state: any, action: any) => {
      state.tabSelectedPeriodTitle.allPeriods = action.payload.forAllPeriods;
      state.tabSelectedPeriodTitle.period = action.payload.forPeriod;
      state.tabSelectedPeriodTitle.periods = action.payload.forPeriods;
    },
    updateSelectedPeriodsData: (state: any, action: any) => {
      state.selectedPeriods = action.payload.selectedPeriods;
      state.selectedPeriodText.titleVariant =
        action.payload.selectedPeriodText.titleVariant;
      state.selectedPeriodText.details =
        action.payload.selectedPeriodText.details;
    },
    updateModalYearRange: (state: any, action: any) => {
      state.modalYearRange = action.payload;
    },
    updateSelectedYears: (state: any, action: any) => {
      state.selectedYears = action.payload;
    },
    updateVisiblePeriods: (state: any, action: any) => {
      state.visiblePeriods = action.payload.periods;
      state.visiblePeriodIds = action.payload.periodIds;
    },
  },
});

export const pubAnalysisHeaderSelector = (state: RootState) =>
  state.pubAnalysisHeader;

export const {
  updateAllPeriodsData: updateAllPeriodsDataAction,
  updateTabSelectedPeriodTitle: updateTabSelectedPeriodTitleAction,
  updateSelectedPeriodsData: updateSelectedPeriodsDataAction,
  updateModalYearRange: updateModalYearRangeAction,
  updateSelectedYears: updateSelectedYearsAction,
  updateVisiblePeriods: updateVisiblePeriodsAction,
} = pubAnalysisHeaderSlice.actions;

export default pubAnalysisHeaderSlice.reducer;
