import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import { fetchSendContactUsGuestPayload } from "./contactUsGuestAPI";
import type { ContactUsGuestPayload } from "../../../app/types/payload";
import type { ContactUsGuestFormValues } from "../types";

export interface ContactUsState {
  contactUsGuestStatus: "idle" | "loading" | "failed";
  contactUsGuestSuccess: boolean;
  errors: Partial<ContactUsGuestFormValues> | undefined;
}

const initialState: ContactUsState = {
  contactUsGuestStatus: "idle",
  contactUsGuestSuccess: false,
  errors: undefined,
};

export const fetchSubmitContactUsGuestThunk = createAsyncThunk(
  "contactUs/guest",
  async (payload: ContactUsGuestPayload, thunkAPI) => {
    const response = await fetchSendContactUsGuestPayload(payload, thunkAPI);
    if (response !== null) {
      return thunkAPI.rejectWithValue(response);
    }
    return response;
  }
);

export const useContactUsGuestSlice = createSlice({
  name: "contactUsGuest",
  initialState,
  reducers: {
    resetContactUsGuestForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubmitContactUsGuestThunk.fulfilled, (state) => {
        state.contactUsGuestStatus = "idle";
        state.contactUsGuestSuccess = true;
        state.errors = undefined;
      })
      .addCase(fetchSubmitContactUsGuestThunk.pending, (state) => {
        state.contactUsGuestStatus = "idle";
        state.errors = undefined;
      })
      .addCase(
        fetchSubmitContactUsGuestThunk.rejected,
        (state, action: any) => {
          state.contactUsGuestStatus = "failed";
          state.contactUsGuestSuccess = false;
          // error messages appear in payload { telephone: "message here" }
          if (typeof action.payload === "object") {
            state.errors = action.payload;
          }
        }
      );
  },
});

export const contactUsGuestStatusSelector = (state: RootState) =>
  state.contactUsGuest.contactUsGuestStatus;

export const contactUsGuestSuccessStatusSelector = (state: RootState) =>
  state.contactUsGuest.contactUsGuestSuccess;

export const contactUsGuestErrorsSelector = (state: RootState) =>
  state.contactUsGuest.errors;

export const { resetContactUsGuestForm: resetContactUsGuestFormAction } =
  useContactUsGuestSlice.actions;

export default useContactUsGuestSlice.reducer;
