import React from "react";

function AlertIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 26 26"
      fill="currentColor"
      name="AlertIcon"
      {...props}
    >
      <path d="M23.346 19.904c-3.33-2.067-3.152-8.78-3.149-8.88a6.971 6.971 0 0 0-4.915-6.66v-.602a2.026 2.026 0 0 0-2.019-2.028c-1.113 0-2.019.91-2.019 2.028v.601a6.986 6.986 0 0 0-4.914 6.694c.064 1.878-.376 7.127-3.15 8.847a.985.985 0 0 0 .514 1.82h5.865c.387 1.696 1.9 2.967 3.704 2.967 1.805 0 3.317-1.27 3.705-2.967h5.864a.98.98 0 0 0 .943-.715.987.987 0 0 0-.429-1.105m-10.962-.147H6.101c2.34-3.317 2.196-8.497 2.188-8.734 0-2.755 2.231-4.996 4.975-4.996 2.743 0 4.974 2.241 4.975 4.962-.01.266-.153 5.45 2.187 8.768h-8.042" />
    </svg>
  );
}

export default AlertIcon;
