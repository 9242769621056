import { TableHeadProps } from "../../../../../../app/types/props";

export const tableHeadData: TableHeadProps = [
  {
    id: 0,
    title: "adminSection.requestArchivePage.tableHeader.id",
    filteringInfo: "",
  },
  {
    id: 1,
    title: "adminSection.requestArchivePage.tableHeader.firstName",
    filteringInfo: "firstname",
  },
  {
    id: 2,
    title: "adminSection.requestArchivePage.tableHeader.lastName",
    filteringInfo: "surname",
  },
  {
    id: 3,
    title: "adminSection.requestArchivePage.tableHeader.username",
    filteringInfo: "username",
  },
  {
    id: 4,
    title: "adminSection.requestArchivePage.tableHeader.email",
    filteringInfo: "email",
  },
  {
    id: 5,
    title: "adminSection.requestArchivePage.tableHeader.requestedOn",
    filteringInfo: "requestedDate",
  },
  {
    id: 6,
    title: "adminSection.requestArchivePage.tableHeader.createdOn",
    filteringInfo: "requestApprovedDate",
  },
  {
    id: 7,
    title: "adminSection.requestArchivePage.tableHeader.lastAmendedDate",
    filteringInfo: "amendedDate",
  },
  {
    id: 8,
    title: "adminSection.requestArchivePage.tableHeader.lastAmendedBy",
    filteringInfo: "amendedBy",
  },
  {
    id: 9,
    title: "adminSection.requestArchivePage.tableHeader.status",
    filteringInfo: "status",
  },
  {
    id: 10,
    title: "adminSection.requestArchivePage.tableHeader.userType",
    filteringInfo: "userAccountType",
  },
  {
    id: 11,
    title: "adminSection.requestArchivePage.tableHeader.originatingSystem",
    filteringInfo: "territory",
  },
  {
    id: 12,
    title: "adminSection.requestArchivePage.tableHeader.adminUsername",
    filteringInfo: "adminName",
  },
];
