import React from "react";
function PieChartIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className="icon-pieview"
      width="18"
      height="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="svg-fill"
        d="M16.963 9.018H9.092V1.165a.319.319 0 0 0-.32-.318C4.083.847.266 4.655.266 9.336c0 4.682 3.817 8.49 8.508 8.49 4.691 0 8.508-3.808 8.508-8.49a.318.318 0 0 0-.318-.318Zm-8.19 8.173c-4.34 0-7.871-3.524-7.871-7.855 0-4.224 3.36-7.679 7.553-7.846v7.846c0 .175.142.318.318.318h7.865c-.168 4.184-3.632 7.537-7.865 7.537ZM10.843.173a.318.318 0 0 0-.32.318v6.78c0 .176.143.318.32.318h6.795a.318.318 0 0 0 .318-.318c0-3.913-3.191-7.098-7.114-7.098Zm.318 6.78V.817c3.318.161 5.989 2.826 6.15 6.136h-6.15Z"
      />
    </svg>
  );
}
export default PieChartIcon;
