import React from "react";
function SyncQuotingIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      name="SyncQuotingIcon"
      viewBox="0 0 27 27"
      {...props}
    >
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <circle cx="12.432" cy="12.778" r="12.432" stroke="currentColor" />
        <path
          fill="currentColor"
          strokeWidth={0}
          d="M14.176 8.27a.482.482 0 0 0-.683.676l3.463 3.462H5.53a.477.477 0 0 0-.48.48c0 .267.213.485.48.485h11.426l-3.463 3.457a.49.49 0 0 0 0 .683.479.479 0 0 0 .683 0l4.283-4.283a.471.471 0 0 0 0-.676l-4.283-4.285Z"
        />
      </g>
    </svg>
  );
}

export default SyncQuotingIcon;
