import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function fetchDashboardStats(params: any, thunkAPI?: any) {
  return request(
    `${USER_API_RESOURCE_BASE_URLS.stats}?clientDocumentType=RECORDING`,
    {
      method: "POST",
      body: JSON.stringify(params?.data),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchLatestStatements(params: any, thunkAPI?: any) {
  const { payeeOnly = false, documentType = "RECORDING", data } = params;

  return request(
    `${USER_API_RESOURCE_BASE_URLS.statementsPeriod}?isPayeeOnly=${payeeOnly}&clientDocumentType=${documentType}&maxResults=5`,
    { method: "POST", body: JSON.stringify(data), signal: thunkAPI?.signal }
  );
}

export function fetchPeriods(params: any, thunkAPI?: any) {
  const { clientIds, clientLists } = params.data;
  const payload = {
    clientIds,
    clientLists,
    documentType: "RECORDING",
    withPipeline: true,
    yearsBack: 10,
  };

  return request(`${USER_API_RESOURCE_BASE_URLS.periods}`, {
    method: "POST",
    body: JSON.stringify(payload),
    signal: thunkAPI?.signal,
  });
}

export function fetchTopProducts(params: any, thunkAPI?: any) {
  const { distChannel = "SYNCHRONISATION", count = 5, data } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.dashboardTopProducts}?distChannel=${distChannel}&count=${count}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchTopPerformingTerritories(
  params: {
    clientIds: number[];
    clientLists: any[];
    periodIds: number[];
  },
  thunkAPI?: any
) {
  return request(
    `${USER_API_RESOURCE_BASE_URLS.dashboardTopTerritories}?count=4&clientDocumentType=RECORDING`,
    {
      method: "POST",
      body: JSON.stringify(params),
      signal: thunkAPI?.signal,
    }
  );
}

// new
export function fetchTopAlbumSingles(params: any, thunkAPI?: any) {
  return request(
    `${USER_API_RESOURCE_BASE_URLS.albumsTop5}?latestPeriodId=${params.latestPeriodId}&previousPeriodId=${params.previousPeriodId}`,
    {
      method: "POST",
      body: JSON.stringify({
        clientIds: params.clientIds,
        clientLists: params.clientLists,
      }),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchLatestStatementsFile(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.statementsFiles}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
    isDownload: true,
    fileName,
  });
}

export function fetchRoyaltiesTrend(params: any, thunkAPI?: any) {
  const {
    clientIds,
    clientLists,
    periodIds,
    summedBy = "YEARS",
    currencyId,
  } = params;
  const { documentType = "RECORDING" } = params;
  const payload = {
    clientSelection: {
      clientIds,
      clientLists,
      periodIds,
    },
    summedBy,
    currencyId,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.dashboardTrends}?documentType=${documentType}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      signal: thunkAPI?.signal,
    }
  );
}
