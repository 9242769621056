import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../atom/Button/Button";
import { ButtonVariantEnum } from "../../../types/enums";
import Modal from "../../modal/Modal";
import Range from "../../../atom/Range/Range";
import Checkbox from "../../../atom/Checkbox/Checkbox";
import styles from "./modalPeriodSelector.module.scss";
import { ModalPeriodSelectorProps } from "../../../types/props";

export default function ModalPeriodSelector(
  props: ModalPeriodSelectorProps
): JSX.Element {
  const { t } = useTranslation();
  const [dates, setDates] = useState<Array<any>>([]);

  const isDateArrayReady = dates && dates.length !== 0;

  function generateDateOptions(props: ModalPeriodSelectorProps): Array<any> {
    if (props.startYear !== 0 && props.endYear !== 0) {
      const dateoptions = [];
      for (let y = props.startYear; y <= props.endYear; y++) {
        let isChecked = true;
        if (props.selectedYears && props.selectedYears.length > 0) {
          isChecked = props.selectedYears.indexOf(y) > -1;
        }
        dateoptions.push({
          year: y,
          isChecked,
        });
      }
      return dateoptions;
    } else return [];
  }

  function onPeriodSelected() {
    if (isDateArrayReady) {
      const filteredDates = dates?.filter((d) => {
        return d.isChecked;
      });
      const selectedDates: Array<number> = [];

      for (let i = 0; i < filteredDates.length; i++)
        selectedDates.push(filteredDates[i].year);

      if (props.onPeriodSelected) props.onPeriodSelected(selectedDates);
    }
  }

  function onRangeChange(num1: number, num2: number) {
    if (isDateArrayReady) {
      const min = Math.min(num1, num2);
      const max = Math.max(num1, num2);
      const newDates = [...dates];
      for (let i = 0; i < dates.length; i++) {
        newDates[i].isChecked =
          dates[i].year >= min &&
          dates[i].year <= max &&
          (newDates[i].isChecked ||
            newDates[i].year === min ||
            newDates[i].year === max);
      }
      setDates(newDates);
    }
  }

  function onCheckboxChange(isChecked: boolean, value: string) {
    if (isDateArrayReady) {
      const newDates = [...dates];
      for (let i = 0; i < dates.length; i++)
        if (dates[i].year === Number(value)) {
          newDates[i].isChecked = !dates[i].isChecked;
          break;
        }
      setDates(newDates);
    }
  }

  function getRangeSelectedStart() {
    if (isDateArrayReady)
      for (let i = 0; i < dates.length; i++)
        if (dates[i].isChecked) return dates[i].year;
  }

  function getRangeSelectedEnd() {
    if (isDateArrayReady)
      for (let i = dates.length - 1; i >= 0; i--)
        if (dates[i].isChecked) return dates[i].year;
  }

  useEffect(() => {
    if (props.startYear && props.endYear) {
      const initialDates = generateDateOptions(props);
      setDates(initialDates);
    }
  }, [props, isDateArrayReady]);

  const handleCancelBtn = () => {
    props.closePeriodModal();
  };

  const handleUpdateBtn = () => {
    onPeriodSelected();
    props.closePeriodModal();
  };

  if (!isDateArrayReady) return <></>;
  return (
    <div className={styles.modalPeriodSelector}>
      <Modal
        id={props.modalId}
        isModalOpen={props.isModalOpen}
        callbackCloseModal={props.closePeriodModal}
        closeBtnClassName="closePeriodSelector_GTM"
      >
        <h3>{t("modalPeriodSelector.modalTitle")}</h3>
        <p>{t("modalPeriodSelector.modalDirective")}</p>
        <Range
          min={props.startYear}
          max={props.endYear}
          selectedStart={getRangeSelectedStart()}
          selectedEnd={getRangeSelectedEnd()}
          onChange={onRangeChange}
          className="mb-3 mt-8"
        />

        {dates.map((d, i) => {
          return (
            <Checkbox
              text={d.year}
              isChecked={d.isChecked}
              key={i + " " + d.isChecked}
              className={styles.checkbox}
              onChange={onCheckboxChange}
            />
          );
        })}
        <div className="mt-4 flex justify-between">
          <Button
            variant={ButtonVariantEnum.outlineCta}
            onClick={handleCancelBtn}
            className="cancelPeriodSelector_GTM"
          >
            {t("modalPeriodSelector.modalCancelBtn")}
          </Button>
          <Button
            variant={ButtonVariantEnum.primaryCta}
            onClick={handleUpdateBtn}
            className="updatePeriodSelector_GTM"
          >
            {t("modalPeriodSelector.modalPrimaryBtn")}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
