const TermsFR = (): JSX.Element => {
  return (
    <div>
      <h1>Conditions d’Utilisation</h1>
      <h2>
        De la Plateforme Client en ligne de BMG accessible à l’adresse
        www.my.bmg.com
      </h2>
      <p>
        Bienvenue sur la Plateforme Client en Ligne de BMG (la «Plateforme»)
        accessible sur www.my.bmg.com (le «Site»), que BMG Rights Management
        GmbH, Allemagne, exploite pour son compte et pour le compte des sociétés
        du groupe BMG, incluant ses filiales et succursales (individuellement ou
        collectivement «BMG», «Nous», «Notre», «Nos») pour l’usage des
        auteurs-compositeurs, des éditeurs et des autres clients de BMG
        (collectivement, le(s) «Client(s) BMG»).
      </p>
      <p>
        CES CONDITIONS D’UTILISATION CONSTITUENT UN CONTRAT ENTRE BMG ET VOUS,
        INDIVIDUELLEMENT EN VOTRE QUALITE DE CLIENT BMG, ET, SI VOUS ACCEDEZ OU
        UTILISEZ LA PLATEFORME OU LE SITE POUR LE COMPTE D’UN CLIENT BMG DANS LE
        CADRE DE VOS ENGAGEMENTS ET OBLIGATIONS ENVERS CE CLIENT BMG, ENTRE BMG
        ET CE CLIENT BMG, EN TANT QU’UTILISATEUR DE LA PLATEFORME ET DU SITE
        (LES OU CES «CONDITIONS D’UTILISATION»). VOUS DECLAREZ ET GARANTISSEZ
        QUE VOUS ETES AUTORISES A VOUS ENGAGER VOUS-MEME AINSI QUE CE CLIENT BMG
        EN TANT QUE PARTIE CONTRACTANTE A CES CONDITIONS D’UTILISATION. SAUF
        STIPULATION CONTRAIRE FIGURANT DANS CES CONDITIONS D’UTILISATION, LES
        MOTS «VOUS» OU «VOTRE» DOIVENT ETRE CONSIDERES COMME SE REFERANT
        INDIVIDUELLEMENT OU COLLECTIVEMENT A VOUS, ET, LE CAS ECHEANT, AU CLIENT
        BMG POUR LE COMPTE DUQUEL VOUS ACCEDEZ A OU UTILISEZ LA PLATEFORME OU LE
        SITE
      </p>
      <p>
        LORS DE LA CREATION DE VOTRE COMPTE, VOUS DEVEZ ACCEPTER CES CONDITIONS
        D’UTILISATION. EN ACCEDANT A, NAVIGUANT SUR OU UTILISANT LA PLATEFORME
        OU LE SITE, VOUS RECONNAISSEZ QUE VOUS AVEZ LU, COMPRIS ET ACCEPTE
        D’ETRE ENGAGE PAR CES CONDITIONS D’UTILISATION Y COMPRIS LORSQUE VOUS
        ACCEDEZ A NOUVEAU LA PLATEFORME OU LE SITE EN CAS DE MODIFICATIONS
        EFFECTUEES. NOUS POUVONS METTRE A JOUR CES CONDITIONS D’UTILISATION A
        TOUT MOMENT, SANS PREAVIS. SAUF STIPULATION CONTRAIRE, CES MODIFICATIONS
        SERONT EFFECTIVES DES LEUR MISE EN LIGNE. TOUT ACCES A, NAVIGATION SUR
        OU UTILISATION DE LA PLATEFORME OU LE SITE POSTERIEURE A TOUTE
        MODIFICATION DEVRA FAIRE L’OBJET D’UNE NOUVELLE ACCEPTATION PLEINE ET
        SANS RESERVE DES CONDITIONS GENERALES MODIFIEES
      </p>
      <p>
        SI VOUS N’ETES PAS EN ACCORD AVEC CES CONDITIONS D’UTILISATION, OU AVEC
        TOUTE LIGNE DIRECTRICE, POLITIQUE OU REGLE MISE EN LIGNE SUR LE SITE,
        VOUS NE DEVEZ PAS UTILISER LA PLATEFORME OU LE SITE.
      </p>

      <h3>1. L’étendue de l’application</h3>
      <p>
        Ces Conditions d’Utilisation gouvernent votre utilisation de la
        Plateforme et du Site.
      </p>
      <h3>2. Identifiants de connexion</h3>
      <p>
        <span className="text-red-800">2.1</span> Utiliser la Plateforme est une
        possibilité pour les Clients BMG. A ce titre, nous n’envoyons pas à tous
        les Clients BMG des noms d’utilisateur et des mots de passe
        («Identifiants de Connexion» «Login Credentials»), mais, nous demandons
        plutôt aux Clients BMG s’ils souhaitent que nous générions des
        Identifiants de Connexion dans le cas où ils sont intéressés par
        l’utilisation de la Plateforme. Si vous nous avez demandé de générer des
        Identifiants de connexion pour vous, nous les créerons et vous les
        enverrons.
      </p>
      <p>
        <span className="text-red-800">2.2</span> Il est de votre responsabilité
        de garder à tout moment ces Identifiants de Connexion secrets, en
        particulier le mot de passe, et vous êtes le seul responsable à
        l’encontre de BMG de toute activité se produisant par le biais de votre
        compte pour la Plateforme et le Site. Si vous perdez les Identifiants de
        Connexion, ou si vous découvrez ou suspectez que ces Identifiants de
        Connexion sont utilisés par un tiers, vous devez alors immédiatement
        nous le notifier. Nous bloquerons alors l’utilisation ultérieure de ces
        Identifiants de Connexion. Vos Identifiants de Connexion ne doivent pas
        être partagés et vous resterez entièrement responsable de l’utilisation
        de votre compte sur la Plateforme, de toute activité provenant de votre
        compte ou résultant de votre accès à la Plateforme ou au Site. BMG peut
        vous demander de modifier vos Identifiants de Connexion ou certains
        éléments des informations relatives à votre compte, à tout moment et
        pour toute raison. Dans le cas où votre entité solliciterait de nouveaux
        Identifiants de Connexion pour d’autres utilisateurs, nous vous prions
        de contacter BMG par courrier électronique à l’adresse suivante :
        <a href="mailto:my.bmg@bmg.com">my.bmg@bmg.com</a>
      </p>
      <h3>3. Vos paramètres</h3>
      <p>
        Dans la section «My Settings» de la Plateforme, nous pouvons vous
        autoriser à modifier certaines données vous concernant, telles que votre
        adresse postale ou votre adresse de courrier électronique. Si vous le
        faites, nous utiliserons ensuite les données modifiées pour communiquer
        avec vous, ceci incluant notamment toutes communications faites en
        relation avec des Documents Contractuels (tels que définis à l’article
        4.1 ci-dessous). Les relevés que nous vous envoyons par courrier
        ordinaire, par exemple, seront ensuite seulement envoyés à la nouvelle
        adresse. Nous ne sommes pas responsables des erreurs d’envoi des
        communications qui seraient dues à vos modifications.
      </p>
      <h3>4. Exactitude de données de la licence et des redevances</h3>
      <p>
        <span className="text-red-800">4.1</span> La Plateforme est conçue,
        maintenue et mise à votre disposition dans un but d’information
        exclusivement. Les données et autres informations présentes sur la
        Plateforme ne peuvent et ne doivent pas se substituer aux redditions de
        comptes et chiffres officiels. BMG continuera à vous envoyer, par
        d’autres moyens que via la Plateforme, les états de redevances tels que
        prévus par vos contrats avec BMG, en application desquels BMG vous
        adresse des états de redevances («Documents Contractuels»).
      </p>
      <p>
        <span className="text-red-800">4.2</span> Aucune garantie, déclaration,
        autorisation ou représentation n’est consentie, donnée ou faite, et
        aucune responsabilité n’est assumée par BMG, que ce soit de manière
        explicite ou implicite, en ce qui concerne le caractère raisonnable,
        conforme, exact ou exhaustif des données relatives aux oeuvres,
        licences, redevances, états de redevances ou de toute autre donnée,
        information, matériels ou documents auxquels vous auriez accès ou que
        vous pourriez voir, télécharger ou obtenir sur la Plateforme.
      </p>
      <h3>
        5. Droits de propriété intellectuelle et industrielle ; Utilisation de
        la Plateforme
      </h3>
      <p>
        <span className="text-red-800">5.1</span> Les services présents sur
        notre Plateforme sont, entre autres, conservés sur une base de données.
        Cette base de données est protégée notamment par le droit sui generis
        accordé aux producteurs de bases de données prévu aux Sections 87a et
        suivants de la loi allemande sur le droit d’auteur. BMG est propriétaire
        et/ou licencié du Site, de la Plateforme, des logiciels, des bases de
        données et/ou des autres composants sous-jacents du Site et de la
        Plateforme, des textes, des photographies, des illustrations, des
        graphiques, des noms (commerciaux), des logos, des marques de commerce
        et de service (collectivement, «Eléments de Propriété
        Intellectuelle»).Les Eléments de Propriété Intellectuelle sont protégés
        par le droit d’auteur, le droit des marques et/ou tous autres droits de
        propriété intellectuelle. Il vous est, en particulier, interdit de
        copier, transmettre, distribuer, diffuser ou rendre publiquement
        accessible aux tiers tout Elément de Propriété Intellectuelle, sans le
        consentement préalable écrit de BMG.
      </p>
      <p>
        <span className="text-red-800">5.2</span> Les contenus de la Plateforme
        et du Site ne peuvent être utilisés que pour votre information
        personnelle. Sauf stipulation contraire des Conditions d’Utilisation,
        aucune partie de la Plateforme ou du Site et aucun contenu ne peut être
        copié, reproduit, publié à nouveau, téléchargé, mis en ligne, diffusé
        publiquement, encodé, traduit, transmis ou distribué de quelle que
        manière que ce soit (ceci incluant le «mirroring») sur tout autre
        ordinateur, serveur, site internet ou tout autre moyen de publication ou
        de distribution, ou dans le cadre de toute entreprise commerciale, sans
        le consentement préalable écrit de BMG.
      </p>
      <p>
        <span className="text-red-800">5.3</span> Vous ne devez pas essayer
        d’obtenir un accès non autorisé à toute section ou caractéristique de la
        Plateforme ou du Site, ou à tout autre système ou réseau connecté au
        Site ou à tout serveur BMG, ou à un des services offerts sur ou via la
        Plateforme ou le Site, par piratage, récupération de mots de passe ou
        tout autre moyen illégitime ou illégal. Sans que cela soit limitatif,
        vous ne pouvez pas utiliser la Plateforme ou le Site pour accéder ou
        tenter d’accéder aux données fournies par un autre Client de BMG ou
        utiliser la Plateforme ou le Site pour violer les droits d’un autre
        Client de BMG ou de tout tiers.
      </p>
      <p>
        <span className="text-red-800">5.4</span> Vous ne pouvez pas créer des
        en-têtes ou manipuler des identifiants dans le but de cacher l’origine
        de messages ou de transmissions que vous envoyez à BMG sur ou via la
        Plateforme ou le Site, ou de tout service offert sur ou via la
        Plateforme ou le Site. Vous ne pouvez pas prétendre que vous êtes ou que
        vous représentez quelqu’un d’autre, ou vous faire passer pour toute
        autre personne ou entité.
      </p>
      <h3>6. Notification de réclamation pour violation de droits d’auteur</h3>
      <p>
        BMG respecte tous les droits de propriété intellectuelle des tiers et
        attend des utilisateurs de la Plateforme et du Site qu’ils en fassent de
        même. Le nom et l’adresse de l’agent BMG désigné pour recevoir toutes
        réclamations pour violations de droits de propriété intellectuelle est:
      </p>
      <p>
        Keith C. Hauprich Esq.,
        <br />
        BMG Rights Management (US) LLC
        <br />
        1745 Broadway, New York, NY 10019, USA
        <br />
        Telephone: +1 212 561 3000
        <br />
        Fax: +1 212 683 2040
        <br />
        Email:{" "}
        <a href="mailto:keith.hauprich@bmg.com">keith.hauprich@bmg.com</a>
      </p>
      <p>
        Si vous pensez que votre œuvre a été utilisée ou copiée d’une manière
        constituant une violation des droits de propriété intellectuelle et que
        cette violation se produit sur le Site, des notices écrites de
        réclamations pour violation des droits de propriété intellectuelle
        doivent être envoyées au nom et à l’adresse cités ci-dessus.
      </p>
      <h3>7. Maintenance de la Plateforme</h3>
      <p>
        Nous pouvons effectuer, à tout moment, des travaux de maintenance sur la
        Plateforme et sur le Site. Il se pourra donc que vous ne puissiez pas
        accéder à la Plateforme ou le Site, de manière temporaire, en raison des
        travaux d’entretien en cours. Cette impossibilité temporaire d’accès est
        nécessaire et ne constitue pas une violation du contrat.
      </p>
      <h3>8. Exonération de garanties, déclarations et responsabilités</h3>
      <p>
        La navigation et l‘utilisation de la plateforme et du site et toutes les
        consequences pouvant en decouler se font a vos propres risques. Si vous
        n’etes pas satisfaits de la plateforme ou du site, ou des termes et
        conditions des presentes conditions d’utilisation, vous devez arreter
        d’y acceder et de les utiliser. Dans les limites autorisees par la loi
        applicable, la plateforme et le site et tous les contenus, informations,
        logiciels, documents et materiels accessibles sur et/ou via la
        plateforme ou le site sont fournis «tels quels», «tels que disponibles»,
        avec ou sans erreurs, et vous renoncez a toutes responsabilite,
        declarations et garanties de quelque sorte de bmg, qu’elles soient
        expresses, implicites ou prevues par les lois et reglements.
      </p>
      <p>
        En raison des incertitudes et dangers inherents a la distribution
        electronique, il peut y avoir des retards, omissions, inexactitudes ou
        autres problemes avec les informations presentes sur le site et la
        plateforme. Vous comprenez que vous etes seul responsable de tout
        dommage cause a votre systeme informatique ou de la perte de donnees
        resultant de tout materiel et/ou donnees telechargees depuis, ou
        autrement obtenues via la plateforme et le site.
      </p>
      <p>
        Sans prejudice de ce qui precede, nous ne declarons et ne garantissons
        pas l’exactitude, l’exhaustivite, l’actualite, l’absence d’atteinte, la
        qualite marchande ou l’adaptation pour un usage particulier des
        informations disponibles via le site ou la plateforme ou sur le site ou
        la plateforme eux-memes, de plus que nous ne garantissons pas que le
        site et la plateforme seront exempts de toutes erreurs ou disponibles de
        maniere continue, ou que le site et la plateforme seront libres de virus
        ou de tous autres composants nuisibles. A aucun moment nous ne serons
        responsables envers vous ou toute autre personne de tout dommage
        provenant de l’utilisation du site ou de la plateforme, ceci incluant
        sans limitation, la responsabilite pour des dommages accessoires,
        speciaux, accidentels, indirects ou similaires, meme si nous avons ete
        informes au prealable de la possibilite de tels dommages.
      </p>
      <p>
        Certains droits ou juridictions ne permettant pas l’exclusion ou la
        limitation de la responsabilite pour certaines categories de dommages,
        vous acceptez que notre responsabilite, nonobstant ce qui precede, soit
        limitee en application de ces droits et devant ces juridictions au
        maximum de ce qui est le cas echeant autorise par la loi applicable,
        decoulant de n’importe quel type de reclamation en rapport de quelle que
        maniere que ce soit avec le site ou la plateforme et ne puisse le cas
        echeant pas exceder le montant, s’il en existe un, que vous nous auriez
        verse pour l’utilisation du site et de la plateforme.
      </p>
      <h3>9. Indemnité</h3>
      <p>
        Vous acceptez d’indemniser, défendre et garantir BMG ainsi que ses
        administrateurs, dirigeants, employés, agents, concédants et
        fournisseurs, contre toutes pertes, frais, préjudices et coûts (incluant
        les honoraires d’avocats raisonnables) découlant de toutes violations
        des présentes Conditions d’Utilisation ou de toute activité en relation
        avec votre compte (incluant, sans que cela soit limitatif, les
        comportements négligents et fautifs) effectuées par vous ou par toute
        autre personne accédant au Site et à la Plateforme en utilisant votre
        compte.
      </p>
      <h3>10. Politique de Confidentialité</h3>
      <p>
        Pour de plus amples informations sur la protection des données et les
        pratiques de collectes de BMG, nous vous prions de bien vouloir lire la
        Politique de Confidentialité, qui fait partie intégrante de ces
        Conditions d’Utilisation{" "}
        <strong>(Voir Politique de Confidentialité). </strong>Vous acceptez que
        BMG utilise vos données conformément à la Politique de Confidentialité.
      </p>
      <h3>11. Général</h3>
      <p>
        <span className="text-red-800">11.1</span> Si une des dispositions des
        présentes s’avérait illicite, nulle, invalide ou autrement inexécutable
        en raison de lois d’un pays dans lequel ladite disposition a vocation à
        s’appliquer, cette disposition sera écartée des Conditions d’Utilisation
        dans la mesure où et dans le pays dans lequel ladite disposition est
        illicite, nulle, invalide ou inexécutable. Les autres dispositions
        seront maintenues dans les Conditions d’Utilisation, et resteront en
        vigueur et exécutables.
      </p>
      <p>
        <span className="text-red-800">11.2</span> Nous pouvons modifier,
        restreindre l’accès, suspendre ou arrêter de manière permanente la
        Plateforme, ou toute section de la Plateforme ou du Site, à tout moment,
        sans que ceci n’ouvre droit pour vous à une indemnisation quelconque.
      </p>
      <p>
        <span className="text-red-800">11.3</span> Dans le cas d’un conflit
        entre les termes et conditions prévus aux présentes Conditions
        d’Utilisation et les termes et conditions prévus dans les Documents
        Contractuels, les termes et conditions prévus aux Documents Contractuels
        applicables prévaudront.
      </p>
      <p>
        <span className="text-red-800">11.4</span> La relation contractuelle
        entre BMG et vous en application des présentes Conditions d’Utilisation
        sera exclusivement soumise au droit français, exclusion faite des
        dispositions relatives aux conflits de lois.
      </p>
      <p>
        <span className="text-red-800">11.5</span> BMG se réserve le droit de
        fermer votre accès à la Plateforme en cas de violation des présentes
        Conditions d’Utilisation.
      </p>
      <p>
        <span className="text-red-800">11.6</span> Les juridictions de Paris,
        France, auront compétence exclusive pour statuer sur tout litige
        découlant ou en relation avec les présentes Conditions d’Utilisation et
        la relation contractuelle existant entre BMG et vous en application des
        présentes Conditions d’Utilisation.
      </p>
      <h3>12. Nous contacter</h3>
      <p>
        Pour toutes questions ou commentaires sur les présentes Conditions
        d’Utilisation, vous pouvez nous contacter à l’adresse suivante :
        <a href="mailto:my.bmg@bmg.com">my.bmg@bmg.com</a>
      </p>
      <p>
        <small>Entrée en vigueur : Octobre 2013</small>
      </p>
    </div>
  );
};
export default TermsFR;
