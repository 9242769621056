import InfoIcon from "../../../app/atom/Icon/InfoIcon";
import styles from "../../../app/molecules/sideBasicWrapper/sideBasic.module.scss";

const SuccessComponent = ({ successMessage }: { successMessage: string }) => {
  return (
    <div className={styles.registerSuccessSection}>
      <div className="mr-2">
        <InfoIcon className="fill-current h-5 w-5" />
      </div>
      <div>{successMessage}</div>
    </div>
  );
};

export default SuccessComponent;
