import { TFunction } from "i18next";
import { StatusEnum } from "../../../../app/types/enums";
import { toast } from "react-toastify";

type HandleMessageProps = {
  status: StatusEnum.Failed | StatusEnum.Fulfilled;
  t: TFunction;
  message?: string | undefined;
};

export const handleMessage = ({
  status,
  message,
  t,
}: HandleMessageProps): void => {
  const defaultSuccessMessage = "adminSection.common.defaultSuccessMessage";
  const defaultErrorMessage = "error.genericErrorMsg";
  if (status === StatusEnum.Fulfilled) {
    toast.success(`${t(message || defaultSuccessMessage)}`);
  } else if (status === StatusEnum.Failed) {
    toast.error(
      `${
        message
          ? t("error.errorWithCustomMessage", { errorMsg: message })
          : t(defaultErrorMessage)
      }`
    );
  }
};
