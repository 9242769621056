import {
  PubSyncTabStatusEnum,
  PubSyncTabsEnum,
} from "../../../app/types/enums";
import withClientSelectionData from "../clientSelection/withClientSelectionData";
import withPeriodSelectorData from "../../../app/molecules/periodSelector/withPeriodSelectorData";
import PubSyncHOC from "./PubSyncHOC";

const PubSyncCompleted = (): JSX.Element => (
  <PubSyncHOC
    status={PubSyncTabStatusEnum.completed}
    selectedTab={PubSyncTabsEnum.completed}
    hasPeriodSelector={true}
  />
);

export default withClientSelectionData(
  withPeriodSelectorData(PubSyncCompleted)
);
