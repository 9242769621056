import React from "react";
function QuotingIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 27 27"
      stroke="none"
      name="PaidIcon"
      {...props}
    >
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <circle cx="12.5" cy="12.5" r="12.5" stroke="none" strokeWidth="1.2" />
        <path
          fill="currentColor"
          d="M13.195 12.014h-1.4a1.055 1.055 0 0 1-1.053-1.054c0-.581.473-1.054 1.054-1.054h2.469a.482.482 0 0 0 .481-.481.482.482 0 0 0-.481-.481h-1.287v-.982a.482.482 0 0 0-.48-.481.482.482 0 0 0-.482.48v.983h-.22A2.02 2.02 0 0 0 9.78 10.96a2.02 2.02 0 0 0 2.016 2.016h1.399a1.055 1.055 0 0 1 0 2.109H10.68a.482.482 0 0 0-.48.48c0 .265.216.482.48.482h1.331v1.002c0 .264.217.48.481.48a.482.482 0 0 0 .481-.48v-1.002h.245a2.02 2.02 0 0 0 1.988-2.017 2.014 2.014 0 0 0-2.012-2.016"
        />
      </g>
    </svg>
  );
}

export default QuotingIcon;
