import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { SortingCriteria } from "../../../../app/types/props";
import {
  SortDirectionEnum,
  StatusEnum,
  UserAccountTypeEnum,
} from "../../../../app/types/enums";

import { useFetch } from "../../common/hooks/useFetch";
import { DEFAULT_ROWS_PER_PAGE } from "../../common/adminEndpoints.const";

import { tableHeadData } from "./components/UsersTable/UsersTable.const";

import { getUsersList } from "./UsersAPI";

export type UsersPageState = {
  filterText: string | null;
  pagination: {
    page: number;
    size: number;
    sortingCriteria: SortingCriteria[];
  };
  searchCriteria: {
    userAccountType: UserAccountTypeEnum | null;
    onlyDeleted: boolean;
  };
};

export type UsersListItem = {
  id: number;
  userAccountType: UserAccountTypeEnum;
  username: string;
  email: string;
  createdOn: string;
  modifiedOn: string;
  modifiedBy: string;
  mechanical: boolean;
  groups: string[];
};

type UsersInitialState = {
  usersPageState: UsersPageState;
  tableData: {
    list: UsersListItem[] | null;
    count: number | null;
    totalCount: number | null;
  };
  status: StatusEnum;
};

const initialState: UsersInitialState = {
  usersPageState: {
    filterText: null,
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: tableHeadData?.[0].filteringInfo,
          direction: SortDirectionEnum.Ascending,
        },
      ],
    },
    searchCriteria: {
      userAccountType: null,
      onlyDeleted: false,
    },
  },
  tableData: {
    list: null,
    count: 0,
    totalCount: 0,
  },
  status: StatusEnum.Idle,
};

export const getUsersThunk = createAsyncThunk(
  "admin/users",
  async (payload: any, { rejectWithValue }) => {
    try {
      const getUsers = await useFetch();
      const response = await getUsers({
        data: payload,
        fetchFunction: getUsersList,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateAdminUsersPageState: (state, action: any) => {
      state.usersPageState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(getUsersThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getUsersThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      });
  },
});

export const UsersSelector = (state: RootState) => state.users;

export default usersSlice.reducer;
