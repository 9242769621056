/* eslint-disable no-template-curly-in-string */
import { setLocale } from "yup";

export const setYupLocale = (locale: string) => {
  let loc = {};

  switch (locale) {
    case "en":
    case "en_GB":
    case "en_US":
      loc = {
        mixed: {
          required: "Please complete",

          notType: function notType(_ref: { type: any }) {
            switch (_ref.type) {
              case "number":
                return "Numerical value required";
              case "string":
                return "Alphanumeric value required";
              default:
                return "Wrong data type";
            }
          },
        },
        string: {
          email: "This email is invalid",
          min: "At least ${min} characters required",
          max: "Maximum ${max} characters",
          length: "The input must be exactly ${length} characters",
          matches: "Please check the format of your input",
          required: "Please complete",
          url: "This must be a valid URL",
          trim: "The input must be a trimmed string",
          lowercase: "The input must be a lowercase string",
          uppercase: "The input must be a upper case string",
        },
        number: {
          min: "The input must be greater than or equal to ${min}",
          max: "The input must be less than or equal to ${max}",
          positive: "Here is a selection required",
          lessThan: "The input must be less than ${less}",
          moreThan: "The input must be greater than ${more}",
          integer: "The input must be an integer",
        },
      };
      break;
    case "de":
    case "de_DE":
      loc = {
        mixed: {
          required: "Bitte vervollständigen",

          notType: function notType(_ref: { type: any }) {
            switch (_ref.type) {
              case "number":
                return "Numerischer Wert erforderlich";
              case "string":
                return "Alphanumerischer Wert erforderlich";
              default:
                return "Falscher Datentyp";
            }
          },
        },
        string: {
          email: "Diese E-Mail ist nicht gültig",
          min: "Mindestens ${min} Zeichen erforderlich",
          max: "Maximal ${max} Zeichen",
          length: "Die Eingabe muss genau ${length} Zeichen umfassen",
          matches: "Bitte überprüfen Sie das Format Ihrer Eingabe",
          required: "Bitte vervollständigen",
          url: "Dies muss eine gültige URL sein",
          trim: "Die Eingabe muss eine gekürzte Zeichenfolge sein",
          lowercase:
            "Die Eingabe muss eine Zeichenfolge in Kleinbuchstaben sein",
          uppercase:
            "Die Eingabe muss eine Zeichenfolge in Großbuchstaben sein",
        },
        number: {
          min: "Die Eingabe muss größer oder gleich ${min} sein.",
          max: "Die Eingabe muss kleiner oder gleich ${max} sein.",
          positive: "Hier ist eine Auswahl erforderlich",
          lessThan:
            "Hier ist eine Auswahl erforderlich. Die Eingabe muss kleiner als ${less} sein.",
          moreThan: "Die Eingabe muss größer als ${more} sein",
          integer: "Die Eingabe muss eine Ganzzahl sein",
        },
      };
      break;
    case "es":
    case "es_ES":
      loc = {
        mixed: {
          required: "Por favor complete",

          notType: function notType(_ref: { type: any }) {
            switch (_ref.type) {
              case "number":
                return "Valor numérico requerido";
              case "string":
                return "Valor alfanumérico requerido";
              default:
                return "Tipo de datos incorrecto";
            }
          },
        },
        string: {
          email: "Este email es invalido",
          min: "Se requieren al menos ${min} caracteres",
          max: "Máximo ${max} caracteres",
          length: "La entrada debe tener exactamente ${length} caracteres",
          matches: "Por favor verifique el formato de su entrada.",
          required: "Por favor completo",
          url: "Esta debe ser una URL válida.",
          trim: "La entrada debe ser una cadena recortada.",
          lowercase: "La entrada debe ser una cadena en minúsculas.",
          uppercase: "La entrada debe ser una cadena en mayúsculas.",
        },
        number: {
          min: "La entrada debe ser mayor o igual a ${min}",
          max: "La entrada debe ser menor o igual a ${max}",
          positive: "Aquí hay una selección requerida.",
          lessThan: "La entrada debe ser menor que ${less}",
          moreThan: "La entrada debe ser mayor que ${more}",
          integer: "La entrada debe ser un número entero.",
        },
      };
      break;
    case "it":
    case "it_IT":
      loc = {
        mixed: {
          required: "Completare prego",

          notType: function notType(_ref: { type: any }) {
            switch (_ref.type) {
              case "number":
                return "Valore numerico richiesto";
              case "string":
                return "Valore alfanumerico obbligatorio";
              default:
                return "Tipo di dati errato";
            }
          },
        },
        string: {
          email: "Questa email non è valida",
          min: "Sono richiesti almeno ${min} caratteri",
          max: "Numero massimo di ${max} caratteri",
          length: "L'input deve contenere esattamente ${length} caratteri",
          matches: "Controlla il formato del tuo input",
          required: "Completare, prego",
          url: "Deve essere un URL valido",
          trim: "L'input deve essere una stringa troncata",
          lowercase: "L'input deve essere una stringa minuscola",
          uppercase: "L'input deve essere una stringa maiuscola",
        },
        number: {
          min: "L'input deve essere maggiore o uguale a ${min}",
          max: "L'input deve essere inferiore o uguale a ${max}",
          positive: "Ecco una selezione obbligatoria",
          lessThan: "L'input deve essere inferiore a ${less}",
          moreThan: "L'input deve essere maggiore di ${more}",
          integer: "L'input deve essere un numero intero",
        },
      };
      break;
    case "pt":
    case "pt_BR":
      loc = {
        mixed: {
          required: "Por favor complete",

          notType: function notType(_ref: { type: any }) {
            switch (_ref.type) {
              case "number":
                return "Valor numérico obrigatório";
              case "string":
                return "Valor alfanumérico obrigatório";
              default:
                return "Tipo de dados errado";
            }
          },
        },
        string: {
          email: "Este email é inválido",
          min: "São necessários pelo menos ${min} caracteres",
          max: "Máximo de ${max} caracteres",
          length: "A entrada deve ter exatamente ${length} caracteres",
          matches: "Verifique o formato da sua entrada",
          required: "Por favor completo",
          url: "Este deve ser um URL válido",
          trim: "A entrada deve ser uma string aparada",
          lowercase: "A entrada deve ser uma string minúscula",
          uppercase: "A entrada deve ser uma string maiúscula",
        },
        number: {
          min: "A entrada deve ser maior ou igual a ${min}",
          max: "A entrada deve ser menor ou igual a ${max}",
          positive: "Aqui está uma seleção necessária",
          lessThan: "A entrada deve ser menor que ${less}",
          moreThan: "A entrada deve ser maior que ${more}",
          integer: "A entrada deve ser um número inteiro",
        },
      };
      break;
    default:
      loc = {
        mixed: {
          required: "Pflichtfeld",

          notType: function notType(_ref: { type: any }) {
            switch (_ref.type) {
              case "number":
                return "Numerischer Wert erforderlich";
              case "string":
                return "Alphanumerischer Wert erforderlich";
              default:
                return "Falscher Datentyp";
            }
          },
        },
        string: {
          email: "Diese eMail ist ungültig",
          min: "Mindestens ${min} Zeichen erforderlich",
          max: "Maximal ${max} Zeichen",
          length: "Die Eingabe muss exakt ${length} Zeichen betragen",
          matches: "Bitte prüfe das Format deiner Eingabe",
          url: "Die URL ist ungültig",
          trim: "Die Eingabe darf keine Leerzeichen enthalten",
          lowercase: "Die Eingabe muss aus Kleinbuchstaben bestehen",
          uppercase: "Die Eingabe muss aus Grossbuchstaben bestehen",
        },
        number: {
          min: "Wert zu niedrig (minimun ${min})",
          max: "Wert zu hoch (höchstens ${max})",
          positive: "Auswahl erforderlich",
          lessThan: "Die Eingabe muss geringer sein als ${less}",
          moreThan: "Die Eingabe muss größer sein als ${more}",
          integer: "Die Eingabe muss eine Ganzzahl sein",
        },
      };
  }
  setLocale(loc);
};
