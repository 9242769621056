const TermsSV = (): JSX.Element => {
  return (
    <div>
      <h1>Omfattning</h1>
      <h2>för BMG:s online klientplattform på my.bmg.com</h2>
      <p>
        Välkommen till BMG:s online klientplattform (“
        <strong>Plattformen</strong>”) på my.bmg.com (“
        <strong>Webbplatsen</strong>”), vilken BMG Rights Management GmbH,
        Tyskland, driver på eget uppdrag samt för BMG-koncernen, inklusive dess
        filialer och dotterbolag (var för sig och tillsammans “
        <strong>BMG</strong>”, “<strong>vi</strong>”, “<strong>oss</strong>”, “
        <strong>vår</strong>”), för låtskrivare, förläggare och andra
        BMG-klienter (tillsammans, “ <strong>BMG-Klient(er)</strong>”)
        <strong>.</strong>
      </p>
      <p>
        DESSA ANVÄNDARVILLKOR UTGÖR ETT JURIDISKT BINDANDE AVTAL MELLAN BMG OCH
        DIG, ELLER PÅ UPPDRAG AV EN BMG-KLIENT OCH, OM DU BESÖKER ELLER ANVÄNDER
        PLATTFORMEN ELLER WEBBPLATSEN PÅ UPPDRAG AV EN BMG-KLIENT INOM RAMEN FÖR
        DITT ÅTAGANDE MED BMG-KLIENTEN, MELLAN BMG OCH SÅDAN BMG-KLIENT, SOM EN
        ANVÄNDARE AV PLATTFORMEN OCH WEBBPLATSEN (DESSA “
        <strong>ANVÄNDARVILLKOR</strong>”). DU GARANTERAR ATT DU ÄR BEHÖRIG ATT
        BINDA DIG OCH SÅDAN BMG-KLIENT SOM KONTRAKTSPARTNER TILL DESSA
        ANVÄNDARVILLKOR. SÅVIDA INTE ANNAT ANGES I DESSA ANVÄNDARVILLKOR SKALL
        GENOMGÅENDE I ÅTERSTODEN AV DESSA ANVÄNDARVILLKOR ORDEN "DU" ELLER "DIN"
        ANSES REFERERA ENSKILT TILL DIG OCH, OM TILLÄMPLIGT, GEMENSAMT TILL DIG
        OCH BMG-KLIENTEN PÅ VARS UPPDRAG DU BESÖKER ELLER ANVÄNDER PLATTFORMEN
        ELLER WEBBPLATSEN.
      </p>
      <p>
        GENOM REGISTRERING, BESÖK, BLÄDDRING ELLER ANVÄNDNING AV PLATTFORMEN
        ELLER WEBBPLATSEN, BEKRÄFTAR DU ATT DU HAR LÄST, FÖRSTÅTT OCH SAMTYCKT
        TILL ATT VARA BUNDEN AV DESSA ANVÄNDARVILLKOR, INKLUSIVE ALLA ÄNDRINGAR
        SOM HAR GJORTS NÄR DU ÅTERBESÖKER PLATTFORMEN ELLER WEBBPLATSEN. VI KAN
        UPPDATERA DESSA ANVÄNDARVILLKOR FRÅN TID TILL ANNAN UTAN ATT I FÖRVÄG
        MEDDELA DIG. SÅVIDA INTE ANNAT ANGES KOMMER SÅDANA ÄNDRINGAR ATT GÄLLA
        NÄR DE SÄTTS UPP PÅ WEBBPLATSEN.
      </p>
      <p>
        OM DU INTE SAMTYCKER TILL DESSA ANVÄNDARVILLKOR ELLER POLICIES SOM FINNS
        PÅ WEBBPLATSEN, BER VI DIG ATT INTE ANVÄNDA PLATTFORMEN ELLER
        WEBBPLATSEN.
      </p>
      <h3>1. Omfattning</h3>
      <p>
        Dessa Användarvillkor reglerar din användning av Plattformen och
        Webbplatsen.
      </p>
      <h3>2. Inloggningsuppgifter</h3>
      <p>
        <span className="text-red-800">2.1</span> Användningen av Plattformen är
        ett tillval för BMG-Klienter. Vi skickar därför inte ut användarnamn och
        lösenord (“<strong>Inloggningsuppgifter</strong>”) till alla
        BMG-Klienter, utan endast på begäran.
      </p>
      <p>
        <span className="text-red-800">2.2</span> Det är ditt ansvar att alltid
        hålla Inloggningsuppgifterna, särskilt lösenordet, hemligt och du är
        ensam ansvarig gentemot BMG för alla aktiviteter som sker via ditt konto
        på Plattformen och Webbplatsen. Om du förlorar dina Inloggningsuppgifter
        eller om du upptäcker eller misstänker att dina Inloggningsuppgifter
        används av tredje part måste du omedelbart meddela oss detta. Vi kommer
        då att blockera vidare användning av Inloggningsuppgifterna. Dina
        Inloggningsuppgifter får inte delas med andra och du ansvarar för ditt
        konto på Plattformen och Webbplatsen och de aktiviteter som sker på ditt
        konto eller som ett resultat av din åtkomst till Plattformen och
        Webbplatsen. BMG kan kräva att du ändrar dina Inloggningsuppgifter eller
        vissa delar av din kontoinformation när som helst utan att ange orsak.
        Om ditt företag behöver ytterligare Inloggningsuppgifter för andra
        användare, kontakta BMG via e-post på{" "}
        <a href="mailto:my.bmg@bmg.com">my.bmg@bmg.com</a>.
      </p>
      <h3>3. Dina inställningar</h3>
      <p>
        Under “Mina inställningar” på Plattformen kan vi tillåta att du
        redigerar viss information om dig själv, såsom din adress. Vi kommer
        därefter att använda dina redigerade uppgifter för att kommunicera med
        dig, inklusive all kommunikation som görs i anslutning till
        Kontraktsdokumentet (se definition i punkten 4.1 nedan). De meddelanden
        vi skickar med vanlig post kommer exempelvis att endast skickas till den
        nya adressen. Vi ansvarar inte för feladresserad kommunikation som beror
        på dina redigeringar.
      </p>
      <h3>4. Korrekthet hos licens- och royaltydata</h3>
      <p>
        <span className="text-red-800">4.1</span> Plattformen är designad och
        tillgänglig för dig endast i informationssyfte. Data och annan
        information på Plattformen kan inte och skall inte, ersätta officiella
        uppgifter och rapporter som BMG tillhandahåller. BMG kommer att
        fortsätta att skicka bindande royaltyrapporter till dig, på andra sätt
        än genom Plattformen, enligt vad som krävs av dina kontrakt med BMG
        enligt vilka du tar emot royaltyrapporter (“
        <strong>Kontraktsdokument</strong>”).
      </p>
      <p>
        <span className="text-red-800">4.2</span> BMG varken garanterar,
        bemyndigar, befullmäktigar eller påtar sig något som helst ansvar, vare
        sig uttalat eller underförstått, beträffande rimligheten, korrektheten,
        riktigheten eller fullständigheten av de låtar, licenser, royaltydata,
        royaltyrapporter samt övrig data, information, material eller
        dokumentation som görs tillgänglig, laddas upp eller laddas ned på
        Plattformen.
      </p>
      <h3>
        5. Industriella- och immateriella rättigheter; Användning av Plattformen
      </h3>
      <p>
        <span className="text-red-800">5.1</span> Tjänsterna på Plattformen är
        bland annat baserade på en databas. Denna databas är bland annat skyddad
        av sui generis skyddet för databaser enligt § 49 i svenska
        upphovsrättslagen. Webbplatsen, Plattformen, underliggande program,
        databasen och andra komponenter på Webbplatsen och Plattformen, text,
        fotografier, illustrationer, grafik, (firma-) namn, logotyper,
        varumärken och tjänstemärken (tillsammans “
        <strong>Immateriell Egendom</strong>”) ägs eller licensieras av BMG och
        omfattas av ett upphovsrättsligt, varumärkesrättsligt och/eller annat
        immaterialrättsligt skydd. Du har ingen rätt att duplicera, sända,
        distribuera eller offentliggöra någon Immateriell Egendom till tredje
        part utan att först ha inhämtat BMG:s skriftliga godkännande.
      </p>
      <p>
        <span className="text-red-800">5.2</span> Innehållet på Plattformen och
        Webbplatsen får endast användas för personligt informationssyfte.
        Förutom vad som uttryckligen anges i dessa Användarvillkor får ingen del
        av Plattformen eller Webbplatsen eller innehållet kopieras,
        reproduceras, återpubliceras, laddas upp, sättas upp, offentliggöras,
        kodas, översättas, översändas eller distribueras på något sätt
        (inklusive "avspeglas") på någon annan dator, server, webbsida eller
        andra medium för publikation eller distribution eller till något annat
        företag, utan att först ha inhämtat BMG:s skriftliga godkännande.
      </p>
      <p>
        <span className="text-red-800">5.3</span> Åtkomst, eller försök till
        åtkomst, till någon del eller funktion på Plattformen eller Webbplatsen
        eller något annat system eller någon BMG-server, eller till någon av
        tjänsterna som erbjuds på eller genom Plattformen eller Webbplatsen,
        genom hackning, "lösenordsgrävning" eller på annat olagligt sätt är
        förbjuden. Du får inte använda Plattformen eller Webbplatsen för åtkomst
        eller försök till åtkomst till data som tillhandahålls av andra
        BMG-Klienter eller använda Plattformen eller Webbplatsen för att bryta
        mot andra BMG-Klienters eller tredje parts rättigheter.
      </p>
      <p>
        <span className="text-red-800">5.4</span> Du får inte förfalska rubriker
        eller på annat sätt manipulera identifierare för att dölja ett
        meddelandes ursprung eller ett översändande av ett meddelande som du
        skickar till BMG på eller genom Plattformen eller Webbplatsen. Du får
        inte utge dig för att vara eller representera någon annan eller imitera
        någon annan person eller företag.
      </p>
      <h3>6. Notering om påstått upphovsrättsintrång</h3>
      <p>
        BMG respekterar immateriella rättigheter och förväntar sig att du gör
        detsamma. Om du tror att ditt arbete har använts eller kopierats på ett
        sätt som utgör ett upphovsrättsintrång i enlighet med svenska
        upphovsrättslagen, Lag (1960:729) om upphovsrätt till litterära och
        konstnärliga verk och att sådant intrång sker på Webbplatsen skall ett
        skriftligt meddelande skickas till nedan angivna adress.
      </p>
      <p>
        Keith C. Hauprich Esq.,
        <br />
        BMG Rights Management (US) LLC
        <br />
        1745 Broadway, New York, NY 10019, USA
        <br />
        Telephone: +1 212 561 3000
        <br />
        Fax: +1 212 683 2040
        <br />
        E-post:{" "}
        <a href="mailto:keith.hauprich@bmg.com">keith.hauprich@bmg.com</a>
      </p>
      <h3>7. Underhåll av Plattformen </h3>
      <p>
        Vi kommer, från tid till annan, att utföra underhållsarbete på
        Plattformen och Webbplatsen. Det kan finnas perioder då du inte kommer
        att kunna komma åt Plattformen eller Webbplatsen på grund av att
        underhållsarbete utförs. Sådant underhållsarbete är nödvändigt och utgör
        inget kontraktsbrott.
      </p>
      <h3>8. Friskrivning från garantier och ansvar</h3>
      <p>
        Din användning av och bläddring på, och all din tillit till, plattformen
        eller webbplatsen sker på egen risk. Om du är missnöjd med plattformen
        eller webbplatsen eller med något av villkoren i dessa användarvillkor
        är din enda och exklusiva rätt att avsluta åtkomsten till och
        användningen av plattformen och webbplatsen. Inom ramen för vad som är
        tillåtet enligt tillämplig lag ska plattformen och webbplatsen inklusive
        dess innehåll, information, program, dokument och material som är
        åtkomligt från och/eller genom plattformen tillhandahållas "i befintligt
        skick" och utan några garantier överhuvudtaget.
      </p>
      <p>
        På grund av tillhörande risker och osäkerhet med elektronisk
        distribution kan det förekomma fördröjningar, utelämnanden av,
        felaktigheter eller andra problem med sådan information som finns på
        webbplatsen och plattformen. Du är ensamt ansvarig för all skada på din
        dator eller förlust av data som kan uppstå som ett resultat av det
        material och/eller data som laddas ned från eller på annat sätt
        tillhandahålls genom webbplatsen eller plattformen.
      </p>
      <p>
        Utan begränsning av det föregående kan vi inte garantera korrektheten,
        fullständigheten, aktualiteten, icke-överträdelsen, säljbarheten eller
        lämpligheten av den information som finns tillgänglig genom eller på
        webbplatsen och plattformen. Vi kan heller inte garantera att
        webbplatsen och plattformen kommer att vara felfri eller kontinuerligt
        tillgänglig eller fri från virus eller andra skadliga komponenter. Under
        inga omständigheter kommer vi att vara ersättningskyldiga för skador som
        uppstår genom användning av webbplatsen eller plattformen, inklusive
        utan begränsning, ansvar för följdskador, särskilda, oavsiktliga,
        indirekta eller liknande skador trots att vi har fått information om
        möjligheten att sådana skador kan uppstå. För jurisdiktioner som inte
        medger uteslutande eller begränsning av vissa kategorier av skador
        samtycker du till att vårt ansvar, utan hinder av det föregående, är
        fullt begränsat till vad som medges enligt tillämplig lag som uppstår
        från någon form av lagligt yrkande anknutet till webbplatsen eller
        plattformen och skall inte överstiga kostnaden, om någon, som du har
        betalt för att använda webbplatsen och plattformen.
      </p>
      <h3>9. Skadestånd</h3>
      <p>
        Du samtycker till att betala skadestånd, försvara och hålla BMG och
        deras tjänstemän, direktörer, anställda, agenter, licensgivare och
        leverantörer skadelösa från och mot alla förluster, utgifter, skador och
        kostnader (inklusive skäliga advokatkostnader) som är ett resultat av
        ett brott mot dessa Användarvillkor eller någon aktivitet relaterad till
        ditt konto (inklusive, utan begränsning, försumlighet eller felaktigt
        uppträdande) av dig eller annan person med åtkomst till Webbplatsen
        eller Plattformen med ditt konto.
      </p>
      <h3>10. Sekretess</h3>
      <p>
        För information om BMG:s behandling och insamling av personuppgifter läs
        igenom sekretesspolicyn som utgör en integrerad del av dessa
        Användarvillkor. Du samtycker till BMG:s användning av dina
        personuppgifter i enlighet med sekretesspolicyn.
      </p>
      <h3>11. Allmänt</h3>

      <p>
        <span className="text-red-800">11.1</span> Skulle något av villkoren i
        dessa Användarvillkor vara ogiltigt eller på annat sätt ej verkställbart
        till följd av lagstiftning i det land där villkoret skall gälla skall
        villkoret bortses ifrån varvid dessa Användarvillkor i dess övriga delar
        alltjämt skall vara gällande.
      </p>
      <p>
        <span className="text-red-800">11.2</span> Vi kan när som helst ändra,
        begränsa åtkomst till, eller tillfälligt eller permanent stänga av hela
        eller delar av Plattformen eller Webbplatsen.
      </p>
      <p>
        <span className="text-red-800">11.3</span> Vid en eventuell konflikt
        mellan något av villkoren i dessa Användarvillkor och villkoren i ett
        tillämpligt Kontraktsdokument, äger villkoren i det tillämpliga
        Kontraktsdokumentet företräde.
      </p>
      <p>
        <span className="text-red-800">11.4</span> Kontraktsförhållanden mellan
        BMG och dig som täcks av dessa Användarvillkor skall regleras av svensk
        lag, med undantag för vad som gäller enligt svensk lag och/eller EU:s
        regelverk kring tillämplig lag.
      </p>
      <p>
        <span className="text-red-800">11.5</span> BMG förbehåller sig rätten
        att avsluta din åtkomst till Plattformen vid brott mot dessa
        Användarvillkor.
      </p>
      <p>
        <span className="text-red-800">11.6</span> Tvist med anledning av ett
        kontraktsförhållande i relation till dessa Användarvillkor samt dessa
        Användarvillkor i sig själva ska avgöras vid Stockholms tingsrätt som
        första instans.
      </p>
      <h3>12. Kontakta oss</h3>
      <p>
        För frågor eller kommentarer om dessa Användarvillkor kontakta oss på:{" "}
        <a href="mailto:my.bmg@bmg.com">my.bmg@bmg.com</a>
      </p>
      <p>
        <small>Ikraftträdandedatum: October 2013</small>
      </p>
    </div>
  );
};
export default TermsSV;
