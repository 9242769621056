import { useTranslation } from "react-i18next";
import styles from "./../analysis.module.scss";

const AnalysisDigitalBreakdownTerritory = ({
  data,
  status,
  documentType,
}: {
  data: any;
  status: "noData" | "loading" | "idle" | "failed";
  documentType: "PUBLISHING" | "RECORDING";
}): JSX.Element => {
  const { t } = useTranslation();
  const dataArray =
    documentType === "PUBLISHING" ? data?.sourcesByTerritory : data?.resArr;

  if (status === "loading") <p className="pl-2 pt-4">{t("app.loading")}</p>;

  if (!data || !dataArray) {
    if (status === "idle")
      return (
        <p className="pl-2 pt-4">{t("analysis.songs.breakdown.noData")}</p>
      );
    else return <p className="pl-2 pt-4">{t("app.loading")}</p>;
  }

  const renderStatRow = (i: number, name: string, revenue: any) => {
    return (
      <div className={styles.statRow} key={i}>
        <div
          className={styles.bar}
          style={{ width: revenue.percent + "%" }}
        ></div>
        <div className={styles.info}>
          <span>{name} </span>
        </div>
        <div className={styles.value}>
          <span className={styles.royalty}>
            {revenue.currency} {revenue.formattedShort}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={`smallScrollbar ${styles.territoryStatsContainer}`}>
      {dataArray?.map((t: any, i: number) => {
        return renderStatRow(i, t.name, t.revenue);
      })}
    </div>
  );
};
export default AnalysisDigitalBreakdownTerritory;
