import React from "react";
function SyncAllIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      name="SyncAllIcon"
      viewBox="0 0 27 27"
      {...props}
    >
      <defs>
        <path id="syncall_a" d="M.002.001h2.84v11.626H.002z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <circle cx="12.5" cy="12.5" r="12.5" stroke="currentColor" />
        <g transform="translate(5.829 6.783)" strokeWidth={0}>
          <mask id="syncall_b" fill="#fff">
            <use xlinkHref="#syncall_a" />
          </mask>
          <g>
            <path
              d="M1.42 2.846A1.423 1.423 0 0 0 1.42 0C.637.001 0 .641 0 1.422c0 .782.639 1.424 1.42 1.424Zm0-2.184a.761.761 0 0 1 0 1.52.763.763 0 0 1-.76-.76c0-.418.343-.76.76-.76ZM1.42 7.235c.785 0 1.422-.64 1.422-1.421a1.42 1.42 0 1 0-1.42 1.42Zm0-2.181a.761.761 0 1 1 0 1.522.761.761 0 0 1 0-1.522ZM1.42 11.627c.785 0 1.422-.64 1.422-1.421 0-.785-.64-1.421-1.421-1.421-.782 0-1.421.639-1.421 1.42 0 .783.639 1.422 1.42 1.422Zm0-2.184a.761.761 0 0 1 0 1.52.763.763 0 0 1-.76-.76c0-.418.343-.76.76-.76Z"
              mask="url(#syncall_b)"
              fill="currentColor"
            />
          </g>
        </g>
        <g strokeWidth={0} fill="currentColor">
          <path d="M10.57 8.536h8.13a.331.331 0 1 0 0-.663h-8.13a.331.331 0 1 0 0 .663ZM10.57 12.928h8.13a.331.331 0 1 0 0-.663h-8.13a.331.331 0 1 0 0 .663ZM10.57 17.317h8.13a.331.331 0 1 0 0-.663h-8.13a.331.331 0 1 0 0 .663Z" />
        </g>
      </g>
    </svg>
  );
}

export default SyncAllIcon;
