import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export const updateAdvanceRequestSettings = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.advanceRequest}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};

export function getAdvanceRequestSettings() {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.advanceRequest}`, {
    method: "GET",
  });
}
