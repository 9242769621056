import React from "react";
function NewsIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 22 22"
      name="NewsIcon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="10" cy="10" r="9" />
        <path d="M3.31 4.02A8.977 8.977 0 0 0 10 7a8.978 8.978 0 0 0 6.747-3.043M3.31 15.937a8.977 8.977 0 0 1 6.69-2.98A8.978 8.978 0 0 1 16.747 16" />
        <path d="M9.411 19.026c-2.582-2.185-4.206-5.35-4.206-8.873 0-3.562 1.661-6.76 4.295-8.946M10.294 19.026c2.582-2.185 4.206-5.35 4.206-8.873 0-3.562-1.66-6.76-4.295-8.946M1 10h18M10 1v18" />
      </g>
    </svg>
  );
}

export default NewsIcon;
