import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { StatusEnum, UserAccountTypeEnum } from "../../../../app/types/enums";
import { IColumn } from "../../../../app/types/interfaces";

import { useFetch } from "../../common/hooks/useFetch";

import {
  createAdminAccount,
  deleteAdminAccountById,
  getAdminAccountById,
  getCountriesList,
  resendWelcomeEmail,
  restoreAdminAccount,
  updateAdminAccount,
} from "./AddEditAdminAccountsAPI";
import { TableHeadDataItem } from "./components/tables/TableHeaderData.const";

type AdminAccountsRequestData = {
  id: number;
};

export type LinkedClientsState = TableHeadDataItem & {
  depth: number;
};

export type FormDataState = {
  id: number | null;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  userAccountType: string;
  newPassword: string;
  company: string;
  fax: string;
  taxId: string;
  phone: string;
  languageId: number | null;
  termsOfUseLanguage?: string;
  country: any;
  mechanical: boolean;
  hasAcceptedTerms?: boolean;
  internal?: boolean;
  fromAccessRequest?: boolean;
  deleted?: boolean;
  acceptedTermsLanguage?: string;
  adminGroup?: string | number;
  mechanicalGroup?: string;
  publishingGroup?: string | number;
  publishingRole?: string;
  showPublishingAdvanceRequest?: string;
  songRegistration?: string;
  updateBankDetails?: string;
  showPublishingPipelineIncome?: string;
  dashboardView?: string;
  publishingAdvanceRequestEmail?: string;
  recordingGroup?: string | number;
  recordingRole?: string;
  showRecordingAdvanceRequest?: string;
  journalCreator?: string;
  journalReviewer?: string;
  showRecordingPipelineIncome?: string;
  digitalFlash?: string;
  showRecordingUpdateBankDetails?: string;
  recordingAdvanceRequestEmail?: string;
  recordingJournalReviewerEmail?: string;
  publishingClients?: LinkedClientsState[] | number[];
  recordingClients?: LinkedClientsState[] | number[];
  mechanicalClients?: LinkedClientsState[] | number[];
  groups?:
    | {
        id: number;
        name: string;
        groupType: {
          id: number;
          groupType: string;
        };
      }[]
    | undefined;
};

export type EditAdminAccountProps = {
  id: number;
  filteredData: FormDataState;
};

type AdminAccountsEditInitialState = {
  countries: IColumn[];
  userAccountType: UserAccountTypeEnum | null;
  formData: FormDataState | null;
  publishingLinkedClients: LinkedClientsState[];
  recordingLinkedClients: LinkedClientsState[];
  mechanicalLinkedClients: LinkedClientsState[];
  status: StatusEnum;
};

const initialState: AdminAccountsEditInitialState = {
  countries: [],
  userAccountType: null,
  formData: null,
  publishingLinkedClients: [],
  recordingLinkedClients: [],
  mechanicalLinkedClients: [],
  status: StatusEnum.Idle,
};

export const getCountriesListThunk = createAsyncThunk(
  "admin/countries",
  async (_, { rejectWithValue }) => {
    try {
      const getCountries = await useFetch();
      const response = await getCountries({
        fetchFunction: getCountriesList,
      });

      return response.resArr
        .map((country: { id: string; name: string }) => ({
          value: country.name,
          label: country.name,
        }))
        .sort((a: any, b: any) => a.value.localeCompare(b.value));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdminAccountByIdThunk = createAsyncThunk(
  "admin/admin-users/getById",
  async (id: number, { rejectWithValue }) => {
    try {
      const requestData = {
        id,
      } as AdminAccountsRequestData;

      const _getAdminAccountById = await useFetch();
      const response = await _getAdminAccountById<AdminAccountsRequestData>({
        data: requestData,
        fetchFunction: getAdminAccountById,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAdminAccountByIdThunk = createAsyncThunk(
  "admin/admin-users/deleteById",
  async (id: number, { rejectWithValue }) => {
    try {
      const requestData = {
        id,
      } as AdminAccountsRequestData;

      const _deleteAdminAccountById = await useFetch();
      const response = await _deleteAdminAccountById<AdminAccountsRequestData>({
        data: requestData,
        fetchFunction: deleteAdminAccountById,
        customSuccessMsg:
          "adminSection.adminAccountsPage.successDeleteAdminMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createAdminAccountThunk = createAsyncThunk(
  "admin/users/create",
  async (payload: any, { rejectWithValue }) => {
    try {
      const _createAdminAccount = await useFetch();
      const response = await _createAdminAccount({
        data: payload,
        fetchFunction: createAdminAccount,
        customSuccessMsg:
          "adminSection.adminAccountsPage.successAddAdminMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAdminAccountThunk = createAsyncThunk(
  "admin/admin-users/update",
  async (payload: any, { rejectWithValue }) => {
    try {
      const _updateAdminAccount = await useFetch();
      const response = await _updateAdminAccount({
        data: payload,
        fetchFunction: updateAdminAccount,
        customSuccessMsg:
          "adminSection.adminAccountsPage.successEditAdminMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resendWelcomeEmailThunk = createAsyncThunk(
  "admin/admin-users/resend-welcome-email",
  async (id: number, { rejectWithValue }) => {
    try {
      const requestData = {
        id,
      } as AdminAccountsRequestData;

      const _resendWelcomeEmail = await useFetch();
      const response = await _resendWelcomeEmail<AdminAccountsRequestData>({
        data: requestData,
        fetchFunction: resendWelcomeEmail,
        customSuccessMsg:
          "adminSection.adminAccountsPage.successResendWelcomeEmail",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const restoreAdminAccountThunk = createAsyncThunk(
  "admin/admin-users/restore",
  async (id: number, { rejectWithValue }) => {
    try {
      const requestData = {
        id,
      } as AdminAccountsRequestData;

      const _restoreAdminAccount = await useFetch();
      const response = await _restoreAdminAccount<AdminAccountsRequestData>({
        data: requestData,
        fetchFunction: restoreAdminAccount,
        customSuccessMsg:
          "adminSection.adminAccountsPage.successRestoreAdminMessage",
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const adminAccountsEditSlice = createSlice({
  name: "adminAccountsEdit",
  initialState,
  reducers: {
    resetData: (state) => {
      state.formData = null;
      state.userAccountType = null;
      state.publishingLinkedClients = [];
      state.recordingLinkedClients = [];
      state.mechanicalLinkedClients = [];
      state.status = StatusEnum.Idle;
    },
    setUserAccountType: (state, action: PayloadAction<UserAccountTypeEnum>) => {
      state.userAccountType = action.payload;
    },
    updatePublishingLinkedClients: (state, action: any) => {
      state.publishingLinkedClients = action.payload;
    },
    updateRecordingLinkedClients: (state, action: any) => {
      state.recordingLinkedClients = action.payload;
    },
    updateMechanicalLinkedClients: (state, action: any) => {
      state.mechanicalLinkedClients = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountriesListThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.countries = action.payload;
      })
      .addCase(getCountriesListThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getCountriesListThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(getAdminAccountByIdThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.formData = action.payload;
      })
      .addCase(getAdminAccountByIdThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getAdminAccountByIdThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(deleteAdminAccountByIdThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(deleteAdminAccountByIdThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(deleteAdminAccountByIdThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(createAdminAccountThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(createAdminAccountThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(createAdminAccountThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(updateAdminAccountThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(updateAdminAccountThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(updateAdminAccountThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      });
  },
});

export const addEditAdminAccountsSelector = (state: RootState) =>
  state.adminAccountsEdit;

export default adminAccountsEditSlice.reducer;
