import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PubAnalysisSongProps } from "../../../../features/publishing/analysis/song/pubAnalysisSongSlice";
import { ButtonVariantEnum } from "../../../types/enums";
import Button from "../../../atom/Button";
import AnalysisSongBreakdown from "../analysisSongBreakdown/AnalysisSongBreakdown";
import styles from "../analysis.module.scss";
import AnalysisSongInfiniteTableHeader from "./AnalysisSongInfiniteTableHeader";

interface LeftSideProps {
  bottomTargetPageCount: number;
  selectedClients: any[];
  filterAnalysisSongsWith: (columnName: string) => void;
  handleSongClick: (clickedSong: any) => void;
  selectedPeriods: number[];
  selectedSong: any;
  songs: PubAnalysisSongProps;
  windowWidth: number | undefined;
}

const AnalysisSongLeftSide = (props: LeftSideProps) => {
  const { t } = useTranslation();
  const {
    bottomTargetPageCount,
    selectedClients,
    filterAnalysisSongsWith,
    handleSongClick,
    selectedPeriods,
    selectedSong,
    songs,
    windowWidth,
  } = props;
  return (
    <div className={styles.songsTable}>
      <AnalysisSongInfiniteTableHeader
        filterAnalysisSongsWith={filterAnalysisSongsWith}
      />
      <div>
        <table className={`w-full`}>
          <tbody>
            {songs?.data?.songDescriptions?.map((song: any) => (
              <Fragment key={song.id}>
                <tr
                  onClick={() => handleSongClick(song)}
                  className={
                    selectedSong && song.id === selectedSong.id
                      ? `closeBreakdown_GTM ${styles.selected}`
                      : "viewBreakdown_GTM"
                  }
                >
                  <td className={styles.songCol}>
                    <p>{song.name}</p>
                    <p className={"font-light"}>
                      {t("analysis.songs.id")}: {song.id}
                    </p>
                  </td>
                  <td className={styles.composerCol}>{song.composers}</td>
                  <td className={styles.royaltiesCol}>
                    {song.royalties?.currencySign} {song.royalties?.moneyValue}
                  </td>
                  <td className={`${styles.unselectBtnCell} "text-right"`}>
                    <Button
                      className={`${
                        selectedSong && song.id === selectedSong.id
                          ? "block"
                          : "hidden"
                      } ${styles.unselectBtn}`}
                      variant={ButtonVariantEnum.cleanCta}
                      onClick={() => handleSongClick(song.id)}
                    >
                      +
                    </Button>
                  </td>
                </tr>
                {selectedSong && selectedSong.id === song.id && (
                  <tr className={styles.noHover}>
                    <td colSpan={4} className={`w-full ${styles.mobileCol}`}>
                      {windowWidth && windowWidth < 1200 && (
                        <div className="block w-full">
                          <AnalysisSongBreakdown
                            isSelected={selectedSong}
                            song={{
                              songId: selectedSong.id,
                              name: selectedSong.name,
                              composer: selectedSong.composers,
                            }}
                            periodIds={selectedPeriods}
                            clientIds={selectedClients?.map((c) => c.id)}
                            mobileView={true}
                            handleClose={handleSongClick}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
        {songs?.data?.songDescriptions?.length < songs?.total && (
          <h5 className="p-3">{t("app.loading")}</h5>
        )}
        {bottomTargetPageCount > 0 &&
          songs?.total !== 0 &&
          songs?.data?.songDescriptions?.length === songs?.total && (
            <p className="py-8 text-center">
              <b>{t("analysis.seenAll")}</b>
            </p>
          )}
      </div>
    </div>
  );
};

export default AnalysisSongLeftSide;
