import {
  RecSyncTabStatusEnum,
  RecSyncTabsEnum,
} from "../../../app/types/enums";
import withClientSelectionData from "../clientSelection/withClientSelectionData";
import RecSyncHOC from "./RecSyncHOC";

const RecSyncLicensing = (): JSX.Element => (
  <RecSyncHOC
    status={RecSyncTabStatusEnum.licensing}
    selectedTab={RecSyncTabsEnum.licensing}
  />
);

export default withClientSelectionData(RecSyncLicensing);
