import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export const downloadNewTemplate = (params: any) => {
  const { data, fileName } = params;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/bulk/template`, {
    method: "GET",
    body: JSON.stringify(data),
    isDownload: true,
    fileName,
  });
};

export const uploadFile = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/bulk`, {
    method: "POST",
    body: payload,
    isContentTypeMultipartFormData: true,
  });
};

export const downloadResult = (params: any) => {
  const { data, fileName } = params;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/bulk/result`, {
    method: "POST",
    body: JSON.stringify(data),
    isDownload: true,
    fileName,
  });
};
