import React from "react";

function AlertIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      name="CheckIcon"
      {...props}
    >
      <path
        fill="#32CDAB"
        fillRule="evenodd"
        stroke="#32CDAB"
        d="M17.836 5.566a.228.228 0 0 0-.322.034l-6.456 8a.29.29 0 0 0-.018.026c-.002.003-.003.007-.006.01l-1.747 2.182a.283.283 0 0 1-.197.107.283.283 0 0 1-.21-.074L3.87 11.294a.23.23 0 0 0-.307.338l5.009 4.558a.736.736 0 0 0 1.07-.084l1.77-2.21a.251.251 0 0 0 .017-.028l6.44-7.98a.23.23 0 0 0-.034-.322"
      />
    </svg>
  );
}

export default AlertIcon;
