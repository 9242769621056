import { memo, useDeferredValue, useMemo } from "react";
import { useTranslation } from "react-i18next";
import deepCompare from "../../../utils/deepCompare";
import MusicNoteIcon from "../../../atom/Icon/MusicNoteIcon";
import SyncSuccessMsg from "../syncSuccessMsg";
import styles from "./syncTableView.module.scss";
import type { PubSyncTabData } from "../../../../features/publishing/sync/pubSyncSlice";
import Tooltip from "../../../atom/Tooltip/Tooltip";
import SyncTableViewHead from "./SyncTableViewHead";

interface SyncTableViewProps {
  documentType: string;
  onColumnSorting: (event: Event) => void;
  songs: PubSyncTabData;
  status: string;
  topTargetIsOnScreen: boolean;
  bottomTargetPageCount: number;
}

const SyncTableView = (props: SyncTableViewProps) => {
  const {
    documentType,
    onColumnSorting,
    songs: songsData,
    status,
    topTargetIsOnScreen,
    bottomTargetPageCount,
  } = props;
  const { t } = useTranslation();
  const songs = useDeferredValue(songsData);

  const TableBody = useMemo(
    () => (
      <tbody>
        {!!songs?.data?.length &&
          songs.data.map((song: any, index: number) => (
            <tr
              key={`${song.uniqueLicenseEntryId}_${index}`}
              className={`border-b border-gray-100 ${
                index === 0 ? "firstRow" : ""
              }`}
            >
              <td className={`${styles.td} ${styles.songColumn}`}>
                <div className="flex">
                  <MusicNoteIcon className="h-5 w-5" />
                  <div className="pl-1">
                    <div>
                      {documentType === "PUBLISHING"
                        ? song.songTitle
                        : song.recordingTitle}
                    </div>
                    <div className="text-xs font-light text-gray-800">
                      {documentType === "PUBLISHING"
                        ? `${t("sync.table.songCode")}: ${song.songCode}`
                        : `${t("sync.table.isrc")}: ${song.recordingIsrc}`}
                    </div>
                  </div>
                </div>
              </td>
              <td className={`${styles.td} ${styles.composersColumn}`}>
                <div className={`${styles.columnName}`}>
                  {documentType === "PUBLISHING"
                    ? t("sync.table.composers")
                    : t("sync.table.artists")}
                </div>
                <div>
                  {documentType === "PUBLISHING"
                    ? song.songWriters
                    : song.recordingArtist}
                </div>
              </td>
              <td className={`${styles.td} ${styles.projectColumn}`}>
                <div className={`${styles.columnName}`}>
                  {t("sync.table.project")}
                </div>
                <div>{song.projectName}</div>
                <div className="text-xs font-light text-gray-800">
                  {t("sync.table.licenseId")}: {song.licenseGenId}
                </div>
                <div className="mt-2 md:mt-4">
                  <span className="mr-2 font-medium md:font-normal">
                    {t("sync.table.media")}:
                  </span>
                  <span className="font-light">{song.mediaType}</span>
                </div>
              </td>
              <td className={`${styles.td} ${styles.feeColumn}`}>
                <div className={`${styles.columnName}`}>
                  {t("sync.table.fee")}
                  <Tooltip
                    className="ml-1"
                    text={t("sync.table.feeInfo")}
                    id="fee"
                  />
                </div>
                <div>
                  <span className="mr-1 font-light">
                    {song.fee.currencySign}
                  </span>
                  {song.fee.moneyValue}
                </div>
              </td>
              {status === "ALL" && (
                <td className={`${styles.td} ${styles.statusColumn}`}>
                  <div className={`${styles.columnName}`}>
                    {t("sync.table.status")}
                    <Tooltip
                      className="ml-1"
                      text={t("sync.table.statusInfo")}
                      id="status"
                    />
                  </div>
                  <div>{song.status}</div>
                </td>
              )}
              {status !== "COMPLETED" && (
                <td className={`${styles.td} ${styles.dateColumn}`}>
                  <div className={`${styles.columnName}`}>
                    {t("sync.table.date")}
                  </div>
                  <div>{song.statusLastModifiedDate}</div>
                </td>
              )}
              {status === "COMPLETED" && (
                <>
                  <td className={`${styles.td} ${styles.royalityColumn}`}>
                    <div className={`${styles.columnName}`}>
                      {t("sync.table.royalities")}
                      <Tooltip
                        className="ml-1"
                        text={t("sync.table.royalitiesInfo")}
                        id="status"
                      />
                    </div>
                    <div>
                      <span className="mr-1 font-light">
                        {song.royalty.currencySign}
                      </span>
                      {song.royalty.moneyValue}
                    </div>
                  </td>
                  <td className={`${styles.td} ${styles.dateColumn}`}>
                    <div className={`${styles.columnName}`}>
                      {t("sync.table.period")}
                      <Tooltip
                        className="ml-1"
                        text={t("sync.table.periodInfo")}
                        id="status"
                      />
                    </div>
                    <div>{song.period}</div>
                  </td>
                </>
              )}
            </tr>
          ))}
      </tbody>
    ),
    [documentType, songs.data, status, t]
  );

  const songCounter = songs.data ? songs.data.length : 0;
  return (
    <>
      {songCounter === 0 && songs.noDataFlag !== undefined && (
        <SyncSuccessMsg
          message={t(`sync.${songs.noDataFlag}`) as string}
          id="syncSuccessFeedback"
        />
      )}
      {songCounter > 0 && (
        <div className="smallScrollbar">
          <table className={`${styles.table}`}>
            {!!songs?.data?.length && (
              <SyncTableViewHead
                documentType={documentType}
                onColumnSorting={onColumnSorting}
                status={status}
                topTargetIsOnScreen={topTargetIsOnScreen}
                bottomTargetPageCount={bottomTargetPageCount}
              />
            )}
            {TableBody}
          </table>
        </div>
      )}
    </>
  );
};

export default memo(
  SyncTableView,
  (prevProps: SyncTableViewProps, nextProps: SyncTableViewProps) =>
    deepCompare(prevProps, nextProps)
);
