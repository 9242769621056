import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function fetchOverview(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.recordingOverview}`, {
    method: "POST",
    body: JSON.stringify(params.data),
    signal: thunkAPI?.signal,
  });
}

export function fetchOverviewPeriodDetail(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.recordingOverviewDetails}`, {
    method: "POST",
    body: JSON.stringify(params.data),
    signal: thunkAPI?.signal,
  });
}

export function fetchOverviewCostDetail(params: any, thunkAPI?: any) {
  return request(
    `${USER_API_RESOURCE_BASE_URLS.recordingOverviewCosts}?periodId=${params.data.periodId}`,
    {
      method: "POST",
      body: JSON.stringify(params.data),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchOverviewTransactionDetail(params: any, thunkAPI?: any) {
  return request(
    `${USER_API_RESOURCE_BASE_URLS.recordingOverviewTransactions}?periodId=${params.data.periodId}`,
    {
      method: "POST",
      body: JSON.stringify(params.data),
      signal: thunkAPI?.signal,
    }
  );
}

export function downloadCostBreakdown(params: any, thunkAPI?: any) {
  const { periodId, data, fileName } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.recordingOverviewCostDownload}?periodId=${periodId}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
      isDownload: true,
      fileName,
    }
  );
}

export function downloadOverviewData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.recordingOverviewDownload}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
    isDownload: true,
    fileName,
  });
}
