import AnalysisAlbumBreakdown from "../analysisAlbumBreakdown/AnalysisAlbumBreakdown";
import styles from "../analysis.module.scss";

interface RightSideProps {
  selectedAlbum: any;
  topTargetIsOnScreen: boolean;
  selectedPeriods: number[];
  selectedClients: number[];
  handleBreakdownClose?: (clickedSong: any | undefined) => void;
}

const AnalysisAlbumRightSide = (props: RightSideProps) => {
  const {
    selectedAlbum,
    topTargetIsOnScreen,
    selectedPeriods,
    selectedClients,
    handleBreakdownClose,
  } = props;

  return (
    <div className={`${styles.rightSide} ${selectedAlbum ? " md:flex-1" : ""}`}>
      {selectedAlbum && (
        <div
          className={`${styles.rightSideSelected} ${
            selectedAlbum ? styles.isOpen : ""
          } ${topTargetIsOnScreen ? "" : `${styles.isOpenFixed}`}`}
        >
          <AnalysisAlbumBreakdown
            isSelected={selectedAlbum}
            productKey={selectedAlbum.productKey}
            periodIds={selectedPeriods}
            clientIds={selectedClients}
            mobileView={false}
            handleClose={handleBreakdownClose}
          />
        </div>
      )}
    </div>
  );
};

export default AnalysisAlbumRightSide;
