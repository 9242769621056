import React from "react";
function LogoutIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      name="LogoutIcon"
      {...props}
    >
      <path d="M5.06 7.678a.347.347 0 0 0 0 .49l4.024 4.065a.348.348 0 1 0 .495-.49L6.14 8.268h9.502a.348.348 0 1 0 0-.697H6.147l3.432-3.465a.349.349 0 1 0-.495-.49L5.06 7.678Zm2.464 7.466H.697V.704h6.827a.349.349 0 0 0 0-.697H.349A.349.349 0 0 0 0 .356v15.136c0 .193.156.35.349.35h7.175a.349.349 0 0 0 0-.698Z" />
    </svg>
  );
}

export default LogoutIcon;
