import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import PubAnalysisHeader from "../PubAnalysisHeader";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { pubClientSelectionSelector } from "../../pubroot/pubrootSlice";
import debounce from "../../../../app/utils/debounce";
import {
  fetchPubAnalysisTypeThunk,
  pubAnalysisTypeDownload,
  pubAnalysisTypeSelector,
  updateAnalysisTypePageStateAction,
} from "../pubAnalysisSlice";
import deepCompare from "../../../../app/utils/deepCompare";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  getPubIncomeTypeDetails,
  getTranslationForChart,
} from "../../../../app/utils/incomeType";
import Loader from "../../../../app/atom/Loader/Loader";
import Button from "../../../../app/atom/Button/Button";
import { ButtonVariantEnum } from "../../../../app/types/enums";
import ResetIcon from "../../../../app/atom/Icon/ResetIcon";
import Grid from "../../../../app/atom/Grid/Grid";
import { getNow, getToday } from "../../../../app/utils/dateTime";
import { useWindowSize } from "../../../../app/hooks";
import styles from "../../../../app/molecules/analysis/analysis.module.scss";
import type {
  TabTypeIncomeTypeEntry,
  TabTypeAnalyzeByMainIncomeTypesEntry,
  TabTypeAnalyzeIncomeTypes,
} from "../pubAnalysisSlice";
import { pubAnalysisHeaderSelector } from "../pubAnalysisHeaderSlice";

type TabTypeUpdatedChartData = {
  name: any;
  y: any;
  color: any;
  opacity: number;
};

const PubAnalysisType = (): JSX.Element => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();

  // selectors
  const clientSelection = useAppSelector(pubClientSelectionSelector);
  const pubAnalysisType = useAppSelector(pubAnalysisTypeSelector);
  const pubAnalysisHeaderState = useAppSelector(pubAnalysisHeaderSelector);

  // states
  const [mobileCollapsedRows, setMobileCollapsedRows] = useState<number[]>([]);
  const [selectedType, setSelectedType] = useState<any>(null);
  const [sortColumn, setSortColumn]: [
    { columnName: "name" | "category" | "raw" | undefined; isSortAsc: boolean },
    any
  ] = useState({
    columnName: undefined,
    isSortAsc: false,
  });
  const [tableDetailsData, setTableDetailsData]: [
    Array<TabTypeIncomeTypeEntry>,
    any
  ] = useState([]);

  // dispatch refs
  const dispatchedTypeFetch = useRef<any>();
  const dispatchedTypeDetailsFetch = useRef<any>();
  const dispatchedDownload = useRef<any>();

  // abort functions
  const abortDispatchedDownload = useCallback(() => {
    if (dispatchedDownload.current) dispatchedDownload.current.abort();
  }, []);

  const abortDispatchedTypeFetch = useCallback(() => {
    if (dispatchedTypeFetch.current) dispatchedTypeFetch.current.abort();
  }, []);

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const abortDispatchedTypeDetailsFetch = useCallback(() => {
    if (dispatchedTypeDetailsFetch.current)
      dispatchedTypeDetailsFetch.current.abort();
  }, []);

  const incomeTypes = useMemo(() => {
    if (pubAnalysisType.chartData && pubAnalysisType.chartData.data) {
      const incomeArray = pubAnalysisType.chartData.data?.map(
        (type: TabTypeAnalyzeByMainIncomeTypesEntry) => {
          const details = getPubIncomeTypeDetails(type.mainIncType);
          const incomeType = {
            ...type,
            color: details.color,
            order: details.order,
          };
          return incomeType;
        }
      );
      return incomeArray.sort((t1: any, t2: any) => {
        return t1.order - t2.order;
      });
    } else return [];
  }, [pubAnalysisType.chartData]);

  const handleChartClick = useCallback(
    (clickedIncomeTypeName: string) => {
      const selectedType = incomeTypes.filter((income: any) => {
        return income.title === clickedIncomeTypeName;
      });
      if (selectedType.length !== 0) setSelectedType(selectedType[0]);
    },
    [incomeTypes]
  );

  const getPieOptions = useCallback(
    (chartData: TabTypeUpdatedChartData[]) => {
      return {
        title: {
          text: "",
        },
        chart: {
          backgroundColor: "transparent",
          plotBorderWidth: 0,
          plotShadow: false,
          height: 276,
          width: 276,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            borderRadius: 0,
            dataLabels: {
              enabled: false,
            },
            center: ["50%", "50%"],
          },
          series: {
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                opacity: 1,
              },
            },
            cursor: "pointer",
            point: {
              events: {
                click: function (e: any) {
                  handleChartClick(e.point.name);
                },
              },
            },
          },
        },
        series: [
          {
            name: "Stats",
            type: "pie",
            innerSize: "79%",
            data: chartData,
          },
        ],
      };
    },
    [handleChartClick]
  );

  useEffect(() => {
    return () => {
      abortDispatchedTypeFetch();
      abortDispatchedTypeDetailsFetch();
      abortDispatchedDownload();
    };
  }, [
    abortDispatchedTypeFetch,
    abortDispatchedTypeDetailsFetch,
    abortDispatchedDownload,
  ]);

  const handleAnalysisTypeFetch = useMemo(
    () =>
      debounce((pageState: any) => {
        abortDispatchedTypeFetch();
        dispatchedTypeFetch.current = dispatch(
          fetchPubAnalysisTypeThunk(pageState)
        );
      }, 500),
    [abortDispatchedTypeFetch, dispatch]
  );

  useEffect(() => {
    if (
      !authState?.isAuthenticated ||
      clientSelection.selectedClients.length === 0
    ) {
      return;
    }
    const pubAnalysisTypeState = {
      ...pubAnalysisType.pageState,
      data: {
        periodIds: pubAnalysisHeaderState.selectedPeriods,
        clientIds: clientSelection?.selectedClients?.map((c) => c.id) || [],
        clientLists: [],
      },
    };

    const deep = deepCompare(pubAnalysisTypeState, pubAnalysisType.pageState);
    if (!deep) {
      dispatch(updateAnalysisTypePageStateAction(pubAnalysisTypeState));
      handleAnalysisTypeFetch(pubAnalysisTypeState);
    }
  }, [
    authState?.isAuthenticated,
    clientSelection.selectedClients,
    handleAnalysisTypeFetch,
    pubAnalysisType.pageState,
    dispatch,
    pubAnalysisHeaderState.selectedPeriods,
  ]);

  useEffect(() => {
    const { columnName, isSortAsc } = sortColumn;
    if (!columnName) {
      setTableDetailsData(pubAnalysisType.detailsData);
    } else {
      const compareFunction = (
        a: TabTypeIncomeTypeEntry,
        b: TabTypeIncomeTypeEntry
      ) => {
        const sortOrder = isSortAsc ? 1 : -1;
        const result =
          a[columnName] < b[columnName]
            ? -1
            : a[columnName] > b[columnName]
            ? 1
            : 0;
        return result * sortOrder;
      };
      const sortedTableDetailsData = pubAnalysisType.detailsData
        .slice()
        .sort(compareFunction);
      setTableDetailsData(sortedTableDetailsData);
    }
  }, [sortColumn, pubAnalysisType.detailsData]);

  const handleTypeSelection = (rowData: any) => () => {
    setSelectedType(rowData);
  };

  const handleReset = () => {
    setSelectedType(null);
  };

  const renderChartStatRow = useCallback(
    (i: number, rowData: TabTypeAnalyzeIncomeTypes) => {
      const selectionClass = selectedType
        ? selectedType.title === rowData.categoryName
          ? styles.selected
          : styles.unselected
        : null;
      return (
        <div
          className={`${styles.statRow} ${selectionClass}`}
          key={`row_${i}`}
          onClick={handleTypeSelection(rowData)}
        >
          <div className={styles.info}>
            <i
              style={{
                backgroundColor: rowData.color,
              }}
            ></i>
            <span>
              {t(`analysis.territories.mainIncType.${rowData.mainIncType}`)}
            </span>
          </div>
          <div className={styles.value}>
            <span className={styles.royalty}>
              <span>{rowData.revenue?.currency} </span>
              <span>{rowData.revenue?.formattedShort}</span>
            </span>
            <span className={styles.percent}>{rowData.revenue?.percent}%</span>
          </div>
        </div>
      );
    },
    [selectedType, t]
  );

  useEffect(() => {
    const updatedChartData = incomeTypes.map((d: TabTypeAnalyzeIncomeTypes) => {
      return {
        name: d.categoryName,
        y: d.revenue.percent,
        color: d.color,
        opacity: selectedType
          ? selectedType.title === d.categoryName
            ? 1
            : 0.4
          : 1,
      };
    });

    const newPieOptions: any = getPieOptions(updatedChartData);
    chartComponentRef.current?.chart.update(newPieOptions);
  }, [getPieOptions, incomeTypes, selectedType]);

  const renderTypeChart = () => {
    const chartData = incomeTypes.map((d: TabTypeAnalyzeIncomeTypes) => {
      return {
        name: d.categoryName,
        y: d.revenue.percent,
        color: d.color,
        opacity: 1,
      };
    });

    const pie_options = getPieOptions(chartData);

    const column_options = {
      chart: {
        type: "column",
        backgroundColor: "transparent",
        plotBorderWidth: 0,
        plotShadow: false,
        height: 130,
        width: width ? width - 40 : 300,
      },
      tooltip: { enabled: false },
      title: {
        style: { display: "none" },
      },
      subtitle: {
        style: { display: "none" },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false,
            },
          },
          minPointLength: 1,
        },
      },
      series: [
        {
          name: "Stats",
          type: "column",
          data: chartData,
        },
      ],
    };

    return (
      !!pubAnalysisType.chartData?.data?.length && (
        <div className={`${styles.typeStatsContainer}`}>
          <div className="sm:hidden">
            <HighchartsReact
              highcharts={Highcharts}
              options={column_options}
              ref={chartComponentRef}
            />
            <hr className="-mt-4 border-t border-gray-100" />
          </div>
          <div className="sm:max-w-1/2 relative hidden w-full sm:block sm:w-1/2">
            <HighchartsReact
              highcharts={Highcharts}
              options={pie_options}
              ref={chartComponentRef}
            />
            <div className={styles.chartLabel}>
              {!selectedType && (
                <div>
                  {t("pipelineBreakdownModal.type.total")}
                  <br />
                  {pubAnalysisType.chartData?.total.currency}{" "}
                  {pubAnalysisType.chartData?.total.formattedShort}
                </div>
              )}
              {selectedType && (
                <div>
                  {t(
                    `analysis.territories.mainIncType.${selectedType.mainIncType}`
                  )}
                  <br />
                  {selectedType.revenue?.currency}{" "}
                  {selectedType.revenue?.formattedLong}
                  <ResetBtn />
                </div>
              )}
            </div>
          </div>
          <div className="w-full pt-4 sm:w-1/2 sm:pt-0 base:pl-3">
            {incomeTypes.map((d: TabTypeAnalyzeIncomeTypes, i: number) => {
              return renderChartStatRow(i, d);
            })}
            {selectedType && <ResetBtn />}
          </div>
        </div>
      )
    );
  };

  const handleColumnHeaderClick = useCallback(
    (columnName: "name" | "category" | "raw") => () => {
      setSortColumn({
        columnName: columnName,
        isSortAsc:
          columnName === sortColumn.columnName ? !sortColumn.isSortAsc : true,
      });
    },
    [sortColumn.columnName, sortColumn.isSortAsc]
  );

  const handleDownloadBtn = useCallback(() => {
    const params = {
      data: {
        clientIds: clientSelection?.selectedClients?.map((c) => c.id) || [],
        periodIds: pubAnalysisHeaderState.selectedPeriods,
        analyzeBy: "BY_TYPE",
      },
      fileName:
        "Royalties_" +
        t("analysis.type.byType") +
        "_" +
        getToday() +
        "-" +
        getNow() +
        ".xlsx",
    };
    abortDispatchedDownload();
    dispatchedDownload.current = dispatch(pubAnalysisTypeDownload(params));
  }, [
    abortDispatchedDownload,
    clientSelection.selectedClients,
    dispatch,
    pubAnalysisHeaderState.selectedPeriods,
    t,
  ]);

  const handleMobileTableRowClick = useCallback(
    (i: number) => () => {
      if (width && width > 767) return;
      if (mobileCollapsedRows.includes(i)) {
        setMobileCollapsedRows(
          mobileCollapsedRows.filter((item) => item !== i)
        );
      } else {
        setMobileCollapsedRows([...mobileCollapsedRows, i]);
      }
    },
    [mobileCollapsedRows, width]
  );

  const ResetBtn = () => (
    <Button
      variant={ButtonVariantEnum.cleanCta}
      icon={<ResetIcon />}
      className={`resetToAll_GTM ${styles.resetBtn}`}
      onClick={handleReset}
    >
      {t("analysis.type.resetToAll")}
    </Button>
  );

  return (
    <Grid>
      <div className={styles.analysis}>
        {(pubAnalysisType.chartStatus === "loading" ||
          pubAnalysisType.detailsStatus === "loading" ||
          pubAnalysisType.downloadStatus === "loading") && <Loader />}
        <div className={styles.analysisHeader}>
          <PubAnalysisHeader
            tab="type"
            documentType="PUBLISHING"
            onDownloadBtnClick={handleDownloadBtn}
          />
        </div>
        {pubAnalysisType.chartStatus === "idle" &&
          pubAnalysisType.detailsStatus === "idle" &&
          pubAnalysisType.chartData === null &&
          pubAnalysisType.detailsData.length === 0 && (
            <p className="p-5 text-xs sm:text-sm">{t("analysis.noData")}</p>
          )}
        <div className={styles.analysisType}>
          <div className={styles.analysisTypeHeader}>
            <p className={styles.period}>
              {
                pubAnalysisHeaderState.tabSelectedPeriodTitle[
                  pubAnalysisHeaderState.selectedPeriodText?.titleVariant
                ]
              }{" "}
              <span>{pubAnalysisHeaderState.selectedPeriodText.details}</span>
            </p>
            {!selectedType && pubAnalysisType.chartData && (
              <p className={styles.royalty}>
                {pubAnalysisType.chartData.total.currency}{" "}
                <span className={styles.moneyValue}>
                  {pubAnalysisType.chartData.total.formattedLong}
                </span>
              </p>
            )}
            {selectedType && (
              <p className={styles.royalty}>
                {selectedType.revenue?.currency}{" "}
                <span className={styles.moneyValue}>
                  {selectedType.revenue?.formattedLong}
                </span>
              </p>
            )}
          </div>
          {pubAnalysisType.chartData && renderTypeChart()}
          <div className={styles.typeDetailsTable}>
            {!!tableDetailsData?.length && (
              <div className={styles.row}>
                <div
                  className={`sortByTypeName_GTM ${styles.colHeader}`}
                  onClick={handleColumnHeaderClick("name")}
                >
                  {t("analysis.type.type")}
                </div>
                <div
                  className={`sortByCategory_GTM ${styles.colHeader}`}
                  onClick={handleColumnHeaderClick("category")}
                >
                  {t("analysis.type.category")}
                </div>
                <div
                  className={`sortByRoyalties_GTM ${styles.colHeader}`}
                  onClick={handleColumnHeaderClick("raw")}
                >
                  {t("analysis.type.royalties")}
                </div>
              </div>
            )}

            {tableDetailsData.map(
              (detail: TabTypeIncomeTypeEntry, i: number) => {
                if (
                  selectedType &&
                  detail.mainIncType !== selectedType.mainIncType
                )
                  return <Fragment key={`detail_${i}`}></Fragment>;

                return (
                  <div
                    key={`detail_${i}`}
                    className={`${styles.row} ${
                      mobileCollapsedRows.includes(i) ? styles.collapsed : ""
                    }`}
                    onClick={handleMobileTableRowClick(i)}
                  >
                    <div>{detail.name}</div>
                    <div>{getTranslationForChart(detail.mainIncType, t)}</div>
                    <div className={styles.revenue}>
                      <span>{detail.currency}</span>{" "}
                      <span>{detail.royalties}</span>
                    </div>
                  </div>
                );
              }
            )}
            {selectedType && (
              <div className={`${styles.row} ${styles.resetRow}`}>
                <div className="col-span-3">
                  <ResetBtn />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default PubAnalysisType;
