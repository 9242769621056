import { useEffect, useCallback, RefObject, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DownloadIcon } from "../../../atom/Icon";
import { getNow, getToday } from "../../../utils/dateTime";
import { ButtonVariantEnum } from "../../../types/enums";
import {
  FetchRecAnalysisAlbumBreakdown_Territory_Thunk,
  FetchRecAnalysisAlbumBreakdown_Trend_Thunk,
  FetchRecAnalysisAlbumBreakdown_Track_Thunk,
  recAnalysisAlbumBreakdown_Download_Thunk,
  recAnalysisAlbumBreakdownTerritorySelector,
  recAnalysisAlbumBreakdownTrendSelector,
  recAnalysisAlbumBreakdownTracksSelector,
  recAnalysisAlbumBreakdownDownloadStatusSelector,
} from "../../../../features/recording/analysis/album/recAnalysisAlbumBreakdownSlice";
import Button from "../../../atom/Button/Button";
import ChevronIcon from "../../../atom/Icon/ChevronIcon";
import Loader from "../../../atom/Loader";
import AnalysisSourceBreakdownTerritory from "./AnalysisAlbumBreakdown_Territory";
import AnalysisSourceBreakdownTrend from "./AnalysisAlbumBreakdown_Trend";
import AnalysisAlbumBreakdownTracks from "./AnalysisAlbumBreakdown_Tracks";
import { RecAnalysisAlbumBreakdownTypeTrendTerritoryParams } from "../../../../features/recording/analysis/album/recAnalysisAlbumBreakdownAPI";
import styles from "../analysis.module.scss";
import { PeriodSelector } from "../../periodSelector/periodSelectorSlice";
import debounce from "../../../utils/debounce";

export declare type RecAnalysisAlbumBreakdownViewProps =
  RecAnalysisAlbumBreakdownTypeTrendTerritoryParams & {
    mobileView: boolean;
    isSelected: any;
    handleClose?: (clickedSong: any) => void;
  };

const AnalysisAlbumBreakdown = (props: RecAnalysisAlbumBreakdownViewProps) => {
  const { productKey, clientIds, isSelected, periodIds, mobileView } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();

  const trackBreakdownPosRef = useRef<HTMLParagraphElement>(null);
  const trendBreakdownPosRef = useRef<HTMLParagraphElement>(null);
  const territoryBreakdownPosRef = useRef<HTMLParagraphElement>(null);

  const fetchedProductKey = useRef<number>(-1);

  // dispatch refs
  const dispatchedTrack = useRef<any>();
  const dispatchedTrend = useRef<any>();
  const dispatchedTerritory = useRef<any>();
  const dispatchedDownload = useRef<any>();

  // abort functions
  const abortDispatchedDownload = useCallback(() => {
    if (dispatchedDownload.current) dispatchedDownload.current.abort();
  }, []);
  const abortDispatchedTrend = useCallback(() => {
    if (dispatchedTrend.current) dispatchedTrend.current.abort();
  }, []);
  const abortDispatchedTrack = useCallback(() => {
    if (dispatchedTrack.current) dispatchedTrack.current.abort();
  }, []);
  const abortDispatchedTerritory = useCallback(() => {
    if (dispatchedTerritory.current) dispatchedTerritory.current.abort();
  }, []);

  const allPeriods = useSelector(PeriodSelector);

  const albumBreakdown_Track = useSelector(
    recAnalysisAlbumBreakdownTracksSelector
  );

  const albumBreakdown_Trend = useSelector(
    recAnalysisAlbumBreakdownTrendSelector
  );

  const albumBreakdown_Territories = useSelector(
    recAnalysisAlbumBreakdownTerritorySelector
  );

  const albumBreakdown_DownloadsStatus = useSelector(
    recAnalysisAlbumBreakdownDownloadStatusSelector
  );

  const handleTrackFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedTrack();
        dispatchedTrack.current = dispatch(
          FetchRecAnalysisAlbumBreakdown_Track_Thunk(currentParams)
        );
      }, 500),
    [abortDispatchedTrack, dispatch]
  );

  const handleTrendFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedTrend();
        dispatchedTrend.current = dispatch(
          FetchRecAnalysisAlbumBreakdown_Trend_Thunk(currentParams)
        );
      }, 500),
    [abortDispatchedTrend, dispatch]
  );

  const handleTerritoryFunctionFetch = useMemo(
    () =>
      debounce((currentParams: any) => {
        abortDispatchedTerritory();
        dispatchedTerritory.current = dispatch(
          FetchRecAnalysisAlbumBreakdown_Territory_Thunk(currentParams)
        );
      }, 500),
    [abortDispatchedTerritory, dispatch]
  );

  const assignTrackData = useCallback(() => {
    handleTrackFunctionFetch({
      clientIds: clientIds,
      productKey: productKey,
      periodIds: periodIds,
    });
  }, [handleTrackFunctionFetch, clientIds, productKey, periodIds]);

  const assignTrendData = useCallback(() => {
    handleTrendFunctionFetch({
      clientIds: clientIds,
      productKey: productKey,
      periodIds: allPeriods?.periods?.map((p) => p.periodNum) || [],
    });
  }, [handleTrendFunctionFetch, clientIds, productKey, allPeriods?.periods]);

  const assignTerritoryData = useCallback(() => {
    handleTerritoryFunctionFetch({
      clientIds: clientIds,
      productKey: productKey,
      periodIds: periodIds,
    });
  }, [handleTerritoryFunctionFetch, clientIds, productKey, periodIds]);

  useEffect(() => {
    return () => {
      abortDispatchedTrend();
      abortDispatchedTrack();
      abortDispatchedTerritory();
      abortDispatchedDownload();

      fetchedProductKey.current = -1;
    };
  }, [
    abortDispatchedDownload,
    abortDispatchedTerritory,
    abortDispatchedTrack,
    abortDispatchedTrend,
  ]);

  useEffect(() => {
    if (fetchedProductKey.current !== productKey) {
      assignTrackData();
      assignTrendData();
      assignTerritoryData();

      fetchedProductKey.current = productKey;
    }
  }, [assignTerritoryData, assignTrendData, assignTrackData, productKey]);

  const scrollToBreakdown =
    (refObject: RefObject<HTMLParagraphElement>) => () => {
      refObject.current?.scrollIntoView({ behavior: "smooth" });
    };

  const handleDownloadBtn = useCallback(() => {
    const analyzeBy = "BY_ALBUM_TERRITORY_DETAILS";
    const params = {
      data: {
        periodIds: allPeriods?.periods?.map((p) => p.periodNum) || [],
        clientIds: clientIds,
        analyzeBy,
        albumId: productKey,
      },
      fileName:
        "Royalties_" + analyzeBy + "_" + getToday() + "-" + getNow() + ".xlsx",
    };
    dispatch(recAnalysisAlbumBreakdown_Download_Thunk(params));
  }, [allPeriods.periods, clientIds, dispatch, productKey]);

  const handleCloseBreakdown = useCallback(() => {
    props.handleClose && props.handleClose("");
  }, [props]);

  return (
    <div className={styles.breakDown}>
      {(albumBreakdown_Trend.status === "loading" ||
        albumBreakdown_Track.status === "loading" ||
        albumBreakdown_Territories.status === "loading" ||
        albumBreakdown_DownloadsStatus === "loading") && <Loader />}
      <header
        id="rightScrollableHeader"
        className="sticky top-0 z-[4] bg-white pt-5"
      >
        <div className="flex justify-between">
          {!mobileView && <h3 className={styles.title}>{isSelected?.title}</h3>}
          {props.handleClose && (
            <Button
              className={`${styles.unselectBtn} ${styles.breakdownUnselectBtn} self-start md:mr-2`}
              variant={ButtonVariantEnum.cleanCta}
              onClick={handleCloseBreakdown}
            >
              +
            </Button>
          )}
        </div>
        {!props.mobileView && (
          <div className={styles.description}>
            <p>Configuration: {isSelected?.configDesc}</p>
            <p>Artist: {isSelected?.artist}</p>
            <p>Barcode: {isSelected?.barCode}</p>
            <p>
              {t("analysis.albums.productCode")}: {isSelected?.productCode}
            </p>
          </div>
        )}
        <div className={styles.breakdownButtons}>
          <Button
            icon={<DownloadIcon />}
            className={`downloadData_GTM ${styles.downloadBtn}`}
            disabled={albumBreakdown_DownloadsStatus === "loading"}
            onClick={handleDownloadBtn}
            variant={ButtonVariantEnum.cleanCta}
          >
            {t("analysis.songs.breakdown.downloadData")}
          </Button>
          <Button
            onClick={scrollToBreakdown(territoryBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={`${styles.scrollNavBtn}`}
            icon={<ChevronIcon />}
          >
            {t("analysis.songs.breakdown.territory")}
          </Button>
          <Button
            onClick={scrollToBreakdown(trendBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={`${styles.scrollNavBtn}`}
            icon={<ChevronIcon />}
          >
            {t("analysis.songs.breakdown.trend")}
          </Button>
          <Button
            onClick={scrollToBreakdown(trackBreakdownPosRef)}
            variant={ButtonVariantEnum.textLink}
            className={`${styles.scrollNavBtn}`}
            icon={<ChevronIcon />}
          >
            {t("analysis.tracks.tracks")}
          </Button>
        </div>
      </header>
      <div
        className={`${styles.scrollable} ${styles.breakDownContent} ${
          isSelected ? styles.isOpen : ""
        }`}
      >
        <section>
          <div className={styles.percentageList}>
            <p ref={territoryBreakdownPosRef} className={styles.breakdownTitle}>
              {t("analysis.songs.breakdown.breakdownByTerritory")}
            </p>
            <AnalysisSourceBreakdownTerritory
              data={albumBreakdown_Territories.data}
              status={albumBreakdown_Territories.status}
            />
            <p ref={trendBreakdownPosRef} className={styles.breakdownTitle}>
              {t("analysis.songs.breakdown.breakdownByTrend")}
            </p>
            <AnalysisSourceBreakdownTrend
              data={albumBreakdown_Trend.data}
              status={albumBreakdown_Trend.status}
            />
            <p ref={trackBreakdownPosRef} className={styles.breakdownTitle}>
              {t("analysis.tracks.breakdownByType")}
            </p>
            <AnalysisAlbumBreakdownTracks
              data={albumBreakdown_Track.data}
              status={albumBreakdown_Track.status}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default AnalysisAlbumBreakdown;
