const TermsDE = (): JSX.Element => {
  return (
    <div>
      <h1>Allgemeine Nutzungsbedingungen</h1>
      <h2>für das BMG Online-Kundenportal (my.bmg.com)</h2>
      <p>
        Willkommen beim BMG Online-Kundenportal (nachfolgend das „Portal“) auf
        my.bmg.com (nachfolgend die „Website“), das von der BMG Rights
        Management GmbH (nachfolgend „BMG“, „wir“, „uns“, „unser“ etc.) für BMG
        und die anderen Unternehmen der BMG-Gruppe zur Nutzung durch Autoren,
        Verlage und andere BMG-Kunden (nachfolgend insgesamt die „BMG-Kunden“)
        betrieben wird.
      </p>
      <h3>1. Geltungsbereich und Vertragsverhältnis</h3>
      <p>
        <span className="text-red-800">1.1</span> Diese Allgemeinen
        Nutzungsbedingungen gelten für die gesamte Nutzung des Portals und der
        Website.
      </p>
      <p>
        <span className="text-red-800">1.2</span> Der Vertrag zur Nutzung des
        Portals kommt zwischen BMG und den BMG-Kunden zu Stande, auch wenn der
        einzelne Nutzer gegebenenfalls nicht der Kunde bzw. ein Angestellter des
        Kunden, sondern ein vom Kunden beauftragter Dritter ist. Verfügt der
        Drittnutzer nicht über die Rechtsmacht, den BMG-Kunden vertraglich zu
        binden, gilt § 179 BGB.
      </p>
      <h3>2. Login-Daten</h3>
      <p>
        <span className="text-red-800">2.1</span> Das Portal zu nutzen, ist eine
        Option für die BMG-Kunden. Aus diesem Grund senden wir nicht einfach
        allen BMG-Kunden Benutzernamen und Passwörter (nachfolgend
        „Zugangsdaten“) zu, sondern fragen die BMG-Nutzer, ob sie Zugangsdaten
        erstellt haben möchten, weil sie sich für eine Nutzung des Portals
        interessieren. Wenn Sie uns gebeten haben, Zugangsdaten für Sie zu
        erstellen, werden wir Ihnen diese zusenden.
      </p>
      <p>
        <span className="text-red-800">2.2</span> Sie sind verpflichtet, die
        Zugangsdaten, insbesondere Ihr Passwort, sorgsam aufzubewahren und stets
        geheim zu halten. Wenn Sie Ihre Zugangsdaten verlieren oder wenn Sie
        feststellen oder vermuten, dass Ihre Zugangsdaten von einem Dritten
        verwendet werden, sind Sie verpflichtet, uns sofort entsprechend zu
        informieren. Wir werden die Zugangsdaten dann gegen eine weitere
        Verwendung sperren. Sie sind grundsätzlich für alle Aktivitäten, die wir
        im Portal und auf der Website nach Nutzung Ihrer Zugangsdaten über bzw.
        auf Ihrem Benutzerkonto verzeichnen, allein und vollumfänglich
        verantwortlich und bleiben dies, bis die Nutzung Ihrer Zugangsdaten auf
        Ihre oder unsere Veranlassung gesperrt wurde. Anders ist dies nur dann,
        wenn die Nutzung Ihrer Zugangsdaten durch einen Dritten nicht auf einer
        Verletzung Ihrer Verpflichtungen gründet.
      </p>
      <p>
        <span className="text-red-800">2.3</span> BMG kann Sie jederzeit dazu
        auffordern, Ihre Zugangsdaten oder bestimmte Teile Ihrer
        Benutzerkontoinformationen zu ändern.
      </p>
      <p>
        <span className="text-red-800">2.4</span> Sollten Sie, z.B. für
        bestimmte Ihrer Mitarbeiter, zusätzliche Zugangsdaten benötigen,
        kontaktieren Sie uns bitte per E-Mail an{" "}
        <a href="mailto:my.bmg@bmg.com">my.bmg@bmg.com</a>.
      </p>
      <h3>3. Ihre Einstellungen</h3>
      <p>
        Auf der Website können Sie gegebenenfalls bestimmte Angaben und Daten
        über sich selbst bearbeiten (z.B. Ihre Anschrift). Wenn Sie das tun,
        werden wir danach die geänderten Angaben bzw. Daten zur Kommunikation
        mit Ihnen nutzen, auch für alle Kommunikation die auf Ihren sonstigen
        Verträgen mit BMG und anderen Unternehmen der BMG-Gruppe gründen. Die
        Abrechnungen zum Beispiel, die wir Ihnen per Post zusenden, werden dann
        an die neue Anschrift geschickt. Wir übernehmen keine Verantwortung für
        fehlgeleitete Kommunikation, wenn die Fehlleitung aus den von Ihnen
        vorgenommenen Änderungen im Portal resultiert.
      </p>
      <h3>4. Lizenz- und Abrechnungsdaten im Portal</h3>
      <p>
        <span className="text-red-800">4.1</span> Wir betreiben das Portal
        ausschließlich für Informationszwecke. Die Daten und anderen
        Informationen im Portal können und sollen daher kein Ersatz für die
        Ihnen aufgrund der mit Ihnen geschlossenen Verträge zugesandten
        Abrechnungen und Informationen sein. BMG wird Ihnen diese im Einklang
        mit dem jeweiligen Vertrag weiterhin außerhalb des Portals zusenden,
      </p>
      <p>
        <span className="text-red-800">4.2</span> BMG verspricht daher keine
        Garantie und übernimmt keine Gewähr oder Haftung für die Richtigkeit,
        Genauigkeit und Vollständigkeit von Werk-, Lizenz- oder
        Abrechnungsdaten, Abrechnungen oder anderen Daten, Informationen,
        Materialien und Dokumenten, die Sie in dem Portal ansehen, abrufen oder
        herunterladen können.
      </p>
      <h3>5. Schutzrechte; Nutzung des Portals</h3>
      <p>
        <span className="text-red-800">5.1</span> Die Leistungen des Portals
        basieren unter anderem auf einer Datenbank. Diese Datenbank ist unter
        anderem durch das Datenbankherstellerrecht gemäß §§ 87a ff. UrhG
        geschützt. Alle Schutzrechte hieran sowie alle Schutzrechte an der
        Website, dem Portal, der diesem zugrundeliegenden Software, Datenbank
        und anderen Bestandteilen von Portal und Website, Texten, Fotos,
        Illustrationen, Schaubildern, Logos, Marken und anderen Kennzeichen
        (nachfolgend insgesamt „Intellectual Property“) stehen ausschließlich
        BMG zu oder sind von BMG lizenziert und durch Urheberrecht,
        Kennzeichenrechte und andere Schutzrechte geschützt. Sie sind daher
        insbesondere nicht berechtigt, ohne vorherige schriftliche Zustimmung
        von BMG Intellectual Property zu vervielfältigen, zu verbreiten oder
        öffentlich zugänglich zu machen.
      </p>
      <p>
        <span className="text-red-800">5.2</span> Sie dürfen die Inhalte von
        Portal und Website nur für Ihre eigenen Informationszwecke nutzen.
        Soweit in diesen Allgemeinen Nutzungsbedingungen nicht ausdrücklich
        anders geregelt, dürfen Inhalte oder andere Bestandteile von Portal und
        Website ohne vorherige schriftliche Zustimmung von BMG weder
        vervielfältigt, zweitveröffentlicht, anderweitig auf System hochgeladen,
        öffentlich wiedergegeben oder sonst zugänglich gemacht noch encodiert,
        gesendet oder irgendwie an andere Computer, Server (auch kein
        „Mirroring“) oder sonstige Veröffentlichungsmedien verteilt werden.
      </p>
      <p>
        <span className="text-red-800">5.3</span> Es ist Ihnen untersagt zu
        versuchen, sich unautorisierten Zugriff auf bzw. Zugang zum Portal,
        Teilen des Portals, anderen Systemen und Netzwerken, die mit Portal,
        Website oder anderen BMG-Servern in Verbindung stehen, oder den
        Leistungen, die wir auf Portal oder Website anbieten, zu verschaffen,
        sei es durch Computerhacking, „Passwort Mining“ oder auf andere Weise.
        Insbesondere dürfen Sie Portal und Website nicht für Versuche nutzen, zu
        Daten anderer BMG-Kunden Zugang zu erlangen oder die Rechte anderer
        BMG-Kunden oder Dritter zu verletzen.
      </p>
      <p>
        <span className="text-red-800">5.4</span> Sie dürfen keine Header
        fälschen oder anderweitig identifizierende Angaben oder Daten
        manipulieren, um die Herkunft von Nachrichten oder anderen Angaben bzw.
        Daten zu verschleiern, die Sie auf oder durch Portal oder Website oder
        einen dort vermittelten Dienst an BMG senden. Es ist Ihnen untersagt,
        sich als einen anderen auszugeben oder vorzuspiegeln, einen anderen zu
        vertreten.
      </p>
      <h3>6. Wartung des Portals</h3>
      <p>
        Wir werden von Zeit zu Zeit Wartungsarbeiten an Portal und Website
        durchführen. Es kann daher Zeiten geben, in denen ein Zugang zu Portal
        und/oder Website nicht möglich ist. Solche Wartungsarbeiten sind üblich
        und nicht zu vermeiden. Die vorübergehende Nichtverfügbarkeit von Portal
        und Website ist keine Vertragsverletzung.
      </p>
      <h3>7. Freistellung</h3>
      <p>
        Sie sind verpflichtet, uns und alle Unternehmen der BMG-Gruppe
        einschließlich unserer und deren Geschäftsführung und Mitarbeiter von
        allen Ansprüchen, Kosten und Schäden (einschließlich angemessener
        Rechtsverteidigungskosten) freizustellen, die aus von Dritten gegen BMG
        und/oder andere Unternehmen der BMG-Gruppe geltend gemachten Ansprüchen
        basieren, welche auf Behauptungen beruhen, die, das Zutreffen der
        Behauptungen vorausgesetzt, eine Verletzung von in diesem Allgemeinen
        Nutzungsbedingungen genannten Verpflichtungen bedeuten würden oder aus
        Aktivitäten in Ihrem Benutzerkontos resultieren.
      </p>
      <h3>8. Datenschutz</h3>
      <p>
        Alle Informationen zu Datenerhebung, -verarbeitung und -nutzung finden
        sich in der Datenschutzerklärung (siehe „Privacy Policy“).
      </p>
      <h3>9. Schlussbestimmungen</h3>
      <p>
        <span className="text-red-800">9.1</span> BMG ist berechtigt, das
        Vertragsverhältnis über die Nutzung des Portals mit Ihnen insgesamt oder
        teilweise an mit BMG verbundene Unternehmen zu übertragen.
      </p>
      <p>
        <span className="text-red-800">9.2</span> Für das Vertragsverhältnis
        über die Nutzung des Portals einschließlich dieser Allgemeinen
        Nutzungsbedingungen gilt ausschließlich das Recht der Bundesrepublik
        Deutschland unter Ausschluss des UN-Kaufrechtsübereinkommens und des
        deutschen und europäischen Internationalen Privatrechts.
      </p>
      <p>
        <span className="text-red-800">9.3</span> Sind Sie Kaufmann im Sinne des
        HGB, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder
        im Zusammenhang mit dem Vertragsverhältnis, für das diese Allgemeinen
        Nutzungsbedingungen Anwendung finden, Berlin.
      </p>
      <p>
        <span className="text-red-800">9.4</span> Sollten einzelne Bestimmungen
        dieser Allgemeinen Nutzungsbedingungen einschließlich dieser Regelung
        ganz oder teilweise unwirksam sein, bleibt die Wirksamkeit der übrigen
        Bestimmungen oder Teile solcher Bestimmungen unberührt. Anstelle der
        unwirksamen oder fehlenden Bestimmungen treten die jeweiligen
        gesetzlichen Regelungen.
      </p>
      <p>
        <span className="text-red-800">9.5</span> Durch andere Verträge zwischen
        Ihnen und BMG bzw. Unternehmen der BMG-Gruppe begründete
        Vertragsverhältnisse werden durch diese Allgemeinen Nutzungsbedingungen
        nicht berührt.
      </p>
      <h3>10. Kontakt</h3>
      <p>
        Für alle Fragen zu diesen Allgemeinen Nutzungsbedingungen schreiben Sie
        uns einfach an: <a href="mailto:my.bmg@bmg.com">my.bmg@bmg.com</a>
      </p>
      <p>
        <small>Stand: Oktober 2013</small>
      </p>
    </div>
  );
};
export default TermsDE;
