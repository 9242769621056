import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export const getClientLists = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.clients}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const getCountriesList = () => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.countries}`, {
    method: "GET",
  });
};

export const createNewUser = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};

export const getUserById = (payload: { id: number }) => {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/${id}`, {
    method: "GET",
  });
};

export const deleteUserById = (payload: { id: number }) => {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/${id}`, {
    method: "DELETE",
  });
};

export const resendWelcomeEmail = (payload: { id: number }) => {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/${id}/welcome-email`, {
    method: "PUT",
  });
};

export const updateUser = (payload: { id: number; data: any }) => {
  const { id, data } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

export const restoreUserAccount = (payload: { id: number }) => {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}/${id}/deleted`, {
    method: "PUT",
  });
};

export const impersonateUserAccount = (payload: { id: number }) => {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.impersonation}`, {
    method: "PUT",
    body: JSON.stringify({ user: id }),
  });
};

export const unImpersonateUserAccount = () => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.impersonation}`, {
    method: "DELETE",
  });
};

export const getUsersGroups = (groupType: string) => {
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.groups}?groupType=${groupType}`,
    {
      method: "GET",
    }
  );
};

export const getUsersGroupPermissions = (payload: { groupId: number }) => {
  const { groupId } = payload;
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.groups}/${groupId}/permissions`,
    {
      method: "GET",
    }
  );
};

export const getUserLogs = (payload: { userId: string }) => {
  const { userId } = payload;
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.activityLog}/latest?userId=${userId}&events=LOGIN,WELCOME_EMAIL_SENT,PASSWORD_RESET_EMAIL_SENT`,
    {
      method: "GET",
    }
  );
};
