import { TableHeadProps } from "../../../../../../app/types/props";

export const MAX_CHARACTERS = 200;

export const tableHeaderData: TableHeadProps = [
  {
    id: 1,
    title: "adminSection.news.tableHeader.publicationDate",
    filteringInfo: "",
  },
  {
    id: 2,
    title: "adminSection.news.tableHeader.title",
    filteringInfo: "title",
  },
  {
    id: 3,
    title: "adminSection.news.tableHeader.text",
    filteringInfo: "",
  },
  {
    id: 4,
    title: "adminSection.news.tableHeader.shownTo",
    filteringInfo: "",
  },
  {
    id: 5,
    title: "adminSection.news.tableHeader.priority",
    filteringInfo: "",
  },
  {
    id: 6,
    title: "adminSection.news.tableHeader.showIn",
    filteringInfo: "",
  },
  {
    id: 7,
    title: "adminSection.news.tableHeader.expiryDate",
    filteringInfo: "",
  },
];
