import { useTranslation } from "react-i18next";
import styles from "./../analysis.module.scss";

const AnalysisSongBreakdownSource = ({
  data,
  status,
}: {
  data: any;
  status: "noData" | "loading" | "idle" | "failed";
}): JSX.Element => {
  const { t } = useTranslation();

  if (status === "noData" || status === "loading")
    <p className="pl-2 pt-4">{t("app.loading")}</p>;

  if (!data || !data.sources) {
    if (status === "idle")
      return (
        <p className="pl-2 pt-4">{t("analysis.songs.breakdown.noData")}</p>
      );
    else return <p className="pl-2 pt-4">{t("app.loading")}</p>;
  }

  const renderStatRow = (i: number, name: string, revenue: any) => {
    return (
      <div className={styles.statRow} key={i}>
        <span>{name}</span>
        <span className={styles.royalty}>
          {revenue.currency} {revenue.formattedShort}
        </span>
      </div>
    );
  };

  return (
    <div className={`smallScrollbar ${styles.sourcesContainer}`}>
      {data.sources.map((d: any, i: number) => {
        return renderStatRow(i, d.sourceName, d.sourceRevenue);
      })}
    </div>
  );
};
export default AnalysisSongBreakdownSource;
