import request from "../../../../app/utils/requestUtils";
import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export const getJobs = () => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.elasticsearch}`, {
    method: "GET",
  });
};

export const getRunningJobs = () => {
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.elasticsearch}?onlyRunning=true`,
    { method: "GET" }
  );
};

export const triggerJob = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.elasticsearch}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const cancelPublishingJobs = () => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.elasticsearch}/publishing`, {
    method: "DELETE",
  });
};

export const cancelRecordingJobs = () => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.elasticsearch}/recording`, {
    method: "DELETE",
  });
};
