import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchDigitalFlashPeriods,
  fetchDigitalFlashStats,
  fetchDigitalFlashTopAlbums,
  fetchDigitalFlashGraphData,
  fetchDigitalFlashTopRetailers,
  fetchDigitalFlashTopTerritories,
  fetchDigitalFlashTopTracks,
} from "./digitalFlashAPI";
import { RootState } from "../../../app/redux/store";
import { StatusEnum } from "../../../app/types/enums";

export enum DigitalFlashGraphFilterEnum {
  trackStreams = "trackStreams",
  trackDownloads = "trackDownloads",
  albumDownloads = "albumDownloads",
}

export enum DigitalFlashGraphDataEnum {
  trackStreams = "trackStreamsData",
  trackDownloads = "trackDownloadsData",
  albumDownloads = "albumDownloadsData",
}

export type DigitalFlashPeriodType = {
  periodName: string;
  days: string;
};

export type DigitalFlashStatsType = {
  albumDownloads: string;
  trackDownloads: string;
  trackStreams: string;
};

export type DigitalFlashGraphDataType = {
  categories: Array<string>;
  [DigitalFlashGraphDataEnum.trackStreams]: Array<{
    name: string;
    data: Array<number>;
  }>;
  [DigitalFlashGraphDataEnum.trackDownloads]: Array<{
    name: string;
    data: Array<number>;
  }>;
  [DigitalFlashGraphDataEnum.albumDownloads]: Array<{
    name: string;
    data: Array<number>;
  }>;
};

export type DigitalFlashTopTrackType = {
  name: string;
  streams: string;
  downloads: string;
  isrc: string;
};

export type DigitalFlashTopAlbumType = {
  name: string;
  downloads: string;
  barCode: string;
};

export type DigitalFlashTopRetailerType = {
  name: string;
  albumDownloads: string;
  trackDownloads: string;
  streams: string;
};

export type DigitalFlashTopTerritoryType = {
  name: string;
  albumDownloads: string;
  trackDownloads: string;
  streams: string;
};

interface DigitalFlashState {
  pageState: {
    data: {
      clientIds: Array<number>;
      clientLists: Array<any>;
      period: string | null;
    };
  };
  pageStatus: StatusEnum;
  periodsData: Array<DigitalFlashPeriodType> | null;
  stats: DigitalFlashStatsType;
  statsStatus: StatusEnum;
  graphData: DigitalFlashGraphDataType | null;
  graphDataStatus: StatusEnum;
  topTracks: Array<DigitalFlashTopTrackType> | null;
  topTracksStatus: StatusEnum;
  topAlbums: Array<DigitalFlashTopAlbumType> | null;
  topAlbumsStatus: StatusEnum;
  topRetailers: Array<DigitalFlashTopRetailerType> | null;
  topRetailersStatus: StatusEnum;
  topTerritories: Array<DigitalFlashTopTerritoryType> | null;
  topTerritoriesStatus: StatusEnum;
}

const initialStatsState = {
  albumDownloads: "...",
  trackDownloads: "...",
  trackStreams: "...",
};

const initialState: DigitalFlashState = {
  pageState: {
    data: {
      clientIds: [],
      clientLists: [],
      period: null,
    },
  },
  pageStatus: StatusEnum.Idle,
  periodsData: null,
  stats: initialStatsState,
  statsStatus: StatusEnum.Idle,
  graphData: null,
  graphDataStatus: StatusEnum.Idle,
  topTracks: null,
  topTracksStatus: StatusEnum.Idle,
  topAlbums: null,
  topAlbumsStatus: StatusEnum.Idle,
  topRetailers: null,
  topRetailersStatus: StatusEnum.Idle,
  topTerritories: null,
  topTerritoriesStatus: StatusEnum.Idle,
};

export const fetchDigitalFlashPeriodsThunk = createAsyncThunk(
  "recDigitalFlash/periodsData",
  async (thunkAPI) => {
    return await fetchDigitalFlashPeriods(thunkAPI);
  }
);

export const fetchDigitalFlashStatsThunk = createAsyncThunk(
  "recDigitalFlash/stats",
  async (payload: any, thunkAPI) => {
    return await fetchDigitalFlashStats(payload, thunkAPI);
  }
);

export const fetchDigitalFlashGraphDataThunk = createAsyncThunk(
  "recDigitalFlash/graphData",
  async (payload: any, thunkAPI) => {
    return await fetchDigitalFlashGraphData(payload, thunkAPI);
  }
);

export const fetchDigitalFlashTopTracksThunk = createAsyncThunk(
  "recDigitalFlash/topTracks",
  async (payload: any, thunkAPI) => {
    return await fetchDigitalFlashTopTracks(payload, thunkAPI);
  }
);

export const fetchDigitalFlashTopAlbumsThunk = createAsyncThunk(
  "recDigitalFlash/topAlbums",
  async (payload: any, thunkAPI) => {
    return await fetchDigitalFlashTopAlbums(payload, thunkAPI);
  }
);

export const fetchDigitalFlashTopRetailersThunk = createAsyncThunk(
  "recDigitalFlash/topRetailers",
  async (payload: any, thunkAPI) => {
    return await fetchDigitalFlashTopRetailers(payload, thunkAPI);
  }
);

export const fetchDigitalFlashTopTerritoriesThunk = createAsyncThunk(
  "recDigitalFlash/topTerritories",
  async (payload: any, thunkAPI) => {
    return await fetchDigitalFlashTopTerritories(payload, thunkAPI);
  }
);

const digitalFlashSlice = createSlice({
  name: "recDigitalFlash",
  initialState,
  reducers: {
    updatePageState: (state, action) => {
      state.pageState.data.clientIds = action.payload.data.clientIds;
      state.pageState.data.clientLists = action.payload.data.clientLists;
      state.pageState.data.period = action.payload.data.period;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDigitalFlashPeriodsThunk.fulfilled, (state, action) => {
        state.periodsData = action.payload?.resArr;
        state.pageStatus = StatusEnum.Fulfilled;
      })
      .addCase(fetchDigitalFlashStatsThunk.fulfilled, (state, action) => {
        state.stats = action.payload;
        state.statsStatus = StatusEnum.Fulfilled;
      })
      .addCase(fetchDigitalFlashGraphDataThunk.fulfilled, (state, action) => {
        state.graphData = action.payload;
        state.graphDataStatus = StatusEnum.Fulfilled;
      })
      .addCase(fetchDigitalFlashTopTracksThunk.fulfilled, (state, action) => {
        state.topTracks = action.payload?.tracks;
        state.topTracksStatus = StatusEnum.Fulfilled;
      })
      .addCase(fetchDigitalFlashTopAlbumsThunk.fulfilled, (state, action) => {
        state.topAlbums = action.payload?.albums;
        state.topAlbumsStatus = StatusEnum.Fulfilled;
      })
      .addCase(
        fetchDigitalFlashTopRetailersThunk.fulfilled,
        (state, action) => {
          state.topRetailers = action.payload?.retailers;
          state.topRetailersStatus = StatusEnum.Fulfilled;
        }
      )
      .addCase(
        fetchDigitalFlashTopTerritoriesThunk.fulfilled,
        (state, action) => {
          state.topTerritories = action.payload?.territories;
          state.topTerritoriesStatus = StatusEnum.Fulfilled;
        }
      )
      .addCase(fetchDigitalFlashPeriodsThunk.pending, (state) => {
        state.pageStatus = StatusEnum.Loading;
      })
      .addCase(fetchDigitalFlashStatsThunk.pending, (state) => {
        state.stats = initialStatsState;
        state.statsStatus = StatusEnum.Loading;
      })
      .addCase(fetchDigitalFlashGraphDataThunk.pending, (state) => {
        state.graphDataStatus = StatusEnum.Loading;
      })
      .addCase(fetchDigitalFlashTopTracksThunk.pending, (state) => {
        state.topTracksStatus = StatusEnum.Loading;
      })
      .addCase(fetchDigitalFlashTopAlbumsThunk.pending, (state) => {
        state.topAlbumsStatus = StatusEnum.Loading;
      })
      .addCase(fetchDigitalFlashTopRetailersThunk.pending, (state) => {
        state.topRetailersStatus = StatusEnum.Loading;
      })
      .addCase(fetchDigitalFlashTopTerritoriesThunk.pending, (state) => {
        state.topTerritoriesStatus = StatusEnum.Loading;
      })
      .addCase(fetchDigitalFlashPeriodsThunk.rejected, (state) => {
        state.pageStatus = StatusEnum.Failed;
      })
      .addCase(fetchDigitalFlashStatsThunk.rejected, (state) => {
        state.statsStatus = StatusEnum.Failed;
      })
      .addCase(fetchDigitalFlashGraphDataThunk.rejected, (state) => {
        state.graphDataStatus = StatusEnum.Failed;
      })
      .addCase(fetchDigitalFlashTopTracksThunk.rejected, (state) => {
        state.topTracksStatus = StatusEnum.Failed;
      })
      .addCase(fetchDigitalFlashTopAlbumsThunk.rejected, (state) => {
        state.topAlbumsStatus = StatusEnum.Failed;
      })
      .addCase(fetchDigitalFlashTopRetailersThunk.rejected, (state) => {
        state.topRetailersStatus = StatusEnum.Failed;
      })
      .addCase(fetchDigitalFlashTopTerritoriesThunk.rejected, (state) => {
        state.topTerritoriesStatus = StatusEnum.Failed;
      });
  },
});

export const digitalFlashPageStateSelector = (state: RootState) =>
  state.recDigitalFlash.pageState;
export const digitalFlashPageStatusSelector = (state: RootState) =>
  state.recDigitalFlash.pageStatus;
export const digitalFlashPeriodsDataSelector = (state: RootState) =>
  state.recDigitalFlash.periodsData;
export const digitalFlashStatsSelector = (state: RootState) =>
  state.recDigitalFlash.stats;
export const digitalFlashStatsStatusSelector = (state: RootState) =>
  state.recDigitalFlash.statsStatus;
export const digitalFlashGraphDataSelector = (state: RootState) =>
  state.recDigitalFlash.graphData;
export const digitalFlashGraphDataStatusSelector = (state: RootState) =>
  state.recDigitalFlash.graphDataStatus;
export const digitalFlashTopTracksSelector = (state: RootState) =>
  state.recDigitalFlash.topTracks;
export const digitalFlashTopTracksStatusSelector = (state: RootState) =>
  state.recDigitalFlash.topTracksStatus;
export const digitalFlashTopAlbumsSelector = (state: RootState) =>
  state.recDigitalFlash.topAlbums;
export const digitalFlashTopAlbumsStatusSelector = (state: RootState) =>
  state.recDigitalFlash.topAlbumsStatus;
export const digitalFlashTopRetailersSelector = (state: RootState) =>
  state.recDigitalFlash.topRetailers;
export const digitalFlashTopRetailersStatusSelector = (state: RootState) =>
  state.recDigitalFlash.topRetailersStatus;
export const digitalFlashTopTerritoriesSelector = (state: RootState) =>
  state.recDigitalFlash.topTerritories;
export const digitalFlashTopTerritoriesStatusSelector = (state: RootState) =>
  state.recDigitalFlash.topTerritoriesStatus;

export const { updatePageState } = digitalFlashSlice.actions;

export default digitalFlashSlice.reducer;
