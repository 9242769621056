import React from "react";
function AreaChartIconChartIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.624.072H.384A.381.381 0 0 0 0 .451C0 .66.172.829.383.829h1.24c.213 0 .384-.17.384-.378a.381.381 0 0 0-.383-.379Zm4.328 12.234c.212 0 .383-.17.383-.379 0-1.997.142-3.56 1.949-4.006.87-.287 1.901-.293 2.898-.299 2.294-.013 4.872-.038 5.122-3.87l.887.937a.387.387 0 0 0 .543.018.375.375 0 0 0 .018-.535l-1.566-1.653a.386.386 0 0 0-.561.002L14.07 4.183a.375.375 0 0 0 .02.535.386.386 0 0 0 .543-.021l.908-.97c-.204 3.109-2.053 3.124-4.362 3.138-1.058.006-2.151.012-3.109.33-2.502.617-2.502 3.093-2.502 4.732a.38.38 0 0 0 .384.379ZM1.624 3.673H.384A.381.381 0 0 0 0 4.05c0 .21.172.379.383.379h1.24c.213 0 .384-.17.384-.379a.381.381 0 0 0-.383-.378Zm0 10.8H.384a.381.381 0 0 0-.384.379c0 .209.172.378.383.378h1.24c.213 0 .384-.17.384-.378a.381.381 0 0 0-.383-.379Zm0-3.6H.384A.381.381 0 0 0 0 11.25c0 .209.172.379.383.379h1.24c.213 0 .384-.17.384-.38a.381.381 0 0 0-.383-.378Zm0-3.601H.384A.381.381 0 0 0 0 7.651c0 .209.172.378.383.378h1.24c.213 0 .384-.17.384-.378a.381.381 0 0 0-.383-.379Zm15.843 8.745a.381.381 0 0 0-.384.379v1.225c0 .21.172.379.384.379.211 0 .383-.17.383-.379v-1.225a.381.381 0 0 0-.383-.379Zm.15-1.544H3.087V.38A.381.381 0 0 0 2.703 0a.381.381 0 0 0-.383.379v14.473c0 .209.172.378.383.378h14.914a.38.38 0 0 0 .383-.378.38.38 0 0 0-.383-.379Zm-3.797 1.544a.381.381 0 0 0-.383.379v1.225c0 .21.172.379.383.379.211 0 .384-.17.384-.379v-1.225a.382.382 0 0 0-.384-.379Zm-10.94 0a.381.381 0 0 0-.383.379v1.225c0 .21.171.379.383.379.211 0 .384-.17.384-.379v-1.225a.382.382 0 0 0-.384-.379Zm7.293 0a.381.381 0 0 0-.383.379v1.225c0 .21.171.379.383.379s.384-.17.384-.379v-1.225a.381.381 0 0 0-.384-.379Zm-3.647 0a.382.382 0 0 0-.384.379v1.225c0 .21.172.379.384.379.212 0 .384-.17.384-.379v-1.225a.381.381 0 0 0-.384-.379Z" />
    </svg>
  );
}
export default AreaChartIconChartIcon;
