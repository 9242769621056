import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export const getJobs = () => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.misc}`, {
    method: "GET",
  });
};

export const triggerJob = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.misc}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};
