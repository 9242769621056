import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector } from "react-redux";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet, useNavigate } from "react-router-dom";
import Loader from "../../app/atom/Loader";
import { ROUTES } from "./routes.const";
import { changePasswordStateSelector } from "../more/settings/settingsSlice";

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();
  const changePasswordStatus = useSelector(changePasswordStateSelector);

  useEffect(() => {
    if (
      !authState ||
      changePasswordStatus.changePwdLoadingStatus === "loading"
    ) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );

      navigate(`/${ROUTES.mybmg}/${ROUTES.login}`, { state: { originalUri } });
    }
    // eslint-disable-next-line
  }, [oktaAuth, authState?.isAuthenticated, !!authState]);

  if (
    (!authState || !authState?.isAuthenticated) &&
    changePasswordStatus.changePwdLoadingStatus !== "loading"
  ) {
    return <Loader />;
  }

  return <Outlet />;
};
