import { PageTitleProps } from "../../types/props";

function PageTitle({
  text,
  description,
  className,
  variant,
}: PageTitleProps): JSX.Element {
  const getClasses = () => {
    const addClasses = ["pagetitle"];
    if (className) addClasses.push(className);
    if (variant) addClasses.push(variant);
    if (!variant) addClasses.push("primaryTitle");
    return addClasses.join(" ");
  };

  return (
    <h4 className={getClasses()}>
      {text}{" "}
      {!!description && <span className="mx-1 inline-block font-light">|</span>}{" "}
      {!!description && <span className="xs:-tracking-2xl">{description}</span>}
    </h4>
  );
}

export default PageTitle;
