import React from "react";
function LoginServiceIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      name="LoginServiceIcon"
      {...props}
    >
      <defs>
        <mask
          id="a"
          width="40"
          height="40"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx="32.5" cy="32.5" r="32.5" fill="#d20020" />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17.364 30.182v-9.814a2 2 0 0 1 1.998-2.004h28.73c1.104 0 1.999.89 1.999 1.997v18.733a2.003 2.003 0 0 1-2 1.997H21"
        />
        <path
          stroke="#FFF"
          strokeWidth="2"
          d="m31 41.09-.91 5.455M36.455 41.09l.909 4.546"
        />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M28.273 46.545h10.909"
        />
        <rect
          width="9.091"
          height="16.364"
          x="11.909"
          y="30.182"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          rx="1"
        />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M21.91 37.455h27.272"
        />
      </g>
    </svg>
  );
}

export default LoginServiceIcon;
