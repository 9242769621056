import request from "../../../../app/utils/requestUtils";
import { SingleGroup } from "../../../../app/types/props";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export type AddEditAdminGroupRequestBody = Pick<
  SingleGroup,
  | "name"
  | "email"
  | "emailName"
  | "description"
  | "language"
  | "phone"
  | "groupType"
  | "admin"
>;

export function fetchAdminGroupByID(payload: { id: number }) {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.adminGroups}/${id}`, {
    method: "GET",
  });
}

export function addNewAdminGroup(payload: {
  data: AddEditAdminGroupRequestBody;
}) {
  const { data } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.adminGroups}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
}

export function editAdminGroup(payload: {
  id: number;
  data: AddEditAdminGroupRequestBody;
}) {
  const { data, id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.adminGroups}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
}

export function deleteAdminGroup(payload: { id: number }) {
  const { id } = payload;
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.adminGroups}/${id}`, {
    method: "DELETE",
  });
}
