import request from "../../../../app/utils/requestUtils";

import { GroupsRequestBody } from "../../../../app/types/props";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export const getGroups = (payload: GroupsRequestBody) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.groups}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};
