import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import {
  FetchAnalysisSourceBreakdown_Trend,
  FetchAnalysisSourceBreakdown_Type,
  FetchAnalysisSourceBreakdown_Territory,
  downloadSourcesBreakdownData,
} from "./recAnalysisSourceBreakdownAPI";
import { RecAnalysisSourceData } from "./recAnalysisSourceBreakdownAPI";

export declare type RecAnalysisSourceBreakdownProps =
  | RecAnalysisSourceData
  | null
  | undefined;

export interface recAnalysisSourceBreakdownState {
  sourceBreakdownType: {
    dataArray: Array<RecAnalysisSourceBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  sourceBreakdownTrend: {
    dataArray: Array<RecAnalysisSourceBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  sourceBreakdownTerritory: {
    dataArray: Array<RecAnalysisSourceBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  sourceBreakdownDownloadStatus: "idle" | "loading" | "failed";
}

const initialState: recAnalysisSourceBreakdownState = {
  sourceBreakdownType: { dataArray: [], status: "noData" },
  sourceBreakdownTrend: { dataArray: [], status: "noData" },
  sourceBreakdownTerritory: { dataArray: [], status: "noData" },
  sourceBreakdownDownloadStatus: "idle",
};

export const FetchRecAnalysisSourceBreakdown_Type_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisSourceBreakdown_Type",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisSourceBreakdown_Type(params, thunkAPI);
  }
);

export const FetchRecAnalysisSourceBreakdown_Trend_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisSourceBreakdown_Trend",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisSourceBreakdown_Trend(params, thunkAPI);
  }
);

export const FetchRecAnalysisSourceBreakdown_Territory_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisSourceBreakdown_Territory",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisSourceBreakdown_Territory(params, thunkAPI);
  }
);

export const recAnalysisSourcesBreakdown_Download_Thunk = createAsyncThunk(
  "recAnalysis/breakdown/downloadSources",
  async (payload: any, thunkAPI) => {
    const response = await downloadSourcesBreakdownData(payload, thunkAPI);
    return response;
  }
);

export const FindRecAnalysisSourceBreakdown = (
  sources: Array<RecAnalysisSourceBreakdownProps>,
  sourceCode: string
): RecAnalysisSourceData | null => {
  const breakdown = sources.find((source) => {
    return source?.sourceName === sourceCode;
  });
  return breakdown ? breakdown : null;
};

export const recAnalysisSourceBreakdownSlice = createSlice({
  name: "recAnalysisSourceBreakdown",
  initialState,
  reducers: {
    clearTrendBreakdownData: (state, action: any) => {
      state.sourceBreakdownTrend.dataArray = [];
      state.sourceBreakdownTrend.status = "noData";
    },
    clearTerritoryBreakdownData: (state, action: any) => {
      state.sourceBreakdownTerritory.dataArray = [];
      state.sourceBreakdownTerritory.status = "noData";
    },
  },
  extraReducers: (builder) => {
    builder
      // type
      .addCase(
        FetchRecAnalysisSourceBreakdown_Type_Thunk.fulfilled,
        (state, action) => {
          const typeData = action.payload;
          typeData.sourceName = action.meta.arg.sourceName;
          state.sourceBreakdownType.dataArray.push(typeData);
          state.sourceBreakdownType.status = "idle";
        }
      )
      .addCase(FetchRecAnalysisSourceBreakdown_Type_Thunk.pending, (state) => {
        state.sourceBreakdownType.status = "loading";
      })
      .addCase(FetchRecAnalysisSourceBreakdown_Type_Thunk.rejected, (state) => {
        state.sourceBreakdownType.status = "failed";
      })
      // trend
      .addCase(
        FetchRecAnalysisSourceBreakdown_Trend_Thunk.fulfilled,
        (state, action) => {
          const typeData = action.payload;
          typeData.sourceName = action.meta.arg.sourceName;
          state.sourceBreakdownTrend.dataArray.push(typeData);
          state.sourceBreakdownTrend.status = "idle";
        }
      )
      .addCase(FetchRecAnalysisSourceBreakdown_Trend_Thunk.pending, (state) => {
        state.sourceBreakdownTrend.status = "loading";
      })
      .addCase(
        FetchRecAnalysisSourceBreakdown_Trend_Thunk.rejected,
        (state) => {
          state.sourceBreakdownTrend.status = "failed";
        }
      )
      // territory
      .addCase(
        FetchRecAnalysisSourceBreakdown_Territory_Thunk.fulfilled,
        (state, action) => {
          const territoryData = action.payload;
          territoryData.sourceName = action.meta.arg.sourceName;
          state.sourceBreakdownTerritory.dataArray.push(territoryData);
          state.sourceBreakdownTerritory.status = "idle";
        }
      )
      .addCase(
        FetchRecAnalysisSourceBreakdown_Territory_Thunk.pending,
        (state) => {
          state.sourceBreakdownTerritory.status = "loading";
        }
      )
      .addCase(
        FetchRecAnalysisSourceBreakdown_Territory_Thunk.rejected,
        (state) => {
          state.sourceBreakdownTerritory.status = "failed";
        }
      )
      // download
      .addCase(
        recAnalysisSourcesBreakdown_Download_Thunk.fulfilled,
        (state) => {
          state.sourceBreakdownDownloadStatus = "idle";
        }
      )
      .addCase(recAnalysisSourcesBreakdown_Download_Thunk.pending, (state) => {
        state.sourceBreakdownDownloadStatus = "loading";
      })
      .addCase(recAnalysisSourcesBreakdown_Download_Thunk.rejected, (state) => {
        state.sourceBreakdownDownloadStatus = "failed";
      });
  },
});

export const recAnalysisSourceBreakdownTypeSelector = (state: RootState) =>
  state.recAnalysisSourceBreakdown.sourceBreakdownType;
export const recAnalysisSourceBreakdownTrendSelector = (state: RootState) =>
  state.recAnalysisSourceBreakdown.sourceBreakdownTrend;
export const recAnalysisSourceBreakdownTerritorySelector = (state: RootState) =>
  state.recAnalysisSourceBreakdown.sourceBreakdownTerritory;
export const recAnalysisSourceBreakdownDownloadStatusSelector = (
  state: RootState
) => state.recAnalysisSourceBreakdown.sourceBreakdownDownloadStatus;

export const {
  clearTrendBreakdownData: clearTrendBreakdownDataAction,
  clearTerritoryBreakdownData: clearTerritoryBreakdownDataAction,
} = recAnalysisSourceBreakdownSlice.actions;

export default recAnalysisSourceBreakdownSlice.reducer;
