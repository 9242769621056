import React from "react";
function MechDashIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 22 22"
      fill="none"
      strokeWidth={0}
      name="MechDashIcon"
      {...props}
    >
      <path d="M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11Z" />
      <circle cx="11" cy="11" r="1.7" stroke="#FFF" strokeWidth=".6" />
      <circle cx="11" cy="11" r="1" fill="#FFF" />
      <g stroke="#FFF" strokeLinecap="round" strokeWidth=".6">
        <path d="M4.5 11a6.5 6.5 0 0 0 6.5 6.5" />
        <path d="M5.583 11A5.417 5.417 0 0 0 11 16.417M17.5 11A6.5 6.5 0 0 0 11 4.5" />
        <path d="M16.417 11A5.417 5.417 0 0 0 11 5.583" />
      </g>
      <circle
        cx="11"
        cy="11"
        r="7.7"
        fill="none"
        stroke="#FFF"
        strokeWidth=".6"
      />
    </svg>
  );
}

export default MechDashIcon;
