import { useTranslation } from "react-i18next";
import Modal from "../../../app/molecules/modal/Modal";
import { MessagePriorityModalProps } from "../../../app/types/props";
import Button from "../../../app/atom/Button/Button";
import styles from "./message.module.scss";

const MessagePriorityModal = (
  props: MessagePriorityModalProps
): JSX.Element => {
  const { message, numberOfPrioMessages, onMarkAsReadMessage } = props;
  const { t } = useTranslation();

  const handleButtonClick = () => {
    onMarkAsReadMessage(message.id);
  };

  return (
    <Modal
      id="priorityMessageModal"
      isModalOpen
      disableBackdropClick
      hideCloseButton
    >
      <div className="flex justify-between">
        <h3>{message.title}</h3>
        {numberOfPrioMessages > 1 && (
          <span className="font-light text-gray-400">
            {message.currentIndex}/{numberOfPrioMessages}
          </span>
        )}
      </div>
      <div
        className={`${styles.messageModalBody}`}
        dangerouslySetInnerHTML={{ __html: message.text }}
      />
      <div className="mt-16 flex items-center justify-center">
        <Button onClick={handleButtonClick} className="bg-red-800 px-12">
          {t("message.close")}
        </Button>
      </div>
    </Modal>
  );
};

export default MessagePriorityModal;
