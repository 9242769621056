import { RefObject, useEffect, useRef, useState } from "react";

const useInfiniteScroll = (
  ref: RefObject<HTMLElement>,
  options: {
    rootMargin?: string;
    threshold?: number;
  } = { rootMargin: "0px", threshold: 1 }
) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    if (observerRef.current) return;
    observerRef.current = new IntersectionObserver(([entry]) => {
      setIsOnScreen(entry.isIntersecting);
      entry.isIntersecting && setPageCount((pageCount) => pageCount + 1);
    }, options);
  }, [options]);

  useEffect(() => {
    observerRef.current &&
      ref.current &&
      observerRef.current.observe(ref.current);

    return () => {
      observerRef.current && observerRef.current.disconnect();
    };
  }, [ref]);

  return { isOnScreen, pageCount, setPageCount };
};

export default useInfiniteScroll;
