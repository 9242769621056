import React from "react";
function MailIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      name="MailIcon"
      width="100%"
      height="100%"
      viewBox="0 0 22 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.16.332a.32.32 0 0 0-.046-.14.329.329 0 0 0-.097-.111c-.011-.009-.02-.02-.032-.028-.007-.004-.015-.004-.022-.008-.023-.012-.047-.019-.072-.026a.403.403 0 0 0-.072-.014c-.008 0-.016-.005-.025-.005H.374C.364 0 .357.004.348.005.325.007.303.013.28.02.254.026.23.033.205.045.198.05.19.05.183.053.17.06.163.073.152.08a.326.326 0 0 0-.098.111.375.375 0 0 0-.03.064.416.416 0 0 0-.015.076C.007.346 0 .359 0 .374v15.252c0 .206.168.374.374.374h20.42a.374.374 0 0 0 .374-.374V.374c0-.015-.006-.028-.008-.042Zm-1.054.416c-1.108 1.642-4.658 6.406-9.522 8.088C5.721 7.154 2.171 2.39 1.062.748h19.044ZM.748 1.62c1.034 1.473 3.168 4.173 6.107 6.153-2.992 2.342-5.16 5.268-6.107 6.674V1.62Zm.368 13.632C1.862 14.105 4.22 10.72 7.522 8.2a14.498 14.498 0 0 0 2.944 1.388h.002a.374.374 0 0 0 .231 0h.003c1.046-.35 2.028-.83 2.941-1.387 3.301 2.52 5.66 5.904 6.407 7.05H1.116Zm19.304-.799c-.945-1.404-3.113-4.333-6.11-6.678 2.942-1.98 5.077-4.682 6.11-6.155v12.833Z"
      />
    </svg>
  );
}

export default MailIcon;
