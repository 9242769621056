import React from "react";

function ChevronIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="-10 -6 26 26"
      name="ChevronIcon"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="m7.636 7.093-6.004 6.69-1.144-1.275 4.86-5.415-4.86-5.415L1.632.404z"
      />
    </svg>
  );
}
export default ChevronIcon;
