import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import {
  validateEmail,
  fetchSendRegistrationPayload,
  fetchUserAccountTypes,
} from "./registerAPI";
import type { RegistrationPayload } from "../../../app/types/payload";
import type { UserAccountTypeEntry } from "../../../app/types/props";

export type UserRegistrationValidationState = {
  emailAlreadyInUse: boolean;
};

export type UserRegistrationValidationFetchStatus = {
  emailStatus: "idle" | "loading" | "failed";
};

export interface UserRegistrationState {
  validations: UserRegistrationValidationState;
  validationState: UserRegistrationValidationFetchStatus;
  registrationStatus: "idle" | "loading" | "failed";
  registrationSuccess: boolean;
  userAccountTypesState: {
    accountTypes: UserAccountTypeEntry[] | undefined;
    accountTypesStatus: "idle" | "loading" | "failed";
  };
  ApiError: string | undefined;
  errors: null | object;
}

const initialStateWithoutAccounts: Omit<
  UserRegistrationState,
  "userAccountTypesState"
> = {
  validations: {
    emailAlreadyInUse: false,
  },
  validationState: {
    emailStatus: "idle",
  },
  ApiError: undefined,
  registrationStatus: "idle",
  registrationSuccess: false,
  errors: null,
};

const initialState: UserRegistrationState = {
  ...initialStateWithoutAccounts,
  userAccountTypesState: {
    accountTypes: undefined,
    accountTypesStatus: "idle",
  },
};

export const fetchRegisterValidationEmailThunk = createAsyncThunk(
  "/users/validate-email",
  async (payload: string, thunkAPI) => {
    const response = await validateEmail(payload, thunkAPI);
    if (response?.resStatus === 200) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

export const fetchSubmitRegistrationThunk = createAsyncThunk(
  "/register",
  async (payload: RegistrationPayload, thunkAPI) => {
    const response = await fetchSendRegistrationPayload(payload, thunkAPI);
    if (response?.resStatus === 200) {
      return response;
    } else {
      delete response.resStatus;
      return thunkAPI.rejectWithValue(response?.payees || response);
    }
  }
);

export const fetchGetAccountTypesThunk = createAsyncThunk(
  "/userAccountTypes",
  async (thunkAPI) => {
    const response = await fetchUserAccountTypes(thunkAPI);
    return response.resArr;
  }
);

export const useRegistrationSlice = createSlice({
  name: "userRegistration",
  initialState,
  reducers: {
    validateRegistrationEMail: (state, action: any) => {
      state.validations.emailAlreadyInUse = action.payload;
    },
    resetRegistrationForm: (state) => {
      state = {
        ...state,
        ...initialStateWithoutAccounts,
      };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchRegisterValidationEmailThunk.fulfilled,
        (state, action: any) => {
          state.validationState.emailStatus = "idle";
          state.validations.emailAlreadyInUse = false;
        }
      )
      //
      .addCase(fetchRegisterValidationEmailThunk.pending, (state) => {
        state.validationState.emailStatus = "loading";
        state.ApiError = undefined;
      })
      .addCase(
        fetchRegisterValidationEmailThunk.rejected,
        (state, action: any) => {
          if (action?.payload?.resStatus === 409) {
            state.validations.emailAlreadyInUse = true;
          }
          state.validationState.emailStatus = "failed";
        }
      )

      // get user account types
      .addCase(fetchGetAccountTypesThunk.fulfilled, (state, action: any) => {
        state.userAccountTypesState.accountTypesStatus = "idle";
        state.userAccountTypesState.accountTypes = action.payload;
        state.ApiError = undefined;
      })
      .addCase(fetchGetAccountTypesThunk.pending, (state) => {
        state.userAccountTypesState.accountTypesStatus = "loading";
        state.ApiError = undefined;
      })
      .addCase(fetchGetAccountTypesThunk.rejected, (state) => {
        state.userAccountTypesState.accountTypesStatus = "failed";
        state.userAccountTypesState.accountTypes = undefined;
        state.ApiError = "failedFetchUserAccountTypes";
      })

      //
      .addCase(fetchSubmitRegistrationThunk.fulfilled, (state) => {
        state.registrationStatus = "idle";
        state.registrationSuccess = true;
        state.errors = null;
      })
      .addCase(fetchSubmitRegistrationThunk.pending, (state) => {
        state.registrationStatus = "loading";
        state.ApiError = undefined;
        state.errors = null;
      })
      .addCase(fetchSubmitRegistrationThunk.rejected, (state, action: any) => {
        state.registrationStatus = "failed";
        state.registrationSuccess = false;
        state.errors = action.payload || null;
      });
  },
});

export const validationsStatusSelector = (state: RootState) =>
  state.userRegistration.validations;

export const validationsFetchStateSelector = (state: RootState) =>
  state.userRegistration.validationState;

export const userAccountTypesFetchStateSelector = (state: RootState) =>
  state.userRegistration.userAccountTypesState.accountTypesStatus;

export const userAccountTypesSelector = (state: RootState) =>
  state.userRegistration.userAccountTypesState.accountTypes;

export const registrationErrorsSelector = (state: RootState) =>
  state.userRegistration.errors;
export const registrationApiErrorSelector = (state: RootState) =>
  state.userRegistration.ApiError;

export const registrationStatusSelector = (state: RootState) =>
  state.userRegistration.registrationStatus;

export const registrationSuccessStatusSelector = (state: RootState) =>
  state.userRegistration.registrationSuccess;

export const {
  validateRegistrationEMail: validationsStatusSelectorAction,
  resetRegistrationForm: resetRegistrationFormAction,
} = useRegistrationSlice.actions;

export default useRegistrationSlice.reducer;
