import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function fetchDashboardStats(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.stats}`, {
    method: "POST",
    body: JSON.stringify(params?.data),
    signal: thunkAPI?.signal,
  });
}

// periodIds must be an array of 1 element only (latest periodId)
export function fetchTopRoyaltySources(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.sources}`, {
    method: "POST",
    body: JSON.stringify(params),
    signal: thunkAPI?.signal,
  });
}

export function fetchTopPerformingSongs(params: any, thunkAPI?: any) {
  return request(
    `${USER_API_RESOURCE_BASE_URLS.songsTop5}?latestPeriodId=${params.latestPeriodId}&previousPeriodId=${params.previousPeriodId}`,
    {
      method: "POST",
      body: JSON.stringify({
        clientIds: params.clientIds,
        clientLists: params.clientLists,
      }),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchTopPerformingTerritories(
  params: {
    clientIds: number[];
    clientLists: any[];
    periodIds: number[];
  },
  thunkAPI?: any
) {
  return request(
    `${USER_API_RESOURCE_BASE_URLS.dashboardTopTerritories}?count=4`,
    {
      method: "POST",
      body: JSON.stringify(params),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchLatestStatements(params: any, thunkAPI?: any) {
  const { payeeOnly = false, documentType = "PUBLISHING", data } = params;

  return request(
    `${USER_API_RESOURCE_BASE_URLS.statementsPeriod}?clientDocumentType=${documentType}&isPayeeOnly=${payeeOnly}&maxResults=5`,
    { method: "POST", body: JSON.stringify(data), signal: thunkAPI?.signal }
  );
}

export function fetchLatestStatementsFile(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.statementsFiles}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
    isDownload: true,
    fileName,
  });
}

export function fetchRoyaltiesTrend(data: any, thunkAPI?: any) {
  return request(
    `${USER_API_RESOURCE_BASE_URLS.dashboardTrends}?&documentType=PUBLISHING`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchDashboardPipelineBreakdown_Type(
  params: any,
  thunkAPI?: any
) {
  const { data } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.pipelineTypes}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
  });
}

export function FetchDashboardPipelineBreakdown_Songs(
  params: any,
  thunkAPI?: any
) {
  const { data } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.pipelineSongs}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
  });
}

export function FetchDashboardPipelineBreakdown_Sources(
  params: any,
  thunkAPI?: any
) {
  const { data } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.pipelineSources}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
  });
}

export function fetchPeriods(params: any, thunkAPI?: any) {
  const { data } = params;
  const payload = {
    ...data,
    withPipeline: true,
    yearsBack: 12,
  };
  return request(`${USER_API_RESOURCE_BASE_URLS.periods}`, {
    method: "POST",
    body: JSON.stringify(payload),
    signal: thunkAPI?.signal,
  });
}
