import React from "react";
function FinancialIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 26 26"
      fill="none"
      name="FinancialIcon"
      {...props}
    >
      <path d="M7.449 4.816a.386.386 0 0 0-.388.384v14.077c0 .212.173.384.388.384a.387.387 0 0 0 .388-.384V5.2a.387.387 0 0 0-.388-.384Zm-3.53 3.143a.387.387 0 0 0-.389.384v10.934c0 .212.174.384.389.384a.386.386 0 0 0 .387-.384V8.343a.386.386 0 0 0-.387-.384Zm7.06.125a.386.386 0 0 0-.388.384v10.809c0 .212.174.384.388.384a.386.386 0 0 0 .388-.384V8.467a.386.386 0 0 0-.388-.383ZM.388 10.648a.386.386 0 0 0-.388.384v8.245c0 .212.174.384.388.384a.386.386 0 0 0 .388-.384v-8.245a.386.386 0 0 0-.388-.384ZM21.57 5.874a.386.386 0 0 0-.388.384v13.019c0 .212.173.384.388.384a.387.387 0 0 0 .388-.384V6.258a.387.387 0 0 0-.388-.384Zm-.12-2.61a.387.387 0 0 0 .385-.389L21.805.38a.384.384 0 0 0-.42-.379l-2.509.21a.386.386 0 0 0-.354.416.393.393 0 0 0 .42.351L20.465.85l-5.952 6.494L7.702.73a.39.39 0 0 0-.534-.009L.138 7.093a.382.382 0 0 0-.024.544.39.39 0 0 0 .548.024l6.76-6.127 6.837 6.638c.072.071.17.11.271.11h.012a.391.391 0 0 0 .275-.126l6.225-6.79.017 1.518c.002.21.175.38.388.38h.004Zm-6.94 7.37a.386.386 0 0 0-.389.384v8.259c0 .212.174.384.388.384a.386.386 0 0 0 .388-.384v-8.26a.386.386 0 0 0-.388-.384Zm3.53-2.652a.386.386 0 0 0-.388.384v10.91c0 .213.174.385.388.385a.386.386 0 0 0 .388-.384V8.366a.386.386 0 0 0-.388-.384Z" />
    </svg>
  );
}

export default FinancialIcon;
