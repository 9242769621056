import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export function getRequestArchiveItem(id: number) {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.requestArchiveEdit}/${id}`, {
    method: "GET",
  });
}

export function getRequestArchiveUser(id: number) {
  return request(
    `${ADMIN_API_RESOURCE_BASE_URLS.requestArchiveEdit}/${id}/user`,
    {
      method: "GET",
    }
  );
}
