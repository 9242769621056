/* eslint-disable prefer-rest-params */
import useScript from "../../hooks/useSrcipt";

export const ConsentTool = () => {
  useScript("../../scripts/sp.js");
  (window as any)._sp_ = {
    config: {
      accountId: 1111,
      baseEndpoint: "https://cdn.privacy-mgmt.com",
      events: {
        onMessageReady: function () {
          // eslint-disable-next-line no-console
          console.log("[event] onMessageReady", arguments);
        },
        onConsentReady: function () {
          // eslint-disable-next-line no-console
          console.log("[event] onConsentReady", arguments);
        },
      },
    },
  };
  useScript("https://cdn.privacy-mgmt.com/wrapperMessagingWithoutDetection.js");

  return <></>;
};
