import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { SortDirectionEnum, StatusEnum } from "../../../../app/types/enums";
import {
  GroupsInitialState,
  GroupsPayload,
  GroupsRequestBody,
} from "../../../../app/types/props";

import {
  DEFAULT_ROWS_PER_PAGE,
  groupsTableHeaderData,
} from "../../common/adminEndpoints.const";
import { useFetch } from "../../common/hooks/useFetch";

import { getGroups } from "./GroupsAPI";

const initialState: GroupsInitialState = {
  groupsPageState: {
    filterText: "",
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
    },
    sortingCriteria: [
      {
        sortColumn: groupsTableHeaderData?.[0].filteringInfo,
        direction: SortDirectionEnum.Descending,
      },
    ],
    searchCriteria: null,
  },
  tableData: {
    list: null,
    count: 0,
    totalCount: 0,
  },

  status: StatusEnum.Idle,
};

export const getGroupsThunk = createAsyncThunk(
  "admin/Groups",
  async (payload: GroupsPayload, { rejectWithValue }) => {
    try {
      const requestData = {
        ...payload,
      } as GroupsRequestBody;

      const fetchGroup = await useFetch();
      const response = await fetchGroup<GroupsRequestBody>({
        data: requestData,
        fetchFunction: getGroups,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    updatePageState: (state, action: any) => {
      state.groupsPageState.sortingCriteria = action.payload.sortingCriteria;
      state.groupsPageState.filterText = action.payload.filterText;
      state.groupsPageState.pagination = action.payload.pagination;
      state.groupsPageState.searchCriteria = action.payload.searchCriteria;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroupsThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(getGroupsThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getGroupsThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      });
  },
});
export const GroupsSelector = (state: RootState) => state.groups;
export default groupsSlice.reducer;
