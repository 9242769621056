import CheckIcon from "../Icon/CheckIcon";

import styles from "./checkbox.module.scss";

import { CheckboxProps } from "../../types/props";

export default function Checkbox(props: CheckboxProps): JSX.Element {
  const { text, className = "", disabled = false } = props;

  function handleClick() {
    const val = props.value ? props.value : props.text;

    if (props.onChange) props.onChange(!props.isChecked, val);
  }

  const checkedClass = props.isChecked ? "checked" : "";

  return (
    <div
      className={`${styles.checkbox} ${checkedClass} ${className} ${
        disabled && styles.checkboxDisable
      }`}
      onClick={handleClick}
    >
      <div>{props.isChecked && <CheckIcon />}</div>

      <label>{text}</label>
    </div>
  );
}
