import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { SortingCriteria } from "../../../../app/types/props";
import {
  SortDirectionEnum,
  StatusEnum,
  UserAccountTypeEnum,
} from "../../../../app/types/enums";

import { DEFAULT_ROWS_PER_PAGE } from "../../common/adminEndpoints.const";
import { useFetch } from "../../common/hooks/useFetch";

import {
  getOriginatingSystem,
  getPendingRequestList,
  updateAccount,
  updateAccountStatus,
} from "./PendingRequestAPI";
import { tableHeaderData } from "./PendingRequest.const";

export type PendingRequestPageState = {
  filterText: string;
  pagination: {
    page: number;
    size: number;
    sortingCriteria: SortingCriteria[];
  };
  searchCriteria: {
    filterColumn: string;
    userAccountType: string;
  };
};

export type PendingRequestListItem = {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  requestedDate: string;
  userAccountType: UserAccountTypeEnum;
  originatingSystem: string;
};

type PendingRequestInitialState = {
  pendingRequestPageState: PendingRequestPageState;
  tableData: {
    list: PendingRequestListItem[] | null;
    count: number | null;
    totalCount: number | null;
  };
  status: StatusEnum;
  originatingSystem: string[];
};

const initialState: PendingRequestInitialState = {
  pendingRequestPageState: {
    filterText: "",
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: tableHeaderData?.[0].filteringInfo,
          direction: SortDirectionEnum.Ascending,
        },
      ],
    },
    searchCriteria: {
      filterColumn: "",
      userAccountType: "",
    },
  },
  tableData: {
    list: null,
    count: 0,
    totalCount: 0,
  },
  status: StatusEnum.Idle,
  originatingSystem: [],
};

export const getPendingRequestThunk = createAsyncThunk(
  "admin/access-requests/pending",
  async (payload: any, { rejectWithValue }) => {
    try {
      const getPendingRequest = await useFetch();
      const response = await getPendingRequest({
        data: payload,
        fetchFunction: getPendingRequestList,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAccountStatusThunk = createAsyncThunk(
  "admin/access-requests/id/status",
  async (payload: any, { rejectWithValue }) => {
    try {
      const updateStatus = await useFetch();
      const response = await updateStatus({
        data: payload,
        fetchFunction: updateAccountStatus,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOriginatingSystemThunk = createAsyncThunk(
  "admin/client-owning-system",
  async (_, { rejectWithValue }) => {
    try {
      const _getOriginatingSystem = await useFetch();
      const response = await _getOriginatingSystem({
        fetchFunction: getOriginatingSystem,
      });

      return response?.resArr?.sort();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAccountThunk = createAsyncThunk(
  "admin/access-request/id",
  async (payload: any, { rejectWithValue }) => {
    try {
      const _updateAccount = await useFetch();
      const response = await _updateAccount({
        data: payload,
        fetchFunction: updateAccount,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const pendingRequestSlice = createSlice({
  name: "pendingRequest",
  initialState,
  reducers: {
    updatePageState: (state, action: any) => {
      state.pendingRequestPageState.searchCriteria =
        action.payload.searchCriteria;
      state.pendingRequestPageState.filterText = action.payload.filterText;
      state.pendingRequestPageState.pagination = action.payload.pagination;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPendingRequestThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(getPendingRequestThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getPendingRequestThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      })
      .addCase(updateAccountStatusThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(updateAccountStatusThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(updateAccountStatusThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(getOriginatingSystemThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.originatingSystem = action.payload;
      })
      .addCase(getOriginatingSystemThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getOriginatingSystemThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(updateAccountThunk.fulfilled, (state) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(updateAccountThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(updateAccountThunk.rejected, (state) => {
        state.status = StatusEnum.Failed;
      });
  },
});

export const PendingRequestSelector = (state: RootState) =>
  state.pendingRequest;

export default pendingRequestSlice.reducer;
