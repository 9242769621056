import { useTranslation } from "react-i18next";

import Button from "../../../app/atom/Button";
import Input from "../../../app/atom/Input";
import ErrorMessage from "../../../app/molecules/authErrorMessage";
import styles from "../../../app/molecules/sideBasicWrapper/sideBasic.module.scss";
import { IAuthentication } from "../../../app/types/interfaces";
import { ButtonVariantEnum } from "../../../app/types/enums";

const LoginPassword = ({
  authenticationHooks,
}: {
  authenticationHooks: IAuthentication;
}) => {
  const { t } = useTranslation();

  return (
    <form className="contents" onSubmit={authenticationHooks.idxAuthenticate}>
      {/* HEAD Section Start*/}
      <div className="pb-5 text-lg tracking-7xl text-green-700">
        {t("loginMFA.verifyPasswordTitle")}
      </div>
      {/* HEAD Section End*/}

      {/* Error Section Start */}
      {authenticationHooks.authApiError && (
        <ErrorMessage
          error={authenticationHooks.authApiError}
          id="loginFormFeedback"
        />
      )}
      {/* Error Section End */}

      {/* Body Section Start*/}
      <div className={styles.inputContainer}>
        <Input
          id="password"
          placeholder={t("login.passwordPlaceholder") || ""}
          label={t("login.passwordLabel") || ""}
          className="mb-6 mt-5 block font-light text-white"
          type="password"
          autoComplete="on"
          onChange={(e) => {
            authenticationHooks.setPassword(e.target.value);
          }}
        ></Input>
      </div>
      {/* Body Section End*/}

      {/* Below Action Items Start*/}
      <>
        <Button
          dataTestId="passwordConfirmButton"
          className={`mt-4 w-full`}
          type="submit"
        >
          {t("loginMFA.verifyPasswordBtn")}
        </Button>
        <div className="pt-6">
          <Button
            dataTestId="loginMFASkipGA"
            variant={ButtonVariantEnum.textLink}
            className="whitespace-pre-wrap text-lg text-white hover:text-white"
            onClick={authenticationHooks.onSkipHandler}
          >
            {t("loginMFA.skip")}
          </Button>
        </div>
      </>
      {/* Below Action Items End*/}
    </form>
  );
};

export default LoginPassword;
