import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function fetchDigitalFlashPeriods(thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.digitalFlashPeriods}`, {
    signal: thunkAPI?.signal,
  });
}

export function fetchDigitalFlashStats(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.digitalFlashStats}`, {
    method: "POST",
    body: JSON.stringify(params?.data),
    signal: thunkAPI?.signal,
  });
}

export function fetchDigitalFlashGraphData(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.digitalFlashGraph}`, {
    method: "POST",
    body: JSON.stringify(params?.data),
    signal: thunkAPI?.signal,
  });
}

export function fetchDigitalFlashTopTracks(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.digitalFlashTopTracks}`, {
    method: "POST",
    body: JSON.stringify(params?.data),
    signal: thunkAPI?.signal,
  });
}

export function fetchDigitalFlashTopAlbums(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.digitalFlashTopAlbums}`, {
    method: "POST",
    body: JSON.stringify(params?.data),
    signal: thunkAPI?.signal,
  });
}

export function fetchDigitalFlashTopRetailers(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.digitalFlashTopRetailers}`, {
    method: "POST",
    body: JSON.stringify(params?.data),
    signal: thunkAPI?.signal,
  });
}
export function fetchDigitalFlashTopTerritories(params: any, thunkAPI?: any) {
  return request(`${USER_API_RESOURCE_BASE_URLS.digitalFlashTopTerritories}`, {
    method: "POST",
    body: JSON.stringify(params?.data),
    signal: thunkAPI?.signal,
  });
}
