import React from "react";
function ExclamationIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 26 26"
      fill="none"
      name="ExclamationIcon"
      {...props}
    >
      <circle cx="10" cy="10" r="10" />
      <path
        fill="#FFF"
        d="M9.655 16c.765 0 1.393-.582 1.393-1.317 0-.75-.628-1.332-1.393-1.332-.766 0-1.394.582-1.394 1.332 0 .735.628 1.317 1.394 1.317Zm-.888-4.467h1.807L10.91 4H8.43l.337 7.533Z"
      />
    </svg>
  );
}

export default ExclamationIcon;
