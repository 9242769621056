import { useEffect, useState, useCallback } from "react";
import { useAppSelector } from "../../../app/redux/hooks";
import { PeriodSelector } from "../../../app/molecules/periodSelector/periodSelectorSlice";
import withClientSelectionData from "../clientSelection/withClientSelectionData";
import HorizontalPeriodSelector from "../../../app/molecules/periodSelector/horizontalPeriodSelector/HorizontalPeriodSelector";
import styles from "./pub-sync-hoc.module.scss";

type IProps = {
  setSelectedPeriod: (selectedPeriod: number | null) => void;
  selectedPeriod: string;
};

const PubSyncPeriodSelector = (props: IProps): JSX.Element => {
  const { setSelectedPeriod, selectedPeriod } = props;

  const periodSelector = useAppSelector(PeriodSelector);
  const [visiblePeriods, setVisiblePeriods] = useState<Array<string>>([]);

  const isPeriodListReady = periodSelector.periods.length !== 0;

  useEffect(() => {
    if (isPeriodListReady) {
      const periodArray: Array<string> = [];
      periodSelector.periods.forEach((period) => {
        periodArray.push(period.periodStr);
      });
      setVisiblePeriods(periodArray);
    }
  }, [periodSelector.periods, isPeriodListReady]);

  const onSelectPeriod = useCallback(
    (period: string) => {
      let actualValue = null;
      if (period !== "") {
        const periodParts = period.split("/");
        actualValue = Number(periodParts[1] + periodParts[0]);
      }
      setSelectedPeriod(actualValue);
    },
    [setSelectedPeriod]
  );

  return isPeriodListReady ? (
    <div className={styles.horizontalPeriodSelection}>
      <HorizontalPeriodSelector
        dateArray={visiblePeriods}
        selectedPeriod={selectedPeriod}
        onPeriodSelect={onSelectPeriod}
      />
    </div>
  ) : (
    <></>
  );
};

export default withClientSelectionData(PubSyncPeriodSelector);
