import request from "../../../../app/utils/requestUtils";

import { ADMIN_API_RESOURCE_BASE_URLS } from "../../common/adminEndpoints.const";

export const getUsersList = (payload: any) => {
  return request(`${ADMIN_API_RESOURCE_BASE_URLS.users}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};
