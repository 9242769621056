import React from "react";

function CheckCircleIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      fill="none"
      name="CheckCircleIcon"
      {...props}
    >
      <path
        fill="#54A078"
        stroke="#54A078"
        strokeWidth=".3"
        d="M14.657 3.343A7.948 7.948 0 0 0 9 1a7.948 7.948 0 0 0-5.657 2.343A7.948 7.948 0 0 0 1 9c0 2.137.832 4.146 2.343 5.657A7.948 7.948 0 0 0 9 17a7.948 7.948 0 0 0 5.657-2.343A7.948 7.948 0 0 0 17 9a7.948 7.948 0 0 0-2.343-5.657ZM9 16.063A7.07 7.07 0 0 1 1.937 9 7.07 7.07 0 0 1 9 1.937 7.07 7.07 0 0 1 16.063 9 7.07 7.07 0 0 1 9 16.063Z"
      />
      <path
        fill="#54A078"
        stroke="#54A078"
        strokeWidth=".3"
        d="M12.822 6.433a.469.469 0 0 0-.663 0l-4.14 4.14-2.178-2.18a.469.469 0 0 0-.663.663l2.51 2.51a.467.467 0 0 0 .663 0l4.471-4.47a.469.469 0 0 0 0-.663Z"
      />
    </svg>
  );
}

export default CheckCircleIcon;
