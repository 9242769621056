import AnalysisSourceBreakdown from "../analysisSourceBreakdown/AnalysisSourceBreakdown";
import styles from "../analysis.module.scss";

interface RightSideProps {
  selectedSource: any;
  topTargetIsOnScreen: boolean;
  selectedPeriods: number[];
  selectedClients: number[];
  handleBreakdownClose?: (clickedSong: any | undefined) => void;
  documentType: "PUBLISHING" | "RECORDING";
}

const AnalysisSourceRightSide = (props: RightSideProps) => {
  const {
    selectedSource,
    topTargetIsOnScreen,
    selectedPeriods,
    selectedClients,
    handleBreakdownClose,
    documentType,
  } = props;

  return (
    <div
      className={`${styles.rightSide} ${selectedSource ? " md:flex-1" : ""}`}
    >
      {selectedSource && (
        <div
          className={`${styles.rightSideSelected} ${
            selectedSource ? styles.isOpen : ""
          } ${
            topTargetIsOnScreen
              ? ""
              : `${styles.isOpenFixed} ${styles.withSubtitle}`
          } `}
        >
          <AnalysisSourceBreakdown
            documentType={documentType}
            isSelected={selectedSource}
            sourceCode={
              documentType === "PUBLISHING"
                ? selectedSource?.sourceCode
                : undefined
            }
            sourceName={selectedSource.sourceName}
            periodIds={selectedPeriods}
            clientIds={selectedClients}
            mobileView={false}
            handleClose={handleBreakdownClose}
          />
        </div>
      )}
    </div>
  );
};

export default AnalysisSourceRightSide;
