import React from "react";
function ContactBigIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 21 14"
      name="ContactBigIcon"
      {...props}
    >
      <path d="M10.748 9.64h5.722a.339.339 0 0 0 0-.677h-5.722a.339.339 0 0 0 0 .677Zm0-2.326h5.722a.339.339 0 0 0 0-.677h-5.722a.339.339 0 0 0 0 .677Zm0-2.326h5.722a.339.339 0 0 0 0-.677h-5.722a.339.339 0 0 0 0 .677ZM18.838 0H.337A.338.338 0 0 0 0 .338v13.277c0 .187.151.339.339.339h18.498a.339.339 0 0 0 .339-.339V.338A.339.339 0 0 0 18.837 0ZM.677.677h17.82v12.6H.678V.677Zm1.718 10.828a.338.338 0 0 0 .405-.256c.21-.931.645-2.434.876-2.766.415-.597 1.144-.585 1.183-.585l1.034-.002c.007-.001.953-.096 1.368.605l.026.037c.25.316.72 1.842.919 2.713a.34.34 0 0 0 .66-.152c-.105-.457-.615-2.403-1.033-2.962-.64-1.051-1.958-.92-1.978-.916h-.163A2.404 2.404 0 0 0 7.79 4.845a2.409 2.409 0 0 0-4.816 0c0 1.22.914 2.22 2.092 2.376h-.193c-.044 0-1.121-.031-1.75.876-.373.534-.87 2.505-.982 3.004a.338.338 0 0 0 .256.404Zm1.253-6.66a1.731 1.731 0 0 1 3.462 0 1.732 1.732 0 0 1-3.462 0Z" />
    </svg>
  );
}

export default ContactBigIcon;
