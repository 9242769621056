import {
  DashboardListEnum,
  EnableSettingsEnum,
  LanguagesValuesEnum,
  MechanicalGroupOptionsEnum,
  PendingRequestTypeEnum,
  PublishingRoleEnum,
  RecordingRoleEnum,
  UserAccountTypeEnum,
} from "../../../app/types/enums";
import { SelectOptions, TableHeadProps } from "../../../app/types/props";

export const DEFAULT_ROWS_PER_PAGE = 10;
export const MAX_ROWS_PER_PAGE = 50;

export const ADMIN_API_RESOURCE_BASE_URLS = {
  groups: "/admin/groups",
  adminGroups: "/admin/admin-groups",
  adminAccounts: "/admin/admin-users",
  countries: "/admin/countries",
  activityLog: "/admin/activity-log",
  advanceRequest: "/admin/advance-request/settings",
  clients: "/admin/clients",
  maintenance: "/admin/maintenance-configuration",
  requestArchive: "/admin/access-requests/archived",
  requestArchiveEdit: "/admin/access-requests",
  emailFooters: {
    emailFooters: "/admin/email-footers",
    getRecordingOrMechanicalOwningSystems: "/admin/client-owning-sites",
    getPublishingOwningSystems: "/admin/client-and-footer-owning-systems",
  },
  news: "/admin/news",
  elasticsearch: "/admin/elasticsearch",
  misc: "/admin/misc",
  users: "/admin/users",
  impersonation: "/admin/impersonation",
  systemClosingDates: "/admin/system-closing-dates",
  systemClosingDatesOptions: "/admin/system-closing-dates/options",
  systemClosingDatesPreview: "/admin/system-closing-dates/preview",
  parents: "/admin/parents",
  parentsCurrency: "/admin/parents/currency",
  accessRequests: "/admin/access-requests",
  accessRequestsPending: "/admin/access-requests/pending",
  clientOwning: "/admin/client-owning",
  clientOwningSystems: "/admin/client-owning-systems",
  clientOwningSites: "/admin/client-owning-sites",
  clientCommunication: {
    clientCommunication: "/admin/client-communication",
    sendPreview: "/admin/client-communication/send-preview",
    publishingClients: "/admin/client-communication/publishing-clients",
    recordingClients: "/admin/client-communication/recording-clients",
    mechanicalClients: "/admin/client-communication/mechanical-clients",
  },
  companyCodes: "/admin/company-codes",
};

export const groupsLanguageOptions: SelectOptions = [
  { label: "termsOfUseModal.en", value: LanguagesValuesEnum.English },
  { label: "termsOfUseModal.en_uk", value: LanguagesValuesEnum.English_UK },
  { label: "termsOfUseModal.en_us", value: LanguagesValuesEnum.English_US },
  { label: "termsOfUseModal.de_de", value: LanguagesValuesEnum.German },
  { label: "termsOfUseModal.fr_fr", value: LanguagesValuesEnum.French },
  { label: "termsOfUseModal.sv_sv", value: LanguagesValuesEnum.Swedish },
];

export const languageOptions: SelectOptions = [
  { id: 1, label: "termsOfUseModal.en", value: LanguagesValuesEnum.English },
  {
    id: 6,
    label: "termsOfUseModal.en_us",
    value: LanguagesValuesEnum.English_US,
  },
  { id: 7, label: "termsOfUseModal.de_de", value: LanguagesValuesEnum.German },
  { id: 8, label: "termsOfUseModal.sp_sp", value: LanguagesValuesEnum.Spanish },
  { id: 9, label: "termsOfUseModal.it_it", value: LanguagesValuesEnum.Italian },
  {
    id: 10,
    label: "termsOfUseModal.pt_br",
    value: LanguagesValuesEnum.Portuguese,
  },
  {
    id: 11,
    label: "termsOfUseModal.fr_fr",
    value: LanguagesValuesEnum.French,
  },
];

export const userTypeOptions: SelectOptions = [
  {
    label: "contactUsGuest.accountTypes.admin",
    value: UserAccountTypeEnum.admin,
  },
  {
    label: "contactUsGuest.accountTypes.publishing",
    value: UserAccountTypeEnum.publishing,
  },
  {
    label: "contactUsGuest.accountTypes.recording",
    value: UserAccountTypeEnum.recording,
  },
  {
    label: "contactUsGuest.accountTypes.threesixty",
    value: UserAccountTypeEnum.threesixty,
  },
  {
    label: "contactUsGuest.accountTypes.mechanical",
    value: UserAccountTypeEnum.mechanical,
  },
];

export const enabledDisabledOptions: SelectOptions = [
  {
    label: "adminSection.common.group",
    value: EnableSettingsEnum.Group,
  },
  {
    label: "adminSection.common.enabled",
    value: EnableSettingsEnum.Enabled,
  },
  {
    label: "adminSection.common.disabled",
    value: EnableSettingsEnum.Disabled,
  },
];

export const enabledDisabledGroupEnabledOptions: SelectOptions = [
  {
    label: "adminSection.common.groupEnabled",
    value: EnableSettingsEnum.Group,
  },
  {
    label: "adminSection.common.enabled",
    value: EnableSettingsEnum.Enabled,
  },
  {
    label: "adminSection.common.disabled",
    value: EnableSettingsEnum.Disabled,
  },
];

export const enabledDisabledGroupDisabledOptions: SelectOptions = [
  {
    label: "adminSection.common.groupDisabled",
    value: EnableSettingsEnum.Group,
  },
  {
    label: "adminSection.common.enabled",
    value: EnableSettingsEnum.Enabled,
  },
  {
    label: "adminSection.common.disabled",
    value: EnableSettingsEnum.Disabled,
  },
];

export const publishingRoleOptions: SelectOptions = [
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.composer",
    value: PublishingRoleEnum.Composer,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.manager",
    value: PublishingRoleEnum.Manager,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.accountant",
    value: PublishingRoleEnum.Accountant,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.heir",
    value: PublishingRoleEnum.Heir,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.publisher",
    value: PublishingRoleEnum.Publisher,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.staff",
    value: PublishingRoleEnum.Staff,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.lawyer",
    value: PublishingRoleEnum.Lawyer,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.auditor",
    value: PublishingRoleEnum.Auditor,
  },
];

export const recordingRoleOptions: SelectOptions = [
  {
    label: "register.roles.artist",
    value: RecordingRoleEnum.Artist,
  },
  {
    label: "register.roles.producer",
    value: RecordingRoleEnum.Producer,
  },
  {
    label: "register.roles.mixer",
    value: RecordingRoleEnum.Mixer,
  },
  {
    label: "register.roles.engineer",
    value: RecordingRoleEnum.Engineer,
  },
  {
    label: "register.roles.manager",
    value: RecordingRoleEnum.Manager,
  },
  {
    label: "register.roles.accountant",
    value: RecordingRoleEnum.Accountant,
  },
  {
    label: "register.roles.lawyer",
    value: RecordingRoleEnum.Lawyer,
  },
  {
    label: "register.roles.auditor",
    value: RecordingRoleEnum.Auditor,
  },
  {
    label: "register.roles.staff",
    value: RecordingRoleEnum.Staff,
  },
  {
    label: "register.roles.other",
    value: RecordingRoleEnum.Other,
  },
];

export const dashboardOptions: SelectOptions = [
  {
    label: "adminSection.adminAccountsPage.dashboardOptions.publishing",
    value: DashboardListEnum.Publishing,
  },
  {
    label: "adminSection.adminAccountsPage.dashboardOptions.creative",
    value: DashboardListEnum.Creative,
  },
];

export const mechanicalGroupOptions: SelectOptions = [
  {
    label: "adminSection.adminAccountsPage.formLabels.mechanicalGroup",
    value: MechanicalGroupOptionsEnum.MechanicalGroup,
  },
];

export const groupsTableHeaderData: TableHeadProps = [
  {
    id: 1,
    title: `adminSection.common.groupsTableHeader.name`,
    filteringInfo: "name",
  },
  {
    id: 2,
    title: "adminSection.common.groupsTableHeader.description",
    filteringInfo: "description",
  },
  {
    id: 3,
    title: "adminSection.common.groupsTableHeader.type",
    filteringInfo: "",
  },
];

export const parentsFor = [
  {
    label: "contactUsGuest.accountTypes.publishing",
    value: PendingRequestTypeEnum.publishing,
  },
  {
    label: "contactUsGuest.accountTypes.recording",
    value: PendingRequestTypeEnum.recording,
  },
];
