import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/redux/hooks";
import { recClientSelectionSelector } from "../recroot/recrootSlice";
import Grid from "../../../app/atom/Grid";
import Tabs, { Tab } from "../../../app/molecules/tabs/Tabs";
import SyncOverviewIcon from "../../../app/atom/Icon/SyncOverviewIcon";
import SyncAllIcon from "../../../app/atom/Icon/SyncAllIcon";
import SyncQuotingIcon from "../../../app/atom/Icon/SyncQuotingIcon";
import SyncLicensingIcon from "../../../app/atom/Icon/SyncLicensingIcon";
import SyncReceivedIcon from "../../../app/atom/Icon/SyncReceivedIcon";
import SyncCompletedIcon from "../../../app/atom/Icon/SyncCompletedIcon";
import PageHeader from "../../../app/molecules/pageHeader/PageHeader";
import Loader from "../../../app/atom/Loader";
import ClientSelection from "../../../app/molecules/clientSelection/ClientSelection";
import { recSyncStatusSelector, recSyncOverviewSelector } from "./recSyncSlice";
import { TabsVariantEnum } from "../../../app/types/enums";
import type { RecSyncProps } from "../../../app/types/props";

const RecSyncHeaderWrapper = (props: RecSyncProps): JSX.Element => {
  const { selectedTab } = props;

  const { t } = useTranslation();
  const clientSelection = useAppSelector(recClientSelectionSelector);
  const pageStatus = useAppSelector(recSyncStatusSelector);
  const recSyncOverview = useAppSelector(recSyncOverviewSelector || []);

  const getlicensesByStatusCount = useMemo(
    () => (licenses: any[], status: string) => {
      const license = licenses?.find(
        (license: { status: string; count: number }) =>
          license.status === status
      );
      return license?.count || 0;
    },
    []
  );

  const MemoTabsComponent = memo(() => (
    <Tabs
      id="tabs-container"
      variant={TabsVariantEnum.boxedTabs}
      selectedTabIndex={selectedTab}
    >
      <Tab
        linkTo="/mybmg/user/recording/sync/overview"
        title={
          <>
            <SyncOverviewIcon />
            <span>{t("sync.overview")}</span>
            <p className="h-5"></p>
          </>
        }
      />
      <Tab
        disabled={
          getlicensesByStatusCount(
            recSyncOverview.licensesByStatus || [],
            "All"
          ) === 0
        }
        linkTo="/mybmg/user/recording/sync/all"
        title={
          <>
            <SyncAllIcon />
            <span>{t("sync.all")}</span>
            <p>
              {getlicensesByStatusCount(
                recSyncOverview.licensesByStatus || [],
                "All"
              )}
            </p>
          </>
        }
      />
      <Tab
        disabled={
          getlicensesByStatusCount(
            recSyncOverview.licensesByStatus || [],
            "Quoting"
          ) === 0
        }
        linkTo="/mybmg/user/recording/sync/quoting"
        title={
          <>
            <SyncQuotingIcon />
            <span>{t("sync.quoting")}</span>
            <p>
              {getlicensesByStatusCount(
                recSyncOverview.licensesByStatus || [],
                "Quoting"
              )}
            </p>
          </>
        }
      />
      <Tab
        disabled={
          getlicensesByStatusCount(
            recSyncOverview.licensesByStatus || [],
            "Licensing"
          ) === 0
        }
        linkTo="/mybmg/user/recording/sync/licensing"
        title={
          <>
            <SyncLicensingIcon />
            <span>{t("sync.licensing")}</span>
            <p>
              {getlicensesByStatusCount(
                recSyncOverview.licensesByStatus || [],
                "Licensing"
              )}
            </p>
          </>
        }
      />
      <Tab
        disabled={
          getlicensesByStatusCount(
            recSyncOverview.licensesByStatus || [],
            "Received"
          ) === 0
        }
        linkTo="/mybmg/user/recording/sync/received"
        title={
          <>
            <SyncReceivedIcon />
            <span>{t("sync.received")}</span>
            <p>
              {getlicensesByStatusCount(
                recSyncOverview.licensesByStatus || [],
                "Received"
              )}
            </p>
          </>
        }
      />
      <Tab
        disabled={
          getlicensesByStatusCount(
            recSyncOverview.licensesByStatus || [],
            "Completed"
          ) === 0
        }
        linkTo="/mybmg/user/recording/sync/completed"
        title={
          <>
            <SyncCompletedIcon />
            <span>{t("sync.completed")}</span>
            <p>
              {getlicensesByStatusCount(
                recSyncOverview.licensesByStatus || [],
                "Completed"
              )}
            </p>
          </>
        }
      />
    </Tabs>
  ));

  const addClassName =
    props.selectedTab === 0 ? { className: "p-5 sm:p-8 sm:py-6" } : undefined;

  return (
    <Grid>
      {/* Loader */}
      {clientSelection.selectedClientsStatus === "loading" && <Loader />}
      <div className={`col-span-full`}>
        <PageHeader
          documentType="RECORDING"
          pageTitle={t("sync.pageTitle")}
          selectClientsBtn={
            <ClientSelection
              isLoading={pageStatus === "loading"}
              type="SelectClients"
              documentType="RECORDING"
            />
          }
          createANewListBtn={
            <ClientSelection
              isLoading={pageStatus === "loading"}
              type="CreateANewList"
              documentType="RECORDING"
            />
          }
          viewAndEditListBtn={
            <ClientSelection
              type="ViewAndEditCurrentClientList"
              documentType="RECORDING"
            />
          }
          viewAndEditBtn={
            <ClientSelection
              type="ViewAndEditClientSelection"
              documentType="RECORDING"
            />
          }
          clientSelection={clientSelection}
        />
        <MemoTabsComponent />
        <div {...addClassName}>{props.children}</div>
      </div>
    </Grid>
  );
};

export default RecSyncHeaderWrapper;
