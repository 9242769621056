import { AdminHeaderProps, TableHeadProps } from "../../../../app/types/props";
import {
  ClientCommunicationTypeEnum,
  Publishing_RecordingRole,
} from "../../../../app/types/enums";

export const MAX_TOTAL_SIZE_10 = 10 * 1024 * 1024; // 10 MB

export const MAX_TOTAL_SIZE_15 = 15 * 1024 * 1024; // 15 MB

export const allowedExtensions = [
  ".bmp",
  ".doc",
  ".docx",
  ".jpg",
  ".jpeg",
  ".gif",
  ".pdf",
  ".png",
  ".ppt",
  ".pptx",
  ".xls",
  ".xlsx",
];

export const clientCommunicationHeaderData: AdminHeaderProps = {
  titleData: {
    text: "adminSection.clientCommunication.title",
  },
};

export const paymentDays = [
  { value: "ALL_OPTIONS", label: "All", checked: true },
  { value: "THIRTY_DAYS", label: "30 Days", checked: false },
  { value: "FORTYFIVE_DAYS", label: "45 Days", checked: false },
  { value: "SIXTY_DAYS", label: "60 Days", checked: false },
  { value: "NINETY_DAYS", label: "90 Days", checked: false },
];

export const paymentCycle = [
  { value: "ALL_OPTIONS", label: "All", checked: true },
  { value: "ONE_MONTHS", label: "1 Month", checked: false },
  { value: "THREE_MONTHS", label: "3 Months", checked: false },
  { value: "SIX_MONTHS", label: "6 Months", checked: false },
];

export const chooseSystemOptions = [
  {
    label: "adminSection.clientCommunication.all",
    value: ClientCommunicationTypeEnum.all,
  },
  {
    label: "contactUsGuest.accountTypes.publishing",
    value: ClientCommunicationTypeEnum.publishing,
  },
  {
    label: "contactUsGuest.accountTypes.recording",
    value: ClientCommunicationTypeEnum.recording,
  },
  {
    label: "contactUsGuest.accountTypes.mechanical",
    value: ClientCommunicationTypeEnum.mechanical,
  },
];

export const chooseIntegratedSystemOptions = [
  {
    label: "contactUsGuest.accountTypes.publishing",
    value: ClientCommunicationTypeEnum.publishing,
  },
  {
    label: "contactUsGuest.accountTypes.recording",
    value: ClientCommunicationTypeEnum.recording,
  },
  {
    label: "contactUsGuest.accountTypes.mechanical",
    value: ClientCommunicationTypeEnum.mechanical,
  },
];

export const publishingTableHeaderData: TableHeadProps = [
  {
    id: 1,
    title: "adminSection.clientCommunication.table.clientId",
    filteringInfo: "clientId",
  },
  {
    id: 2,
    title: "adminSection.clientCommunication.table.clientName",
    filteringInfo: "clientName",
  },
  {
    id: 3,
    title: "adminSection.clientCommunication.table.user",
    filteringInfo: "username",
  },
  {
    id: 4,
    title: "adminSection.clientCommunication.table.email",
    filteringInfo: "userEmail",
  },
  {
    id: 5,
    title: "adminSection.clientCommunication.table.names",
    filteringInfo: "userFirstName",
  },
  {
    id: 6,
    title: "adminSection.clientCommunication.table.publishingRole",
    filteringInfo: "publishingRole",
  },
];

export const recordingTableHeaderData: TableHeadProps = [
  {
    id: 1,
    title: "adminSection.clientCommunication.table.clientKey",
    filteringInfo: "clientKey",
  },
  {
    id: 2,
    title: "adminSection.clientCommunication.table.clientCode",
    filteringInfo: "clientCode",
  },
  {
    id: 3,
    title: "adminSection.clientCommunication.table.clientName",
    filteringInfo: "clientName",
  },
  {
    id: 4,
    title: "adminSection.clientCommunication.table.user",
    filteringInfo: "username",
  },
  {
    id: 5,
    title: "adminSection.clientCommunication.table.email",
    filteringInfo: "userEmail",
  },
  {
    id: 6,
    title: "adminSection.clientCommunication.table.names",
    filteringInfo: "userFirstName",
  },
  {
    id: 7,
    title: "adminSection.clientCommunication.table.recordingRole",
    filteringInfo: "recordingRole",
  },
];

export const mechanicalTableHeaderData: TableHeadProps = [
  {
    id: 1,
    title: "adminSection.clientCommunication.table.clientKey",
    filteringInfo: "clientKey",
  },
  {
    id: 2,
    title: "adminSection.clientCommunication.table.clientCode",
    filteringInfo: "clientCode",
  },
  {
    id: 3,
    title: "adminSection.clientCommunication.table.clientName",
    filteringInfo: "clientName",
  },
  {
    id: 4,
    title: "adminSection.clientCommunication.table.user",
    filteringInfo: "username",
  },
  {
    id: 5,
    title: "adminSection.clientCommunication.table.email",
    filteringInfo: "userEmail",
  },
  {
    id: 6,
    title: "adminSection.clientCommunication.table.names",
    filteringInfo: "userFirstName",
  },
];

export const recordingRoleOptions = [
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.composer",
    value: Publishing_RecordingRole.COMPOSER_ARTIST,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.manager",
    value: Publishing_RecordingRole.MANAGER,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.accountant",
    value: Publishing_RecordingRole.ACCOUNTANT,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.publisher",
    value: Publishing_RecordingRole.PUBLISHER,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.lawyer",
    value: Publishing_RecordingRole.LAWYER,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.auditor",
    value: Publishing_RecordingRole.AUDITOR,
  },
  { label: "register.roles.artist", value: Publishing_RecordingRole.ARTIST },
  {
    label: "register.roles.producer",
    value: Publishing_RecordingRole.PRODUCER,
  },
  {
    label: "register.roles.engineer",
    value: Publishing_RecordingRole.ENGINEER,
  },
  {
    label: "adminSection.adminAccountsPage.publishingRoleOptions.staff",
    value: Publishing_RecordingRole.STAFF,
  },
  { label: "register.roles.other", value: Publishing_RecordingRole.OTHER },
];
