import { USER_API_RESOURCE_BASE_URLS } from "../../../../app/config/userEndpoints.const";
import request from "../../../../app/utils/requestUtils";

export type RecAnalysisSourceBreakdownParams = {
  sourceCode?: string;
  sourceName: string;
  clientIds: number[];
  clientLists?: {
    id: string;
    type: "static";
  }[];
  periodIds: number[];
};

export type RecAnalysisSourceBreakdownTypeTrendTerritoryParams = {
  isSelected: boolean;
} & RecAnalysisSourceBreakdownParams;

export type RecAnalysisSourceDataEntry = {
  currency: string;
  raw: number;
  formattedShort: string;
  formattedLong: string;
  formattedPercent: string;
  percent: number;
};

export type RecAnalysisSourceByTerritoryEntry = {
  name: string;
  iso: string;
  revenue: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
  zero: boolean;
};

export type RecAnalysisSourceByTypeEntry = {
  mainIncType: string;
  categoryName: string;
  color: string;
  revenue: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
};

export type RecAnalysisSourceRoyaltiesPerPeriodOrYearEntry = {
  periodOrYear: string;
  royalty: RecAnalysisSourceDataEntry;
  zero: boolean;
};

export type RecAnalysisSourceData = {
  sourceCode?: string;
  sourceName: string;
  perYear?: boolean;
  totalRoyalty: RecAnalysisSourceDataEntry | null;
  incomeTypes?: RecAnalysisSourceByTypeEntry[] | null;
  sourcesByTerritory?: RecAnalysisSourceByTerritoryEntry[] | null;
  royaltiesPerPeriodOrYear?:
    | RecAnalysisSourceRoyaltiesPerPeriodOrYearEntry[]
    | null;
};

export function FetchAnalysisSourceBreakdown_Type(
  params: RecAnalysisSourceBreakdownParams,
  thunkAPI?: any
): Promise<RecAnalysisSourceData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.recordingSourcesChannels}?sourceName=${params.sourceName}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisSourceBreakdown_Territory(
  params: RecAnalysisSourceBreakdownParams,
  thunkAPI?: any
): Promise<RecAnalysisSourceData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.recordingSourcesTerritories}?sourceName=${params.sourceName}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisSourceBreakdown_Trend(
  params: RecAnalysisSourceBreakdownParams,
  thunkAPI?: any
): Promise<RecAnalysisSourceData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.recordingSourcesTrends}?sourceName=${params.sourceName}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function downloadSourcesBreakdownData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.analysisDownload}?clientDocumentType=RECORDING`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
      isDownload: true,
      fileName,
    }
  );
}
