import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/redux/store";
import {
  fetchMessages,
  softDeleteMessage,
  markMessageAsRead,
} from "./messageAPI";

export interface MessageState {
  isMessageOpen: boolean;
  messages: {
    data: Array<any>;
    total: number;
  };
}

const initialState: MessageState = {
  isMessageOpen: false,
  messages: {
    data: [],
    total: 0,
  },
};

export const fetchMessagesThunk = createAsyncThunk(
  "message/fetchmessages",
  async (payload, thunkAPI?: any) => {
    const response = await fetchMessages(thunkAPI);
    return response;
  }
);

export const softDeleteMessageThunk = createAsyncThunk(
  "message/softdeletemessage",
  async (payload: any, thunkAPI?: any) => {
    const response = await softDeleteMessage(payload, thunkAPI);
    return response;
  }
);

export const markMessageAsReadThunk = createAsyncThunk(
  "message/markmessageasread",
  async (payload: any, thunkAPI?: any) => {
    const response = await markMessageAsRead(payload, thunkAPI);
    return response;
  }
);

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessageState: (state, action: any) => {
      state.isMessageOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessagesThunk.fulfilled, (state, action: any) => {
        state.messages = action.payload;
      })
      .addCase(softDeleteMessageThunk.fulfilled, (state, action: any) => {
        state.messages.data = state.messages.data.filter(
          (message) => message.id !== action.payload.id
        );
      })
      .addCase(markMessageAsReadThunk.fulfilled, (state, action: any) => {
        const index = state.messages.data.findIndex(
          (message) => message.id === action.payload.id
        );

        state.messages.data[index].read = true;
      });
  },
});

export const isMessageOpenSelector = (state: RootState) =>
  state.message.isMessageOpen;
export const messagesSelector = (state: RootState) => state.message.messages;

export const { setMessageState: setMessageStateAction } = messageSlice.actions;

export default messageSlice.reducer;
