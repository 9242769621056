import Image from "../Image";

const SortDownBGIcon = (
  <Image
    alt=""
    className="SortDownBGIcon h-full"
    src={`data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbi10cmlhbmdsZSIgd2lkdGg9IjhweCIgaGVpZ2h0PSI1cHgiIHZpZXdCb3g9IjAgMCA4IDUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjOTk5Ij4KICAgIDx0aXRsZT50cmlhbmdsZS1kb3duLXJlZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+CiAgICA8ZGVmcy8+CiAgICA8ZyBpZD0idHJpYW5nbGUtZG93bi1yZWQiIGNsYXNzPSJzdmctZmlsbCI+CiAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlLXJlZCIgcG9pbnRzPSIwIDAgNy40MDEzMjkxOSAwIDMuNzAwNjY0NTkgNSIvPgogICAgPC9nPgo8L3N2Zz4=`}
  />
);

export default SortDownBGIcon;
