export const ROUTES = {
  error: "error",
  mybmg: "mybmg",
  login: "login",
  request: "request",
  account: "account",
  forgot: "forgot",
  username: "username",
  resetpassword: "resetpassword",
  password: "password",
  setpassword: "setpassword",
  contactUs: "contactUs",
  admin: "admin",
  pending: "pending",
  archive: "archive",
  user: "user",
  users: "users",
  clients: "clients",
  parents: "parents",
  bulkImport: "bulk-import",
  usergroups: "usergroups",
  admins: "admins",
  admingroups: "admingroups",
  editPage: "edit",
  add: "add",
  addById: "add/:id",
  edit: "edit/:id",
  maintenance: "maintenance",
  news: "news",
  activitylog: "activitylog",
  duedates: "duedates",
  communication: "communication",
  footers: "footers",
  advancerequest: "advancerequest",
  elasticsearch: "elasticsearch",
  misc: "misc",
  help: "help",
  combdashboard: "combdashboard",
  dashboard: "dashboard",
  analysis: "analysis",
  territories: "territories",
  songs: "songs",
  sources: "sources",
  digital: "digital",
  type: "type",
  tracks: "tracks",
  albums: "albums",
  distchannels: "distchannels",
  sync: "sync",
  overview: "overview",
  all: "all",
  quoting: "quoting",
  licensing: "licensing",
  received: "received",
  completed: "completed",
  contacts: "contacts",
  contact: "contact",
  songregistration: "songregistration",
  statements: "statements",
  taxdocuments: "taxdocuments",
  welcometour: "welcometour",
  publishing: "publishing",
  recording: "recording",
  mechanical: "mechanical",
  styleGuide: "style-guide",
  privacypolicy: "privacypolicy",
  disclaimer: "disclaimer",
  termsofuse: "termsofuse",
  internalError: "internal-error",
  forbidden: "forbidden",
  more: "more",
  settings: "settings",
  digitalflash: "digitalflash",
};
