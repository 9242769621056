import React, { useEffect, useCallback, useState } from "react";
import { Sidebar } from "../../app/molecules/sidebar";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/redux/hooks";
import * as Icons from "./sidebarIcons";
import * as Paths from "../routes/paths.const";
import {
  sidebarPermissionsStatusSelector,
  sidebarPermissionsSelector,
} from "../appMain/appMainSlice";
import SiteLayoutWrapper from "../common/SiteLayoutWrapper/SiteLayoutWrapper";
import type { SidebarMenuProps } from "../../app/types/props";
import type { RoutePermissions, RoutePermissionGroup } from "./types";

const SiteLayout = ({
  children,
  requiredPermission,
  permissionGroup,
}: {
  children: React.ReactNode;
  requiredPermission?: RoutePermissions | RoutePermissions[];
  permissionGroup?: RoutePermissionGroup;
}): JSX.Element => {
  const navigate = useNavigate();
  const sidebarPermissions = useAppSelector(sidebarPermissionsSelector);
  const sidebarPermStatus = useAppSelector(sidebarPermissionsStatusSelector);

  const [hasError, setHasError] = useState(false);
  const [filteredMenus, setFilteredMenus] = useState<SidebarMenuProps | null>(
    null
  );

  const buildMainMenuByPermissions = useCallback(() => {
    const menuArrPUB: SidebarMenuProps = [];
    const menuArrREC: SidebarMenuProps = [];
    const menuArrMEC: SidebarMenuProps = [];
    const menuArrMORE: SidebarMenuProps = [];
    const menuArr360: SidebarMenuProps = [];
    const menuArrALL: SidebarMenuProps = [];
    const menuLogout: SidebarMenuProps = [];

    // DASHBOARD_LINK = green squared icon
    // DASHBOARD_TITLE = colored icon

    // fill threesixty
    if (sidebarPermissions?.threeSixty?.includes("DASHBOARD_TITLE")) {
      menuArr360.push({
        i18nKey: "COMBINED_DASHBOARD",
        link: Paths.PATH_COMBINED_DASHBOARD,
        icon: Icons.DashboardSidebarIcon,
        isDashboard: false, // keep false, otherwise icon will get filled with a color
      });
    }

    // fill publishing
    if (sidebarPermissions?.publishing?.includes("DASHBOARD_LINK")) {
      menuArrPUB.push({
        i18nKey: "DASHBOARD",
        link: Paths.PATH_PUB_DASHBOARD,
        icon: Icons.DashboardSidebarIcon,
        isDashboard: true,
      });
    }
    if (sidebarPermissions?.publishing?.includes("DASHBOARD_TITLE")) {
      menuArrPUB.push({
        i18nKey: "PUBLISHING_DASHBOARD_TITLE",
        link: Paths.PATH_PUB_DASHBOARD,
        icon: Icons.PubDashSidebarIcon,
        isDashboard: true,
      });
    }
    if (sidebarPermissions?.publishing?.includes("ANALYSIS_LINK")) {
      menuArrPUB.push({
        i18nKey: "PUBLISHING_ANALYSIS_LINK",
        link: Paths.PATH_PUB_ANALYSIS_SONGS,
        icon: Icons.FinancialSidebarIcon,
      });
    }
    if (sidebarPermissions?.publishing?.includes("SYNC_LICENSING_LINK")) {
      menuArrPUB.push({
        i18nKey: "PUBLISHING_SYNC_LICENSING_LINK",
        link: Paths.PATH_PUB_SYNC_OVERVIEW,
        icon: Icons.SyncSidebarIcon,
      });
    }
    if (sidebarPermissions?.publishing?.includes("SONG_REGISTRATION_LINK")) {
      menuArrPUB.push({
        i18nKey: "PUBLISHING_SONG_REGISTRATION_LINK",
        link: Paths.PATH_PUB_REGISTRATION,
        icon: Icons.RegistrationSidebarIcon,
      });
    }
    if (sidebarPermissions?.publishing?.includes("STATEMENTS_LINK")) {
      menuArrPUB.push({
        i18nKey: "PUBLISHING_STATEMENTS_LINK",
        link: Paths.PATH_PUB_STATEMENTS,
        icon: Icons.StatementsSidebarIcon,
      });
    }
    if (sidebarPermissions?.publishing?.includes("OVERVIEW_LINK")) {
      menuArrPUB.push({
        i18nKey: "PUBLISHING_OVERVIEW_LINK",
        link: Paths.PATH_PUB_OVERVIEW,
        icon: Icons.OverviewSidebarIcon,
      });
    }
    if (sidebarPermissions?.publishing?.includes("CLIENTS_LINK")) {
      menuArrPUB.push({
        i18nKey: "PUBLISHING_CLIENTS_LINK",
        link: Paths.PATH_PUB_CLIENTS,
        icon: Icons.ClientsSidebarIcon,
      });
    }
    if (sidebarPermissions?.publishing?.includes("CONTACT_ITEM")) {
      menuArrPUB.push({
        i18nKey: "PUBLISHING_CONTACT_ITEM",
        link: Paths.PATH_PUB_CONTACTS,
        icon: Icons.ContactBigSidebarIcon,
      });
    }

    // fill recording
    // unused permission: DIGITAL_FLASH_LINK
    if (sidebarPermissions?.recording?.includes("DASHBOARD_LINK")) {
      menuArrREC.push({
        i18nKey: "DASHBOARD",
        link: Paths.PATH_REC_DASHBOARD,
        icon: Icons.RecDashSidebarIcon,
        isDashboard: true,
      });
    }
    if (sidebarPermissions?.recording?.includes("DASHBOARD_TITLE")) {
      menuArrREC.push({
        i18nKey: "RECORDING_DASHBOARD_TITLE",
        link: Paths.PATH_REC_DASHBOARD,
        icon: Icons.RecDashSidebarIcon,
        isDashboard: true,
      });
    }
    if (sidebarPermissions?.recording?.includes("ANALYSIS_LINK")) {
      menuArrREC.push({
        i18nKey: "RECORDING_ANALYSIS_LINK",
        link: Paths.PATH_REC_ANALYSIS_TRACKS,
        icon: Icons.FinancialSidebarIcon,
      });
    }
    if (sidebarPermissions?.recording?.includes("SYNC_LICENSING_LINK")) {
      menuArrREC.push({
        i18nKey: "RECORDING_SYNC_LICENSING_LINK",
        link: Paths.PATH_REC_SYNC_OVERVIEW,
        icon: Icons.SyncSidebarIcon,
      });
    }
    if (sidebarPermissions?.recording?.includes("DIGITAL_FLASH_LINK")) {
      menuArrREC.push({
        i18nKey: "RECORDING_DIGITAL_FLASH",
        link: Paths.PATH_ERC_DIGITAL_FLASH,
        icon: Icons.DigitalFlashSidebarIcon,
      });
    }
    // if (sidebarPermissions?.recording?.includes("JOURNAL_LINK")) {
    //   menuArrREC.push({
    //     i18nKey: "RECORDING_JOURNAL_LINK",
    //     link: "/",
    //     icon: Icons.RecJournalSidebarIcon,
    //   });
    // }
    if (sidebarPermissions?.recording?.includes("STATEMENTS_LINK")) {
      menuArrREC.push({
        i18nKey: "RECORDING_STATEMENTS_LINK",
        link: Paths.PATH_REC_STATEMENTS,
        icon: Icons.StatementsSidebarIcon,
      });
    }
    if (sidebarPermissions?.recording?.includes("OVERVIEW_LINK")) {
      menuArrREC.push({
        i18nKey: "RECORDING_OVERVIEW_LINK",
        link: Paths.PATH_REC_OVERVIEW,
        icon: Icons.OverviewSidebarIcon,
      });
    }
    if (sidebarPermissions?.recording?.includes("CONTACT_ITEM")) {
      menuArrREC.push({
        i18nKey: "RECORDING_CONTACT_ITEM",
        link: Paths.PATH_REC_CONTACTS,
        icon: Icons.ContactBigSidebarIcon,
      });
    }

    // fill mechanical
    if (sidebarPermissions?.mechanical?.includes("DASHBOARD_LINK")) {
      menuArrMEC.push({
        i18nKey: "DASHBOARD",
        link: Paths.PATH_MEC_DASHBOARD,
        icon: Icons.DashboardSidebarIcon,
        isDashboard: true,
      });
    }
    if (sidebarPermissions?.mechanical?.includes("DASHBOARD_TITLE")) {
      menuArrMEC.push({
        i18nKey: "MECHANICAL_DASHBOARD_TITLE",
        link: Paths.PATH_MEC_DASHBOARD,
        icon: Icons.MechDashSidebarIcon,
        isDashboard: true,
      });
    }
    if (sidebarPermissions?.mechanical?.includes("STATEMENTS_LINK")) {
      menuArrMEC.push({
        i18nKey: "MECHANICAL_STATEMENTS_LINK",
        link: Paths.PATH_MEC_STATEMENTS,
        icon: Icons.StatementsSidebarIcon,
      });
    }
    if (sidebarPermissions?.mechanical?.includes("CONTACT_ITEM")) {
      menuArrMEC.push({
        i18nKey: "MECHANICAL_CONTACT_ITEM",
        link: Paths.PATH_MEC_CONTACTS,
        icon: Icons.ContactBigSidebarIcon,
      });
    }

    // more contact not yet implemented, because it is never used?
    if (sidebarPermissions?.more?.includes("CONTACT_ITEM")) {
      menuArrMORE.push({
        i18nKey: "MORE_CONTACT",
        link: Paths.PATH_MORE_CONTACT,
        icon: Icons.ContactBigSidebarIcon,
        more: true,
      });
    }
    if (sidebarPermissions?.more?.includes("SETTINGS_LINK")) {
      menuArrMORE.push({
        i18nKey: "MORE_SETTINGS_LINK",
        link: Paths.PATH_SETTINGS,
        icon: Icons.SettingSidebarIcon,
        more: true,
      });
    }
    if (sidebarPermissions?.more?.includes("HELP_LINK")) {
      menuArrMORE.push({
        i18nKey: "MORE_HELP_LINK",
        link: Paths.PATH_HELP,
        more: true,
        icon: Icons.HelpSidebarIcon,
      });
    }
    if (sidebarPermissions?.more?.includes("ADMIN")) {
      menuArrMORE.push({
        i18nKey: "MORE_ADMIN",
        link: Paths.PATH_MORE_ADMIN,
        more: true,
        icon: Icons.AdminSidebarIcon,
      });
    }
    menuLogout.push({
      i18nKey: "MORE_LOGOUT_LINK",
      more: true,
      icon: Icons.LogoutSidebarIcon,
      action: "logOut",
    });

    // set dividers to each first item in array
    if (menuArrPUB?.length > 0) menuArrPUB[0].divider = true;
    if (menuArrREC?.length > 0) menuArrREC[0].divider = true;
    if (menuArrMEC?.length > 0) menuArrMEC[0].divider = true;
    if (menuArr360?.length > 0) menuArr360[0].divider = true;
    if (menuArrMORE?.length > 0) menuArrMORE[0].moreDivider = true;

    // merge all menu arrays
    const completeMenuArr = menuArrALL.concat(
      menuArr360,
      menuArrPUB,
      menuArrREC,
      menuArrMEC,
      menuArrMORE,
      menuLogout
    );
    setFilteredMenus(completeMenuArr);
  }, [sidebarPermissions]);

  const validatePermission = useCallback(() => {
    let hasPermission = true;

    if (requiredPermission !== undefined && permissionGroup !== undefined) {
      const permissionsArray: RoutePermissions[] = Array.isArray(
        requiredPermission
      )
        ? requiredPermission
        : [requiredPermission as RoutePermissions];

      hasPermission =
        permissionsArray?.some((permission) => {
          return (
            sidebarPermissions?.[permissionGroup]?.includes(permission) || false
          );
        }) || false;
    }
    if (!hasPermission) {
      navigate(Paths.PATH_ERROR403, { replace: true });
      return;
    }
    buildMainMenuByPermissions();
  }, [
    buildMainMenuByPermissions,
    navigate,
    permissionGroup,
    requiredPermission,
    sidebarPermissions,
  ]);

  useEffect(() => {
    if (sidebarPermissions !== null) {
      if (filteredMenus === null) validatePermission();
      setHasError(false);
    }
    if (sidebarPermStatus === "failed") {
      setHasError(true);
    }
  }, [
    filteredMenus,
    sidebarPermStatus,
    sidebarPermissions,
    validatePermission,
  ]);

  return (
    <SiteLayoutWrapper
      sidebarComponent={Sidebar}
      children={children}
      menus={hasError ? [] : filteredMenus}
    />
  );
};

export default SiteLayout;
