/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip as TooltipLib } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import type { TooltipProps } from "../../types/props";
import styles from "./tooltip.module.scss";

// https://react-tooltip.com/docs/options#available-props
const Tooltip = (props: TooltipProps): JSX.Element => {
  const { text, className, id, withIcon = true } = props;

  return (
    <>
      <a
        data-tooltip-id={id}
        data-tooltip-content={text}
        data-tooltip-place="bottom"
        data-tooltip-position-strategy="fixed"
        className={className !== undefined ? `tooltip ${className}` : `tooltip`}
      >
        {withIcon && <i className="tooltip-icon"></i>}
      </a>
      <TooltipLib id={id} className={`${styles.tooltipBmg}`} />
    </>
  );
};

export default Tooltip;
