import { useEffect, useCallback, useState, useMemo, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { useTranslation } from "react-i18next";
import {
  updateSelectedCurrencyAction,
  selectedCurrencySelector,
  meSelector,
} from "../../../features/appMain/appMainSlice";
import * as pubSlice from "../../../features/publishing/pubroot/pubrootSlice";
import * as pubClientSlice from "../../../features/publishing/clients/clientsSlice";
import * as recSlice from "../../../features/recording/recroot/recrootSlice";
import * as mecSlice from "../../../features/mechanical/mecroot/mecrootSlice";
import { ChevronIcon } from "../../atom/Icon";
import deepCompare from "../../utils/deepCompare";
import debounce from "../../utils/debounce";
import { ButtonVariantEnum } from "../../types/enums";
import { EditIcon } from "../../atom/Icon";
import type {
  ClientSelectionStateProps,
  clientSelectionTabs,
  ClientSelectionTypes,
} from "../../types/props";
import SelectClientsBtn from "../selectClientsBtn/selectClientsBtn";
import Link from "../../atom/Link/Link";

type appStateProps = {
  button: JSX.Element | null;
  showTab: clientSelectionTabs | null;
  editListName: string | undefined;
  showDataBtnText: string;
  isDeleteBtnVisible: boolean;
  titleTooltipText: string;
  containerClassName: string;
};

const useClientSelection = ({
  documentType,
  type,
  editListName,
  editListId = undefined,
  page = undefined,
  editListType = undefined,
}: {
  editListName: string | undefined;
  documentType: "PUBLISHING" | "RECORDING" | "MECHANICAL" | "NONE";
  type: ClientSelectionTypes;
  page?: "clientsPage" | "other" | undefined;
  editListId?: number | undefined;
  editListType?: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const rootSelector = useMemo(() => {
    let rootSlice: any = pubSlice;
    let rootClientSelector = pubSlice.pubClientSelectionSelector;
    switch (documentType) {
      case "RECORDING":
        rootSlice = recSlice;
        rootClientSelector = recSlice.recClientSelectionSelector;
        break;
      case "MECHANICAL":
        rootSlice = mecSlice;
        rootClientSelector = mecSlice.mecClientSelectionSelector;
        break;
    }
    return { rootSlice, rootClientSelector };
  }, [documentType]);

  const clientSelection: ClientSelectionStateProps = useAppSelector(
    rootSelector.rootClientSelector
  );

  const { total: allClientsTotal } = useAppSelector(
    pubClientSlice.allClientsSelector
  );

  const selectedCurrency = useAppSelector(selectedCurrencySelector);

  const {
    publishingCurrencyId,
    recordingCurrencyId,
    mechanicalCurrencyId,
    publishingClients,
    recordingClients,
    mechanicalClients,
    publishingCurrencies,
    recordingCurrencies,
    mechanicalCurrencies,
  } = useAppSelector(meSelector);

  const currencies =
    documentType === "PUBLISHING"
      ? publishingCurrencies
      : documentType === "RECORDING"
      ? recordingCurrencies
      : mechanicalCurrencies;

  const [tabAllClientsStart, setTabAllClientsStart] = useState(0);

  const [appState, setAppState] = useState<appStateProps>({
    button: null,
    showTab: null,
    editListName: editListName,
    showDataBtnText: t("clientSelection.showData"),
    isDeleteBtnVisible: false,
    titleTooltipText: t("clientSelection.tooltipTextSelectOrSave"),
    containerClassName: "",
  });
  const [modalOpen, setModalOpen] = useState(false);

  const [tabAllClients_sortColumn, settabAllClients_SortColumn] = useState("");
  const [tabAllClients_isSortAsc, settabAllClients_IsSortAsc] = useState(true);
  const [tabAllClients_filterText, settabAllClients_FilterText] = useState("");
  const [tabParents_sortColumn, settabParents_SortColumn] = useState("");
  const [tabParents_isSortAsc, settabParents_IsSortAsc] = useState(true);
  const [tabParents_filterText, settabParents_FilterText] = useState("");

  const dispatchedParents = useRef<any>();
  const dispatchedClients = useRef<any>();
  const clientsFetched = useRef<boolean>(false);

  const setSelectedCurrency = useCallback(
    (currency: { id: any; name: string }) => {
      setTabAllClientsStart(0);
      dispatch(updateSelectedCurrencyAction(currency));
    },
    [dispatch]
  );

  // abort functions
  const abortParents = useCallback(() => {
    if (dispatchedParents.current) {
      dispatchedParents.current?.abort();
    }
  }, []);
  const abortClients = useCallback(() => {
    if (dispatchedClients.current) {
      dispatchedClients.current?.abort();
    }
  }, []);

  const setInitialCurrencySelection = useCallback(() => {
    if (Array.isArray(currencies) && currencies?.length !== 0) {
      let defaultCurrencyObject: any = null;
      if (clientSelection?.selectedClients?.length) {
        const currencyId = clientSelection.selectedClients[0].currencyId;
        defaultCurrencyObject = currencies?.find((c: any) => {
          return c.symbol === currencyId;
        });
      }
      if (!defaultCurrencyObject) {
        defaultCurrencyObject = currencies?.find((c: any) => {
          if (documentType === "PUBLISHING")
            return c.symbol === publishingCurrencyId;
          else if (documentType === "RECORDING")
            return c.symbol === recordingCurrencyId;
          else if (documentType === "MECHANICAL")
            return c.symbol === mechanicalCurrencyId;
          else return null;
        });
      }
      if (defaultCurrencyObject) {
        setSelectedCurrency({
          id: defaultCurrencyObject.symbol,
          name: defaultCurrencyObject.name,
        });
      }
    }
  }, [
    clientSelection?.selectedClients,
    currencies,
    documentType,
    mechanicalCurrencyId,
    publishingCurrencyId,
    recordingCurrencyId,
    setSelectedCurrency,
  ]);

  const reloadClientList = useCallback(
    (clientListId: number, clientListType: string) => {
      let thunkClients: any = pubSlice.selectClientListThunk;
      switch (documentType) {
        case "RECORDING":
          thunkClients = recSlice.selectClientListThunk;
          break;
        case "MECHANICAL":
          thunkClients = mecSlice.selectClientListThunk;
          break;
      }
      dispatch(
        thunkClients({
          selectedList: { id: clientListId, type: clientListType },
        })
      );
    },
    [dispatch, documentType]
  );

  const unMount = useCallback(() => {
    abortClients();
    abortParents();
    clientsFetched.current = false;
  }, [abortClients, abortParents]);

  // unmount
  useEffect(() => {
    return () => unMount();
  }, [unMount]);

  // clear parents/appState on modal close
  // reload some state
  useEffect(() => {
    if (modalOpen === false) {
      unMount();
      clientsFetched.current = false;
    }
  }, [modalOpen, page, unMount]);

  // for edit list: fetch selected clients
  // for CreateANewListLink: fetch tabAllClients (can be empty)
  useEffect(() => {
    if (!modalOpen) return;
    switch (type) {
      case "ViewAndEditClientList":
        if (editListId && editListType && clientSelection.tabAllClients.total)
          reloadClientList(editListId, editListType);
        break;
      case "CreateANewListLink":
        if (
          page === "clientsPage" &&
          clientSelection.tabAllClients.total === null
        ) {
          dispatch(
            pubSlice.pubFetchAllClientsTabThunk({
              documentType: documentType,
            })
          );
        }
        break;
    }
  }, [
    clientSelection?.tabAllClients.total,
    dispatch,
    documentType,
    editListId,
    editListType,
    modalOpen,
    page,
    reloadClientList,
    type,
  ]);

  const handleClientListSelect = useCallback(
    (selectedList: any) => {
      let thunk = pubSlice.selectClientListThunk;
      switch (documentType) {
        case "RECORDING":
          thunk = recSlice.selectClientListThunk;
          break;
        case "MECHANICAL":
          thunk = mecSlice.selectClientListThunk;
          break;
      }
      dispatch(thunk({ selectedList }));
    },
    [dispatch, documentType]
  );

  const onSelectClientList = useCallback(
    (selectedValue: string) => {
      if (selectedValue === "") setModalOpen(true);
      else {
        const selectedList = clientSelection?.clientLists?.list.find(
          (list: any) => {
            return list.id === selectedValue;
          }
        );
        if (selectedList.id !== clientSelection?.selectedClientList?.id) {
          handleClientListSelect(selectedList);
        }
      }
    },
    [
      clientSelection?.clientLists?.list,
      clientSelection?.selectedClientList?.id,
      handleClientListSelect,
    ]
  );

  const onDeleteClientList = useCallback(
    (listIdToBeDeleted: number) => {
      let thunkDelete = pubSlice.ClientSelectionClientListDeleteThunk;
      switch (documentType) {
        case "RECORDING":
          thunkDelete = recSlice.ClientSelectionClientListDeleteThunk;
          break;
        case "MECHANICAL":
          thunkDelete = mecSlice.ClientSelectionClientListDeleteThunk;
          break;
      }
      dispatch(
        thunkDelete({
          id: listIdToBeDeleted,
        })
      );
    },
    [dispatch, documentType]
  );

  const modalTabAllClientsFetchData = useMemo(
    () =>
      debounce((pageState: any, documentType: string) => {
        let thunkAll: any = pubSlice.pubFetchAllClientsTabThunk;
        let thunkMoreAll: any = pubSlice.pubFetchMoreAllClientsTabThunk;
        let thunkTabAll: any = pubSlice.updateTabAllClientsPageStateAction;
        switch (documentType) {
          case "RECORDING":
            thunkAll = recSlice.recFetchAllClientsTabThunk;
            thunkMoreAll = recSlice.recFetchMoreAllClientsTabThunk;
            thunkTabAll = recSlice.updateTabAllClientsPageStateAction;
            break;
          case "MECHANICAL":
            thunkAll = mecSlice.mecFetchAllClientsTabThunk;
            thunkMoreAll = mecSlice.mecFetchMoreAllClientsTabThunk;
            thunkTabAll = mecSlice.updateTabAllClientsPageStateAction;
            break;
        }
        if (pageState.start === 0) {
          dispatchedClients.current = dispatch(thunkAll(pageState));
        } else {
          dispatchedClients.current = dispatch(thunkMoreAll(pageState));
        }
        dispatch(thunkTabAll(pageState));
      }, 500),
    [dispatch]
  );

  const modalTabParentsUpdatePageState = useMemo(
    () =>
      debounce((pageState: any) => {
        let thunkTabParents: any = pubSlice.updateTabParentsPageStateAction;
        switch (documentType) {
          case "RECORDING":
            thunkTabParents = recSlice.updateTabParentsPageStateAction;
            break;
          case "MECHANICAL":
            thunkTabParents = mecSlice.updateTabParentsPageStateAction;
            break;
        }
        dispatch(thunkTabParents(pageState));
      }, 500),
    [dispatch, documentType]
  );

  const modalTabParentsFetchData = useMemo(
    () =>
      debounce((pageState: any, documentType: string) => {
        let thunkParents = pubSlice.fetchClientSelectionParentsThunk;
        switch (documentType) {
          case "RECORDING":
            thunkParents = recSlice.fetchClientSelectionParentsThunk;
            break;
          case "MECHANICAL":
            thunkParents = mecSlice.fetchClientSelectionParentsThunk;
            break;
        }
        dispatchedParents.current = dispatch(thunkParents(pageState));
      }, 500),
    [dispatch]
  );

  const modalTabParentsHandler = useCallback(
    (fetchFlag = false) => {
      if (
        selectedCurrency === null ||
        currencies?.length === 0 ||
        !documentType
      )
        return;
      const newState_tabParents = {
        ...clientSelection.tabParentsPageState,
        sortColumn: tabParents_sortColumn,
        filterText: tabParents_filterText,
        currencyId: selectedCurrency.id,
        clientDocumentType: documentType,
        isAsc: tabParents_isSortAsc,
      };
      modalTabParentsUpdatePageState(newState_tabParents);
      if (
        !deepCompare(
          newState_tabParents,
          clientSelection.tabParentsPageState
        ) ||
        fetchFlag
      ) {
        abortParents();
        modalTabParentsFetchData(newState_tabParents, documentType);
      }
    },
    [
      abortParents,
      clientSelection.tabParentsPageState,
      currencies?.length,
      documentType,
      modalTabParentsFetchData,
      modalTabParentsUpdatePageState,
      selectedCurrency,
      tabParents_filterText,
      tabParents_isSortAsc,
      tabParents_sortColumn,
    ]
  );

  const dispatchedSubClients = useRef<any>();

  const modalTabSubClientsFetchData = useCallback(
    (pageState: any) => {
      let thunkSubClients = pubSlice.pubFetchSubClientsThunk;
      switch (documentType) {
        case "RECORDING":
          thunkSubClients = recSlice.recFetchSubClientsThunk;
          break;
        case "MECHANICAL":
          thunkSubClients = mecSlice.mecFetchSubClientsThunk;
          break;
      }
      dispatchedSubClients.current = dispatch(thunkSubClients(pageState));
    },
    [dispatch, documentType]
  );

  const abortModalTabSubClientsFetchData = useCallback(() => {
    if (dispatchedSubClients.current) dispatchedSubClients.current.abort();
  }, []);

  const modalTabResetSubClientsData = useCallback(() => {
    let thunkResetSubClients: any = pubSlice.resetPubSubClientsAction;
    switch (documentType) {
      case "RECORDING":
        thunkResetSubClients = recSlice.resetRecSubClientsAction;
        break;
      case "MECHANICAL":
        thunkResetSubClients = mecSlice.resetMecSubClientsAction;
        break;
    }
    dispatch(thunkResetSubClients());
  }, [dispatch, documentType]);

  const getIsClients = useCallback(() => {
    return (documentType === "PUBLISHING" &&
      publishingClients &&
      typeof publishingClients === "number" &&
      publishingClients > 1) ||
      (documentType === "RECORDING" &&
        recordingClients &&
        typeof recordingClients === "number" &&
        recordingClients > 1) ||
      (documentType === "MECHANICAL" &&
        mechanicalClients &&
        typeof mechanicalClients === "number" &&
        mechanicalClients > 1)
      ? true
      : false;
  }, [documentType, mechanicalClients, publishingClients, recordingClients]);

  const getState = useCallback(async () => {
    let button = null;
    let showTab: clientSelectionTabs = null;
    const editListName2 = editListName;
    const showDataBtnText = t("clientSelection.showData");
    const isDeleteBtnVisible = false;
    const titleTooltipText = t("clientSelection.tooltipTextSelectOrSave");
    let containerClassName = "";

    switch (type) {
      case "CreateANewList":
        {
          const { default: CreateNewListBtn } = await import(
            "../createNewListBtn/CreateNewListBtn"
          );

          let show = false;
          switch (page) {
            case "clientsPage":
              show = getIsClients();
              break;
            default:
              show = getIsClients();
          }

          if (show) {
            button = (
              <CreateNewListBtn
                text={t("clients.createNewList_btn")}
                onClick={() => setModalOpen(true)}
              ></CreateNewListBtn>
            );
          }
        }
        showTab = "allClients";
        containerClassName = `w-auto sm:w-full whitespace-nowrap`;
        break;
      case "CreateANewListLink":
        button = (
          <Link
            text={t("clients.createNewList_btn")}
            to=""
            isInternalLink={false}
            onClick={() => setModalOpen(true)}
            iconRight={
              <i className="chevron">
                <ChevronIcon className="h-6 w-6 fill-green-700" />
              </i>
            }
          />
        );
        showTab = "selectedClients";
        containerClassName = "inline";
        break;
      case "ViewAndEditClientSelection":
        if (getIsClients()) {
          button = (
            <Link
              text={t("clientSelection.viewAndEdit")}
              to=""
              isInternalLink={false}
              onClick={() => setModalOpen(true)}
              className="viewAndEditClientSelection_GTM cursor-pointer pl-1 text-sm font-normal tracking-base text-gray-800 underline hover:no-underline sm:pl-3 sm:text-base md:text-base md:text-gray-700"
            />
          );
        }
        showTab = "selectedClients";
        containerClassName = "inline";
        break;
      case "ViewAndEditClientList":
        {
          const { default: Button } = await import("../../atom/Button/Button");
          button = (
            <Button
              className="viewAndEditList_GTM text-gray-500 p-0 text-xs tracking-xl lg:text-sm lg:tracking-3xl lg:text-gray-900"
              variant={ButtonVariantEnum.cleanCta}
              onClick={() => setModalOpen(true)}
              icon={<EditIcon className="h-4 w-4" />}
            >
              {t("clients.viewEditList")}
            </Button>
          );
        }
        showTab = "allClients";
        containerClassName = `inline-block`;
        break;
    }

    const newAppState = {
      button,
      showTab,
      editListName: editListName2,
      showDataBtnText,
      isDeleteBtnVisible,
      titleTooltipText,
      containerClassName,
    };

    if (!deepCompare(appState, newAppState)) {
      setAppState(newAppState);
    }
  }, [appState, editListName, getIsClients, page, t, type]);

  useEffect(() => {
    if (type === "ViewAndEditCurrentClientList") {
      let button = null;

      if (getIsClients()) {
        button = (
          <Link
            text={t("clientSelection.viewAndEditList")}
            to=""
            isInternalLink={false}
            onClick={() => setModalOpen(true)}
            className="viewAndEditCurrentClientList_GTM cursor-pointer pl-1 text-sm font-normal tracking-base text-gray-800 underline hover:no-underline sm:pl-3 sm:text-base md:text-base md:text-gray-700"
          />
        );
      }
      const currentListType = clientSelection?.selectedClientList?.type || "";
      const isCurrencyList = currentListType === "currency";
      const isSelectAllList = currentListType === "selectAll";
      const editListName2 =
        editListName ||
        (isCurrencyList || isSelectAllList
          ? ""
          : clientSelection?.selectedClientList?.name);

      setAppState({
        button,
        showTab: "selectedClients" as clientSelectionTabs,
        editListName: editListName2,
        showDataBtnText: t("clientSelection.saveAndShowData"),
        isDeleteBtnVisible: isCurrencyList ? false : true,
        titleTooltipText: t("clientSelection.tooltipTextEdit"),
        containerClassName: "inline",
      });
    }
  }, [
    clientSelection?.selectedClientList?.name,
    clientSelection?.selectedClientList?.type,
    documentType,
    editListName,
    getIsClients,
    mechanicalClients,
    publishingClients,
    recordingClients,
    t,
    type,
  ]);

  useEffect(() => {
    if (type === "SelectClients") {
      let button = null;
      let showTab: clientSelectionTabs = null;
      let containerClassName = "";

      if (getIsClients()) {
        button = (
          <SelectClientsBtn
            selectedClientIds={[]}
            onSelectClientList={onSelectClientList}
            onDeleteClientList={onDeleteClientList}
            clientLists={clientSelection?.clientLists?.list}
          />
        );
      }
      showTab = "allClients";
      containerClassName = `w-11/12 sm:w-full whitespace-nowrap`;

      setAppState({
        button,
        showTab,
        editListName,
        showDataBtnText: t("clientSelection.showData"),
        isDeleteBtnVisible: false,
        titleTooltipText: t("clientSelection.tooltipTextSelectOrSave"),
        containerClassName,
      });
    }
  }, [
    clientSelection?.clientLists?.list,
    clientSelection?.clientLists.total,
    documentType,
    editListName,
    getIsClients,
    mechanicalClients,
    onDeleteClientList,
    onSelectClientList,
    publishingClients,
    recordingClients,
    t,
    type,
  ]);

  useEffect(() => {
    if (documentType) setInitialCurrencySelection();
  }, [documentType, setInitialCurrencySelection]);

  // load currencies & build button
  useEffect(() => {
    if (
      appState.button === null &&
      (publishingClients || recordingClients || mechanicalClients)
    ) {
      getState();
    }
  }, [
    appState.button, // keep
    getState,
    allClientsTotal, // keep
    currencies, // keep
    publishingClients, // keep
    recordingClients, // keep
    mechanicalClients, // keep
    type,
    documentType,
  ]);

  // clients
  useEffect(() => {
    if (!modalOpen || selectedCurrency === null || currencies?.length === 0) {
      return;
    }
    const newState_tabAllClients = {
      ...clientSelection.tabAllClientsPageState,
      data: { withSubClients: true },
      start: tabAllClientsStart,
      count: 200,
      sortColumn: tabAllClients_sortColumn,
      filterText: tabAllClients_filterText,
      currencyId: selectedCurrency.id,
      isAsc: tabAllClients_isSortAsc,
    };
    if (
      !deepCompare(
        newState_tabAllClients,
        clientSelection.tabAllClientsPageState
      )
    ) {
      abortClients();
      modalTabAllClientsFetchData(newState_tabAllClients, documentType);
    }
  }, [
    clientSelection.tabAllClientsPageState,
    tabAllClients_filterText,
    tabAllClients_isSortAsc,
    tabAllClients_sortColumn,
    modalTabAllClientsFetchData,
    modalOpen,
    abortClients,
    selectedCurrency,
    currencies?.length,
    documentType,
    tabAllClientsStart,
  ]);

  // parents
  useEffect(() => {
    if (!modalOpen) return;
    modalTabParentsHandler();
  }, [
    currencies?.length,
    documentType,
    modalOpen,
    modalTabParentsHandler,
    selectedCurrency,
  ]);

  const handleShowDataBtnClick = useCallback(
    (
      selectedClients: any[],
      selectedParents: any[],
      createListName?: string,
      editListName?: string
    ) => {
      const selectedClientIds = selectedClients.map((sc) => sc.id);
      const selectedParentIds = selectedParents.map((sp) => sp.id);

      let thunkClients: any = pubSlice.selectClientsThunk;
      let thunkCreateClients: any = pubSlice.createClientListThunk;
      let thunkClientsUpdate: any = pubSlice.updateClientListThunk;
      let thunkClientsUnselect: any = pubSlice.unselectClientListAction;
      let thunkParentsUnselect: any = pubSlice.unselectParentsListAction;
      switch (documentType) {
        case "RECORDING":
          thunkClients = recSlice.selectClientsThunk;
          thunkCreateClients = recSlice.createClientListThunk;
          thunkClientsUpdate = recSlice.updateClientListThunk;
          thunkClientsUnselect = recSlice.unselectClientListAction;
          thunkParentsUnselect = recSlice.unselectParentsListAction;
          break;
        case "MECHANICAL":
          thunkClients = mecSlice.selectClientsThunk;
          thunkCreateClients = mecSlice.createClientListThunk;
          thunkClientsUpdate = mecSlice.updateClientListThunk;
          thunkClientsUnselect = mecSlice.unselectClientListAction;
          thunkParentsUnselect = mecSlice.unselectParentsListAction;
          break;
      }
      dispatch(
        thunkClients({
          clients: selectedClients,
          clientIds: selectedClientIds,
          parents: selectedParents,
          parentIds: selectedParentIds,
          documentType: documentType,
        })
      ).then(() => modalTabParentsHandler(true));
      if (createListName) {
        dispatch(
          thunkCreateClients({
            clientIds: selectedClientIds,
            name: createListName,
            clientDocumentType: documentType,
          })
        );
      } else if (editListName) {
        const currentListId = clientSelection?.selectedClientList?.id;
        dispatch(
          thunkClientsUpdate({
            clientIds: selectedClientIds,
            name: editListName,
            id: currentListId,
          })
        );
      } else {
        dispatch(thunkClientsUnselect());
        dispatch(thunkParentsUnselect({ data: { selectedParentIds } }));
      }
    },
    [
      clientSelection?.selectedClientList?.id,
      dispatch,
      documentType,
      modalTabParentsHandler,
    ]
  );

  const handleShowDataBtn = useCallback(
    (
      selectedClients: any[],
      selectedParents: any[],
      createListName: string,
      editListName: string
    ) => {
      handleShowDataBtnClick(
        selectedClients,
        selectedParents,
        createListName,
        editListName
      );
    },
    [handleShowDataBtnClick]
  );

  const onTabAllClientsColumnHeaderClick = useCallback(
    (columnName: string) => {
      if (tabAllClients_sortColumn === columnName)
        settabAllClients_IsSortAsc(!tabAllClients_isSortAsc);
      else {
        settabAllClients_SortColumn(columnName);
        settabAllClients_IsSortAsc(true);
      }
    },
    [tabAllClients_isSortAsc, tabAllClients_sortColumn]
  );

  const addTempClientsToBeSelected = useCallback(
    (addedClientIds: number[]) => {
      dispatch(
        rootSelector.rootSlice.fetchClientsByParentThunk({
          data: {
            clientIds: addedClientIds,
          },
        })
      );
    },
    [dispatch, rootSelector.rootSlice]
  );

  const removeTempParentsClientsToBeSelected = useCallback(() => {
    dispatch(rootSelector.rootSlice.removeTempClientsToBeSelectedAction());
  }, [dispatch, rootSelector.rootSlice]);

  const onTabParentsColumnHeaderClick = useCallback(
    (columnName: string) => {
      if (tabParents_sortColumn === columnName)
        settabParents_IsSortAsc(!tabParents_isSortAsc);
      else {
        settabParents_SortColumn(columnName);
        settabParents_IsSortAsc(true);
      }
    },
    [tabParents_isSortAsc, tabParents_sortColumn]
  );

  const deleteCurrentClientList = useCallback(() => {
    onDeleteClientList(clientSelection.selectedClientList.id);
  }, [clientSelection?.selectedClientList?.id, onDeleteClientList]);

  const tabAllClientsData = useMemo(() => {
    return {
      selectedClients:
        type === "CreateANewList" ? [] : clientSelection.selectedClients,
      clients: clientSelection?.tabAllClients?.list,
      clientsTotal: clientSelection?.tabAllClients?.total,
      tabAllClientsPageState: clientSelection.tabAllClientsPageState,
      tabAllClientsStart: tabAllClientsStart,
      setTabAllClientsStart: setTabAllClientsStart,
      filterText: tabAllClients_filterText,
      setFilterText: settabAllClients_FilterText,
      selectedCurrency: selectedCurrency,
      onHeaderClick: onTabAllClientsColumnHeaderClick,
      sortColumn: tabAllClients_sortColumn,
      isSortAsc: tabAllClients_isSortAsc,
    };
  }, [
    clientSelection.selectedClients,
    clientSelection?.tabAllClients?.list,
    clientSelection?.tabAllClients?.total,
    clientSelection.tabAllClientsPageState,
    onTabAllClientsColumnHeaderClick,
    selectedCurrency,
    tabAllClientsStart,
    tabAllClients_filterText,
    tabAllClients_isSortAsc,
    tabAllClients_sortColumn,
    type,
  ]);

  const tabParentsData = useMemo(() => {
    return {
      parents: clientSelection.parents || undefined,
      selectedParents:
        type === "CreateANewList" ? [] : clientSelection.selectedParents,
      onHeaderClick: onTabParentsColumnHeaderClick,
      filterText: tabParents_filterText,
      setFilterText: settabParents_FilterText,
      selectedCurrency: selectedCurrency,
      sortColumn: tabParents_sortColumn,
      isSortAsc: tabParents_isSortAsc,
    };
  }, [
    clientSelection.parents,
    clientSelection.selectedParents,
    onTabParentsColumnHeaderClick,
    selectedCurrency,
    tabParents_filterText,
    tabParents_isSortAsc,
    tabParents_sortColumn,
    type,
  ]);

  const memoValues = useMemo(() => {
    return {
      appState,
      addTempClientsToBeSelected,
      removeTempParentsClientsToBeSelected,
      clientSelection,
      currencies,
      isLoading: currencies?.length === 0 || false,
      handleShowDataBtn,
      setSelectedCurrency,
      deleteCurrentClientList,
      tabAllClientsData,
      tabParentsData,
      modalOpen,
      setModalOpen,
      modalTabSubClientsFetchData,
      abortModalTabSubClientsFetchData,
      modalTabResetSubClientsData,
    };
  }, [
    addTempClientsToBeSelected,
    removeTempParentsClientsToBeSelected,
    appState,
    clientSelection,
    currencies,
    deleteCurrentClientList,
    handleShowDataBtn,
    modalOpen,
    setSelectedCurrency,
    tabAllClientsData,
    tabParentsData,
    modalTabSubClientsFetchData,
    abortModalTabSubClientsFetchData,
    modalTabResetSubClientsData,
  ]);

  return memoValues;
};

export default useClientSelection;
