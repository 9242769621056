import React from "react";
import styles from "./sync.module.scss";
import { DefaultTFuncReturn } from "i18next";

export declare type BlockProps = {
  title: string;
  subTitle?: DefaultTFuncReturn;
  children: React.ReactElement;
};

const Block = (props: BlockProps): JSX.Element => {
  const { title, subTitle, children } = props;

  return (
    <div className="relative col-span-12 h-full border border-gray-100 bg-white base:col-span-6">
      <div className="flex h-14 flex-col justify-center border-b border-gray-100 px-4">
        <div className={styles.blockTitle}>{title}</div>
        {subTitle && <span className={styles.blockSubTitle}>{subTitle}</span>}
      </div>
      {children}
    </div>
  );
};

export default Block;
