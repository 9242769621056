import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";
export function fetchRecoveryToken(params: any, thunkAPI?: any) {
  const { data } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.resetPasswordValidate}`, {
    method: "POST",
    noAuthorization: true,
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
  });
}
