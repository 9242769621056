import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../app/redux/store";
import { SortDirectionEnum, StatusEnum } from "../../../../app/types/enums";
import { CommonRequestBodyTableState } from "../../../../app/types/props";

import { DEFAULT_ROWS_PER_PAGE } from "../../common/adminEndpoints.const";
import { useFetch } from "../../common/hooks/useFetch";

import { getActivityLog, exportActivityLogCSV } from "./activityLogRequestAPI";

import { TableColumnsVariantEnum } from "./activityLog.const";

export type ActivityLogItem = {
  id: number;
  useremail: string;
  date: string;
  detail: string;
  agent: string;
  operatingSystem: string;
  host: string;
  userGroups: string;
  event: string;
};

type ActivityLogInitialState = {
  activityLogState: CommonRequestBodyTableState;
  tableData: {
    list: ActivityLogItem[] | null;
    count: number | null;
    totalCount: number | null;
  };
  status: StatusEnum;
};

export const getActivityLogThunk = createAsyncThunk(
  "getActivityLog",
  async (payload: any, { rejectWithValue }) => {
    try {
      const getLog = await useFetch();
      const response = await getLog({
        data: payload,
        fetchFunction: getActivityLog,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const exportActivityLogCsvThunk = createAsyncThunk(
  "exportActivityLogCSV",
  async (payload: any) => {
    const response = await exportActivityLogCSV(payload);

    return response;
  }
);

const initialState: ActivityLogInitialState = {
  activityLogState: {
    filterText: "",
    pagination: {
      page: 0,
      size: DEFAULT_ROWS_PER_PAGE,
      sortingCriteria: [
        {
          sortColumn: TableColumnsVariantEnum.date,
          direction: SortDirectionEnum.Ascending,
        },
      ],
    },
  },
  tableData: {
    list: null,
    count: 0,
    totalCount: 0,
  },
  status: StatusEnum.Idle,
};

export const activityLogSlice = createSlice({
  name: "activityLog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActivityLogThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.tableData.list = action.payload.data;
        state.tableData.totalCount = action.payload.totalCount;
        state.tableData.count = action.payload.count;
      })
      .addCase(getActivityLogThunk.pending, (state) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getActivityLogThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
        state.tableData.list = [];
        state.tableData.totalCount = null;
        state.tableData.count = null;
      });
  },
});
export const activityLogSelector = (state: RootState) => state.activityLog;
export default activityLogSlice.reducer;
