import { USER_API_RESOURCE_BASE_URLS } from "../../../../app/config/userEndpoints.const";
import request from "../../../../app/utils/requestUtils";

export type PubAnalysisSourceBreakdownParams = {
  sourceCode?: string;
  sourceName: string;
  clientIds: number[];
  clientLists?: {
    id: string;
    type: "static";
  }[];
  periodIds: number[];
};

export type PubAnalysisSourceBreakdownTypeTrendTerritoryParams = {
  isSelected: boolean;
} & PubAnalysisSourceBreakdownParams;

export type PubAnalysisSourceDataEntry = {
  currency: string;
  raw: number;
  formattedShort: string;
  formattedLong: string;
  formattedPercent: string;
  percent: number;
};

export type PubAnalysisSourceByTerritoryEntry = {
  name: string;
  iso: string;
  revenue: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
  zero: boolean;
};

export type PubAnalysisSourceByTypeEntry = {
  mainIncType: string;
  categoryName: string;
  color: string;
  revenue: {
    currency: string;
    raw: number;
    formattedShort: string;
    formattedLong: string;
    formattedPercent: string;
    percent: number;
  };
};

export type PubAnalysisSourceRoyaltiesPerPeriodOrYearEntry = {
  periodOrYear: string;
  royalty: PubAnalysisSourceDataEntry;
  zero: boolean;
};

export type PubAnalysisSourceData = {
  sourceCode?: string;
  sourceName: string;
  perYear?: boolean;
  totalRoyalty: PubAnalysisSourceDataEntry | null;
  incomeTypes?: PubAnalysisSourceByTypeEntry[] | null;
  sourcesByTerritory?: PubAnalysisSourceByTerritoryEntry[] | null;
  royaltiesPerPeriodOrYear?:
    | PubAnalysisSourceRoyaltiesPerPeriodOrYearEntry[]
    | null;
};

export function FetchAnalysisSourceBreakdown_Type(
  params: PubAnalysisSourceBreakdownParams,
  thunkAPI?: any
): Promise<PubAnalysisSourceData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.sourcesType}?sourceCode=${params.sourceCode}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisSourceBreakdown_Territory(
  params: PubAnalysisSourceBreakdownParams,
  thunkAPI?: any
): Promise<PubAnalysisSourceData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.sourcesTerritory}?sourceCode=${params.sourceCode}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisSourceBreakdown_Trend(
  params: PubAnalysisSourceBreakdownParams,
  thunkAPI?: any
): Promise<PubAnalysisSourceData> {
  const data = {
    clientIds: params.clientIds,
    clientLists: params.clientLists,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.sourcesTrends}?sourceCode=${params.sourceCode}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function downloadSourcesBreakdownData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.analysisDownload}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
    isDownload: true,
    fileName,
  });
}
