import React from "react";

function CalendarIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      name="CalendarIcon"
      {...props}
    >
      <path
        fill="#4A4A4A"
        fillRule="evenodd"
        d="M16.667 13.333H14A.333.333 0 1 0 14 14h2.667a.333.333 0 1 0 0-.667Zm0-2.666H14a.333.333 0 1 0 0 .666h2.667a.333.333 0 1 0 0-.666Zm2.666 7.666c0 .552-.448 1-1 1H1.667c-.552 0-1-.448-1-1V3.667c0-.552.448-1 1-1h3.666V3A.333.333 0 1 0 6 3v-.333h8.667V3a.333.333 0 1 0 .666 0v-.333h3c.552 0 1 .448 1 1v14.666ZM15.333 2V.333a.333.333 0 1 0-.666 0V2H6V.333a.333.333 0 1 0-.667 0V2H1.667C.747 2 0 2.748 0 3.667v14.666C0 19.253.748 20 1.667 20h16.666c.92 0 1.667-.748 1.667-1.667V3.667C20 2.747 19.252 2 18.333 2h-3Zm1.334 6H14a.333.333 0 1 0 0 .667h2.667a.333.333 0 1 0 0-.667Zm-5.334 0H8.667a.333.333 0 1 0 0 .667h2.666a.333.333 0 1 0 0-.667Zm5.334-2.667H3.333a.333.333 0 1 0 0 .667h13.334a.333.333 0 1 0 0-.667Zm0 10.667H14a.333.333 0 1 0 0 .667h2.667a.333.333 0 1 0 0-.667ZM6 8H3.333a.333.333 0 1 0 0 .667H6A.333.333 0 1 0 6 8Zm0 8H3.333a.333.333 0 1 0 0 .667H6A.333.333 0 1 0 6 16Zm0-2.667H3.333a.333.333 0 1 0 0 .667H6a.333.333 0 1 0 0-.667Zm0-2.666H3.333a.333.333 0 1 0 0 .666H6a.333.333 0 1 0 0-.666Zm5.333 2.666H8.667a.333.333 0 1 0 0 .667h2.666a.333.333 0 1 0 0-.667Zm0-2.666H8.667a.333.333 0 1 0 0 .666h2.666a.333.333 0 1 0 0-.666Zm0 5.333H8.667a.333.333 0 1 0 0 .667h2.666a.333.333 0 1 0 0-.667Z"
      />
    </svg>
  );
}

export default CalendarIcon;
