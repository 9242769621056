import { handleMessage } from "../utils/handleMessage";
import { StatusEnum } from "../../../../app/types/enums";
import { t } from "i18next";

type FetchFunctionWithData<T> = (payload: T) => Promise<any>;
type FetchFunction = () => Promise<any>;

export type FetchDataProps<T> = {
  fetchFunction: FetchFunction | FetchFunctionWithData<T>;
  data?: T;
  customSuccessMsg?: string;
  customErrorMsg?: string;
};

export const useFetch = () => {
  const fetchData = async <T>({
    fetchFunction,
    data,
    customSuccessMsg,
    customErrorMsg,
  }: FetchDataProps<T>) => {
    try {
      const response = await (!data
        ? (fetchFunction as FetchFunction)()
        : (fetchFunction as FetchFunctionWithData<T>)(data));

      if (response.resStatus >= 200 && response.resStatus <= 202) {
        if (customSuccessMsg) {
          handleMessage({
            status: StatusEnum.Fulfilled,
            message: customSuccessMsg,
            t,
          });
        }

        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error: any) {
      handleMessage({
        status: StatusEnum.Failed,
        message: customErrorMsg || error,
        t,
      });
      throw JSON.parse(JSON.stringify(error));
    }
  };
  return fetchData;
};
