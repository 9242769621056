import React from "react";
function HelpIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 26 26"
      fill="none"
      name="HelpIcon"
      {...props}
    >
      <g fill="none">
        <path
          stroke="currentColor"
          strokeWidth="2"
          d="M13.174 24.506c6.34 0 11.478-5.139 11.478-11.478 0-6.34-5.139-11.478-11.478-11.478-6.34 0-11.478 5.139-11.478 11.478 0 6.34 5.139 11.478 11.478 11.478Z"
        />
        <path
          fill="currentColor"
          d="M11.752 16.003v2.41h2.457v-2.41h-2.457Zm-2.52-5.165h2.3c0-.282.032-.545.094-.79.063-.246.16-.46.29-.642.13-.183.297-.329.5-.438.204-.11.447-.165.729-.165.417 0 .743.115.978.345.235.23.352.584.352 1.064.01.282-.04.517-.149.704-.11.188-.253.36-.43.517-.177.156-.37.313-.58.47a3.94 3.94 0 0 0-.594.555 3.234 3.234 0 0 0-.493.775c-.14.302-.227.678-.258 1.127v.704h2.113v-.595c.041-.313.143-.574.305-.782.162-.209.347-.394.556-.556.208-.162.43-.323.665-.485a3.049 3.049 0 0 0 1.127-1.417c.13-.323.195-.735.195-1.236a2.81 2.81 0 0 0-.195-.978 2.742 2.742 0 0 0-.65-.979c-.302-.302-.701-.555-1.197-.759-.496-.203-1.114-.305-1.855-.305-.574 0-1.093.097-1.557.29-.465.193-.861.461-1.19.806a3.676 3.676 0 0 0-.767 1.22c-.182.47-.279.987-.29 1.55Z"
        />
      </g>
    </svg>
  );
}

export default HelpIcon;
