import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function fetchContacts(params: any, thunkAPI?: any) {
  const { documentType = "MECHANICAL", filterText = "" } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.contacts}?groupType=${documentType}&searchText=${filterText}`,
    { method: "GET", signal: thunkAPI?.signal }
  );
}

export function fetchForms(params: any, thunkAPI?: any) {
  const { documentType = "MECHANICAL" } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.forms}?formDocumentType=${documentType}`,
    {
      method: "GET",
      signal: thunkAPI?.signal,
    }
  );
}

export function downloadContactForms(params: any, thunkAPI?: any) {
  const { documentType = "MECHANICAL", data, fileName } = params;
  return request(
    `${USER_API_RESOURCE_BASE_URLS.formsDownload}?formDocumentType=${documentType}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
      isDownload: true,
      fileName,
    }
  );
}
