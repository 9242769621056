export const jobTypes: { [key: string]: string } = {
  PUBLISHING_CLIENT: "publishing",
  SONG_REGISTRATIONS: "publishing",
  PUBLISHING_INCOME: "publishing",
  PUBLISHING_PIPELINE_INCOME: "publishing",
  SOURCES: "publishing",
  RECORDING_CLIENT: "recording",
  PRODUCT: "recording",
  PIPELINE_PRODUCT: "recording",
  UNITS: "recording",
  MECHANICAL_CLIENT: "mechanical",
  SYNC_LICENSES: "common",
  PIPELINE_SYNC_LICENSES: "common",
};

export const jobOrder: { [key: string]: string[] } = {
  publishing: [
    "PUBLISHING_CLIENT",
    "SONG_REGISTRATIONS",
    "PUBLISHING_INCOME",
    "PUBLISHING_PIPELINE_INCOME",
    "SOURCES",
  ],
  recording: ["RECORDING_CLIENT", "PRODUCT", "PIPELINE_PRODUCT", "UNITS"],
  mechanical: ["MECHANICAL_CLIENT"],
  common: ["SYNC_LICENSES", "PIPELINE_SYNC_LICENSES"],
};

export const prerequisites: { [key: string]: string[] } = {
  PUBLISHING_CLIENT: [],
  SONG_REGISTRATIONS: [],
  PUBLISHING_INCOME: [
    "Publishing Clients",
    "Misc -> Populate publishing client periods",
  ],
  PUBLISHING_PIPELINE_INCOME: [
    "Publishing Clients",
    "Misc -> Populate publishing client periods",
  ],
  SOURCES: ["Publishing Clients", "Misc -> Populate publishing client periods"],
  RECORDING_CLIENT: [],
  PRODUCT: ["Recording Clients", "Misc -> Populate recording client periods"],
  PIPELINE_PRODUCT: [
    "Recording Clients",
    "Misc -> Populate recording client periods",
  ],
  UNITS: [],
  MECHANICAL_CLIENT: [],
  SYNC_LICENSES: [
    "Publishing Income",
    "Products",
    "Misc -> Populate Sync Licenses",
  ],
  PIPELINE_SYNC_LICENSES: [
    "Pipeline Pub Income",
    "Pipeline Products",
    "Misc -> Populate Sync Licenses",
  ],
};
