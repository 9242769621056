import {
  PendingRequestSearchEnum,
  PendingRequestTypeEnum,
  TitleVariantEnum,
} from "../../../../app/types/enums";
import { AdminHeaderProps, TableHeadProps } from "../../../../app/types/props";

export const pendingRequestHeaderData: AdminHeaderProps = {
  titleData: {
    text: "adminSection.pendingRequest.title",
    variant: TitleVariantEnum.primaryTitle,
  },
};

export const tableHeaderData: TableHeadProps = [
  {
    id: 1,
    title: "adminSection.pendingRequest.tableHeader.id",
    filteringInfo: "",
  },
  {
    id: 2,
    title: "adminSection.pendingRequest.tableHeader.firstName",
    filteringInfo: "firstName",
  },
  {
    id: 3,
    title: "adminSection.pendingRequest.tableHeader.lastName",
    filteringInfo: "lastName",
  },
  {
    id: 4,
    title: "adminSection.pendingRequest.tableHeader.userName",
    filteringInfo: "userName",
  },
  {
    id: 5,
    title: "adminSection.pendingRequest.tableHeader.email",
    filteringInfo: "email",
  },
  {
    id: 6,
    title: "adminSection.pendingRequest.tableHeader.requestedOn",
    filteringInfo: "requestOn",
  },
  {
    id: 7,
    title: "adminSection.pendingRequest.tableHeader.accountType",
    filteringInfo: "accountType",
  },
  {
    id: 8,
    title: "adminSection.pendingRequest.tableHeader.originatingSystem",
    filteringInfo: "originatingSystem",
  },
];

export const filterBy = [
  {
    label: "contactUsGuest.accountTypes.publishing",
    value: PendingRequestTypeEnum.publishing,
  },
  {
    label: "contactUsGuest.accountTypes.recording",
    value: PendingRequestTypeEnum.recording,
  },
  {
    label: "contactUsGuest.accountTypes.threesixty",
    value: PendingRequestTypeEnum.threesixty,
  },
  {
    label: "contactUsGuest.accountTypes.mechanical",
    value: PendingRequestTypeEnum.mechanical,
  },
  {
    label: "contactUsGuest.accountTypes.unknown",
    value: PendingRequestTypeEnum.unknown,
  },
];

export const searchBy = [
  {
    label: "adminSection.pendingRequest.tableHeader.firstName",
    value: PendingRequestSearchEnum.firstName,
  },
  {
    label: "adminSection.pendingRequest.tableHeader.lastName",
    value: PendingRequestSearchEnum.lastName,
  },
  {
    label: "adminSection.pendingRequest.tableHeader.email",
    value: PendingRequestSearchEnum.email,
  },
  {
    label: "adminSection.pendingRequest.tableHeader.userName",
    value: PendingRequestSearchEnum.username,
  },
  {
    label: "adminSection.pendingRequest.tableHeader.originatingSystem",
    value: PendingRequestSearchEnum.originatingSystem,
  },
];
