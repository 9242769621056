import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import siteLayoutReducer from "../../features/common/SiteLayoutWrapper/siteLayoutSlice";
import messageReducer from "../../features/common/message/messageSlice";
import appMainReducer from "../../features/appMain/appMainSlice";
import pubRootReducer from "../../features/publishing/pubroot/pubrootSlice";
import combinedDashboardReducer from "../../features/combinedDashboard/combinedDashboardSlice";
import pubDashboardReducer from "../../features/publishing/dashboard/dashboardSlice";
import pubAnalysisHeaderReducer from "../../features/publishing/analysis/pubAnalysisHeaderSlice";
import pubAnalysisSongReducer from "../../features/publishing/analysis/song/pubAnalysisSongSlice";
import pubAnalysisTerritoryReducer from "../../features/publishing/analysis/territory/pubAnalysisTerritorySlice";
import pubAnalysisSourceReducer from "../../features/publishing/analysis/source/pubAnalysisSourceSlice";
import pubAnalysisSourceBreakdownReducer from "../../features/publishing/analysis/source/pubAnalysisSourceBreakdownSlice";
import pubAnalysisDigitalReducer from "../../features/publishing/analysis/digital/pubAnalysisDigitalSlice";
import pubAnalysisSongBreakdownReducer from "../../features/publishing/analysis/song/pubAnalysisSongBreakdownSlice";
import pubAnalysisDigitalBreakdownReducer from "../../features/publishing/analysis/digital/pubAnalysisDigitalBreakdownSlice";
import pubAnalysisReducer from "../../features/publishing/analysis/pubAnalysisSlice";
import pubOverviewReducer from "../../features/publishing/overview/overviewSlice";
import pubClientsReducer from "../../features/publishing/clients/clientsSlice";
import pubContactsReducer from "../../features/publishing/contacts/contactsSlice";
import pubStatementsReducer from "../../features/publishing/statements/statements/statementsSlice";
import pubTaxDocumentsReducer from "../../features/publishing/statements/taxDocuments/taxDocumentsSlice";
import pubSyncReducer from "../../features/publishing/sync/pubSyncSlice";
import recSyncReducer from "../../features/recording/sync/recSyncSlice";
import recDigitalFlashReducer from "../../features/recording/digitalFlash/digitalFlashSlice";
import pubRegistrationReducer from "../../features/publishing/registration/registrationSlice";
import loginReducer from "../../features/siteBasic/login/loginSlice";
import userRegistrationReducer from "../../features/siteBasic/register/registerSlice";
import userForgotUsernameReducer from "../../features/siteBasic/forgotUsername/forgotUsernameSlice";
import userForgotPasswordReducer from "../../features/siteBasic/forgotPassword/forgotPasswordSlice";
import userResetPasswordReducer from "../../features/siteBasic/resetPassword/resetPasswordSlice";
import contactUsGuestReducer from "../../features/siteBasic/contactUsGuest/contactUsGuestSlice";
import contactUsReducer from "../molecules/contacts/contactSlice";
import recRootReducer from "../../features/recording/recroot/recrootSlice";
import recDashboardReducer from "../../features/recording/dashboard/dashboardSlice";
import recAnalysisReducer from "../../features/recording/analysis/recAnalysisSlice";
import recAnalysisHeaderReducer from "../../features/recording/analysis/recAnalysisHeaderSlice";
import recAnalysisTrackReducer from "../../features/recording/analysis/track/recAnalysisTrackSlice";
import recAnalysisTrackBreakdownReducer from "../../features/recording/analysis/track/recAnalysisTrackBreakdownSlice";
import recAnalysisAlbumReducer from "../../features/recording/analysis/album/recAnalysisAlbumSlice";
import recAnalysisAlbumBreakdownReducer from "../../features/recording/analysis/album/recAnalysisAlbumBreakdownSlice";
import recAnalysisTerritoryReducer from "../../features/recording/analysis/territory/recAnalysisTerritorySlice";
import recAnalysisDigitalReducer from "../../features/recording/analysis/digital/recAnalysisDigitalSlice";
import recAnalysisDigitalBreakdownReducer from "../../features/recording/analysis/digital/recAnalysisDigitalBreakdownSlice";
import recAnalysisSourceReducer from "../../features/recording/analysis/source/recAnalysisSourceSlice";
import recAnalysisSourceBreakdownReducer from "../../features/recording/analysis/source/recAnalysisSourceBreakdownSlice";
import recAnalysisChannelBreakdownReducer from "../../features/recording/analysis/channel/recAnalysisChannelBreakdownSlice";
import recOverviewReducer from "../../features/recording/overview/overviewSlice";
import recStatementsReducer from "../../features/recording/statements/statements/statementsSlice";
import recTaxDocumentsReducer from "../../features/recording/statements/taxDocuments/taxDocumentsSlice";
import recContactsReducer from "../../features/recording/contacts/contactsSlice";
import mecRootReducer from "../../features/mechanical/mecroot/mecrootSlice";
import mecDashboardReducer from "../../features/mechanical/dashboard/dashboardSlice";
import mecStatementsReducer from "../../features/mechanical/statements/statementsSlice";
import mecContactsReducer from "../../features/mechanical/contacts/contactsSlice";
import periodsReducer from "../molecules/periodSelector/periodSelectorSlice";
import maintenanceReducer from "../../features/admin/pages/Maintenance/maintenanceSlice";
import clientCommunicationReducer from "../../features/admin/pages/ClientCommunication/ClientCommunicationSlice";
import emailFootersSlice from "../../features/admin/pages/EmailFooters/EmailFootersSlice";
import miscSlice from "../../features/admin/pages/Misc/MiscSlice";
import elasticsearchSlice from "../../features/admin/pages/Elasticsearch/ElasticsearchSlice";
import advanceRequestReducer from "../../features/admin/pages/AdvanceRequestСonfig/AdvanceRequestConfigSlice";
import adminGroupsReducer from "../../features/admin/pages/AdminGroups/AdminGroupsSlice";
import newsReducer from "../../features/admin/pages/News/NewsSlice";
import addEditNewsReducer from "../../features/admin/pages/AddEditNews/AddEditNewsSlice";
import clientsPageReducer from "../../features/admin/pages/ClientsPage/ClientsTableSlice";
import activityLogReducer from "../../features/admin/pages/ActivityLog/activityLogSlice";
import requestArchiveReducer from "../../features/admin/pages/RequestArchive/RequestArchiveSlice";
import requestArchiveEditReducer from "../../features/admin/pages/RequestArchiveEditPage/RequestArchiveEditPageSlice";
import morRootReducer from "../../features/more/morroot/morrootSlice";
import morHelpReducer from "../../features/more/help/helpSlice";
import morContactsReducer from "../../features/more/contacts/contactsSlice";
import addEditAdminGroupReducer from "../../features/admin/pages/AddEditAdminGroup/AddEditAdminGroupSlice";
import pendingRequestReducer from "../../features/admin/pages/PendingRequest/PendingRequestSlice";
import systemClosingDatesReducer from "../../features/admin/pages/SystemClosingDates/systemClosingDatesSlice";
import closingDateDetailsReducer from "../../features/admin/pages/ClosingDateDetails/closingDateDetailsSlice";
import adminAccountsReducer from "../../features/admin/pages/AdminAccounts/AdminAccountsSlice";
import adminAccountsEditReducer from "../../features/admin/pages/AddEditAdminAccounts/AddEditAdminAccountsSlice";
import settingsReducer from "../../features/more/settings/settingsSlice";
import newsGroupsReducer from "../../features/admin/pages/AddEditNews/components/tables/NewsGroupsSlice";
import newsUsersReducer from "../../features/admin/pages/AddEditNews/components/tables/NewsUsersSlice";
import usersReducer from "../../features/admin/pages/Users/UsersSlice";
import groupsReducer from "../../features/admin/pages/Groups/GroupsSlice";
import bulkImportUserAccountsReducer from "../../features/admin/pages/BulkImportUserAccounts/bulkImportUserAccountsSlice";
import addEditUsersReducer from "../../features/admin/pages/AddEditUsers/addEditUsersSlice";
import addEditGroupReducer from "../../features/admin/pages/AddEditGroup/AddEditGroupSlice";
import parentsReducer from "../../features/admin/pages/Parents/ParentsSlice";

export const store = configureStore({
  reducer: {
    sitelayout: siteLayoutReducer,
    message: messageReducer,
    appMain: appMainReducer,
    pubRoot: pubRootReducer,
    combinedDashboard: combinedDashboardReducer,
    pubDashboard: pubDashboardReducer,
    pubAnalysisHeader: pubAnalysisHeaderReducer,
    pubAnalysis: pubAnalysisReducer,
    pubAnalysisSong: pubAnalysisSongReducer,
    pubAnalysisSongBreakdown: pubAnalysisSongBreakdownReducer,
    pubAnalysisTerritory: pubAnalysisTerritoryReducer,
    pubAnalysisSource: pubAnalysisSourceReducer,
    pubAnalysisSourceBreakdown: pubAnalysisSourceBreakdownReducer,
    pubAnalysisDigital: pubAnalysisDigitalReducer,
    pubAnalysisDigitalBreakdown: pubAnalysisDigitalBreakdownReducer,
    pubOverview: pubOverviewReducer,
    pubClients: pubClientsReducer,
    pubContacts: pubContactsReducer,
    pubStatements: pubStatementsReducer,
    pubTaxDocuments: pubTaxDocumentsReducer,
    pubSync: pubSyncReducer,
    pubRegistration: pubRegistrationReducer,
    contactUsGuest: contactUsGuestReducer,
    contactUs: contactUsReducer,
    userLogin: loginReducer,
    userRegistration: userRegistrationReducer,
    userForgotUsername: userForgotUsernameReducer,
    userForgotPassword: userForgotPasswordReducer,
    userResetPassword: userResetPasswordReducer,
    recRoot: recRootReducer,
    recDashboard: recDashboardReducer,
    recAnalysis: recAnalysisReducer,
    recAnalysisHeader: recAnalysisHeaderReducer,
    recAnalysisTrack: recAnalysisTrackReducer,
    recAnalysisTrackBreakdown: recAnalysisTrackBreakdownReducer,
    recAnalysisAlbumBreakdown: recAnalysisAlbumBreakdownReducer,
    recAnalysisAlbum: recAnalysisAlbumReducer,
    recAnalysisTerritory: recAnalysisTerritoryReducer,
    recAnalysisDigital: recAnalysisDigitalReducer,
    recAnalysisDigitalBreakdown: recAnalysisDigitalBreakdownReducer,
    recAnalysisSource: recAnalysisSourceReducer,
    recAnalysisSourceBreakdown: recAnalysisSourceBreakdownReducer,
    recAnalysisChannelBreakdown: recAnalysisChannelBreakdownReducer,
    recOverview: recOverviewReducer,
    recStatements: recStatementsReducer,
    recTaxDocuments: recTaxDocumentsReducer,
    recContacts: recContactsReducer,
    recSync: recSyncReducer,
    recDigitalFlash: recDigitalFlashReducer,
    mecRoot: mecRootReducer,
    mecDashboard: mecDashboardReducer,
    mecStatements: mecStatementsReducer,
    mecContacts: mecContactsReducer,
    periods: periodsReducer,
    maintenance: maintenanceReducer,
    advanceRequest: advanceRequestReducer,
    clientCommunication: clientCommunicationReducer,
    emailFooters: emailFootersSlice,
    elasticsearch: elasticsearchSlice,
    misc: miscSlice,
    adminGroups: adminGroupsReducer,
    addEditAdminGroup: addEditAdminGroupReducer,
    news: newsReducer,
    addEditNews: addEditNewsReducer,
    clientsPage: clientsPageReducer,
    activityLog: activityLogReducer,
    requestArchive: requestArchiveReducer,
    requestArchiveEdit: requestArchiveEditReducer,
    morRoot: morRootReducer,
    morHelp: morHelpReducer,
    morContacts: morContactsReducer,
    pendingRequest: pendingRequestReducer,
    systemClosingDates: systemClosingDatesReducer,
    closingDateDetails: closingDateDetailsReducer,
    adminAccounts: adminAccountsReducer,
    adminAccountsEdit: adminAccountsEditReducer,
    settings: settingsReducer,
    newsGroups: newsGroupsReducer,
    newsUsers: newsUsersReducer,
    users: usersReducer,
    groups: groupsReducer,
    addEditGroup: addEditGroupReducer,
    parents: parentsReducer,
    bulkImportUserAccounts: bulkImportUserAccountsReducer,
    addEditUsers: addEditUsersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
