import React from "react";
function CrossIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="100%"
      height="100%"
      name="CrossIcon"
      {...props}
    >
      <path
        d="m18.83 16 8.59-8.59a2 2 0 0 0-2.83-2.83L16 13.17 7.41 4.59a2 2 0 0 0-2.82 2.82L13.17 16l-8.58 8.59a2 2 0 1 0 2.83 2.83L16 18.83l8.59 8.59a2 2 0 0 0 2.83-2.83Z"
        data-name="Layer 57"
      />
    </svg>
  );
}

export default CrossIcon;
