import { useEffect, useCallback, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import type {
  RecAnalysisAlbumByTrendEntry,
  RecAnalysisAlbumByTrendData,
} from "../../../../features/recording/analysis/album/recAnalysisAlbumBreakdownAPI";
import styles from "./../analysis.module.scss";

const areaChartOptions = {
  chart: {
    type: "areaspline",
    zooming: {
      type: "XY",
    },
    scrollablePlotArea: {
      minWidth: 500,
      scrollPositionX: 1,
    },
  },
  title: {
    text: "",
  },
  xAxis: {
    categories: [""],
    opposite: true,
    lineWidth: 0,
    gridLineWidth: 1,
    gridLineColor: "#f2f2f2",
    labels: {
      style: {
        color: "#666",
      },
    },
  },
  yAxis: {
    title: {
      text: "",
    },
    lineWidth: 0,
    gridLineWidth: 1,
    gridLineColor: "#f2f2f2",
    labels: {
      style: {
        color: "#666",
      },
    },
  },
  plotOptions: {
    areaspline: {
      marker: {
        fillColor: "#50E3C2",
        lineColor: "#f2f2f2",
        lineWidth: 1,
      },
      fillColor: "#e0faf3",
      lineColor: "#50E3C2",
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 2,
        },
      },
    },
  },
  tooltip: {
    formatter: function (): string {
      const x = (this as any)?.x;
      const y = parseFloat((this as any)?.y).toFixed(2);
      return `${x}<br>${y}`;
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      data: [],
    },
  ],
};

const AnalysisAlbumBreakdownTrend = ({
  data,
  status,
}: {
  data: RecAnalysisAlbumByTrendData | null;
  status: "noData" | "loading" | "idle" | "failed";
}): JSX.Element => {
  const { t } = useTranslation();

  const [areaChartData, setAreaChartData] = useState<object | null>(null);

  const getAreaChartData = useCallback(() => {
    if (data?.royaltyTrendsData.royaltiesPerPeriodOrYear.length) {
      const areaChartSeriesData: Array<never> = [];
      const areaChartCategories: any[] = [];

      data?.royaltyTrendsData.royaltiesPerPeriodOrYear?.forEach(
        (periodData: RecAnalysisAlbumByTrendEntry) => {
          areaChartCategories.push(periodData.periodOrYear);
          areaChartSeriesData.push(periodData.royalty.raw as never);
        }
      );
      areaChartOptions.xAxis.categories = areaChartCategories;
      areaChartOptions.series[0].data = areaChartSeriesData;
      setAreaChartData(areaChartOptions);
    }
  }, [data]);

  useEffect(() => {
    if (areaChartData === null) getAreaChartData();
  }, [areaChartData, getAreaChartData]);

  if (status === "loading")
    return <p className="pl-2 pt-4">{t("app.loading")}</p>;

  if (!data) {
    if (status === "idle")
      return (
        <p className="pl-2 pt-4">{t("analysis.songs.breakdown.noData")}</p>
      );
    else return <p className="pl-2 pt-4">{t("app.loading")}</p>;
  }

  return (
    <div className={`smallScrollbar ${styles.trendStatsContainer}`}>
      <HighchartsReact highcharts={Highcharts} options={areaChartData} />
    </div>
  );
};

export default AnalysisAlbumBreakdownTrend;
