import React from "react";
function SyncIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="-2 0 22 22"
      fill="none"
      strokeWidth={0}
      name="SyncIcon"
      {...props}
    >
      <path
        fillRule="evenodd"
        strokeWidth=".75"
        d="M.845 11.538 11.614.094 9.223 10.649h5.045L3.499 22.094 5.89 11.538z"
      />
    </svg>
  );
}

export default SyncIcon;
