import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import {
  getJobs,
  getRunningJobs,
  triggerJob,
  cancelPublishingJobs,
  cancelRecordingJobs,
} from "./ElasticsearchAPI";
import { StatusEnum } from "../../../../app/types/enums";
import { useFetch } from "../../common/hooks/useFetch";
import { jobTypes } from "./Elasticsearch.const";

type Job = {
  job: string;
  status: string;
  startTime?: string;
  finishTime?: string;
  runtime?: string;
  totalProgress?: string;
};

export interface elasticsearchState {
  status: StatusEnum;
  jobs: {
    publishing: Job[];
    recording: Job[];
    mechanical: Job[];
    common: Job[];
  };
  runningJobs: any;
}

const initialState: elasticsearchState = {
  status: StatusEnum.Idle,
  jobs: {
    publishing: [],
    recording: [],
    mechanical: [],
    common: [],
  },
  runningJobs: {},
};

const prepareJobs = (data: Job[]) => {
  return data.reduce(
    (accumulator: any, job: Job) => {
      accumulator[jobTypes[job.job]]?.push({
        job: job.job,
        status: job.status,
        startTime: job.startTime,
        finishTime: job.finishTime,
        runtime: job.runtime,
        totalProgress: job.totalProgress,
      });
      return accumulator;
    },
    {
      publishing: [],
      recording: [],
      mechanical: [],
      common: [],
    }
  );
};

const prepareRunningJobs = (data: Job[]) => {
  return data
    ? data.reduce((jobs, job) => {
        return {
          ...jobs,
          [job.job]: job,
        };
      }, {})
    : {};
};

export const getJobsThunk = createAsyncThunk(
  "admin/elasticsearch/getJobs",
  async (_, { rejectWithValue }) => {
    try {
      const getEsJobs = useFetch();
      const response = await getEsJobs({
        fetchFunction: getJobs,
      });
      return response?.resArr;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRunningJobsThunk = createAsyncThunk(
  "admin/elasticsearch/getRunningJobs",
  async () => {
    try {
      const response = await getRunningJobs();
      return response?.resArr;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
);

export const triggerJobThunk = createAsyncThunk(
  "admin/elasticsearch/triggerJob",
  async (payload: any, { rejectWithValue }) => {
    try {
      const triggerEsJob = useFetch();
      const response = await triggerEsJob({
        fetchFunction: triggerJob,
        data: payload,
        customSuccessMsg: "adminSection.elasticsearch.triggeredJobTitle",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const cancelPublishingJobsThunk = createAsyncThunk(
  "admin/elasticsearch/cancelPublishingJobs",
  async (_, { rejectWithValue }) => {
    try {
      const cancelEsPublishingJobs = useFetch();
      const response = await cancelEsPublishingJobs({
        fetchFunction: cancelPublishingJobs,
        customSuccessMsg: "adminSection.elasticsearch.cancelPublishingTitle",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const cancelRecordingJobsThunk = createAsyncThunk(
  "admin/elasticsearch/cancelRecordingJobs",
  async (_, { rejectWithValue }) => {
    try {
      const cancelEsRecordingJobs = useFetch();
      const response = await cancelEsRecordingJobs({
        fetchFunction: cancelRecordingJobs,
        customSuccessMsg: "adminSection.elasticsearch.cancelRecordingTitle",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const elasticsearchSlice = createSlice({
  name: "elasticsearch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getJobsThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
        state.jobs = prepareJobs(action.payload);
      })
      .addCase(getJobsThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(getJobsThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(getRunningJobsThunk.fulfilled, (state, action: any) => {
        state.runningJobs = prepareRunningJobs(action.payload);
      })
      .addCase(triggerJobThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(triggerJobThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(triggerJobThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(cancelPublishingJobsThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(cancelPublishingJobsThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(cancelPublishingJobsThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      })
      .addCase(cancelRecordingJobsThunk.fulfilled, (state, action: any) => {
        state.status = StatusEnum.Idle;
      })
      .addCase(cancelRecordingJobsThunk.pending, (state, action: any) => {
        state.status = StatusEnum.Loading;
      })
      .addCase(cancelRecordingJobsThunk.rejected, (state, action: any) => {
        state.status = StatusEnum.Failed;
      });
  },
});

export const elasticsearchSelector = (state: RootState) => state.elasticsearch;
export default elasticsearchSlice.reducer;
