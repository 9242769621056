import { USER_API_RESOURCE_BASE_URLS } from "../../../../app/config/userEndpoints.const";
import request from "../../../../app/utils/requestUtils";

export function FetchAnalysisSongBreakdown_Type(params: any, thunkAPI?: any) {
  const data = {
    clientIds: params.clientIds,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.songsType}?songId=${params.songId}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisSongBreakdown_Territory(
  params: any,
  thunkAPI?: any
) {
  const data = {
    clientIds: params.clientIds,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.songsTerritory}?songId=${params.songId}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisSongBreakdown_Source(params: any, thunkAPI?: any) {
  const data = {
    clientIds: params.clientIds,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.songsSource}?songId=${params.songId}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}

export function FetchAnalysisSongBreakdown_Details(
  params: any,
  thunkAPI?: any
) {
  const data = {
    clientIds: params.clientIds,
    periodIds: params.periodIds,
  };
  return request(
    `${USER_API_RESOURCE_BASE_URLS.songsDetails}?songId=${params.songId}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      signal: thunkAPI?.signal,
    }
  );
}
export function downloadSongBreakdownData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.analysisDownload}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
    isDownload: true,
    fileName,
  });
}
