import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import {
  FetchAnalysisChannelBreakdown_Territory,
  FetchAnalysisChannelBreakdown_Trend,
  FetchAnalysisChannelBreakdown_Products,
  downloadChannelBreakdownData,
} from "./recAnalysisChannelBreakdownAPI";

export type RecAnalysisChannelRoyaltyProps = {
  currency: string;
  raw: number;
  formattedShort: string;
  formattedLong: string;
  formattedPercent: string;
  percent: number;
};

export declare type RecAnalysisChannelProductsProps = {
  productKey: string;
  title: string;
  artist: string;
  configDesc: string;
  productCode: string;
  royalty: RecAnalysisChannelRoyaltyProps;
};

export declare type RecAnalysisChannelTerritoryProps = {
  name: string;
  iso: string;
  revenue: RecAnalysisChannelRoyaltyProps;
  zero: boolean;
};

export declare type RecAnalysisChannelTrendProps = {
  periodId: number;
  formattedPeriod: string;
  revenueData: RecAnalysisChannelRoyaltyProps;
};

export declare type RecAnalysisChannelBrekwownProps = {
  code: number;
  category: string;
};

export declare type RecAnalysisChannelProductsBreakdownProps =
  RecAnalysisChannelBrekwownProps & {
    resArr: Array<RecAnalysisChannelProductsProps>;
  };

export declare type RecAnalysisChannelTerritoryBreakdownProps =
  RecAnalysisChannelBrekwownProps & {
    resArr: Array<RecAnalysisChannelTerritoryProps>;
  };

export declare type RecAnalysisChannelTrendBreakdownProps =
  RecAnalysisChannelBrekwownProps & {
    resArr: Array<RecAnalysisChannelTrendProps>;
  };

export interface recAnalysisChannelBreakdownState {
  channelBreakdownTerritory: {
    dataArray: Array<RecAnalysisChannelTerritoryBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  channelBreakdownTrend: {
    dataArray: Array<RecAnalysisChannelTrendBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  channelBreakdownProducts: {
    dataArray: Array<RecAnalysisChannelProductsBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  channelBreakdownDownloadStatus: "idle" | "loading" | "failed";
}

const initialState: recAnalysisChannelBreakdownState = {
  channelBreakdownTerritory: { dataArray: [], status: "noData" },
  channelBreakdownTrend: { dataArray: [], status: "noData" },
  channelBreakdownProducts: { dataArray: [], status: "noData" },
  channelBreakdownDownloadStatus: "idle",
};

export const FetchRecAnalysisChannelBreakdown_Territory_Thunk =
  createAsyncThunk(
    "recording/FetchRecAnalysisChannelBreakdown_Territory",
    async (params: any, thunkAPI) => {
      return await FetchAnalysisChannelBreakdown_Territory(params, thunkAPI);
    }
  );

export const FetchRecAnalysisChannelBreakdown_Trend_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisChannelBreakdown_Trend",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisChannelBreakdown_Trend(params, thunkAPI);
  }
);

export const FetchRecAnalysisChannelBreakdown_Products_Thunk = createAsyncThunk(
  "recording/FetchRecAnalysisChannelBreakdown_Products",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisChannelBreakdown_Products(params, thunkAPI);
  }
);

export const recAnalysisChannelBreakdown_Download_Thunk = createAsyncThunk(
  "recAnalysis/breakdown/downloadChallen",
  async (payload: any, thunkAPI) => {
    const response = await downloadChannelBreakdownData(payload, thunkAPI);
    return response;
  }
);

export const FindRecAnalysisChannelBreakdown = (
  storedBreakdowns: Array<any>,
  findCode: number,
  findCategory: string
) => {
  const breakdown = storedBreakdowns.find(
    (breakdown) =>
      breakdown.code === findCode && breakdown.category === findCategory
  );
  return breakdown || null;
};

export const recAnalysisChannelBreakdownSlice = createSlice({
  name: "recAnalysisChannelBreakdown",
  initialState,
  reducers: {
    // territory
    clearTerritoryBreakdownData: (state, action: any) => {
      state.channelBreakdownTerritory.dataArray = [];
      state.channelBreakdownTerritory.status = "noData";
    },
    // trend
    clearTrendBreakdownData: (state, action: any) => {
      state.channelBreakdownTrend.dataArray = [];
      state.channelBreakdownTrend.status = "noData";
    },
    // products
    clearProductsBreakdownData: (state, action: any) => {
      state.channelBreakdownProducts.dataArray = [];
      state.channelBreakdownProducts.status = "noData";
    },
  },
  extraReducers: (builder) => {
    builder
      // territory
      .addCase(
        FetchRecAnalysisChannelBreakdown_Territory_Thunk.fulfilled,
        (state, action) => {
          const territoryData = action.payload;
          territoryData.code = action.meta.arg.code;
          territoryData.category = action.meta.arg.category;
          state.channelBreakdownTerritory.dataArray.push(territoryData);
          state.channelBreakdownTerritory.status = "idle";
        }
      )
      .addCase(
        FetchRecAnalysisChannelBreakdown_Territory_Thunk.pending,
        (state) => {
          state.channelBreakdownTerritory.status = "loading";
        }
      )
      .addCase(
        FetchRecAnalysisChannelBreakdown_Territory_Thunk.rejected,
        (state) => {
          state.channelBreakdownTerritory.status = "failed";
        }
      )
      // trend
      .addCase(
        FetchRecAnalysisChannelBreakdown_Trend_Thunk.fulfilled,
        (state, action) => {
          const trendData = action.payload;
          trendData.code = action.meta.arg.code;
          trendData.category = action.meta.arg.category;
          state.channelBreakdownTrend.dataArray.push(trendData);
          state.channelBreakdownTrend.status = "idle";
        }
      )
      .addCase(
        FetchRecAnalysisChannelBreakdown_Trend_Thunk.pending,
        (state) => {
          state.channelBreakdownTrend.status = "loading";
        }
      )
      .addCase(
        FetchRecAnalysisChannelBreakdown_Trend_Thunk.rejected,
        (state) => {
          state.channelBreakdownTrend.status = "failed";
        }
      )
      // products
      .addCase(
        FetchRecAnalysisChannelBreakdown_Products_Thunk.fulfilled,
        (state, action) => {
          const productsData = action.payload;
          productsData.code = action.meta.arg.code;
          productsData.category = action.meta.arg.category;
          state.channelBreakdownProducts.dataArray.push(productsData);
          state.channelBreakdownProducts.status = "idle";
        }
      )
      .addCase(
        FetchRecAnalysisChannelBreakdown_Products_Thunk.pending,
        (state) => {
          state.channelBreakdownProducts.status = "loading";
        }
      )
      .addCase(
        FetchRecAnalysisChannelBreakdown_Products_Thunk.rejected,
        (state) => {
          state.channelBreakdownProducts.status = "failed";
        }
      )
      // download
      .addCase(
        recAnalysisChannelBreakdown_Download_Thunk.fulfilled,
        (state) => {
          state.channelBreakdownDownloadStatus = "idle";
        }
      )
      .addCase(recAnalysisChannelBreakdown_Download_Thunk.pending, (state) => {
        state.channelBreakdownDownloadStatus = "loading";
      })
      .addCase(recAnalysisChannelBreakdown_Download_Thunk.rejected, (state) => {
        state.channelBreakdownDownloadStatus = "failed";
      });
  },
});

export const recAnalysisChannelBreakdownTerritorySelector = (
  state: RootState
) => state.recAnalysisChannelBreakdown.channelBreakdownTerritory;
export const recAnalysisChannelBreakdownTrendSelector = (state: RootState) =>
  state.recAnalysisChannelBreakdown.channelBreakdownTrend;
export const recAnalysisChannelBreakdownProductsSelector = (state: RootState) =>
  state.recAnalysisChannelBreakdown.channelBreakdownProducts;
export const recAnalysisChannelBreakdownDownloadStatusSelector = (
  state: RootState
) => state.recAnalysisChannelBreakdown.channelBreakdownDownloadStatus;

export const {
  clearTerritoryBreakdownData: clearTerritoryBreakdownDataAction,
  clearTrendBreakdownData: clearTrendBreakdownDataAction,
  clearProductsBreakdownData: clearProductsBreakdownDataAction,
} = recAnalysisChannelBreakdownSlice.actions;

export default recAnalysisChannelBreakdownSlice.reducer;
