import { USER_API_RESOURCE_BASE_URLS } from "../../../app/config/userEndpoints.const";
import request from "../../../app/utils/requestUtils";

export function fetchDashboardStats(params: any, thunkAPI?: any) {
  return request(
    `${USER_API_RESOURCE_BASE_URLS.stats}?clientDocumentType=MECHANICAL`,
    {
      method: "POST",
      body: JSON.stringify(params?.data),
      signal: thunkAPI?.signal,
    }
  );
}

export function fetchDashboardOverview(params: any, thunkAPI?: any) {
  return request(
    `${USER_API_RESOURCE_BASE_URLS.mechanicalOverview}?clientDocumentType=MECHANICAL`,
    {
      method: "POST",
      body: JSON.stringify(params.data),
      signal: thunkAPI?.signal,
    }
  );
}

export function downloadOverviewData(params: any, thunkAPI?: any) {
  const { data, fileName } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.mechanicalOverviewDownload}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
    isDownload: true,
    fileName,
  });
}
