import { USER_API_RESOURCE_BASE_URLS } from "../../config/userEndpoints.const";
import request from "../../utils/requestUtils";

export function fetchPeriods(params: any, thunkAPI?: any) {
  const { data } = params;
  return request(`${USER_API_RESOURCE_BASE_URLS.periods}`, {
    method: "POST",
    body: JSON.stringify(data),
    signal: thunkAPI?.signal,
  });
}
